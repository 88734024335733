import { Col, Modal } from 'antd'
import React from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import EnrolledPatients from './EnrolledPatients'
import EnrollmentSearch from './EnrollmentSearch.js'
import useCarePlanEnrollment from './hooks/useCarePlanEnrollment.js'

const CarePlanEnrollment = ({ template, setCurrentTemplate }) => {
  const {
    enrolledUsers,
    handleFetchEnrolledUsers,
    handleNewEnrollment,
    handleCancelEnrollment,
    loadingPatients,
    currentPatient,
    setCurrentPatient,
    newEnrollment,
    setNewEnrollment
  } = useCarePlanEnrollment({ template })

  return (
    <CustomRow>
      <Col span={24}>
        <EnrolledPatients
          handleCancelEnrollment={handleCancelEnrollment}
          currentPatient={currentPatient}
          setCurrentPatient={setCurrentPatient}
          loadingPatients={loadingPatients}
          handleFetchEnrolledUsers={handleFetchEnrolledUsers}
          setCurrentTemplate={setCurrentTemplate}
          setNewEnrollment={setNewEnrollment}
          enrolledUsers={enrolledUsers}
          template={template}
          handleRefresh={handleFetchEnrolledUsers}
        />
      </Col>
      <Modal width={1920} destroyOnClose open={newEnrollment} footer={false} maskClosable={false} closable={false}>
        <EnrollmentSearch setModalOpen={setNewEnrollment} template={template} onAfterSuccess={handleNewEnrollment} />
      </Modal>
    </CustomRow>
  )
}

export default CarePlanEnrollment
