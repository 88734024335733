import React, { useState } from 'react'
import '../styling/CustomTabs.css'

const CustomTabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr', paddingBottom: 16 }}>
      <div className='custom-tabs-header'>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`custom-tab ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div style={{ height: '100%', overflow: 'auto' }}>{tabs[activeTab].children}</div>
    </div>
  )
}

export default CustomTabs
