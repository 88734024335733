import Agilite from 'agilite'
import { handleError } from '../../../../lib/utils'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiI2NjU0M2RkODc1OTA0NTAwMDg4NGQ4ZWQiLCJ0ZWFtSWQiOiJtZXRhaGVhbHRoX3NhbmRib3giLCJlbnRpdHlJZCI6bnVsbCwidGltZXN0YW1wIjoiMjAyNC0wNS0yOFQwODowMTo1Ni45MzNaIn0.873KJG77s6m9-KAQl3gGqX_9MBkLO-o41Vm82Z2fZxc'
})
export const uploadFileTEMP = (fileName, contentType, file, persistFile = false, isPublic) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Files.uploadFile(fileName, contentType, file, persistFile, isPublic)
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}
