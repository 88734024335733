import { useCallback } from 'react'
import { message } from 'antd'
import dayjs from 'dayjs'
import { createClinicDataRecords } from '../../../../examination-utils/examination-lib'
import { handleError } from '../../../../../lib/utils'

export const useFormHandling = ({
  availableDataPoints,
  agiliteUser,
  bookingData,
  userRef,
  websocketConnection,
  handleRefresh,
  onAfterSuccess,
  resetForm
}) => {
  const handleDataCapture = useCallback(
    async (formData) => {
      if (!formData || !Object.keys(formData).length) {
        message.error('No data to submit')
        return
      }

      const timestamp = dayjs()

      try {
        const dataRecordPromises = Object.entries(formData).map(([key, value]) => {
          const dataPoint = availableDataPoints.find((i) => i.key === key)
          const category = dataPoint?.category || 'standard'
          const baseData = {
            dateCreated: timestamp,
            createdBy: agiliteUser?._id,
            userRef: bookingData?.userRef || userRef,
            bookingRef: bookingData?._id || '',
            origin: 'booking',
            category,
            key
          }

          if (value && typeof value === 'object') {
            const subFields = Object.entries(value).map(([subKey, subValue]) => ({
              key: subKey,
              value: subValue
            }))
            return createClinicDataRecords({
              ...baseData,
              value: subFields.length > 0 ? subFields : value
            })
          }

          return createClinicDataRecords({
            ...baseData,
            value
          })
        })

        const submittedData = await Promise.all(dataRecordPromises)

        if (bookingData?._id && websocketConnection?.isConnected) {
          websocketConnection.socket.send(
            JSON.stringify({
              bookingRef: bookingData._id,
              data: submittedData
            })
          )
        } else if (handleRefresh) {
          handleRefresh()
        }

        if (onAfterSuccess) {
          onAfterSuccess(submittedData)
        } else {
          resetForm()
        }

        message.success('Data captured successfully')
        return submittedData
      } catch (err) {
        message.error(handleError(err))
        throw err
      }
    },
    [
      availableDataPoints,
      agiliteUser,
      bookingData,
      userRef,
      websocketConnection,
      handleRefresh,
      onAfterSuccess,
      resetForm
    ]
  )

  return { handleDataCapture }
}
