const examTemplatesState = {
  data: [],
  loading: false,
  error: null
}

const dataPointsState = {
  data: [],
  loading: false,
  error: null
}

export { examTemplatesState, dataPointsState }
