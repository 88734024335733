import React, { useMemo } from 'react'
import { Tabs, Button, Form } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { getTabItems } from '../utils/getTabITems'

const TabsHeader = ({
  activeTab,
  onTabChange,
  isAddingTemplate,
  isSubmitting,
  onCancel,
  onSubmit,
  token,
  getUnfilledCount,
  newTemplateName,
  selectedDataPoints,
  form
}) => {
  // Watch all form fields for changes
  const formValues = Form.useWatch([], form)

  const tabItems = useMemo(
    () =>
      getTabItems({
        token,
        getUnfilledCount: (category) => getUnfilledCount(category, selectedDataPoints, form),
        isAddingTemplate
      }),
    // eslint-disable-next-line
    [token, getUnfilledCount, isAddingTemplate, selectedDataPoints, formValues] // Use formValues instead of form.getFieldsValue()
  )

  return (
    <Tabs
      style={{ marginTop: 48 }}
      defaultActiveKey='templates'
      items={tabItems}
      activeKey={activeTab}
      onChange={onTabChange}
      tabBarExtraContent={{
        left: (
          <Button onClick={onCancel} disabled={isSubmitting} style={{ marginRight: 12 }}>
            <FontAwesomeIcon icon={faXmark} color={token.colorBorder} /> {isAddingTemplate ? 'Cancel' : 'Close'}
          </Button>
        ),
        right: (
          <Button
            onClick={onSubmit}
            loading={isSubmitting}
            style={{ background: token.colorSuccess, marginLeft: 12 }}
            disabled={
              isAddingTemplate ? !newTemplateName || selectedDataPoints.length === 0 : selectedDataPoints.length === 0
            }
          >
            {isAddingTemplate ? 'Save Template' : 'Submit Readings'}
          </Button>
        )
      }}
    />
  )
}

export default TabsHeader
