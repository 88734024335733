import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CustomRow from '../../../reusable-components/CustomRow'
import { Button, Col, message, Modal, Space } from 'antd'
import SickNoteWrapper from './Sick Notes/SickNoteWrapper'
import { DocumentViewer } from '../../../reusable-components/DocumentViewer'
import { useDocuments } from './hooks/useDocuments'
import { MODAL_STATES } from './utils/constants'
import DocumentsTable from './components/DocumentsTable'
import DocumentActions from './components/DocumentActions'
import DocumentSelection from './components/Selection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faExclamation, faExclamationCircle, faMailForward } from '@fortawesome/free-solid-svg-icons'
import RefferalLetterCapture from './Referral letters/ReferralLetterCapture.js'
import { handleError } from '../../../lib/utils.js'
import { readReferralLetter, readSickNotes } from '../../examination-utils/examination-lib.js'
import ReferralLetterDisplay from '../referral-letter-display.js'
import SickNoteCapture from '../sick-note-capture.js'
import SickNoteDisplay from '../sick-note-display.js'

const DocumentsWrapper = ({ bookingData, userRef, setBookingData, noBooking }) => {
  const {
    documents,
    setDocuments,
    handleReadDocuments,
    loadingDocuments,
    selectedDocument,
    setSelectedDocument,
    selectionRequired,
    setSelectionRequired,
    toggleModalState,
    modalState,
    handleModalReset,
    searchQuery,
    setSearchQuery,
    dateRange,
    setDateRange
  } = useDocuments({ userRef: userRef ? userRef : bookingData.userRef })

  const [viewOldSickNotes, setViewOldSickNotes] = useState(false)
  const [viewOldReferralLetters, setViewOldReferralLetters] = useState(false)

  const handleSelection = useCallback(
    (documentType) => {
      toggleModalState(documentType)
    },
    // eslint-disable-next-line
    [setSelectionRequired, toggleModalState]
  )

  const handleAfterSuccess = (type, newRecord) => {
    const tmpDocuments = [...documents]
    tmpDocuments.unshift(newRecord)
    setDocuments(tmpDocuments)
    handleModalReset()
    setSelectionRequired(false)
    setSelectedDocument(newRecord.value)
  }

  return (
    <>
      {selectedDocument || selectionRequired ? null : (
        <CustomRow style={{ marginTop: 12 }}>
          <Col span={24}>
            <Space>
              <Button type='primary' onClick={() => setViewOldSickNotes(true)}>
                <FontAwesomeIcon icon={faExclamationCircle} /> View Old Sick Notes v2.4.0
              </Button>
              <Button type='primary' onClick={() => setViewOldReferralLetters(true)}>
                <FontAwesomeIcon icon={faExclamationCircle} /> View Old Referral Letters v2.4.0
              </Button>
            </Space>
          </Col>
        </CustomRow>
      )}
      <CustomRow gutter={selectedDocument ? [0, 0] : [12, 12]} style={{ marginTop: selectedDocument ? 0 : 12 }}>
        {selectedDocument ? (
          <>
            <div
              style={{
                display: 'flex',
                gap: 8,
                padding: 12,
                background: '#323639',
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <Button onClick={() => setSelectedDocument(null)}>
                <FontAwesomeIcon icon={faCaretLeft} /> Back
              </Button>
              {/* <Button>
                Email <FontAwesomeIcon icon={faMailForward} />
              </Button> */}
            </div>
            <DocumentViewer base64={selectedDocument} />
          </>
        ) : (
          <>
            {Object.values(modalState).some(Boolean) ? (
              <>
                <Col span={24}>
                  <Button onClick={() => handleModalReset()}>
                    <FontAwesomeIcon icon={faCaretLeft} /> Back
                  </Button>
                </Col>
                {modalState[MODAL_STATES.REFERRAL] && (
                  <Col span={24}>
                    <RefferalLetterCapture
                      handleAfterSuccess={handleAfterSuccess}
                      setModalOpen={() => toggleModalState(MODAL_STATES.REFERRAL)}
                      bookingData={bookingData}
                    />
                  </Col>
                )}
                {modalState[MODAL_STATES.SICK_NOTE] && (
                  <Col span={24}>
                    <SickNoteWrapper handleAfterSuccess={handleAfterSuccess} bookingData={bookingData} />
                  </Col>
                )}
                {modalState[MODAL_STATES.PDF_VIEWER] && <DocumentViewer base64={selectedDocument} />}
              </>
            ) : (
              <>
                {selectionRequired ? (
                  <>
                    <Col span={24}>
                      <Button onClick={() => setSelectionRequired(false)}>
                        <FontAwesomeIcon icon={faCaretLeft} /> Back
                      </Button>
                    </Col>
                    <Col span={24}>
                      <DocumentSelection handleSelection={handleSelection} />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col span={24}>
                      <DocumentActions
                        handleReadDocuments={handleReadDocuments}
                        loadingDocuments={loadingDocuments}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        setSelectionRequired={setSelectionRequired}
                      />
                    </Col>
                    <Col span={24}>
                      <DocumentsTable
                        documents={documents}
                        loadingDocuments={loadingDocuments}
                        setSelectedDocument={setSelectedDocument}
                      />
                    </Col>
                  </>
                )}
              </>
            )}
          </>
        )}
      </CustomRow>
      <Modal
        open={viewOldSickNotes || viewOldReferralLetters}
        onCancel={() => {
          setViewOldSickNotes(false)
          setViewOldReferralLetters(false)
        }}
        footer={null}
      >
        {viewOldSickNotes && <SickNoteDisplay bookingData={bookingData} userRef={userRef} />}
        {viewOldReferralLetters && <ReferralLetterDisplay bookingData={bookingData} userRef={userRef} />}
      </Modal>
    </>
  )
}

DocumentsWrapper.propTypes = {
  bookingData: PropTypes.object.isRequired,
  setBookingData: PropTypes.func.isRequired,
  noBooking: PropTypes.bool
}

export default React.memo(DocumentsWrapper)
