import React, { useEffect, useState } from 'react'
import { readCareSyncLabTests } from '../../../Super-Admin/CareSync/LabTests/utils/utils'
import { handleError } from '../../../lib/utils'
import { Alert, message, Space, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'

const CareSyncTests = ({ requiredTests }) => {
  console.log('REQUIRED TESTS', requiredTests)
  const [loading, setLoading] = useState(false)
  const [careSyncTests, setCareSyncTests] = useState([])
  useEffect(() => {
    handleFetchCareSyncTests()
  }, [])

  const handleFetchCareSyncTests = async () => {
    try {
      setLoading(true)
      const tmpData = await readCareSyncLabTests()
      // Show alert for required tests
      console.log('TMP DATA', tmpData)
      const tmpTests = tmpData.filter((test) => requiredTests.includes(test.key))
      console.log('REQUIRED TESTS', tmpTests)
      if (requiredTests.length > 0) {
        setCareSyncTests(tmpTests)
      }
    } catch (error) {
      message.error(handleError(error))
    } finally {
      setLoading(false)
    }
  }
  console.log(careSyncTests)
  return (
    <div style={{ marginTop: 12 }}>
      {loading ? (
        <AgiliteSkeleton skActive spinnerTip='Loading required tests...' />
      ) : (
        <Alert
          message={
            <Space direction='vertical'>
              Required Tests:
              <Space>
                {careSyncTests.map((test) => (
                  <Tag key={test.id}>
                    <FontAwesomeIcon icon={faExclamationTriangle} color='goldenrod' /> {test.name}
                  </Tag>
                ))}
              </Space>
            </Space>
          }
        />
      )}
    </div>
  )
}

export default CareSyncTests
