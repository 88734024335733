import React from 'react'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

const TemplateList = ({
  dataTemplates,
  searchTerm,
  activeTemplate,
  token,
  onAddTemplate,
  onSelectTemplate,
  onSelectCustomTemplate
}) => {
  return (
    <div style={{ padding: 10, marginTop: 36, display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Button onClick={onAddTemplate} style={{ justifyContent: 'space-between' }}>
        Add Template
        <FontAwesomeIcon icon={faPlusCircle} color={token.colorSuccess} />
      </Button>

      <Button
        type={activeTemplate === null ? 'primary' : 'default'}
        onClick={onSelectCustomTemplate}
        style={{ justifyContent: 'space-between' }}
      >
        Custom Template
        {activeTemplate === null && <FontAwesomeIcon icon={faCheckCircle} />}
      </Button>

      {dataTemplates
        .filter(
          (template) =>
            activeTemplate?.name === template.name || template.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map((template) => (
          <Button
            key={template.name}
            type={activeTemplate?.name === template.name ? 'primary' : 'default'}
            onClick={() => onSelectTemplate(template)}
            style={{ justifyContent: 'space-between' }}
          >
            {template.name}
            {activeTemplate?.name === template.name && <FontAwesomeIcon icon={faCheckCircle} />}
          </Button>
        ))}
    </div>
  )
}

export default React.memo(TemplateList)
