import React, { useState } from 'react'
import { Form, Input, InputNumber, Select, Button } from 'antd'
import './TestSetup.css'

const TestSetup = ({ onSave, initialData }) => {
  const [form] = Form.useForm()

  const handleSubmit = (values) => {
    onSave({
      ...values,
      price: values.price * 100, // Convert to cents
    })
  }

  return (
    <div className="cs-test-setup">
      <h1 className="cs-test-setup__title">Test Configuration</h1>

      <Form
        form={form}
        layout="vertical"
        initialValues={initialData}
        onFinish={handleSubmit}
        className="cs-test-setup__form"
      >
        <Form.Item
          name="name"
          label="Test Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="benefits"
          label="Benefits"
          rules={[{ required: true }]}
        >
          <Select mode="tags" />
        </Form.Item>

        <Form.Item
          name="preparation"
          label="Preparation Instructions"
          rules={[{ required: true }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="specimen"
          label="Specimen Type"
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value="blood">Blood</Select.Option>
            <Select.Option value="urine">Urine</Select.Option>
            <Select.Option value="saliva">Saliva</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="cycleTime"
          label="Processing Time"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="price"
          label="Price (R)"
          rules={[{ required: true }]}
        >
          <InputNumber 
            min={0}
            precision={2}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Test
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default TestSetup 