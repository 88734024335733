import React from 'react'
import { Typography, Button, Tooltip, Card } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faShare, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import '../../../../styling/ResultListItem.css'

const { Text } = Typography

const ResultListItem = ({ result, dataPoint, onViewDetail }) => {
  return (
    <Card className='cs-result-item__card' bordered={false}>
      <div className='cs-result-item__content'>
        <div className='cs-result-item__header'>
          <Text strong className='cs-result-item__title'>
            {dataPoint?.label}
          </Text>
        </div>

        <div className='cs-result-item__actions'>
          <Tooltip title='Download PDF'>
            <Button
              type='text'
              className='cs-result-item__action-btn'
              icon={<FontAwesomeIcon icon={faDownload} />}
              onClick={(e) => {
                e.stopPropagation()
                // TODO: Implement PDF download
              }}
            />
          </Tooltip>
          <Tooltip title='Share'>
            <Button
              type='text'
              className='cs-result-item__action-btn'
              icon={<FontAwesomeIcon icon={faShare} />}
              onClick={(e) => {
                e.stopPropagation()
                // TODO: Implement sharing
              }}
            />
          </Tooltip>
          <Button type='link' className='cs-result-item__detail-btn' onClick={() => onViewDetail(result)}>
            View <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </div>

        <div className='cs-result-item__info'>
          <div className='cs-result-item__metrics'>
            <Text className='cs-result-item__current-value'>
              {result.value} {dataPoint?.suffix}
            </Text>
          </div>
          <Text type='secondary' className='cs-result-item__date'>
            {new Date(result.dateCreated).toLocaleDateString()}
          </Text>
        </div>
      </div>
    </Card>
  )
}

export default ResultListItem
