import React, { useState, useEffect } from 'react'
import { Card } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faAngleLeft, 
  faAngleRight,
  faCamera,
  faSun,
  faHandPointer,
  faRuler,
  faClockRotateLeft,
  faTableCells,
  faLeaf
} from '@fortawesome/free-solid-svg-icons'
import '../style/PhotoTips.css'

const tips = [
  {
    icon: faCamera,
    title: 'Overhead Angle',
    description: 'Hold your phone directly above the food for the best view',
    animation: 'camera-tilt'
  },
  {
    icon: faSun,
    title: 'Good Lighting',
    description: 'Natural light works best - avoid harsh shadows',
    animation: 'light-pulse'
  },
  {
    icon: faHandPointer,
    title: 'Frame It Right',
    description: 'Include the whole plate in your shot',
    animation: 'frame-zoom'
  },
  {
    icon: faRuler,
    title: 'Distance Matters',
    description: 'Keep phone 12-18 inches above the food for best results',
    animation: 'measure-bounce'
  },
  {
    icon: faClockRotateLeft,
    title: 'Quick Capture',
    description: 'Take the photo right away while food is fresh',
    animation: 'clock-spin'
  },
  {
    icon: faTableCells,
    title: 'Clean Background',
    description: 'Use a plain surface or clean tablecloth',
    animation: 'background-fade'
  },
  {
    icon: faLeaf,
    title: 'Garnish Placement',
    description: 'Keep garnishes visible but not covering main items',
    animation: 'leaf-float'
  }
]

const PhotoTips = () => {
  const [currentTip, setCurrentTip] = useState(0)
  const [isPaused, setIsPaused] = useState(false)

  // Auto-cycle tips every 6 seconds
  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setCurrentTip((prev) => (prev === tips.length - 1 ? 0 : prev + 1))
      }, 6000)  // 6 seconds

      return () => clearInterval(interval)
    }
  }, [isPaused])

  const handleTouchStart = () => {
    setIsPaused(true)  // Pause auto-cycle on touch
  }

  const handleTouchEnd = () => {
    setIsPaused(false)  // Resume auto-cycle after touch
  }

  const nextTip = () => {
    setCurrentTip((prev) => (prev === tips.length - 1 ? 0 : prev + 1))
    setIsPaused(true)
    setTimeout(() => setIsPaused(false), 6000)  // Changed from 8000 to 6000
  }

  const prevTip = () => {
    setCurrentTip((prev) => (prev === 0 ? tips.length - 1 : prev - 1))
    setIsPaused(true)
    setTimeout(() => setIsPaused(false), 6000)  // Changed from 8000 to 6000
  }

  return (
    <div className='photo-tips-container'>
      <Card className='photo-tip-card'>
        <div 
          className='tip-content'
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <div className='navigation-button left' onClick={prevTip}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          
          <div className='tip-main'>
            <div className={`tip-icon ${tips[currentTip].animation}`}>
              <FontAwesomeIcon icon={tips[currentTip].icon} />
            </div>
            <h3>{tips[currentTip].title}</h3>
            <p>{tips[currentTip].description}</p>
          </div>

          <div className='navigation-button right' onClick={nextTip}>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>

        <div className='progress-dots'>
          {tips.map((_, index) => (
            <div 
              key={index}
              className={`dot ${index === currentTip ? 'active' : ''}`}
              onClick={() => {
                setCurrentTip(index)
                setIsPaused(true)
                setTimeout(() => setIsPaused(false), 6000)  // Changed from 8000 to 6000
              }}
            />
          ))}
        </div>
      </Card>
    </div>
  )
}

export default PhotoTips