import React from 'react'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReceipt, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import styles from './BillingSummary.module.css'

const BillingSummary = ({ steps, currentStep }) => {
  const calculateTotals = () => {
    let total = 0
    let medicalAidTotal = 0
    let patientTotal = 0

    steps.slice(0, currentStep + 1).forEach(step => {
      Object.values(step.billing).forEach(item => {
        total += item.amount
        if (item.coveredByMedicalAid) {
          medicalAidTotal += item.amount
        } else {
          patientTotal += item.amount
        }
      })
    })

    return { total, medicalAidTotal, patientTotal }
  }

  const { total, medicalAidTotal, patientTotal } = calculateTotals()

  return (
    <div className={styles.billingSummary}>
      <div className={styles.billingSummaryHeader}>
        <FontAwesomeIcon icon={faReceipt} />
        <h3>Billing Summary</h3>
      </div>

      <div className={styles.billingSummaryContent}>
        {steps.slice(0, currentStep + 1).map((step, index) => (
          <motion.div 
            key={step.id}
            className={styles.billingSummaryItem}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <div className={styles.billingSummaryItemHeader}>
              <h4>{step.title}</h4>
              <span className={styles.billingSummaryItemTime}>
                {step.actualTime || step.estimatedTime}
              </span>
            </div>

            {Object.entries(step.billing).map(([key, item]) => (
              <div key={key} className={styles.billingSummaryCharge}>
                <div className={styles.billingSummaryChargeInfo}>
                  <span>{item.description}</span>
                  <small>{item.provider}</small>
                </div>
                <div className={styles.billingSummaryChargeAmount}>
                  <span>R {item.amount.toFixed(2)}</span>
                  {item.coveredByMedicalAid ? (
                    <FontAwesomeIcon 
                      icon={faCheckCircle} 
                      className={styles.coveredIcon}
                      title="Covered by Medical Aid"
                    />
                  ) : (
                    <FontAwesomeIcon 
                      icon={faExclamationCircle} 
                      className={styles.notCoveredIcon}
                      title="Patient Responsible"
                    />
                  )}
                </div>
              </div>
            ))}
          </motion.div>
        ))}
      </div>

      <div className={styles.billingSummaryTotals}>
        <div className={styles.billingSummaryTotal}>
          <span>Medical Aid Portion:</span>
          <strong className={styles.medicalAidTotal}>
            R {medicalAidTotal.toFixed(2)}
          </strong>
        </div>
        <div className={styles.billingSummaryTotal}>
          <span>Patient Portion:</span>
          <strong className={styles.patientTotal}>
            R {patientTotal.toFixed(2)}
          </strong>
        </div>
        <div className={styles.billingSummaryGrandTotal}>
          <span>Total:</span>
          <strong>R {total.toFixed(2)}</strong>
        </div>
      </div>
    </div>
  )
}

export default BillingSummary 