import { useEffect, useRef, useState } from 'react'
import { ERROR_MESSAGES, MAX_RECORDING_TIME } from '../utils/constants'
import { whisperCreate } from '../../../examination-utils/examination-lib'
import { assistantInitiate } from '../../../../AI Tools/utils'
import { generateAIResponse } from '../../../../AI Tools/assistants-lib'
import { AssistantEnums } from '../../../../AI Tools/assitants-enums'
import { message } from 'antd'

const useTranscribeAi = ({ setNoteState, modalOpen, setModalOpen }) => {
  const [transcriptionState, setTranscriptionState] = useState({
    isRecording: false,
    tmpTranscript: '',
    recordingTime: 0,
    file: null,
    error: false,
    isProcessing: false
  })

  const timerRef = useRef(null)

  useEffect(() => {
    if (transcriptionState.isRecording) {
      timerRef.current = setInterval(() => {
        setTranscriptionState((prev) => {
          const newTime = prev.recordingTime + 1000
          if (newTime >= MAX_RECORDING_TIME) {
            clearInterval(timerRef.current)
            return { ...prev, isRecording: false, recordingTime: 0 }
          }
          return { ...prev, recordingTime: newTime }
        })
      }, 1000)
    } else {
      clearInterval(timerRef.current)
    }

    return () => clearInterval(timerRef.current)
  }, [transcriptionState.isRecording])

  const handleAiTranscription = async (file) => {
    setTranscriptionState((prev) => ({ ...prev, isProcessing: true }))
    try {
      const response = await whisperCreate(file)
      const tmpData = await assistantInitiate(response, AssistantEnums.assistant_ids.CLINICAL_NOTES)
      const aiResponse = await generateAIResponse(tmpData)
      const tmpContent = aiResponse?.data?.data[0]?.content[0]?.text?.value

      if (!tmpContent) {
        throw new Error('Failed to generate transcription')
      }

      setTranscriptionState((prev) => ({
        ...prev,
        isListening: false,
        recordingTime: 0,
        file: null,
        error: false,
        isProcessing: false
      }))
      setNoteState((prev) => ({ ...prev, currentNote: tmpContent }))
      setModalOpen(false)
    } catch (error) {
      console.error('Error uploading file:', error)
      message.error(ERROR_MESSAGES.TRANSCRIPTION_FAILED)
      setTranscriptionState((prev) => ({ ...prev, error: true, file, isProcessing: false }))
    }
  }

  const handleRetry = () => {
    if (transcriptionState.file) {
      handleAiTranscription(transcriptionState.file)
    }
  }

  const handleReset = () => {
    setModalOpen(false)
    setTranscriptionState({
      isRecording: false,
      tmpTranscript: '',
      recordingTime: 0,
      file: null,
      error: false,
      isProcessing: false
    })
  }
  return { transcriptionState, handleReset, handleAiTranscription, handleRetry, setTranscriptionState }
}

export default useTranscribeAi
