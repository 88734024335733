import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import landingImage from '../../../assets/images/landing-image.png'
import '../styles/ProcessingPayment.css'

const ProcessingPayment = ({ transactionId }) => {
  const [timeElapsed, setTimeElapsed] = useState(0)
  
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeElapsed(prev => prev + 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  // Prevent back button
  useEffect(() => {
    const handlePopState = (e) => {
      e.preventDefault()
      window.history.pushState(null, '', window.location.pathname)
    }

    window.history.pushState(null, '', window.location.pathname)
    window.addEventListener('popstate', handlePopState)

    return () => window.removeEventListener('popstate', handlePopState)
  }, [])

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className='processing-payment-container'
    >
      <div className='overlay' />
      <div className='processing-card'>
        <div className='logo-spinner'>
          <img src={landingImage} alt="Processing" />
        </div>
        <h2>Processing Payment</h2>
        <p>Please don't close this window</p>
        
        <p>Estimated wait time: {Math.max(30 - timeElapsed, 0)} seconds</p>
        
        <div className='transaction-info'>
          <small>Transaction ID: {transactionId}</small>
        </div>
      </div>
    </motion.div>
  )
}

export default ProcessingPayment
