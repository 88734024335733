import { useCallback, useEffect, useState } from 'react'
import { handleError } from '../../../../lib/utils'
import {
  createClinicalNote,
  groupData,
  readClinicalEntries,
  readClinicalNotes,
  updateClinicalNote
} from '../../../examination-utils/examination-lib'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { generateAIResponse } from '../../../../AI Tools/assistants-lib'
import { assistantInitiate } from '../../../../AI Tools/utils'
import { AssistantEnums } from '../../../../AI Tools/assitants-enums'
import { readMedicalHistory } from '../../../../Admin/patients/utils/utils'
import { debounce } from 'lodash'
import { DEBOUNCE_DELAY } from '../utils/constants'

export const useNoteState = ({ bookingData, userRef }) => {
  const [transcriptionState, setTranscriptionState] = useState({
    isListening: false,
    isRecording: false,
    tmpTranscript: ''
  })
  const [noteState, setNoteState] = useState({
    currentNote: '',
    noteId: null,
    isAutoGenerating: false,
    isSubmitting: false,
    isUpdating: false,
    isCreating: false,
    fetchingError: '',
    updatingError: ''
  })

  const authState = useSelector((state) => state.auth)
  const [showClinicalNotesTemplates, setShowClinicalNotesTemplates] = useState(false)
  const [showHistorical, setShowHistorical] = useState(false)
  const [showAiTranscription, setShowAiTranscription] = useState(false)

  const checkForExistingNote = async () => {
    if (!bookingData?._id || !authState?.agiliteUser?._id) return

    setNoteState((prev) => ({ ...prev, isSubmitting: true }))
    try {
      const query = {
        bookingRef: bookingData._id,
        createdBy: authState.agiliteUser._id
      }
      const existingNotes = await readClinicalNotes(query)
      const latestNote = existingNotes?.[existingNotes?.length - 1]

      if (latestNote) {
        setNoteState((prev) => ({
          ...prev,
          currentNote: latestNote.content || '',
          noteId: latestNote._id,
          fetchingError: ''
        }))
      }
    } catch (error) {
      setNoteState((prev) => ({
        ...prev,
        fetchingError: handleError(error, true)
      }))
    } finally {
      setNoteState((prev) => ({ ...prev, isSubmitting: false }))
    }
  }

  useEffect(() => {
    checkForExistingNote()
    // eslint-disable-next-line
  }, [bookingData])

  const handleCreateNote = async () => {
    if (!bookingData) {
      message.error('Missing booking information')
      return
    }

    setNoteState((prev) => ({ ...prev, isCreating: true }))
    try {
      const newNote = await createClinicalNote('', bookingData)
      if (!newNote?._id) {
        throw new Error('Failed to create new note')
      }

      setNoteState((prev) => ({
        ...prev,
        noteId: newNote._id,
        currentNote: '',
        isCreating: false
      }))
    } catch (error) {
      message.error(handleError(error))
      setNoteState((prev) => ({ ...prev, isCreating: false }))
    }
  }

  const handleUpdateNote = async (noteId, newNote) => {
    if (!noteId || typeof newNote !== 'string') return

    try {
      await updateClinicalNote(noteId, newNote)
      setNoteState((prev) => ({ ...prev, updatingError: '' }))
    } catch (error) {
      setNoteState((prev) => ({ ...prev, updatingError: handleError(error) }))
    } finally {
      setNoteState((prev) => ({ ...prev, isUpdating: false }))
    }
  }

  useEffect(() => {
    setTranscriptionState((prev) => ({ ...prev, tmpTranscript: '' }))
  }, [noteState.currentNote])

  // eslint-disable-next-line
  const debouncedNoteCapture = useCallback(
    debounce(async (noteId, newNote) => {
      await handleUpdateNote(noteId, newNote)
    }, DEBOUNCE_DELAY),
    []
  )

  const handleAutoGenerateNotes = async () => {
    if (!bookingData?._id || !bookingData?.userRef) {
      message.error('Missing required booking information')
      return
    }

    try {
      setNoteState((prev) => ({ ...prev, isAutoGenerating: true }))

      const [tmpClinicalData, medicalHistory] = await Promise.all([
        readClinicalEntries({ userRef: bookingData?.userRef || userRef }),
        readMedicalHistory({ userRef: bookingData?.userRef || userRef })
      ])

      const payload = {
        patientDetails: bookingData?.patientRecord,
        clinicalData: groupData(tmpClinicalData),
        medicalHistory,
        existingContent: noteState.currentNote
      }

      const tmpData = await assistantInitiate(JSON.stringify(payload), AssistantEnums.assistant_ids.CLINICAL_NOTES)
      const aiResponse = await generateAIResponse(tmpData)
      const tmpContent = aiResponse?.data?.data[0]?.content[0]?.text?.value

      if (!tmpContent) {
        throw new Error('Failed to generate AI content')
      }

      setNoteState((prev) => ({
        ...prev,
        isAutoGenerating: false,
        isUpdating: true,
        currentNote: tmpContent
      }))

      await debouncedNoteCapture(noteState.noteId, tmpContent)
    } catch (error) {
      message.error(handleError(error))
      setNoteState((prev) => ({ ...prev, isAutoGenerating: false }))
    }
  }

  return {
    noteState,
    setNoteState,
    showClinicalNotesTemplates,
    setShowClinicalNotesTemplates,
    showHistorical,
    setShowHistorical,
    checkForExistingNote,
    handleCreateNote,
    handleUpdateNote,
    showAiTranscription,
    setShowAiTranscription,
    handleAutoGenerateNotes,
    transcriptionState,
    debouncedNoteCapture
  }
}
