import axios from 'axios'
import { extractAddressDetails } from '../../lib/utils'
import { geocodeByAddress } from 'react-google-places-autocomplete'

/**
 * Converts full country name to ISO 3166-1 alpha-2 code
 * @param {string} countryName Full country name
 * @returns {string} Two-letter country code or 'ZA' as fallback
 */
const getCountryCode = (countryName) => {
  const countryMap = {
    'south africa': 'ZA',
    'united states': 'US',
    'united kingdom': 'GB'
    // Add more countries as needed
  }
  return countryMap[countryName.toLowerCase()] || 'ZA'
}

/**
 * Initializes a Peach Payments checkout session by making an API call to get a checkout ID
 * @async
 * @returns {Promise<string>} A promise that resolves with the checkout ID
 * @throws {Error} If the API call fails
 */
export const initPeachPaymentCheckout = (patientRecord, amount) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        // TODO: Get address from patient record and format according to Peach Payments requirements
        // const addressObj = await geocodeByAddress(patientRecord.residentialAddress)
        // const { streetAddress, city, province, postalCode, country } = extractAddressDetails(addressObj)

        const response = await axios.post(
          `${process.env.REACT_APP_NODE_RED_URL}/api/peachPayments/initCheckout`,
          {
            amount,
            customer: {
              merchantCustomerId: patientRecord._id,
              givenName: patientRecord.firstName,
              surname: patientRecord.lastName,
              email: patientRecord.emailAddress,
              mobile: patientRecord.phoneNumber,
              idNumber: patientRecord.idNo
            },
            billing: {
              street1: '',
              street2: '',
              city: '',
              country: '',
              state: '',
              postcode: ''
            },
            originator: process.env.REACT_APP_NAME
          },
          {
            headers: {
              'api-key': process.env.REACT_APP_AGILITE_API_KEY
            }
          }
        )

        resolve(response.data.checkoutId)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
