import React from 'react'
import { useSelector } from 'react-redux'
import { convertDateTimeSAST } from '../../../lib/utils'
import dayjs from 'dayjs'

const styles = {
  section: { color: '#ccc' },
  greeting: { fontSize: 20 },
  name: {
    fontSize: 28,
    className: 'custom-font-bold'
  }
}

const DashboardGreeting = () => {
  const { firstName, lastName } = useSelector((state) => state.auth?.agiliteUser ?? {})

  const getGreetingMessage = () => {
    const hour = dayjs(convertDateTimeSAST(new Date(), false)).hour()

    if (hour < 12) return 'Good morning'
    if (hour < 18) return 'Good afternoon'
    return 'Good evening'
  }

  return (
    <section style={styles.section}>
      <div>
        <span style={styles.greeting}>{getGreetingMessage()}</span>{' '}
        <span style={styles.name}>
          {firstName} {lastName}
        </span>
      </div>
    </section>
  )
}

export default DashboardGreeting
