import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { countTotalScripts, createScripts, readScriptHistory, sendDischemScript, updateScript } from '../utils/utils'
import { blobToBase64, extractAddressDetails, handleError } from '../../lib/utils'
import { Button, Col, Modal, message, theme } from 'antd'
import { debounce } from 'lodash'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import ScriptHistoryList from './scripting-history-list-view'
import ScriptingRecordView from './scripting-record-view'
import dayjs from 'dayjs'
import { downloadScript } from '../../Medical Vault/diagnosis-history/utils/utils'
import { readPatients } from '../../Admin/patients/utils/utils'
import CustomRow from '../../reusable-components/CustomRow'
import { updatePatient } from '../../Medical Vault/medical-history/utils/utils'
import { getNextDueDate, prepPatientQuery, validateChronicIllnesses, validateScript } from '../utils/lib'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import { findAgiliteUser } from '../../Auth/utils/utils'
import { current } from '@reduxjs/toolkit'
import { DocumentViewer } from '../../reusable-components/DocumentViewer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const ScriptsWrapper = ({ patientId, action }) => {
  const authState = useSelector((state) => state.auth)
  const clinicState = useSelector((state) => state.clinics.data)
  const [loading, setLoading] = useState(false)
  const [patientSearchQuery, setPatientSearchQuery] = useState('')
  const [scriptHistory, setScriptHistory] = useState([])
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [selectedScript, setSelectedScript] = useState(null)
  const [isCopy, setIsCopy] = useState(null)
  const [printingScript, setPrintingScript] = useState(false)
  const [pageSize, setPageSize] = useState(7)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [patientChronicDetails, setPatientChronicDetails] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [type, setType] = useState('')
  const [onlyDue, setOnlyDue] = useState(true)
  const [totalDue, setTotalDue] = useState(0)
  const [currentScript, setCurrentScript] = useState(null)
  const [rectifyingOldRecord, setRectifyingOldRecord] = useState(false)

  const { token } = theme.useToken()

  const createNewScript = (data) => {
    setSelectedPatient(data)
  }

  const refreshView = async () => {
    let data = []
    let qry = {}
    let patientQry = {}
    let countTotal = 0
    let tmpTotalDue = 0

    if (patientId) {
      qry.userRef = patientId
    } else {
      qry.medProfRef = authState.agiliteUser._id
    }

    qry.onSchedule = type === 'schedule' ? true : { $ne: true }

    setLoading(true)

    try {
      if (patientSearchQuery) {
        // PREP REQUEST QUERY
        patientQry = prepPatientQuery(patientSearchQuery)
        countTotal = await countTotalScripts(qry, patientQry)
        data = await readScriptHistory(qry, true, patientQry, pageSize, page, setTotal)
      } else {
        countTotal = await countTotalScripts(qry)
        data = await readScriptHistory(qry, true, null, pageSize, page, setTotal)
      }
      tmpTotalDue = await countTotalScripts({
        ...qry,
        onSchedule: true,
        nextDue: { $lte: dayjs().format('YYYY-MM-DD') }
      })
      setTotalDue(tmpTotalDue)
      setTotal(countTotal)
      setScriptHistory(data)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  useEffect(() => {
    refreshView()
    // eslint-disable-next-line
  }, [patientSearchQuery, pageSize, page, type])

  const handleSearch = (query) => {
    setLoading(true)
    debouncedFilter(query)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setPage(1)
      setPatientSearchQuery(query)
    }, 1000),
    []
  )

  const viewScript = (scriptDetails) => {
    setSelectedScript(scriptDetails)
  }

  const handleCreateScript = (script, dischemApplicable, practiceNo, dischemType, dischemPaymentType) => {
    setLoading(true)

    if (script.onSchedule && !script.name) {
      setLoading(false)
      return message.error('Script name required.')
    }

    if (script.onSchedule && !script.scheduleInterval) {
      setLoading(false)
      return message.error('Script schedule interval required.')
    }

    if (script.onSchedule && script.scheduleInterval < 1) {
      setLoading(false)
      return message.error("Script schedule interval cant't be 0.")
    }

    let errorHandler = validateScript(script, dischemApplicable)

    // CHECK ERRORS
    if (errorHandler[0]) {
      setLoading(false)
      return message.error(errorHandler[0])
    }

    // CONFIRM CHANGES
    if (validateChronicIllnesses(patientChronicDetails, script, 'confirmation')?.length > 0) {
      // NO ERROR MESSAGES PROCEED
      Modal.confirm({
        title: 'Changes have been made to patient medical history. Please confirm the changes.',
        width: 950,
        content: (
          <CustomRow style={{ maxWidth: '100%' }}>
            <Col span={24}>
              {validateChronicIllnesses(patientChronicDetails, script, 'confirmation').map((message) => {
                return message
              })}
            </Col>
          </CustomRow>
        ),
        // CALL PDF GENERATION && MEDICAL HISTORY UPDATES
        onOk: () => handleCreateScriptExtended(script, dischemApplicable, practiceNo, dischemType, dischemPaymentType),
        onCancel: () => {
          setLoading(false)
        },
        okText: 'Accept Changes',
        cancelText: 'Cancel',
        okButtonProps: { style: { background: token.colorPrimary } }
      })
    } else {
      handleCreateScriptExtended(script, dischemApplicable, practiceNo, dischemType, dischemPaymentType)
    }
  }

  // MEDICAL HISTORY UPDATES
  const getUserRef = (script) => {
    if (patientId) return patientId
    if (isCopy) return selectedScript.patient._id
    if (selectedPatient) return selectedPatient._id
    if (script.onSchedule) return selectedScript.patient._id
  }

  // PDF GENERATION && MEDICAL HISTORY UPDATES
  const handleCreateScriptExtended = async (script, dischemApplicable, practiceNo, dischemType, dischemPaymentType) => {
    let paitentData = selectedPatient ? selectedPatient : selectedScript.patient
    let payload = {}
    const userRef = getUserRef(script)

    // MEDICAL HISTORY
    const medicalHistoryPayload = {
      chronicIllnesses: {
        updatedAt: dayjs(),
        list: [...validateChronicIllnesses(patientChronicDetails, script)]
      }
    }

    // SCRIPT
    payload = {
      name: script.name ? script.name : `New Script - ${dayjs().format('YYYY-MM-DD HH:mm')}`,
      scheduleInterval: script.scheduleInterval,
      onSchedule: script.onSchedule,
      userRef: userRef,
      items: script.items
    }

    if (script.onSchedule) {
      payload.nextDue = getNextDueDate(script.nextDue, script.scheduleInterval) //script.nextDue
    }

    try {
      // UPDATE MEDICAL HISTORY
      await updatePatient(medicalHistoryPayload, {
        userRef: userRef
      })
      // CREATE SCRIPT
      // CALL GENERATE SCRIPT PDF
      if (patientId) {
        paitentData = await readPatients({ _id: patientId })[0]
      }
      const scriptBase64 = await handleGenerateScriptTemplate({
        items: script.items,
        patient: paitentData
      })
      if (script.onSchedule && script._id) {
        payload.document = scriptBase64
        let logs = [...script?.logs, dayjs().format('YYYY-MM-DD')]
        payload.logs = logs
        await updateScript(script._id, payload)
      } else {
        payload.document = scriptBase64
        await createScripts(payload)
      }

      if (dischemApplicable) {
        let mainMember = null
        let patient = null

        patient = await findAgiliteUser({ _id: selectedPatient })

        if (patient.mainMemberId) {
          mainMember = await findAgiliteUser({ _id: patient.mainMemberId })
        }

        await handleDischemSendScript(
          script,
          practiceNo,
          dischemType,
          dischemPaymentType,
          patient,
          mainMember,
          scriptBase64
        )
      }

      // REFRESH AND RESET STATE
      refreshView()
      setIsCopy(null)
      setSelectedScript(null)
      setCurrentScript(payload.document)
      setSelectedPatient(null)
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }

  // RESET STATE ON CANCEL
  const handleCancel = () => {
    if (action) {
    } else {
      setIsEditMode(false)
      setIsCopy(null)
      setSelectedPatient(null)
      setSelectedScript(null)
    }
  }

  // MAIN TITLE
  const generateTitle = () => {
    if (patientId) {
      if (selectedScript) {
        if (isCopy) {
          return 'New Script (copy)'
        } else {
          return `${dayjs(selectedScript.createdAt).format('DD MMM YYYY')}`
        }
      }
    }

    if (selectedPatient) {
      if (patientId) {
        return 'New Script'
      } else {
        return `New Script: ${selectedPatient?.firstName} ${selectedPatient?.lastName}`
      }
    }

    if (selectedScript) {
      if (isCopy) {
        return `New Script (copy): ${selectedScript?.patient?.firstName} ${selectedScript?.patient?.lastName}`
      }
      return `Script: ${selectedScript?.patient?.firstName} ${selectedScript?.patient?.lastName} - ${dayjs(
        selectedScript.createdAt
      ).format('DD MMM YYYY')} `
    }

    return 'Scripts'
  }

  /**
   * Generates a PDF script template and converts it to base64 string
   * @param {Object} record - The script record containing items and patient details
   * @param {Array} record.items - List of prescription items/medications
   * @param {Object} record.patient - Patient information
   * @returns {Promise<string>} Base64 encoded string of the generated PDF
   * @throws {Error} If signature or MP number is missing, or if PDF generation fails
   */
  const handleGenerateScriptTemplate = async (record) => {
    const signature = authState.agiliteUser.extraData.signature
    const mpNumber = authState.agiliteUser.extraData.mpNumber
    const clinicDetails = clinicState.find((i) => authState.agiliteUser.extraData.clinics[0] === i._id)
    let scriptPDF = null
    setPrintingScript(true)

    // NO SIGNATURE SET
    if (!signature) {
      setPrintingScript(false)
      return message.error(
        'No Signature has been set. Please set your Signature in your Personal Details Medical Profile.'
      )
    }

    // NO MP NUMBER SET
    if (!mpNumber) {
      setPrintingScript(false)
      return message.error(
        'No MP Number has been set. Please set your MP Number in your Personal Details Medical Profile.'
      )
    }
    try {
      // VIEWING PATIENT
      if (patientId) {
        const patientData = await readPatients({ _id: patientId })
        scriptPDF = await downloadScript(record.items, signature, clinicDetails, patientData[0])
      } else {
        // VIEWING SCRIPT
        scriptPDF = await downloadScript(record.items, signature, clinicDetails, record.patient)
      }

      const response = await fetch(scriptPDF)
      const pdfBlob = await response.blob()
      const base64String = await blobToBase64(pdfBlob)

      return base64String
    } catch (e) {
      message.error(handleError(e, true))
    }
    setPrintingScript(false)
  }

  const handleDischemSendScript = async (
    script,
    practiceNo,
    collectionDelivery,
    paymentType,
    patient,
    mainMember,
    scriptImage
  ) => {
    const clinicDetails = clinicState.find((i) => authState.agiliteUser.extraData.clinics[0] === i._id)
    let patientAddressResult = null
    let patientAddressObj = null
    let mainMemberAddressResult = null
    let mainMemberAddressObj = null
    let comments = ''

    try {
      if (mainMember) {
        mainMemberAddressResult = await geocodeByAddress(mainMember.residentialAddress)
        mainMemberAddressObj = extractAddressDetails(mainMemberAddressResult)
      } else {
        patientAddressResult = await geocodeByAddress(patient.residentialAddress)
        patientAddressObj = extractAddressDetails(patientAddressResult)
      }

      await sendDischemScript({
        patient: mainMember
          ? { ...mainMember, addressDetails: mainMemberAddressObj }
          : { ...patient, addressDetails: patientAddressObj },
        dependant: mainMember ? { ...patient, addressDetails: patientAddressObj } : null,
        medicalProf: authState.agiliteUser,
        clinic: clinicDetails,
        script,
        practiceNo,
        collectionDelivery,
        paymentType,
        comments,
        scriptImage
      })
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const recitfyOldRecord = async (record) => {
    setRectifyingOldRecord(true)
    try {
      const tmpDocument = await handleGenerateScriptTemplate(record)
      const newRecord = await updateScript(record._id, { document: tmpDocument })
      setCurrentScript(newRecord.document)
      refreshView()
    } catch (error) {
      message.error(handleError(error, true))
    }
    setRectifyingOldRecord(false)
  }
  return (
    <ContainerCard title={generateTitle()} hideTitle={patientId ? true : false}>
      {currentScript ? (
        <div
          style={{
            display: 'flex',
            gap: 8,
            background: '#323639',
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ width: '100%', padding: 8 }}>
            <Button onClick={() => setCurrentScript(null)}>
              <FontAwesomeIcon icon={faArrowLeft} /> Back to Scripts
            </Button>
          </div>
          <DocumentViewer base64={currentScript} />
        </div>
      ) : (
        <>
          {selectedScript ? (
            <ScriptingRecordView
              getUserRef={getUserRef}
              patientChronicDetails={patientChronicDetails}
              setPatientChronicDetails={setPatientChronicDetails}
              isCopy={isCopy}
              handleGenerateScriptTemplate={handleGenerateScriptTemplate}
              loading={loading}
              setLoading={setLoading}
              setIsCopy={setIsCopy}
              data={selectedScript}
              handleCancel={handleCancel}
              selectedPatient={selectedPatient}
              handleCreateScript={handleCreateScript}
              printingScript={printingScript}
              patientId={patientId}
              refreshView={refreshView}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              onlyDue={onlyDue}
              totalDue={totalDue}
              setOnlyDue={setOnlyDue}
              handleDischemSendScript={handleDischemSendScript}
            />
          ) : (
            <>
              {selectedPatient ? (
                <>
                  <ScriptingRecordView
                    patientChronicDetails={patientChronicDetails}
                    setPatientChronicDetails={setPatientChronicDetails}
                    isNew
                    getUserRef={getUserRef}
                    loading={loading}
                    setLoading={setLoading}
                    selectedPatient={selectedPatient}
                    handleCreateScript={handleCreateScript}
                    handleCancel={handleCancel}
                    refreshView={refreshView}
                    patientId={patientId}
                    isEditMode={isEditMode}
                    isEdsetIsEditModeit={setIsEditMode}
                    onlyDue={onlyDue}
                    setOnlyDue={setOnlyDue}
                    totalDue={totalDue}
                    handleDischemSendScript={handleDischemSendScript}
                  />
                </>
              ) : (
                <ScriptHistoryList
                  recitfyOldRecord={recitfyOldRecord}
                  getUserRef={getUserRef}
                  patientChronicDetails={patientChronicDetails}
                  setPatientChronicDetails={setPatientChronicDetails}
                  total={total}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  printingScript={printingScript}
                  handleGenerateScriptTemplate={handleGenerateScriptTemplate}
                  patientSearchQuery={patientSearchQuery}
                  patientId={patientId}
                  createNewScript={createNewScript}
                  loading={loading}
                  setLoading={setLoading}
                  data={scriptHistory}
                  selectedPatient={selectedPatient}
                  viewScript={viewScript}
                  refreshView={refreshView}
                  handleSearch={handleSearch}
                  isEditMode={isEditMode}
                  setIsEditMode={setIsEditMode}
                  type={type}
                  setType={setType}
                  onlyDue={onlyDue}
                  setOnlyDue={setOnlyDue}
                  totalDue={totalDue}
                  setCurrentScript={setCurrentScript}
                />
              )}
            </>
          )}
        </>
      )}
    </ContainerCard>
  )
}

export default ScriptsWrapper
