import { useEffect, useState } from 'react'
import { handleError } from '../../../../lib/utils'
import { message } from 'antd'
import { cancelEnrollement, fetchEnrollment } from '../../../utils/utils'
import { useSelector } from 'react-redux'

const useCarePlanEnrollment = ({ template }) => {
  const authState = useSelector((state) => state.auth)
  const [enrolledUsers, setEnrolledUsers] = useState([])
  const [newEnrollment, setNewEnrollment] = useState(false)
  const [loadingPatients, setLoadingPatients] = useState(false)
  const [currentPatient, setCurrentPatient] = useState(null)

  useEffect(() => {
    handleFetchEnrolledUsers()
    // eslint-disable-next-line
  }, [])

  const handleFetchEnrolledUsers = async () => {
    let tmpUsers = []
    setLoadingPatients(true)
    try {
      tmpUsers = await fetchEnrollment({
        templateRef: template._id,
        $or: [
          { teamMembers: authState.agiliteUser._id }, // Direct match if teamMembers contains ObjectIds
          { 'audit.createdBy': authState.agiliteUser._id },
          { doctorRef: authState.agiliteUser._id }
        ]
      })
      setEnrolledUsers(tmpUsers)
    } catch (error) {
      message.error(handleError(error))
    }
    setLoadingPatients(false)
  }

  const handleNewEnrollment = (record) => {
    let tmpEnrolledUsers = [...enrolledUsers, record]
    setEnrolledUsers(tmpEnrolledUsers)
  }

  const handleCancelEnrollment = async (record) => {
    let tmpEnrollment = [...enrolledUsers]
    let entryIndex = tmpEnrollment.findIndex((i) => i._id === record._id)
    try {
      await cancelEnrollement(record._id)
      tmpEnrollment.splice(entryIndex, 1)
      setEnrolledUsers(tmpEnrollment)
    } catch (error) {
      message.error(handleError(error))
    }
  }

  return {
    enrolledUsers,
    handleFetchEnrolledUsers,
    handleNewEnrollment,
    handleCancelEnrollment,
    loadingPatients,
    currentPatient,
    setCurrentPatient,
    newEnrollment,
    setNewEnrollment
  }
}

export default useCarePlanEnrollment
