import {
  faAdd,
  faBriefcase,
  faBusinessTime,
  faChartLine,
  faClinicMedical,
  faCog,
  faInfoCircle,
  faLocationDot,
  faMoneyBill,
  faPhone,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip
} from 'antd'
import Templates from './templates'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { hexToRGBA, specialFieldValidation } from '../../../lib/utils'
import store from '../../../../store'
import GoogleAddress from '../../../reusable-components/GoogleAddress'

export const DEFAULT_MACRO = {
  isActive: true,
  name: '',
  items: []
}

export const INITIAL_MACRO_STATE = { isActive: true, name: '', items: [] }

export const CLINIC_FORM_CATEGORY_ICONS = {
  BASIC_INFORMATION: faInfoCircle,
  LOCATION_AND_CONTACT: faLocationDot,
  PRACTICE_DETAILS: faBriefcase,
  CGM_INTEGRATION: faChartLine,
  BILLING_MACROS: faMoneyBill
}

export const CLINIC_FORM_CATEGORY_COLORS = {
  BASIC_INFORMATION: '#1890ff', // Bright blue for basic info
  LOCATION_AND_CONTACT: '#f5222d', // Green for location/contact
  PRACTICE_DETAILS: '#722ed1', // Purple for practice details
  CGM_INTEGRATION: '#fa8c16', // Orange for integration
  BILLING_MACROS: '#52c41a' // Red for billing
}

export const CLINIC_FORM_CATEGORY_LABELS = {
  BASIC_INFORMATION: 'Basic Information',
  LOCATION_AND_CONTACT: 'Location & Contact',
  PRACTICE_DETAILS: 'Practice Details',
  CGM_INTEGRATION: 'CGM Integration',
  BILLING_MACROS: 'Billing Macros'
}

export const imageOverlayStyle = ({ loading, token }) => ({
  gridColumn: 1,
  gridRow: 1,
  boxSizing: 'border-box',
  display: 'flex',
  justify: 'center',
  alignItems: 'center',
  width: 128,
  position: 'relative',
  zIndex: 1,
  opacity: 0.4,
  borderRadius: '5%',
  backgroundColor: token.colorBgMask,
  cursor: loading ? 'not-allowed' : 'pointer'
})

export const renderMacroItems = (items) => (
  <Table
    size='small'
    dataSource={items}
    columns={[
      { title: 'Type', dataIndex: 'type', key: 'type' },
      { title: 'Code', dataIndex: 'code', key: 'code' },
      { title: 'Name', dataIndex: 'name', key: 'name' },
      {
        title: 'ICD10',
        dataIndex: 'icd10',
        key: 'icd10',
        render: (text) => text.join(' - ')
      },
      { title: 'QTY', dataIndex: 'qty', key: 'qty' }
    ]}
    pagination={false}
    bordered={true}
  />
)

export const clinicFormTabs = ({
  token,
  unfilledFields,
  handleNewMacro,
  handleMacroDelete,
  handleMacroClick,
  inputRef,
  handleFileChange,
  handleUploadClick,
  image,
  handleIsActive,
  loading,
  clientForm,
  specialFormState,
  setSpecialFormState,
  data,
  handleGetAddress,
  physicalAddress,
  macros,
  bpmProfiles
}) => {
  const coreState = store.getState().core
  return [
    {
      key: '1',
      label: (
        <span>
          <FontAwesomeIcon
            icon={CLINIC_FORM_CATEGORY_ICONS.BASIC_INFORMATION}
            style={{ marginRight: '8px', color: CLINIC_FORM_CATEGORY_COLORS.BASIC_INFORMATION }}
          />
          {CLINIC_FORM_CATEGORY_LABELS.BASIC_INFORMATION}
          <Badge count={unfilledFields[1]} style={{ marginLeft: '8px', backgroundColor: token.colorError }} />
        </span>
      ),
      children: (
        <Row gutter={[16, 16]} justify='start'>
          <Col>
            <Card
              size='small'
              styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
              title={
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                  Basic Information <FontAwesomeIcon icon={faClinicMedical} />
                </Space>
              }
            >
              <Row gutter={[16, 16]}>
                <Col>
                  <Tooltip title='Edit Clinic Logo' style={{ height: '100%' }}>
                    <input
                      type='file'
                      ref={inputRef}
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                      accept='image/*'
                    />
                    <div style={{ display: 'grid', minHeight: '100%' }}>
                      <img
                        referrerPolicy='no-referrer'
                        alt='Clinic Logo'
                        style={{ gridColumn: 1, gridRow: 1, borderRadius: '5%', height: 128, minHeight: '100%' }}
                        src={image}
                      />
                      <div
                        style={imageOverlayStyle({ loading, token })}
                        onClick={loading ? undefined : handleUploadClick}
                      >
                        <FontAwesomeIcon color={token.colorWhite} icon={faEdit} style={{ width: 128 }} size='2x' />
                      </div>
                    </div>
                  </Tooltip>
                </Col>
                <Col>
                  <Form.Item
                    name={Templates.dataModel.name.key}
                    label={Templates.dataModel.name.label}
                    rules={[
                      {
                        required: Templates.dataModel.name.required,
                        message: Templates.dataModel.name.validationMsg
                      }
                    ]}
                  >
                    <Input
                      style={{ maxWidth: '100%', width: 450 }}
                      onChange={(e) => {
                        specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                          key: 'name',
                          event: e,
                          validationConfig: {
                            letters: { allowed: true, onlyCaps: false },
                            numbers: false,
                            spaces: true
                          }
                        })
                      }}
                      maxLength={50}
                      placeholder={Templates.dataModel.name.placeholder}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card
              size='small'
              styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
              title={
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                  Clinic Setup <FontAwesomeIcon icon={faCog} />
                </Space>
              }
            >
              <Row gutter={[16, 16]}>
                <Col>
                  <Card>
                    <Space direction='vertical'>
                      <Space wrap>
                        <p style={{ width: 180 }}>{Templates.dataModel.clinicBookings.label}</p>
                        <Form.Item
                          style={{ margin: '0' }}
                          name={Templates.dataModel.clinicBookings.key}
                          valuePropName='checked'
                        >
                          <Switch checkedChildren='Yes' unCheckedChildren='No' />
                        </Form.Item>
                      </Space>
                      <Space wrap>
                        <p style={{ width: 180 }}>{Templates.dataModel.homeVisits.label}</p>
                        <Form.Item
                          style={{ margin: '0' }}
                          name={Templates.dataModel.homeVisits.key}
                          valuePropName='checked'
                        >
                          <Switch checkedChildren='Yes' unCheckedChildren='No' />
                        </Form.Item>
                      </Space>
                      <Space wrap>
                        <p style={{ width: 180 }}>{Templates.dataModel.isActive.label}</p>
                        <Form.Item
                          style={{ margin: '0' }}
                          name={Templates.dataModel.isActive.key}
                          valuePropName='checked'
                        >
                          <Switch checkedChildren='Active' unCheckedChildren='Inactive' onChange={handleIsActive} />
                        </Form.Item>
                      </Space>
                    </Space>
                  </Card>
                </Col>
                <Col>
                  <Card style={{ minHeight: '100%' }}>
                    <Form.Item
                      style={{ margin: 0, minHeight: '100%' }}
                      name={Templates.dataModel.paymentMethods.key}
                      label={Templates.dataModel.paymentMethods.label}
                      rules={[
                        {
                          required: Templates.dataModel.paymentMethods.required,
                          message: Templates.dataModel.paymentMethods.validationMsg
                        }
                      ]}
                    >
                      <Checkbox.Group valuePropName='checked'>
                        <Row>
                          <Col span={24}>
                            {coreState.entity.configs.paymentMethods.map((option) => (
                              <div key={option} style={{ marginRight: 5 }}>
                                <Checkbox value={option} /> {option}
                              </div>
                            ))}
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Card>
                </Col>
                <Col>
                  <Card style={{ minHeight: '100%' }}>
                    <Form.Item
                      name={Templates.dataModel.clinicType.key}
                      label={Templates.dataModel.clinicType.label}
                      rules={[
                        {
                          required: Templates.dataModel.clinicType.required,
                          message: Templates.dataModel.clinicType.validationMsg
                        }
                      ]}
                    >
                      <Radio.Group>
                        <Space direction='vertical'>
                          <Radio value='Medical Facility'>Medical Facility</Radio>
                          <Radio value='Third Party On-site Clinic'>Third Party On-site Clinic</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      )
    },
    {
      key: '2',
      label: (
        <span>
          <FontAwesomeIcon
            icon={CLINIC_FORM_CATEGORY_ICONS.LOCATION_AND_CONTACT}
            style={{ marginRight: '8px', color: CLINIC_FORM_CATEGORY_COLORS.LOCATION_AND_CONTACT }}
          />
          {CLINIC_FORM_CATEGORY_LABELS.LOCATION_AND_CONTACT}
          <Badge count={unfilledFields[2]} style={{ marginLeft: '8px', backgroundColor: token.colorError }} />
        </span>
      ),
      children: (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              title={
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                  Location Information <FontAwesomeIcon icon={faLocationDot} />
                </Space>
              }
              size='small'
              styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
            >
              <Space direction='vertical' style={{ width: '100%' }}>
                <Form.Item
                  name={Templates.dataModel.province.key}
                  label={Templates.dataModel.province.label}
                  rules={[
                    {
                      required: Templates.dataModel.province.required,
                      message: Templates.dataModel.province.validationMsg
                    }
                  ]}
                >
                  <Select
                    showSearch
                    placeholder='- Select a Province -'
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={Templates.dataModel.province.options}
                  />
                </Form.Item>
                <Form.Item
                  name={Templates.dataModel.address.key}
                  label={Templates.dataModel.address.label}
                  rules={[
                    {
                      required: Templates.dataModel.address.required,
                      message: Templates.dataModel.address.validationMsg
                    }
                  ]}
                >
                  <GoogleAddress
                    selectProps={{
                      placeholder: data.address || Templates.dataModel.address.placeholder,
                      onChange: (e) => {
                        if (e.label) {
                          handleGetAddress(e.label)
                          clientForm.setFieldsValue({
                            [Templates.dataModel.address.key]: e.label,
                            [Templates.dataModel.place_id.key]: e.value.place_id
                          })
                        }
                      }
                    }}
                  />
                </Form.Item>
                {physicalAddress && (
                  <Form.Item
                    name={Templates.dataModel.physicalAddress.key}
                    label={Templates.dataModel.physicalAddress.label}
                    rules={[
                      {
                        required: Templates.dataModel.physicalAddress.required,
                        message: Templates.dataModel.physicalAddress.validationMsg
                      }
                    ]}
                  >
                    <Input.TextArea rows={2} readOnly />
                  </Form.Item>
                )}
              </Space>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                  Contact Information <FontAwesomeIcon icon={faPhone} />
                </Space>
              }
              size='small'
              styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
            >
              <Space direction='vertical' style={{ width: '100%' }}>
                <Form.Item
                  name={Templates.dataModel.email.key}
                  label={Templates.dataModel.email.label}
                  rules={[
                    {
                      required: Templates.dataModel.email.required,
                      message: Templates.dataModel.email.validationMsg
                    },
                    {
                      type: 'email',
                      message: 'Please enter a valid email address'
                    }
                  ]}
                >
                  <Input type='email' placeholder={Templates.dataModel.email.placeholder} />
                </Form.Item>
                <Form.Item
                  name={Templates.dataModel.phoneNumber.key}
                  label={Templates.dataModel.phoneNumber.label}
                  rules={[
                    {
                      required: Templates.dataModel.phoneNumber.required,
                      message: Templates.dataModel.phoneNumber.validationMsg
                    }
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                        key: 'phoneNumber',
                        event: e,
                        validationConfig: {
                          letters: { allowed: false, onlyCaps: false },
                          numbers: true,
                          spaces: false,
                          specialChars: false
                        }
                      })
                    }}
                    maxLength={50}
                    placeholder={Templates.dataModel.phoneNumber.placeholder}
                  />
                </Form.Item>

                <Form.Item name={Templates.dataModel.place_id.key} label={Templates.dataModel.place_id.key} hidden />
              </Space>
            </Card>
          </Col>
        </Row>
      )
    },
    {
      key: '3',
      label: (
        <span>
          <FontAwesomeIcon
            icon={CLINIC_FORM_CATEGORY_ICONS.PRACTICE_DETAILS}
            style={{ marginRight: '8px', color: CLINIC_FORM_CATEGORY_COLORS.PRACTICE_DETAILS }}
          />
          {CLINIC_FORM_CATEGORY_LABELS.PRACTICE_DETAILS}
          <Badge count={unfilledFields[3]} style={{ marginLeft: '8px', backgroundColor: token.colorError }} />
        </span>
      ),
      children: (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              size='small'
              styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
              title={
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                  Practice Details <FontAwesomeIcon icon={faBriefcase} />
                </Space>
              }
            >
              <Space direction='vertical' style={{ width: '100%' }}>
                <Form.Item
                  name={Templates.dataModel.practiceName.key}
                  label={Templates.dataModel.practiceName.label}
                  rules={[
                    {
                      required: Templates.dataModel.practiceName.required,
                      message: Templates.dataModel.practiceName.validationMsg
                    }
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                        key: 'practiceName',
                        event: e,
                        validationConfig: {
                          letters: { allowed: true, onlyCaps: false },
                          numbers: true,
                          spaces: true,
                          specialChars: true
                        }
                      })
                    }}
                    maxLength={50}
                    placeholder={Templates.dataModel.practiceName.placeholder}
                  />
                </Form.Item>
                <Form.Item
                  name={Templates.dataModel.practiceRegNumber.key}
                  label={Templates.dataModel.practiceRegNumber.label}
                  rules={[
                    {
                      required: Templates.dataModel.practiceRegNumber.required,
                      message: Templates.dataModel.practiceRegNumber.validationMsg
                    }
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                        key: 'practiceRegNumber',
                        event: e,
                        validationConfig: {
                          letters: { allowed: true, onlyCaps: false },
                          numbers: true,
                          spaces: true,
                          specialChars: true
                        }
                      })
                    }}
                    maxLength={50}
                    placeholder={Templates.dataModel.practiceRegNumber.placeholder}
                  />
                </Form.Item>
                <Form.Item
                  name={Templates.dataModel.practiceNumber.key}
                  label={Templates.dataModel.practiceNumber.label}
                  rules={[
                    {
                      required: Templates.dataModel.practiceNumber.required,
                      message: Templates.dataModel.practiceNumber.validationMsg
                    }
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                        key: 'practiceNumber',
                        event: e,
                        validationConfig: {
                          letters: { allowed: true, onlyCaps: false },
                          numbers: true,
                          spaces: false,
                          specialChars: true
                        }
                      })
                    }}
                    maxLength={50}
                    placeholder={Templates.dataModel.practiceNumber.placeholder}
                  />
                </Form.Item>
              </Space>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              size='small'
              styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
              title={
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                  Business Process <FontAwesomeIcon icon={faBusinessTime} />
                </Space>
              }
            >
              <Form.Item
                name={Templates.dataModel.bpmKey.key}
                label={Templates.dataModel.bpmKey.label}
                rules={[
                  {
                    required: Templates.dataModel.bpmKey.required,
                    message: Templates.dataModel.bpmKey.validationMsg
                  }
                ]}
              >
                <Select
                  showSearch
                  placeholder='- Select a Business Process -'
                  optionFilterProp='children'
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={bpmProfiles}
                />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      )
    },
    {
      key: '4',
      label: (
        <span>
          <FontAwesomeIcon
            icon={CLINIC_FORM_CATEGORY_ICONS.CGM_INTEGRATION}
            style={{ marginRight: '8px', color: CLINIC_FORM_CATEGORY_COLORS.CGM_INTEGRATION }}
          />
          {CLINIC_FORM_CATEGORY_LABELS.CGM_INTEGRATION}
          <Badge count={unfilledFields[4]} style={{ marginLeft: '8px', backgroundColor: token.colorError }} />
        </span>
      ),
      children: (
        <>
          <Space wrap style={{ marginTop: 20, marginBottom: 10 }}>
            {Templates.dataModel.cgmEnabled.label}
            <Form.Item style={{ margin: '0' }} name={Templates.dataModel.cgmEnabled.key} valuePropName='checked'>
              <Switch checkedChildren='Yes' unCheckedChildren='No' />
            </Form.Item>
          </Space>
          <br />
          <Form.Item
            name={Templates.dataModel.cgmPracticeId.key}
            label={Templates.dataModel.cgmPracticeId.label}
            rules={[
              {
                required:
                  clientForm.getFieldValue(Templates.dataModel.cgmEnabled.key) &&
                  Templates.dataModel.cgmPracticeId.required,
                message: Templates.dataModel.cgmPracticeId.validationMsg
              }
            ]}
          >
            <Input
              onChange={(e) => {
                specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                  key: 'cgmPracticeId',
                  event: e,
                  validationConfig: {
                    letters: { allowed: false, onlyCaps: false },
                    numbers: true,
                    spaces: false,
                    specialChars: false
                  }
                })
              }}
              maxLength={50}
              placeholder={Templates.dataModel.cgmPracticeId.placeholder}
            />
          </Form.Item>
        </>
      )
    },
    {
      key: '5',
      label: (
        <span>
          <FontAwesomeIcon
            icon={CLINIC_FORM_CATEGORY_ICONS.BILLING_MACROS}
            style={{ marginRight: '8px', color: CLINIC_FORM_CATEGORY_COLORS.BILLING_MACROS }}
          />
          {CLINIC_FORM_CATEGORY_LABELS.BILLING_MACROS}
          <Badge count={unfilledFields[5]} style={{ marginLeft: '8px', backgroundColor: token.colorError }} />
        </span>
      ),
      children: (
        <>
          <center>
            <Button
              type='primary'
              style={{ marginBottom: 20 }}
              onClick={handleNewMacro}
              icon={<FontAwesomeIcon icon={faAdd} style={{ color: '#fff' }} />}
            >
              New Macro
            </Button>
          </center>
          <Table
            size='small'
            dataSource={macros}
            pagination={false}
            bordered
            columns={[
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
              },
              {
                title: 'Is Active',
                dataIndex: 'isActive',
                key: 'isActive',
                width: '15%',
                render: (text) => (text ? 'Yes' : 'No')
              },
              {
                title: 'Actions',
                key: 'actions',
                width: '20%',
                render: (_, record, index) => (
                  <FontAwesomeIcon
                    style={{ color: token.colorError, cursor: 'pointer' }}
                    icon={faTrash}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleMacroDelete(index)
                    }}
                  />
                )
              }
            ]}
            expandable={{
              expandedRowRender: renderMacroItems(macros),
              rowExpandable: (record) => record.items.length > 0
            }}
            onRow={(record, rowIndex) => ({
              onClick: () => handleMacroClick(record, rowIndex),
              style: { cursor: 'pointer' }
            })}
            rowKey={(_, index) => index}
          />
        </>
      )
    }
  ]
}
