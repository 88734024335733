import React from 'react'
import { faChainBroken, faRefresh, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Select, Space, Spin, Tooltip, theme, Tag } from 'antd'

import useDiagnosis from '../hooks/useDiagnosis'
import { DIANGOSIS_ERROR_MESSAGE } from '../utils/constants'
import { hexToRGBA } from '../../../../lib/utils'

// Constants

const DiagnosisCapture = ({ bookingData }) => {
  const {
    selectedDiagnoses,
    icd10Data,
    isLoading,
    isSaveError,
    handleDiagnosisSelect,
    handleDiagnosisRemove,
    updateDiagnoses,
    fetchIcd10Data,
    setState
  } = useDiagnosis({ bookingData })

  const { token } = theme.useToken()

  // Memoized diagnosis tags render function
  const DiagnosisTags = React.memo(() => (
    <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 12, width: '100%' }}>
      {selectedDiagnoses.map((diagnosis) => (
        <Tag
          key={diagnosis.code}
          closable
          onClose={() => handleDiagnosisRemove(diagnosis)}
          style={{
            backgroundColor: token.colorPrimaryBg,
            marginBottom: '5px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {`${diagnosis.code}: ${diagnosis.description}`}
        </Tag>
      ))}
    </div>
  ))

  return (
    <Card
      styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
      size='small'
      title='Diagnosis'
      extra={
        <Space>
          {isLoading && <Spin />}
          {isSaveError && (
            <>
              <Tooltip title='Error Saving Diagnosis'>
                <FontAwesomeIcon icon={faChainBroken} color={token.colorError} />
              </Tooltip>
              <Button onClick={() => updateDiagnoses(selectedDiagnoses)}>
                <Space>
                  Retry <FontAwesomeIcon icon={faRefresh} color={token.colorSuccess} />
                </Space>
              </Button>
            </>
          )}
          {!isSaveError && !isLoading && selectedDiagnoses.length > 0 && (
            <Tooltip title='Diagnosis Captured Successfully'>
              <FontAwesomeIcon icon={faCheckCircle} color={token.colorSuccess} />
            </Tooltip>
          )}
        </Space>
      }
    >
      <div
        style={{
          width: '100%',
          background: isSaveError ? hexToRGBA(token.colorError, 0.1) : '',
          padding: isSaveError ? 8 : 0
        }}
      >
        <DiagnosisTags />

        <Select
          style={{ width: '100%' }}
          value={null}
          onSearch={fetchIcd10Data}
          onSelect={handleDiagnosisSelect}
          onClear={() => setState((prev) => ({ ...prev, icd10Data: [] }))}
          onDropdownVisibleChange={(visible) => {
            if (!visible) setState((prev) => ({ ...prev, icd10Data: [] }))
          }}
          dropdownStyle={{ minWidth: 650 }}
          options={icd10Data.map((item) => ({
            value: JSON.stringify(item),
            label: `${item.code}: ${item.description}`
          }))}
          filterOption={false}
          placeholder='- Select Diagnosis -'
          optionFilterProp='label'
          showSearch
          notFoundContent={isLoading ? <Spin size='small' /> : null}
        />

        {isSaveError && (
          <div style={{ width: '100%', marginTop: 12 }}>
            <p>{DIANGOSIS_ERROR_MESSAGE}</p>
          </div>
        )}
      </div>
    </Card>
  )
}

export default React.memo(DiagnosisCapture)
