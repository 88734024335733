import React, { useEffect, useState } from 'react'
import { Button, message, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCog, faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons'
import SearchBar from './SearchBar'
import CategorySection from './CategorySection'
import TestDetail from './TestInfo/TestDetail'
import TestManager from './AdminSetup/TestManager'
import { diagnosticTestCategories } from '../../../mockdata/diagnosticTestsData'
import '../../styling/TestMenu.css'
import Header from './Header'
import { useSelector } from 'react-redux'

const TestMenu = ({ onBack }) => {
  const state = useSelector((state) => state)
  const userProfile = state.auth.agiliteUser
  const [searchQuery, setSearchQuery] = useState('')
  const [activeFilters, setActiveFilters] = useState({
    categories: [],
    specimenType: 'all',
    testType: 'all'
  })
  const [selectedTest, setSelectedTest] = useState(null)
  const [isAdminView, setIsAdminView] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [expandedCategories, setExpandedCategories] = useState({})

  const careSyncTests = useSelector((state) => state.careSyncTests.data)

  // Group tests by category
  const groupedTests = React.useMemo(() => {
    return careSyncTests.reduce((acc, test) => {
      if (!acc[test.category]) {
        acc[test.category] = {
          id: test.category,
          name: test.category.charAt(0).toUpperCase() + test.category.slice(1), // Capitalize category name
          tests: []
        }
      }
      acc[test.category].tests.push(test)
      return acc
    }, {})
  }, [careSyncTests])

  // Automatically expand categories with search results
  useEffect(() => {
    if (searchQuery) {
      const categoriesWithResults = filteredTests.reduce(
        (acc, category) => ({
          ...acc,
          [category.id]: true // Expand categories with matching tests
        }),
        {}
      )
      setExpandedCategories(categoriesWithResults)
    } else {
      // When search is cleared, collapse all categories
      setExpandedCategories({})
    }
  }, [searchQuery])

  const handleSearch = (value) => {
    setIsSearching(!!value)
    setSearchQuery(value)
  }

  const handleFilterChange = (filters) => {
    setActiveFilters(filters)
  }

  const handleTestSelect = (test) => {
    // TODO: Implement test selection/booking
  }

  const handleViewDetails = (test) => {
    setSelectedTest(test)
  }

  const handleBackFromDetails = () => {
    setSelectedTest(null)
  }

  const handleBackFromAdmin = () => {
    setIsAdminView(false)
  }

  const handleToggleExpand = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId]
    }))
  }

  // Filter tests based on search and filters
  const filteredTests = React.useMemo(() => {
    return Object.values(groupedTests)
      .map((category) => ({
        ...category,
        tests: category.tests.filter((test) => {
          const matchesSearch =
            !searchQuery ||
            test.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            test.description.toLowerCase().includes(searchQuery.toLowerCase())

          const matchesFilters = !activeFilters.categories?.length || activeFilters.categories.includes(category.id)

          const matchesAvailability = test.availability

          return matchesSearch && matchesFilters && test.isActive && matchesAvailability
        })
      }))
      .filter((category) => category.tests.length > 0)
  }, [groupedTests, searchQuery, activeFilters])

  if (isAdminView) {
    return <TestManager onBack={handleBackFromAdmin} />
  }

  if (selectedTest) {
    return <TestDetail test={selectedTest} onBack={handleBackFromDetails} />
  }

  //  if(isLoading){
  //   return <MobMedicalHistoryLoadingOverlay loading={true} animated={true} text='Loading tests...' />
  //  }

  return (
    <div className='cs-test-menu'>
      <Header onBack={onBack} title='Available Tests' showAdmin={false} onAdminClick={() => setIsAdminView(true)} />

      {/* <SearchBar onSearch={handleSearch} onFilterChange={handleFilterChange} /> */}

      <div className='cs-test-menu__content'>
        {filteredTests.length > 0 ? (
          filteredTests.map((category) => (
            <CategorySection
              key={category.id}
              category={category}
              tests={category.tests}
              onTestSelect={handleTestSelect}
              onViewDetails={handleViewDetails}
              isExpanded={expandedCategories[category.id] || false}
              onToggleExpand={handleToggleExpand}
            />
          ))
        ) : (
          <div className='cs-test-menu__empty'>
            <FontAwesomeIcon icon={faSearch} className='cs-test-menu__empty-icon' />
            <p className='cs-test-menu__empty-text'>{isSearching ? 'No matching tests found' : 'No tests available'}</p>
            <p className='cs-test-menu__empty-subtext'>
              {isSearching ? 'Try adjusting your search or filters' : 'Please check back later'}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default TestMenu
