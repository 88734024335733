import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Col, Empty, message, Space } from 'antd'
import { geocodeByLatLng, geocodeByAddress } from 'react-google-places-autocomplete'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { deviceDetect } from 'react-device-detect'
import { readClinics } from '../../../../Admin/clinics/utils/utils'
import { convertDateTimeSAST, handleError } from '../../../../lib/utils'
import { readConfig } from '../../../../Admin/config/utils/utils'
import { readMedicalProfessionalUsers } from '../../../../Admin/medical-professionals/utils/utils'
import { readAvailability } from '../../../../Availability/components/utils/utils'
import { readBookings } from '../../../../Bookings/utils/utils'
import CoreEnums from '../../../../../core/utils/enums'
import { isAlreadyBooked, willOverlap } from '../../../../Bookings/utils/lib'
import { readSystemUsers } from '../../../../Admin/system-users/utils/utils'
import BookingDetailsCapture from './MobClinicBookingDetailsCapture'
import CustomRow from '../../../../reusable-components/CustomRow'
import BookingResults from './MobClinicBookingResults'
import { readCareSyncLabTests } from '../../../../Super-Admin/CareSync/LabTests/utils/utils'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'

const MobClinicBookingSearch = ({
  isHomeVisit,
  isVirtualVisit,
  bookingDetails,
  setBookingDetails,
  nextStep,
  prevStep,
  isReceptionBooking,
  patient,
  previousSearch,
  setPreviousSearch,
  mobileSteps,
  setMobileSteps,
  setPatient,
  isCaresync,
  testKey
}) => {
  const [testsTotal, setTestsTotal] = useState(0)
  const authState = useSelector((state) => state.auth)
  const state = useSelector((state) => state)
  const [loading, setLoading] = useState()
  const [location, setLocation] = useState(previousSearch ? previousSearch.location : null)
  const [noLocation, setNoLocation] = useState(false)
  // const [radiusResults, setRadiusResults] = useState(previousSearch ? previousSearch.radiusResults : [])
  const [clinicResults, setClinicResults] = useState(previousSearch ? previousSearch.clinicResults : [])
  const [clinics, setClinics] = useState([])
  const [daysInAdvance, setDaysInAdvance] = useState(previousSearch ? previousSearch.daysInAdvance : null)
  const [booking, setBooking] = useState(
    previousSearch ? previousSearch.booking : { date: dayjs(new Date()), province: bookingDetails.province }
  )

  const [dataResults, setDataResults] = useState(null)
  const [active, setActive] = useState({
    timeslot: null,
    medicalProfRef: null
  })
  const [dependants, setDependants] = useState([])
  const [careSyncBooking, setCareSyncBooking] = useState(isCaresync ? true : false)
  const [testKeys, setTestKeys] = useState(testKey ? [testKey] : [])

  console.log('TEST KEYS', testKeys)

  useEffect(() => {
    setBooking({ ...booking, careSyncTests: testKeys })
    // eslint-disable-next-line
  }, [testKeys])

  useEffect(() => {
    if (isCaresync) {
      setBooking({ ...booking, service: state.services.data.find((item) => item.name === 'CareSync')._id })
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (state.services.data.find((item) => item._id === booking?.service)?.name === 'CareSync') {
      setCareSyncBooking(true)
      setTestKeys([testKey])
    } else {
      setCareSyncBooking(false)
      setTestKeys([])
      setCareSyncBooking(false)
    }
    // eslint-disable-next-line
  }, [booking.service])

  useEffect(() => {
    if (isCaresync || careSyncBooking) {
      setBooking({ ...booking, service: state.services.data.find((item) => item.name === 'CareSync')._id })
    } else {
      setBooking({ ...booking, service: setupServiceValue() })
    }

    // eslint-disable-next-line
  }, [])

  // CARESYNC TESTS
  const [careSyncTests, setCareSyncTests] = useState([])
  const [careSyncTestsLoading, setCareSyncTestsLoading] = useState(false)

  useEffect(() => {
    if (careSyncBooking) {
      handleFetchCareSyncTests()
    }
  }, [careSyncBooking])

  const handleFetchCareSyncTests = async () => {
    setCareSyncTestsLoading(true)
    try {
      const tmpData = await readCareSyncLabTests({ availability: true })
      setCareSyncTests(tmpData)
    } catch (error) {
      message.error(handleError(error))
    }
    setCareSyncTestsLoading(false)
  }

  useEffect(() => {
    setDataResults(null)
  }, [patient, booking.service, booking.province, booking.date])

  useEffect(() => {
    const getCurrentPosition = () => {
      let results = null

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            results = await geocodeByLatLng({ lat: position.coords.latitude, lng: position.coords.longitude })

            if (results.length > 0) {
              position.address = results[0].formatted_address
              setLocation(position)
            } else {
              setLocation(position)
            }
          } catch (e) {
            message.error(handleError(e, true))
          }
        },
        async (error) => {
          try {
            if (authState.agiliteUser.residentialAddress) {
              results = await geocodeByAddress(authState.agiliteUser.residentialAddress)
              setNoLocation(false)
            } else {
              return setNoLocation(true)
            }

            if (results.length > 0) {
              setLocation({
                address: results[0].formatted_address,
                coords: {
                  latitude: results[0].geometry.location.lat(),
                  longitude: results[0].geometry.location.lng()
                }
              })
            } else {
              message.error('Could not find location')
            }
          } catch (e) {
            message.error(handleError(e, true))
          }
        }
      )
    }

    if (!patient) {
      getCurrentPosition()
    }
    fetchClinics()
    setupDependants()
    // eslint-disable-next-line
  }, [patient])

  useEffect(() => {
    if (patient) {
      handlePatientReceptionAddress()
    }
    // eslint-disable-next-line
  }, [patient])

  const fetchClinics = async () => {
    let clinics = []
    try {
      clinics = await readClinics({ isActive: true, entityRef: state.core.entity._id })
      setClinics(clinics)
    } catch (e) {
      message.error(handleError(e))
    }
  }

  const handlePatientReceptionAddress = async () => {
    let clinics = null

    setLoading(true)

    try {
      if (patient && patient.residentialAddress) {
        handleLocationChange({ label: patient.residentialAddress })
      } else if (authState.agiliteUser.extraData.clinics.length > 0) {
        clinics = await readClinics({ _id: authState.agiliteUser.extraData.clinics[0] })

        if (clinics && clinics.length > 0) {
          handleLocationChange({ label: clinics[0].address })
        } else {
          setNoLocation(true)
        }
      } else {
        setNoLocation(true)
      }
    } catch (e) {
      message.error(handleError(e))
    }

    setLoading(false)
  }

  const handleLocationChange = async (value) => {
    let results = null

    try {
      setNoLocation(false)
      results = await geocodeByAddress(value.label)
      readClinics()

      if (results.length > 0) {
        setLocation({
          address: results[0].formatted_address,
          coords: {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng()
          }
        })
      } else {
        message.error('Could not find location')
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleSubmit = async () => {
    setActive({
      timeslot: null,
      medicalProfRef: null
    })
    let tmpArray = []
    let clinics = []
    let qry = null
    // let tmpResults = []

    setLoading(true)

    setDaysInAdvance(tmpArray)

    // const service = new window.google.maps.places.PlacesService(document.createElement('div'))
    const service = new window.google.maps.DistanceMatrixService()
    const tmpClinicArray = []
    let result = null

    try {
      const bookingsConfig = await readConfig()
      for (let x = 0; x <= bookingsConfig[0].bookings.daysInAdvance; x++) {
        tmpArray.push({
          day: formatDateDay(new Date(dayjs(booking.date).add(x, 'day'))),
          date: new Date(dayjs(booking.date).add(x, 'day')),
          entries: []
        })
      }

      qry = { isActive: true }

      if (!isVirtualVisit) {
        qry.province = booking.province
      }

      // Reception
      if (isReceptionBooking) {
        qry._id = authState.agiliteUser.extraData.clinics[0]
      }

      // if (isHomeVisit) {
      //   qry.homeVisits = true
      // } else {
      //   qry.clinicBookings = true
      // }

      clinics = await readClinics(qry, isVirtualVisit)

      for (const clinic of clinics) {
        result = await service.getDistanceMatrix({
          origins: [location.address],
          destinations: [clinic.address],
          travelMode: window.google.maps.TravelMode.DRIVING,
          unitSystem: window.google.maps.UnitSystem.METRIC
        })

        if (result.rows && result.rows.length > 0) {
          if (result.rows[0].elements && result.rows[0].elements.length > 0) {
            if (result.rows[0].elements[0].distance?.value) {
              if (result.rows[0].elements[0].distance.value <= bookingsConfig[0].bookings.searchRadius * 1000) {
                tmpClinicArray.push({
                  clinicAddress: clinic.address,
                  clinicRef: clinic._id,
                  clinicPaymentMethods: clinic.paymentMethods,
                  clinicName: clinic.name,
                  clinicActive: clinic.isActive,
                  distance: result.rows[0].elements[0].distance.value,
                  travelTime: result.rows[0].elements[0].duration.value,
                  availabilityEntries: []
                })
              }
            }
          }
        }
      }

      setClinicResults(tmpClinicArray.sort((a, b) => a.distance - b.distance))
      setMobileSteps(mobileSteps + 1)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  useEffect(() => {
    if (booking.date && booking.service) {
      getData()
    }
    // eslint-disable-next-line
  }, [clinicResults])

  const formatDateDay = (date) => {
    switch (new Date(date).getDay()) {
      case 0:
        return 7
      default:
        return new Date(date).getDay()
    }
  }

  const getData = async () => {
    const tmpDataResults = JSON.parse(JSON.stringify(clinicResults))
    let professionalUsers = []

    try {
      const [currentBookings, availabilityData, professionals] = await Promise.all([
        readBookings({
          $and: [
            { status: { $ne: CoreEnums.bookingStatuses.cancelled } },
            { status: { $ne: CoreEnums.bookingStatuses.completed } }
          ]
        }),
        readAvailability(),
        await readMedicalProfessionalUsers(
          {
            'extraData.isActive': true,
            'extraData.services': { $in: [booking.service] }
          },
          'extraData.services extraData.profession extraData.isActive firstName lastName email phoneNumber profileImage'
        )
      ])

      // Combine users and professionals
      professionals.map((user) => {
        return professionalUsers.push({
          _id: user._id,
          services: user.extraData.services,
          profession: user.extraData.profession,
          isActive: user.extraData.isActive,
          firstName: user.firstName,
          lastName: user.lastName,
          profileImage: user.profileImage,
          email: user.email,
          phoneNumber: user.phoneNumber,
          availability: []
        })
      })

      // Cleanup
      for (const entry of tmpDataResults) {
        entry.entries = []
      }

      tmpDataResults.forEach((clinic, clinicIndex) => {
        const tmpDayResults = JSON.parse(JSON.stringify(daysInAdvance))

        tmpDayResults.forEach((dataEntry, dayIndex) => {
          professionalUsers.forEach((professionalUser) => {
            const tmpProfessionalUser = JSON.parse(JSON.stringify(professionalUser))
            const upcomingBookings = []
            const availabilityEntry = availabilityData
              .concat()
              .find(
                (entry) => entry.profession === tmpProfessionalUser.profession && entry.clinicRef === clinic.clinicRef
              )

            if (availabilityEntry) {
              tmpProfessionalUser.availability =
                availabilityEntry.availability
                  .find((entry) => entry.date === dayjs(dataEntry.date).format('YYYY-MM-DD'))
                  ?.timeSlots.filter((slot) => slot.linkedProfessional === tmpProfessionalUser._id && !slot.blocked)
                  .map((slot) => slot.time) || []
            }

            // Find this medical professionals existing bookings and assign them
            currentBookings.forEach((currentBooking) => {
              if (
                currentBooking.medicalProfRef === tmpProfessionalUser._id &&
                new Date(currentBooking.bookingDate).toDateString() === new Date(dataEntry.date).toDateString() &&
                currentBooking.status !== CoreEnums.bookingStatuses.cancelled
              ) {
                upcomingBookings.push([currentBooking.startTime, currentBooking.endTime])
              }
            })

            if (tmpProfessionalUser.availability.length > 0) {
              const timeSlotIntervals = []
              tmpProfessionalUser.availability.forEach((slot) => {
                if (!timeSlotIntervals.includes(slot)) {
                  if (!isAlreadyBooked(slot, upcomingBookings) && !willOverlap(slot, booking, upcomingBookings)) {
                    // only push if slot (1) isnt booked, (2) won't overlap with any existing timeslots and (3) is later than NOW,
                    if (dayjs(dataEntry.date).format('DD MMM YYYY') === dayjs(new Date()).format('DD MMM YYYY')) {
                      // Check if slot is later than NOW
                      let currentHour = new Date().getHours().toString()
                      let currentMinute = new Date().getMinutes().toString()

                      if (currentMinute.length < 2) {
                        currentMinute = '0' + currentMinute
                      }

                      if (currentHour.length === 1) {
                        // Correctly format current hour if earlier than 10am
                        currentHour = '0' + currentHour
                      }

                      const currentTime = currentHour + ':' + currentMinute

                      if (slot > currentTime) {
                        timeSlotIntervals.push(slot)
                      }
                    } else {
                      timeSlotIntervals.push(slot)
                    }
                  }
                }
              })
              // timeSlotIntervals.splice(timeSlotIntervals.length - 1, 1)
              tmpProfessionalUser.availability = timeSlotIntervals

              if (tmpProfessionalUser.availability.length > 0) {
                tmpDayResults[dayIndex].entries.push(tmpProfessionalUser)
              }
            }
          })

          if (tmpDayResults[dayIndex].entries.length > 0) {
            tmpDataResults[clinicIndex].availabilityEntries.push(tmpDayResults[dayIndex])
          }
        })
      })

      setDataResults(tmpDataResults)
    } catch (e) {
      handleError(e)
    }

    setLoading(false)
  }

  const handleMakeBooking = (date, data, timeslot, key, clinicRef, paymentMethods) => {
    const tmpBookingDetails = bookingDetails
    const serviceInfo =
      state.services.data.find((item) => item._id === booking.service) ||
      state.virtualServices.data.find((item) => item._id === booking.service)

    // Set the active timeslot and key to identify which slot to show as active
    setActive({
      timeslot: timeslot,
      key: key
    })

    // Prepare booking details payload
    tmpBookingDetails.bookingDate = convertDateTimeSAST(date, true)
    tmpBookingDetails.dateCreated = convertDateTimeSAST(date)
    tmpBookingDetails.endTime = dayjs(timeslot, 'HH:mm').add(serviceInfo.timeslotInterval, 'minutes').format('HH:mm')
    tmpBookingDetails.medicalProf = data
    tmpBookingDetails.service = booking.service
    tmpBookingDetails.startTime = timeslot
    tmpBookingDetails.paymentMethods = paymentMethods
    tmpBookingDetails.careSyncTests = testKeys
    tmpBookingDetails.chiefComplaint = 'Care Sync Testing'
    tmpBookingDetails.testsTotal = testsTotal

    if (patient) {
      tmpBookingDetails.userRef = patient._id
    } else {
      tmpBookingDetails.userRef = authState.agiliteUser._id
    }

    if (isHomeVisit) {
      tmpBookingDetails.clinicRef = 'Home Visit'
    } else {
      tmpBookingDetails.clinicRef = clinicRef
    }

    tmpBookingDetails.clinicTermsRef = clinicRef
    setPreviousSearch({
      location: location,
      // radiusResults: radiusResults,
      clinicResults: clinicResults,
      booking: booking,
      dataResults: dataResults,
      daysInAdvance: daysInAdvance
    })
    setBookingDetails(tmpBookingDetails)
  }

  const setupServices = () => {
    const tmpServices = []
    if (isVirtualVisit) {
      state.virtualServices.data.map((service) => {
        tmpServices.push({ label: service.name, value: service._id })

        return null
      })
    } else {
      state.services.data.map((service) => {
        if (isHomeVisit) {
          if (service.type === 'Home Visit') {
            tmpServices.push({ label: service.name, value: service._id })
          }
        } else {
          tmpServices.push({ label: service.name, value: service._id })
        }

        return null
      })
    }

    return tmpServices
  }

  const setupDependants = async () => {
    let responseData = []
    let tmpDependants = []

    try {
      tmpDependants.push({
        label: 'Myself',
        value: ''
      })

      responseData = await readSystemUsers({
        mainMemberId: authState.agiliteUser._id
      })
      console.log('Response Data', responseData)

      responseData.map((dependant) => {
        tmpDependants.push({
          label: dependant.firstName + ' ' + dependant.lastName,
          value: JSON.stringify(dependant)
        })
        return null
      })

      setDependants(tmpDependants)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const setupServiceValue = () => {
    let tmpServices = []
    let serviceValue = ''

    tmpServices = setupServices()

    if (tmpServices.length > 0) {
      serviceValue = setupServices()[0].value
    }

    return serviceValue
  }

  const checkAvailability = (array) => {
    let isAvailable = false

    array.forEach((item) => {
      if (item.availabilityEntries.length > 0) {
        isAvailable = true
      }
    })

    return isAvailable
  }
  return (
    <>
      <CustomRow justify='center'>
        <Col xs={24} sm={24} md={20} lg={16} xl={14}>
          <CustomRow>
            {!dataResults ? (
              <Col span={24}>
                <BookingDetailsCapture
                  testKeys={testKeys}
                  setTestKeys={setTestKeys}
                  testsTotal={testsTotal}
                  setTestsTotal={setTestsTotal}
                  careSyncBooking={careSyncBooking}
                  careSyncTests={careSyncTests}
                  careSyncTestsLoading={careSyncTestsLoading}
                  loading={loading}
                  setBooking={setBooking}
                  booking={booking}
                  setupServiceValue={setupServiceValue}
                  setupServices={setupServices}
                  location={location}
                  setLocation={setLocation}
                  isVirtualVisit={isVirtualVisit}
                  patient={patient}
                  setPatient={setPatient}
                  dependants={dependants}
                  isHomeVisit={isHomeVisit}
                  isReceptionBooking={isReceptionBooking}
                  handleSubmit={handleSubmit}
                  prevStep={prevStep}
                  dataResults={dataResults}
                  clinics={clinics}
                  noLocation={noLocation}
                  handleLocationChange={handleLocationChange}
                />
              </Col>
            ) : (
              <>
                {dataResults?.length > 0 ? (
                  <>
                    {!checkAvailability(dataResults) ? (
                      <>
                        <Col span={24}>
                          <center>
                            <Empty description='Sorry there are no available bookings for this day. Please adjust your search criteria. ' />
                          </center>
                        </Col>
                        <PrevNextFloatingButtons
                          prevOnly
                          prevText='Back To Search'
                          onPrev={() => {
                            setDataResults(null)
                          }}
                        />
                      </>
                    ) : (
                      dataResults.map((result) => {
                        if (result.availabilityEntries.length > 0) {
                          return (
                            <Col span={24}>
                              <BookingResults
                                patient={patient}
                                bookingDetails={bookingDetails}
                                location={location}
                                result={result}
                                isVirtualVisit={isVirtualVisit}
                                active={active}
                                nextStep={nextStep}
                                setDataResults={setDataResults}
                                handleMakeBooking={handleMakeBooking}
                              />
                            </Col>
                          )
                        }
                        return null
                      })
                    )}
                  </>
                ) : undefined}
              </>
            )}
          </CustomRow>
        </Col>
      </CustomRow>
    </>
  )
}

export default MobClinicBookingSearch
