import React from 'react'
import { Card, Tag, Collapse, Progress } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faChevronDown,
  faClock,
  faFire,
  faShield,
  faStar,
  faDumbbell,
  faRunning,
  faPersonSwimming
} from '@fortawesome/free-solid-svg-icons'
import { ExerciseItem } from './ExerciseItem'
import './WorkoutCard.css'

const { Panel } = Collapse

const workoutIcons = {
  strength: faDumbbell,
  cardio: faRunning,
  swimming: faPersonSwimming
}

const difficultyColors = {
  beginner: '#4CAF50',
  intermediate: '#FF9800',
  advanced: '#F44336'
}

export const WorkoutCard = ({
  day,
  workouts,
  isActive,
  onDayChange,
  onExerciseClick,
  isExerciseCompleted
}) => {
  const renderWorkoutCard = (workout) => (
    <div className="workout-wrapper" key={workout.id}>
      <Card>
        <div className="workout-header">
          <div className="workout-title">
            <div className="workout-type">
              <FontAwesomeIcon 
                icon={workoutIcons[workout.type]} 
                className="workout-icon"
              />
              <span className="workout-name">{workout.name}</span>
            </div>
          </div>
          <div className="workout-meta">
            <div className="meta-left">
              {workout.verified && (
                <Tag 
                  color="blue" 
                  icon={<FontAwesomeIcon icon={faShield} />}
                  className="verification-tag"
                >
                  Verified
                </Tag>
              )}
              <Tag 
                color={difficultyColors[workout.difficulty]} 
                className="difficulty-tag"
              >
                {workout.difficulty}
              </Tag>
            </div>
            <div className="points-badge">
              <FontAwesomeIcon icon={faStar} />
              <span>{workout.pointsPerWorkout}</span>
            </div>
          </div>
        </div>

        <div className="workout-stats">
          <div className="stat">
            <FontAwesomeIcon icon={faClock} />
            <span>{workout.duration} min</span>
          </div>
          <div className="stat">
            <FontAwesomeIcon icon={faFire} />
            <span>{workout.calories} cal</span>
          </div>
        </div>

        <div className="workout-exercises">
          {workout.exercises.map((exercise, index) => (
            <ExerciseItem
              key={index}
              exercise={exercise}
              workoutId={workout.id}
              isCompleted={isExerciseCompleted(exercise.id)}
              onClick={() => onExerciseClick(exercise, workout.id)}
            />
          ))}
        </div>
      </Card>
    </div>
  )

  return (
    <Collapse 
      accordion
      expandIcon={({ isActive }) => (
        <FontAwesomeIcon 
          icon={faChevronDown} 
          className={`collapse-icon ${isActive ? 'active' : ''}`}
        />
      )}
      onChange={(key) => onDayChange(key)}
    >
      <Panel 
        header={
          <div className="day-header">
            <span className="day-name">{day.charAt(0).toUpperCase() + day.slice(1)}</span>
            <Progress 
              percent={workouts.filter(w => w.completed).length / workouts.length * 100}
              size="small"
              strokeColor={difficultyColors.intermediate}
            />
          </div>
        }
        key={day}
      >
        {workouts.map(workout => renderWorkoutCard(workout))}
      </Panel>
    </Collapse>
  )
} 