import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import '../styling/MobUberVirtual.css'
import MobVideoConsultation from './MobVideoConsultation'
import { generateAgoraRtcToken } from '../../../../Bookings/utils/utils'
import { generateUniqueUIDFromString, handleError } from '../../../../lib/utils'

const MobUberVirtual = ({ appointment, channelId, userId }) => {
  // AGORA
  const [joined, setJoined] = useState(false)
  const [agoraToken, setAgoraToken] = useState(null)
  const [agoraChannel, setAgoraChannel] = useState(null)
  const [agoraUid, setAgoraUid] = useState(null)

  useEffect(() => {
    handleJoinCall()
    // eslint-disable-next-line
  }, [])

  const handleJoinCall = async () => {
    let agoraCreds = null
    try {
      agoraCreds = await generateAgoraRtcToken(channelId, generateUniqueUIDFromString(userId))
      setAgoraToken(agoraCreds.token)
      setAgoraChannel(agoraCreds.channelName)
      setAgoraUid(agoraCreds.uid)
      setJoined(true)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  return (
    <>
      <MobVideoConsultation
        appointment={appointment}
        uid={agoraUid}
        token={agoraToken}
        channel={agoraChannel}
        patient={true}
        joined={joined}
        setJoined={setJoined}
      />
    </>
  )
}

export default MobUberVirtual
