// src/App.js
import React, { useContext, useEffect, useState } from 'react'
import BluetoothContext from './BluetoothContext'
import OXDetection from './components/Detection/OXDetection'
import BTDetection from './components/Detection/BTDetection'
import BGDetection from './components/Detection/BGDetection'
import BPDetection from './components/Detection/BPDetection'
import DeviceInfo from './components/DeviceInfo'
import BatteryStatus from './components/BatteryStatus'
import CustomRow from '../../reusable-components/CustomRow'
import { Button, Card, Col, Popconfirm, Space, theme, Tooltip } from 'antd'
import CustomButton from '../../reusable-components/CustomButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import '../animations.css'
import { hexToRGBA } from '../../lib/utils'
function App({ userRef, bookingRef, setModalOpen }) {
  const {
    requestDevice,
    connectDevice,
    disconnectDevice,
    bleDevice
    // bleCommandCharacteristic,
    // bleNotifyCharacteristic
  } = useContext(BluetoothContext)
  const [facet, setFacet] = useState('ox')
  const [busy, setBusy] = useState(false)
  const [readings, setReadings] = useState([])

  useEffect(() => {
    if (bleDevice) {
      connectDevice()
    }
    // eslint-disable-next-line
  }, [bleDevice])

  const getTitle = () => {
    switch (facet) {
      case 'ox':
        return 'READING OXYGEN'
      case 'bt':
        return 'READING TEMPERATURE'
      case 'bg':
        return 'READING BLOOD GLUCOSE'
      case 'bp':
        return 'READING BLOOD PRESSURE'
    }
  }

  const { token } = theme.useToken()

  return (
    <div className='App'>
      <div
        style={{
          width: '100%',
          padding: 8,
          background: hexToRGBA(token.colorPrimary, 0.2),
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          top: 0,
          left: 0
        }}
      >
        <h2>LINK-TOP DEVICE</h2>
        {readings.length > 0 && !busy ? (
          <Popconfirm
            title='Are you sure?'
            description='Any unconfirmed readings will be lost.'
            onConfirm={() => {
              setReadings([])
              setModalOpen(false)
            }}
          >
            <Button style={{ fontSize: 24, background: 'transparent', border: 'none' }}>
              <FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />
            </Button>
          </Popconfirm>
        ) : (
          <Tooltip title={busy ? 'Please wait for the operation to finish or cancel it!' : ''}>
            <Button
              onClick={() => {
                disconnectDevice()
                setModalOpen(false)
              }}
              style={{ float: 'right', fontSize: 24, background: 'transparent', border: 'none' }}
              disabled={busy}
            >
              <FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />
            </Button>
          </Tooltip>
        )}
      </div>
      <CustomRow style={{ marginTop: 48 }}>
        <Col span={8}>
          <Card
            // headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
            bodyStyle={{ overflow: 'hidden', padding: 24 }}
            size='small'
            title={
              bleDevice ? (
                <p style={{ color: token.colorSuccess }}>DEVICE CONNECTED</p>
              ) : (
                <CustomButton
                  iconPosition={'end'}
                  type='primary'
                  style={{ background: '#027FF5' }}
                  size='small'
                  icon={'Connect'}
                  text='Connect'
                  onClick={requestDevice}
                />
              )
            }
            extra={
              <Space>
                <img
                  src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYL5VcbRlIn84LKE_fVQ7-YQ3NQkp86EX_zg&s'
                  style={{ height: 20 }}
                  alt='Bluetooth icon'
                />
              </Space>
            }
          >
            {/* <button onClick={connectDevice}>Connect to Device</button> */}
            {/* <div> 
            <p>Device: {bleDevice ? bleDevice.name : 'No device connected'}</p>
            <p>Command Characteristic: {bleCommandCharacteristic ? 'Available' : 'Not available'}</p>
            <p>Notify Characteristic: {bleNotifyCharacteristic ? 'Available' : 'Not available'}</p>
          </div> */}

            <img
              className={!busy ? '' : 'linktop-pulse'}
              style={{ minWidth: '100%', width: 100, position: 'relative', boxSizing: 'border-box' }}
              src='https://linktop.com/wp-content/uploads/2021/12/linktop-HC03-Health-Monitor.png'
              alt='6-in-1 device'
            />
            {bleDevice ? (
              <CustomRow>
                <Col span={24}>
                  <Button
                    onClick={disconnectDevice}
                    type={'primary'}
                    style={{ background: busy ? '' : token.colorError }}
                    disabled={busy || readings.length > 0}
                  >
                    Disconnect
                  </Button>
                </Col>
                <Col span={24}>
                  <DeviceInfo />
                </Col>
                <Col span={24}>
                  <BatteryStatus />
                </Col>
              </CustomRow>
            ) : (
              <center>
                <h1 className='glimmer' style={{ color: token.colorError }}>
                  DEVICE DISCONNECTED
                </h1>
              </center>
            )}
          </Card>
        </Col>
        <Col span={16}>
          <Card size='small' title={getTitle()} headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 250px' }}>
              <div>
                {facet === 'ox' ? (
                  <OXDetection readings={readings} setReadings={setReadings} setBusy={setBusy} />
                ) : undefined}
                {facet === 'bt' ? (
                  <BTDetection readings={readings} setReadings={setReadings} setBusy={setBusy} />
                ) : undefined}
                {facet === 'bg' ? (
                  <BGDetection readings={readings} setReadings={setReadings} setBusy={setBusy} />
                ) : undefined}
                {facet === 'bp' ? (
                  <BPDetection
                    userRef={userRef}
                    bookingRef={bookingRef}
                    readings={readings}
                    setReadings={setReadings}
                    setBusy={setBusy}
                  />
                ) : undefined}
                {/* <ECGDetection /> */}
              </div>
              <div>
                <Space direction='vertical'>
                  <CustomButton
                    disabled={busy || readings.length > 0}
                    onClick={() => {
                      setFacet('ox')
                    }}
                    text={'Oxygen'}
                    type={facet === 'ox' ? 'primary' : 'standard'}
                    style={{ width: 250 }}
                    size='small'
                  />
                  <CustomButton
                    disabled={busy || readings.length > 0}
                    onClick={() => {
                      setFacet('bt')
                    }}
                    text={'Temperature'}
                    type={facet === 'bt' ? 'primary' : 'standard'}
                    style={{ width: 250 }}
                    size='small'
                  />
                  <CustomButton
                    disabled={busy || readings.length > 0}
                    onClick={() => {
                      setFacet('bg')
                    }}
                    text={'Blood Glucose'}
                    type={facet === 'bg' ? 'primary' : 'standard'}
                    style={{ width: 250 }}
                    size='small'
                  />
                  <CustomButton
                    disabled={busy || readings.length > 0}
                    onClick={() => {
                      setFacet('bp')
                    }}
                    text={'Blood Pressure'}
                    type={facet === 'bp' ? 'primary' : 'standard'}
                    style={{ width: 250 }}
                    size='small'
                  />
                </Space>
                {/* <CustomButton text={''} type='primary' style={{ width: 250 }} size='small' /> */}
              </div>
            </div>
          </Card>
        </Col>
      </CustomRow>
    </div>
  )
}

export default App
