import { createSlice } from '@reduxjs/toolkit'
import PatMacroTrackerState from './state'

const patMacroTrackerReducer = createSlice({
  name: 'patMacroTracker',
  initialState: PatMacroTrackerState,
  reducers: {
    setLimits: (state, action) => {
      state.limits = action.payload
    },
    updateLimits: (state, action) => {
      state.limits = action.payload
    },
    setSavedMeals: (state, action) => {
      state.savedMeals = action.payload
    },
    updateSavedMeals: (state, action) => {
      state.savedMeals = action.payload
    },
    addSavedMealEntry: (state, action) => {
      state.savedMeals = [...state.savedMeals, action.payload]
    },
    setTrackerData: (state, action) => {
      state.trackerData = action.payload
    },
    updateTrackerData: (state, action) => {
      state.trackerData = action.payload
    },
    addTrackerDataEntry: (state, action) => {
      state.trackerData = [...state.trackerData, action.payload]
    },
    setHealthyAlternatives: (state, action) => {
      state.healthyAlternatives = action.payload
    },
    addHealthyAlternativeEntry: (state, action) => {
      state.healthyAlternatives = [...state.healthyAlternatives, action.payload]
    },
    updateHealthyAlternatives: (state, action) => {
      state.healthyAlternatives = action.payload
    }
  }
})

export default patMacroTrackerReducer
