const PatMacroTrackerState = {
  limits: {
    macros: { prot: 0, fat: 0, carb: 0, sug: 0, cal: 0 }
  },
  savedMeals: [],
  trackerData: [],
  healthyAlternatives: []
}

export default PatMacroTrackerState
