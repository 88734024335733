import React, { useState } from 'react'
import { Button, Col, Modal, Space, message, theme } from 'antd'
import CustomRow from '../../../reusable-components/CustomRow'
import { handleError, hexToRGBA } from '../../../lib/utils'
import { batch, useDispatch, useSelector } from 'react-redux'
import EditPatientProfile from '../../../Admin/system-users/components/EditPatient'
import systemUsersReducer from '../../../Admin/system-users/utils/reducer'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt } from '@fortawesome/free-solid-svg-icons'
import CustomButton from '../../../reusable-components/CustomButton'

const PatientInformationCard = ({
  data,
  setData,
  previousData,
  previousDataLoading,
  medicalAidInfo = false,
  checkInInfo = true,
  testRequiredInfo = true
}) => {
  const { token } = theme.useToken()
  const [editPatientModalOpen, setEditPatientModalOpen] = useState(false)
  const [editMainMemberModalOpen, setEditMainMemberModalOpen] = useState(false)
  const activePatientRecords = useSelector((state) => state.systemUsers.activeRecords)
  const dispatch = useDispatch()

  const getAge = (dateOfBirth) => {
    // Parse the date of birth string into a Date object
    const dob = new Date(dateOfBirth)

    // Get the current date
    const now = new Date()

    // Calculate the difference in milliseconds between the current date and the date of birth
    let ageDiff = now - dob

    // Convert the age difference from milliseconds to years
    let ageDate = new Date(ageDiff)
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970)

    return calculatedAge
  }
  const handleEditPatientProfile = async (isMainMember) => {
    const tmpActiveRecords = activePatientRecords.concat()
    let tmpIndex = null

    try {
      if (isMainMember) {
        tmpIndex = tmpActiveRecords.findIndex((item) => item._id === data.mainMemberProfile._id)
        if (tmpIndex === -1) {
          tmpActiveRecords.push(data.mainMemberProfile)
        } else {
          tmpActiveRecords[tmpIndex] = data.mainMemberProfile
        }
      } else {
        tmpIndex = tmpActiveRecords.findIndex((item) => item._id === data.userRef)
        if (tmpIndex === -1) {
          tmpActiveRecords.push(data.patientRecord)
        } else {
          tmpActiveRecords[tmpIndex] = data.patientRecord
        }
      }

      batch(() => {
        dispatch(systemUsersReducer.actions.setActiveRecords(tmpActiveRecords))
      })
      if (isMainMember) {
        setEditMainMemberModalOpen(true)
      } else {
        setEditPatientModalOpen(true)
      }
    } catch (e) {
      message.error(handleError(e))
    }
  }
  const onAfterRequest = (response, newRecord) => {
    if (response === 'cancel') {
      setEditPatientModalOpen(false)
      setEditMainMemberModalOpen(false)
    } else {
      if (editMainMemberModalOpen) {
        setData({ ...data, mainMemberProfile: newRecord })
      } else {
        setData({ ...data, patientRecord: newRecord })
      }
      setEditPatientModalOpen(false)
      setEditMainMemberModalOpen(false)
    }
  }
  return (
    <>
      <Modal footer={false} destroyOnClose width={1150} open={editPatientModalOpen || editMainMemberModalOpen}>
        {editPatientModalOpen || editMainMemberModalOpen ? (
          <EditPatientProfile
            refreshView={onAfterRequest}
            isExamination
            dataId={editMainMemberModalOpen ? data.mainMemberProfile._id : data.userRef}
          />
        ) : undefined}
      </Modal>
      {/* <CustomRow
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, backgroundColor: token.colorPrimary }}
        className='basic-card'
      >
        <Col span={24}>
          <h1 style={{ color: '#fff' }}>
            <center>{`Patient: ${data.patientRecord.firstName} ${data.patientRecord.lastName}`.toUpperCase()}</center>
          </h1>
        </Col>
      </CustomRow> */}
      <CustomRow
        gutter={[0, 0]}
        className='basic-card'
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, paddingBottom: 0 }}
      >
        <Col span={24}>
          <div style={{ display: 'grid', gridTemplateColumns: '350px 1fr' }}>
            <div
              style={{
                minHeight: '100%',
                width: 350,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                border: `1px ${token.colorPrimary} solid`,
                alignItems: 'center'
              }}
            >
              {data.patientRecord?.profileImage ? (
                <img src={data.patientRecord.profileImage} style={{ width: '100%' }} alt='Patient' />
              ) : (
                <FontAwesomeIcon icon={faUserAlt} fontSize={72} />
              )}
            </div>
            <div>
              <Space
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
                  padding: 12,
                  background: hexToRGBA(token.colorPrimary, 0.2)
                }}
              >
                <div>
                  <h1>
                    {data.patientRecord.firstName?.toUpperCase()} {data.patientRecord.lastName?.toUpperCase()}
                  </h1>
                  {data.mainMemberProfile ? (
                    <small>
                      MAIN MEMBER: {data.mainMemberProfile.firstName?.toUpperCase()}{' '}
                      {data.mainMemberProfile.lastName?.toUpperCase()}
                    </small>
                  ) : undefined}
                </div>
                <Space>
                  <Button
                    type='primary'
                    onClick={() => {
                      handleEditPatientProfile()
                    }}
                  >
                    Edit Patient Profile
                  </Button>
                  {data.mainMemberProfile ? (
                    <CustomButton
                      size='small'
                      text='Edit Main Member Profile'
                      type='secondary'
                      onClick={() => {
                        handleEditPatientProfile(true)
                      }}
                    />
                  ) : undefined}
                </Space>
              </Space>
              <div style={{ padding: '12px 15px' }}>
                <Space style={{ width: '100%', alignItems: 'flex-start' }}>
                  <Space direction='vertical'>
                    <Space>
                      <p style={{ fontWeight: 'bold' }}>Date Of Birth:</p>
                      <p>{dayjs(data.patientRecord.dateOfBirth).format('DD MMMM YYYY')}</p>
                    </Space>
                    <Space>
                      <p style={{ fontWeight: 'bold' }}>Age:</p>
                      <p>{getAge(data.patientRecord?.dateOfBirth)}</p>
                    </Space>
                    <Space>
                      <p style={{ fontWeight: 'bold' }}>Gender:</p>
                      <p>{data.patientRecord.gender.charAt(0).toUpperCase() + data.patientRecord.gender.slice(1)}</p>
                    </Space>
                    {medicalAidInfo ? (
                      <Col span={24}>
                        <Space>
                          <p style={{ fontWeight: 'bold' }}>Address:</p>
                          <p>{data.patientRecord.residentialAddress}</p>
                        </Space>
                      </Col>
                    ) : null}
                    <Space>
                      <p style={{ fontWeight: 'bold' }}>ID / Passport No:</p>
                      <p>{data.patientRecord.idNo}</p>
                    </Space>
                    <Space>
                      <p style={{ fontWeight: 'bold' }}>Email Address:</p>
                      <p>
                        {data.patientRecord.email || data.patientRecord.tmpEmail
                          ? data.patientRecord.email || data.patientRecord.tmpEmail
                          : 'N/A'}
                      </p>
                    </Space>
                    <Space>
                      <p style={{ fontWeight: 'bold' }}>Phone Number:</p>
                      <p>
                        {data.patientRecord.phoneNumber || data.patientRecord.tmpPhoneNumber
                          ? data.patientRecord.phoneNumber || data.patientRecord.tmpPhoneNumber
                          : 'N/A'}
                      </p>
                    </Space>
                    {data.patientRecord.medicalAid?.name ? (
                      <>
                        <Space>
                          <p style={{ fontWeight: 'bold' }}>Medical Aid:</p>
                          <p>{data.patientRecord.medicalAid?.name}</p>
                        </Space>
                      </>
                    ) : (
                      <Space>
                        <p style={{ fontWeight: 'bold' }}>Medical Aid:</p>
                        <p>Private</p>
                      </Space>
                    )}
                  </Space>
                </Space>
              </div>
            </div>
          </div>
        </Col>
      </CustomRow>
    </>
  )
}

export default PatientInformationCard
