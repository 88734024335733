import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Form, Input, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faCcVisa } from '@fortawesome/free-brands-svg-icons'
import '../styles/CardPayment.css'
import landingImage from '../../../assets/images/landing-image.png'
import EmbeddedPayment from './EmbeddedPayment'

const CardPayment = ({ onSubmit, onBack, amount }) => {
  const [loading, setLoading] = useState(false)
  const [cardNumber, setCardNumber] = useState('•••• •••• •••• ••••')
  const [cardName, setCardName] = useState('YOUR NAME')
  const [expiryDate, setExpiryDate] = useState('MM/YY')
  const [isCardFlipped, setIsCardFlipped] = useState(false)
  const [cvv, setCvv] = useState('•••')

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      await onSubmit(values)
    } finally {
      setLoading(false)
    }
  }

  // Format card number with spaces
  const formatCardNumber = (value) => {
    if (!value) return '•••• •••• •••• ••••'
    // Remove any spaces and non-digits
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')

    // Add space after every 4 digits while typing
    const formatted = v.replace(/(\d{4})(?=\d)/g, '$1 ')
    return formatted
  }

  // Format expiry date
  const formatExpiryDate = (value) => {
    if (!value) return 'MM/YY'
    const v = value.replace(/\D/g, '')

    // Add slash while typing
    if (v.length >= 2) {
      return `${v.substring(0, 2)}/${v.substring(2, 4)}`
    }
    return v
  }

  // Handle expiry date input
  const handleExpiryChange = (e) => {
    let { value } = e.target
    value = value.replace(/\D/g, '')

    if (value.length > 4) return

    if (value.length >= 2) {
      const month = parseInt(value.substring(0, 2))
      if (month > 12) {
        value = '12' + value.substring(2)
      }
    }

    setExpiryDate(formatExpiryDate(value))
  }

  // Handle card number input
  const handleCardNumberChange = (e) => {
    const formattedValue = formatCardNumber(e.target.value)
    setCardNumber(formattedValue)
  }

  // Handle name input
  const handleNameChange = (e) => {
    const upperName = e.target.value.toUpperCase()
    setCardName(upperName || 'YOUR NAME')
  }

  // Handle CVV focus/blur
  const handleCvvFocus = () => {
    setIsCardFlipped(true)
  }

  const handleCvvBlur = () => {
    setIsCardFlipped(false)
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='card-payment-container'>
      <div className='payment-header'>
        <h2>Card Payment</h2>
      </div>

      <div className='card-form'>
        <div className='card-flip-container'>
          <div className={`card-flipper ${isCardFlipped ? 'flipped' : ''}`}>
            {/* Front of card */}
            <div className='virtual-card'>
              <div className='card-top'>
                <div className='card-chip'>
                  <img src={landingImage} alt='Card Chip' />
                </div>
              </div>

              <div className='card-number'>{cardNumber}</div>

              <div className='card-details'>
                <div className='card-holder'>
                  <div className='card-validity'>Card Holder</div>
                  <span>{cardName}</span>
                </div>
                <div className='card-expires'>
                  <div className='card-validity'>Expires</div>
                  <span>{expiryDate}</span>
                </div>
              </div>

              <div className='card-brand'>
                <FontAwesomeIcon icon={faCcVisa} />
              </div>
            </div>

            {/* Back of card */}
            <div className='virtual-card-back'>
              <div className='security-message'>We will never ask you for your CVV.</div>
              <div className='magnetic-stripe'></div>
              <div className='cvv-panel'>
                <div className='cvv-label'>CVV</div>
                <div className='cvv-number'>{cvv || '•••'}</div>
              </div>
            </div>
          </div>
        </div>

        <div className='form-section'>
          <Form layout='vertical' onFinish={handleSubmit}>
            <Form.Item
              label='Card Number'
              name='cardNumber'
              rules={[{ required: true, message: 'Please enter card number' }]}
            >
              <Input placeholder='1234 5678 9012 3456' maxLength={19} onChange={handleCardNumberChange} />
            </Form.Item>

            <Form.Item
              label='Cardholder Name'
              name='cardName'
              rules={[{ required: true, message: 'Please enter cardholder name' }]}
            >
              <Input placeholder='Name on card' onChange={handleNameChange} style={{ textTransform: 'uppercase' }} />
            </Form.Item>

            <div className='card-row'>
              <Form.Item label='Expiry Date' name='expiryDate' rules={[{ required: true, message: 'Required' }]}>
                <Input placeholder='MM/YY' maxLength={5} onChange={handleExpiryChange} value={expiryDate} />
              </Form.Item>

              <Form.Item label='CVV' name='cvv' rules={[{ required: true, message: 'Required' }]}>
                <Input
                  placeholder='123'
                  maxLength={3}
                  onFocus={handleCvvFocus}
                  onBlur={handleCvvBlur}
                  onChange={(e) => setCvv(e.target.value || '')}
                />
              </Form.Item>
            </div>

            <Button onClick={onBack} className='back-button'>
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </Button>

            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              className='submit-button'
              icon={<FontAwesomeIcon icon={faCreditCard} />}
            >
              Pay R {amount?.toFixed(2)}
            </Button>
          </Form>
        </div>
      </div>
    </motion.div>
  )
}

export default CardPayment
