import React, { useState, memo, useEffect } from 'react'
import { Col, Form, Button, message, theme, Modal, Card, Table, Space, Tooltip, Spin } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faEdit,
  faXmarkCircle,
  faAllergies,
  faPills,
  faSyringe,
  faVirus,
  faUserGroup,
  faPersonRunning,
  faBrain,
  faStethoscope
} from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { setMedicalHistory } from '../utils/medical-history-reducer'
import { handleError, hexToRGBA } from '../../../lib/utils'
import { updatePatient } from '../utils/utils'

// Component imports
import CustomRow from '../../../reusable-components/CustomRow'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import PatientMedicalHistory from './patient-medical-history-summary'

// Section imports
import MedHistAllergies from './sections/allergies'
import MedHistChronicIllnesses from './sections/chronic-illnesses'
import MedHistCovid from './sections/covid-information'
import MedHistFamily from './sections/family-history'
import MedHistLifestyle from './sections/life-style'
import MedHistMentalHealth from './sections/mental-health'
import MedHistSurgeries from './sections/surgeries'
import MedHistGeneralMeds from './sections/general-medications'

// Utility imports
import Templates from '../utils/templates'
import { getMedHistProgress } from '../utils/utils'
import { handleGenerateOldMedHist } from '../../../Bookings/components/Examination Components/utils/additional-screening-lib'
import { isDoctor, isNurse } from '../../../lib/profile-utils'

const MedicalHistoryForm = ({
  data: dependantData,
  setHandleSubmit,
  setNotSaved,
  readOnly,
  wrapperLoad,
  isNurseExamination,
  isDependant,
  userRef,
  summary,
  setSummary,
  closeButton,
  onClose,
  setDependantMedHist,
  isPreviousBooking,
  isPatient,
  refreshView,
  updateParentData
}) => {
  const [clientForm] = Form.useForm()
  const [fieldValues, setFieldValues] = useState(Templates.dataTemplate)
  const [loading, setLoading] = useState(false)
  const [facet, setCurrentFacet] = useState('')
  const [oldMedHistLoading, setOldMedHistLoading] = useState(false)
  const { token } = theme.useToken()
  const state = useSelector((state) => state)
  const dispatch = useDispatch()

  // Add missing state
  const [isLoading, setIsLoading] = useState(false)
  const medicalHistoryState = useSelector((state) => state.medicalHistory.patient)

  // Define medical history sections
  const medHistSections = [
    { title: 'Allergies', key: 'allergies', icon: faAllergies, iconColor: '#10b981' },
    { title: 'Chronic Illnesses', key: 'chronicIllnesses', icon: faStethoscope, iconColor: '#8b5cf6' },
    { title: 'General Medications', key: 'meds', icon: faPills, iconColor: '#f59e0b' },
    { title: 'Covid Information', key: 'covidInfo', icon: faVirus, iconColor: '#10b981' },
    { title: 'Family History', key: 'familyHistory', icon: faUserGroup, iconColor: '#f59e0b' },
    { title: 'Lifestyle', key: 'lifestyle', icon: faPersonRunning, iconColor: '#ec4899' },
    { title: 'Mental Health', key: 'mentalHealth', icon: faBrain, iconColor: '#6366f1' },
    { title: 'Surgical History', key: 'surgicalHistory', icon: faSyringe, iconColor: '#64748b' }
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [facet])

  useEffect(() => {
    if (!dependantData) return
    const formattedData = formatMedicalData(dependantData)
    setFieldValues(formattedData)
    clientForm.setFieldsValue(formattedData)
    // eslint-disable-next-line
  }, [dependantData])

  const formatMedicalData = (incomingData) => {
    const tmpData = JSON.parse(JSON.stringify(incomingData || fieldValues))

    if (!tmpData?.covidInfo?.lastVaccinatedDate) {
      tmpData.covidInfo = {
        ...tmpData.covidInfo,
        lastVaccinatedDate: dayjs(new Date())
      }
    } else {
      tmpData.covidInfo.lastVaccinatedDate = dayjs(new Date(tmpData.covidInfo.lastVaccinatedDate))
    }

    if (tmpData.surgicalHistory?.list?.length > 0) {
      tmpData.surgicalHistory.list = tmpData.surgicalHistory.list.map((entry) => ({
        ...entry,
        date: dayjs(entry.date)
      }))
    }

    return tmpData
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const currentSection = medHistSections.find((i) => i.key === facet)
      if (!currentSection) {
        setLoading(false)
        return
      }

      // Assuming `state.auth.agiliteUser._id` is available in the actual environment:
      await updatePatient(values, {
        userRef: isNurseExamination || isDependant ? userRef : state.auth.agiliteUser._id
      })

      message.success(`${currentSection.title} saved successfully`)
      if (setNotSaved) setNotSaved(false)
      if (isNurseExamination || isDependant) refreshView()

      handleNavigateToNextSection()
    } catch (error) {
      message.error(handleError(error, true))
    }
    setLoading(false)
  }

  const handleNavigateToNextSection = () => {
    const nextIndex = medHistSections.findIndex((i) => i.key === facet) + 1
    if (medHistSections[nextIndex]?.key) {
      setCurrentFacet(medHistSections[nextIndex].key)
    } else {
      setCurrentFacet('')
    }
  }

  if (setHandleSubmit) {
    setHandleSubmit(() => handleSubmit)
  }

  const generateFacetContent = () => {
    switch (facet) {
      case 'allergies':
        return (
          <MedHistAllergies
            clientForm={clientForm}
            saveFunction={handleSubmit}
            loading={loading}
            setFacet={setCurrentFacet}
          />
        )
      case 'chronicIllnesses':
        return (
          <MedHistChronicIllnesses
            clientForm={clientForm}
            saveFunction={handleSave}
            loading={isLoading}
            isDependant={isDependant}
            dependantData={dependantData}
            setFacet={setCurrentFacet}
            userRef={userRef}
            refreshView={refreshView}
            updateParentData={updateParentData}
          />
        )
      case 'meds':
        return (
          <MedHistGeneralMeds
            clientForm={clientForm}
            saveFunction={handleSubmit}
            loading={isLoading}
            setFacet={setCurrentFacet}
          />
        )
      case 'covidInfo':
        return <MedHistCovid clientForm={clientForm} setFacet={setCurrentFacet} />
      case 'familyHistory':
        return <MedHistFamily clientForm={clientForm} setFacet={setCurrentFacet} />
      case 'lifestyle':
        return <MedHistLifestyle clientForm={clientForm} setFacet={setCurrentFacet} />
      case 'mentalHealth':
        return <MedHistMentalHealth clientForm={clientForm} setFacet={setCurrentFacet} />
      case 'surgicalHistory':
        return (
          <MedHistSurgeries
            loading={loading}
            saveFunction={handleSubmit}
            clientForm={clientForm}
            dependantData={dependantData}
            isDependant={isDependant}
            setFacet={setCurrentFacet}
          />
        )
      default:
        return null
    }
  }

  const getSectionTitle = () => {
    let section = medHistSections.find((i) => i.key === facet)
    return section?.title || 'Medical History'
  }

  const handleTableRowClick = (record) => {
    setCurrentFacet(record.key)
  }

  const styles = {
    modalCard: {
      borderRadius: '12px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
      overflow: 'hidden'
    },
    sectionTitle: {
      fontSize: '18px',
      fontWeight: '600',
      textAlign: 'center',
      padding: '20px 0',
      borderBottom: '1px solid #e6e6e6',
      background: 'linear-gradient(to right, #f8f9fa, #ffffff)',
      position: 'relative'
    },
    progressBar: {
      width: '100%',
      height: '4px',
      background: '#f0f0f0',
      marginTop: '8px',
      borderRadius: '2px',
      overflow: 'hidden'
    },
    progressFill: {
      height: '100%',
      background: token.colorSuccess,
      transition: 'width 0.3s ease'
    },
    actionBar: {
      padding: '12px 16px',
      background: '#f8f9fa',
      borderBottom: '1px solid #e6e6e6',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    button: {
      borderRadius: '6px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      padding: '4px 16px',
      height: '34px'
    }
  }

  const handleSave = async (data) => {
    try {
      setIsLoading(true)

      // Merge with existing state
      const updatedData = {
        ...medicalHistoryState,
        chronicIllnesses: data.chronicIllnesses
      }

      // Update Redux state
      dispatch(setMedicalHistory(updatedData))

      // Save to backend
      await updatePatient(updatedData, {
        userRef: isNurseExamination || isDependant ? userRef : state.auth.agiliteUser._id
      })

      setIsLoading(false)
      setCurrentFacet('covidInfo')
    } catch (error) {
      setIsLoading(false)
      message.error(handleError(error, true))
    }
  }

  return (
    <>
      {!isPatient ? (
        <>
          <Spin spinning={wrapperLoad} tip={'Please wait...'}>
            <PatientMedicalHistory isPreviousBooking={isPreviousBooking} data={dependantData} setSummary={setSummary} />
          </Spin>
          <Modal
            open={!summary}
            footer={false}
            width={800}
            bodyStyle={{ padding: 0 }}
            style={{ top: 20 }}
            onCancel={() => setSummary(true)}
            closeIcon={<FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: 20 }} />}
          >
            {!wrapperLoad ? (
              <>
                {facet ? (
                  <Form
                    onKeyDown={(e) => {
                      if (e.target.type === 'submit') e.preventDefault()
                    }}
                    onFinishFailed={(e) => {
                      message.error(e.errorFields[0].errors[0])
                    }}
                    onFieldsChange={() => {
                      setFieldValues(clientForm.getFieldValue())
                      if (setNotSaved) {
                        setNotSaved(true)
                      }
                    }}
                    disabled={readOnly}
                    name='form'
                    form={clientForm}
                    initialValues={fieldValues}
                    layout='vertical'
                  >
                    <Card
                      style={styles.modalCard}
                      title={
                        <div style={styles.sectionTitle}>
                          <div>{medHistSections.findIndex((i) => i.key === facet) + 1}/8</div>
                          <div style={{ fontSize: '24px', marginTop: '4px' }}>{getSectionTitle()}</div>
                          <div style={styles.progressBar}>
                            <div
                              style={{
                                ...styles.progressFill,
                                width: `${((medHistSections.findIndex((i) => i.key === facet) + 1) / 8) * 100}%`
                              }}
                            />
                          </div>
                        </div>
                      }
                      bodyStyle={{ padding: 0 }}
                    >
                      <div style={styles.actionBar}>
                        <Button
                          style={{
                            ...styles.button,
                            backgroundColor: token.colorError,
                            color: 'white'
                          }}
                          onClick={() => setCurrentFacet('')}
                          loading={loading}
                        >
                          Back
                        </Button>
                        <Button
                          style={{
                            ...styles.button,
                            backgroundColor: token.colorSuccess,
                            color: 'white'
                          }}
                          onClick={async () => {
                            try {
                              setLoading(true)
                              await handleSubmit(clientForm.getFieldsValue())
                            } catch (error) {
                              message.error(handleError(error, true))
                            } finally {
                              setLoading(false)
                            }
                          }}
                          loading={loading}
                        >
                          Save & Continue
                        </Button>
                      </div>

                      <div style={{ padding: '20px' }}>{generateFacetContent()}</div>
                    </Card>
                  </Form>
                ) : (
                  <Card
                    style={styles.modalCard}
                    title={
                      <div style={styles.sectionTitle}>
                        Medical History
                        <div style={styles.progressBar}>
                          <div
                            style={{
                              ...styles.progressFill,
                              width: `${getMedHistProgress(dependantData)}%`
                            }}
                          />
                        </div>
                      </div>
                    }
                  >
                    <Table
                      pagination={false}
                      showHeader={false}
                      dataSource={medHistSections}
                      columns={[
                        {
                          title: '',
                          width: '100%',
                          render: (record) => (
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '8px 24px',
                                borderBottom: '1px solid #e5e7eb',
                                transition: 'all 0.2s ease',
                                backgroundColor: 'white'
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = hexToRGBA(token.colorPrimary, 0.15)
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = 'white'
                              }}
                              onClick={() => handleTableRowClick(record)}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <div
                                  style={{
                                    width: '36px',
                                    height: '36px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '8px',
                                    backgroundColor: hexToRGBA(record.iconColor, 0.1)
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={record.icon}
                                    style={{
                                      fontSize: 20,
                                      color: record.iconColor
                                    }}
                                  />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                                  <div
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      marginBottom: '1px'
                                    }}
                                  >
                                    {record.title}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: '11px',
                                      color: '#64748b'
                                    }}
                                  >
                                    Updated:{' '}
                                    {fieldValues[record.key]?.updatedAt
                                      ? dayjs(fieldValues[record.key].updatedAt).format('DD MMM YYYY')
                                      : 'Never'}
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                                <Tooltip title='Edit'>
                                  <Button
                                    type='text'
                                    style={{
                                      width: '32px',
                                      height: '32px',
                                      padding: 0,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '8px',
                                      border: '1px solid #e2e8f0',
                                      color: token.colorPrimary,
                                      background: '#fff'
                                    }}
                                    onMouseEnter={(e) => {
                                      e.currentTarget.style.backgroundColor = hexToRGBA(token.colorSecondary, 0.25)
                                      e.currentTarget.style.transform = 'translateY(-1px)'
                                      e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)'
                                      e.currentTarget.style.borderColor = token.colorSecondary
                                      e.currentTarget.style.color = token.colorSecondary
                                    }}
                                    onMouseLeave={(e) => {
                                      e.currentTarget.style.backgroundColor = '#fff'
                                      e.currentTarget.style.transform = 'none'
                                      e.currentTarget.style.boxShadow = 'none'
                                      e.currentTarget.style.borderColor = '#e2e8f0'
                                      e.currentTarget.style.color = token.colorPrimary
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      setCurrentFacet(record.key)
                                    }}
                                  >
                                    <FontAwesomeIcon style={{ fontSize: 16 }} icon={faEdit} />
                                  </Button>
                                </Tooltip>
                                <Tooltip title={fieldValues[record.key]?.updatedAt ? 'Complete' : 'Incomplete'}>
                                  <Button
                                    type='text'
                                    disabled
                                    style={{
                                      width: '32px',
                                      height: '32px',
                                      padding: 0,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '8px',
                                      border: '1px solid #e2e8f0',
                                      color: fieldValues[record.key]?.updatedAt ? token.colorSuccess : token.colorError,
                                      background: '#fff',
                                      cursor: 'default',
                                      opacity: 1
                                    }}
                                    onMouseEnter={(e) => {
                                      e.currentTarget.style.backgroundColor = hexToRGBA(token.colorSecondary, 0.25)
                                      e.currentTarget.style.transform = 'translateY(-1px)'
                                      e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)'
                                      e.currentTarget.style.borderColor = token.colorSecondary
                                    }}
                                    onMouseLeave={(e) => {
                                      e.currentTarget.style.backgroundColor = '#fff'
                                      e.currentTarget.style.transform = 'none'
                                      e.currentTarget.style.boxShadow = 'none'
                                      e.currentTarget.style.borderColor = '#e2e8f0'
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ fontSize: 16 }}
                                      icon={fieldValues[record.key]?.updatedAt ? faCheckCircle : faXmarkCircle}
                                    />
                                  </Button>
                                </Tooltip>
                              </div>
                            </div>
                          )
                        }
                      ]}
                      style={{
                        borderBottom: 'none'
                      }}
                      className='medical-history-table'
                    />
                  </Card>
                )}
              </>
            ) : (
              <CustomRow className='basic-card'>
                <Col span={24}>
                  <AgiliteSkeleton
                    skActive
                    spinnerTip={`Loading ${isNurseExamination ? 'patient' : 'your'} medical history...`}
                  />
                </Col>
              </CustomRow>
            )}
          </Modal>
        </>
      ) : (
        <>
          {!wrapperLoad ? (
            <>
              {facet ? (
                <Form
                  onKeyDown={(e) => {
                    if (e.target.type === 'submit') e.preventDefault()
                  }}
                  onFinishFailed={(e) => {
                    message.error(e.errorFields[0].errors[0])
                  }}
                  onFieldsChange={() => {
                    setFieldValues(clientForm.getFieldValue())
                    if (setNotSaved) {
                      setNotSaved(true)
                    }
                  }}
                  disabled={readOnly}
                  name='form'
                  form={clientForm}
                  initialValues={fieldValues}
                  layout='vertical'
                >
                  <Card
                    title={
                      <center>
                        {medHistSections.findIndex((i) => i.key === facet) + 1}/8 {getSectionTitle()}
                      </center>
                    }
                    type='inner'
                    bodyStyle={{ padding: 0 }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 35,
                        border: '1px rgba(0,0,0,0.25) solid',
                        alignItems: 'center',
                        display: 'flex',
                        position: 'relative',
                        justifyContent: 'center'
                      }}
                    >
                      <div style={{ position: 'absolute' }}>
                        {dependantData[medHistSections.find((i) => i.key === facet).key]?.updatedAt
                          ? `Updated: ${dayjs(
                              dependantData[medHistSections.find((i) => i.key === facet).key]?.updatedAt
                            ).format('DD MMMM YYYY')}`
                          : 'Updated: Never'}
                      </div>
                    </div>
                    {medHistSections.find((i) => i.key === facet)?.hideSave ? undefined : (
                      <div
                        style={{
                          width: '100%',
                          padding: 8,
                          background: hexToRGBA(token.colorWarning, 0.1),
                          marginTop: 1
                        }}
                      >
                        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                          <Button
                            style={{
                              backgroundColor: token.colorError,
                              color: 'white'
                            }}
                            onClick={() => {
                              setCurrentFacet('')
                            }}
                            loading={loading}
                          >
                            Close
                          </Button>
                          <Form.Item noStyle>
                            <Button
                              style={{
                                backgroundColor: token.colorSuccess,
                                color: 'white'
                              }}
                              htmlType='submit'
                              loading={loading}
                            >
                              Save Changes
                            </Button>
                          </Form.Item>
                        </Space>
                      </div>
                    )}

                    {generateFacetContent()}

                    {medHistSections.find((i) => i.key === facet)?.hideSave ? undefined : (
                      <center>
                        <Form.Item noStyle>
                          <Button
                            style={{
                              backgroundColor: token.colorSuccess,
                              color: 'white',
                              marginBottom: 24
                            }}
                            htmlType='submit'
                            loading={loading}
                          >
                            Save Changes
                          </Button>
                        </Form.Item>
                      </center>
                    )}
                  </Card>

                  {readOnly ? null : (
                    <CustomRow justify='center' style={{ marginTop: 24 }} gutter={[18, 18]}>
                      {closeButton && !readOnly ? (
                        <Col>
                          <Button
                            disabled={loading}
                            danger
                            onClick={() => {
                              Modal.confirm({
                                title: 'Confirmation',
                                content: 'Are you sure you want to close this form? Any unsaved changes will be lost.',
                                onOk: onClose,
                                okButtonProps: { type: 'primary', style: { backgroundColor: token.colorSuccess } },
                                okText: 'Yes',
                                className: token.themeControl,
                                cancelButtonProps: { danger: true }
                              })
                            }}
                          >
                            Close
                          </Button>
                        </Col>
                      ) : null}
                    </CustomRow>
                  )}
                </Form>
              ) : (
                <CustomRow>
                  <Col span={24}>
                    <Card
                      size='small'
                      headStyle={{ padding: 0, minHeight: 0, overflow: 'hidden' }}
                      title={
                        <div
                          style={{
                            width: '100%',
                            height: 35,
                            border: '1px rgba(0,0,0,0.25) solid',
                            alignItems: 'center',
                            display: 'flex',
                            position: 'relative',
                            justifyContent: 'center'
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              display: 'flex',
                              justifyContent: 'center',
                              width: getMedHistProgress(dependantData) + '%',
                              background: token.colorSuccess,
                              height: '100%',
                              left: 0,
                              color: 'black',
                              alignItems: 'center'
                            }}
                          ></div>
                          <div style={{ position: 'absolute' }}>
                            {getMedHistProgress(dependantData) === '100'
                              ? 'COMPLETE'
                              : `${getMedHistProgress(dependantData)}%`}
                          </div>
                        </div>
                      }
                    >
                      {isDoctor() || isNurse() ? (
                        <div style={{ padding: '8px 12px 12px 8px', background: '#f5f5f5' }}>
                          <center>
                            <Space>
                              <Button
                                loading={oldMedHistLoading}
                                type='primary'
                                onClick={() => {
                                  handleGenerateOldMedHist(userRef, setOldMedHistLoading)
                                }}
                              >
                                View Old Medical History
                              </Button>
                              <Button
                                type='success'
                                style={{ background: token.colorSuccess, color: '#fff' }}
                                onClick={() => {
                                  setSummary(true)
                                }}
                              >
                                Finish Editing
                              </Button>
                            </Space>
                          </center>
                        </div>
                      ) : undefined}
                      <Table
                        pagination={false}
                        showHeader={false}
                        dataSource={medHistSections}
                        columns={[
                          {
                            title: '',
                            render: (record) => {
                              let updatedAt = fieldValues[record.key]?.updatedAt
                              return (
                                <>
                                  {record.title}
                                  <br />
                                  <p>
                                    Updated:{' '}
                                    <small>{updatedAt ? dayjs(updatedAt).format('DD MMM YYYY') : 'Never'}</small>
                                  </p>
                                </>
                              )
                            }
                          },
                          {
                            title: 'status',
                            key: 'updatedAt',
                            dataIndex: 'key',
                            render: (key) => {
                              let dataSource = fieldValues[key]
                              if (dataSource?.updatedAt) {
                                return (
                                  <Space>
                                    <Tooltip title='Edit'>
                                      <FontAwesomeIcon
                                        style={{ fontSize: 20 }}
                                        color={token.colorPrimary}
                                        icon={faEdit}
                                      />
                                    </Tooltip>

                                    <Tooltip title='Complete'>
                                      <FontAwesomeIcon
                                        style={{ fontSize: 20 }}
                                        color={token.colorSuccess}
                                        icon={faCheckCircle}
                                      />{' '}
                                    </Tooltip>
                                  </Space>
                                )
                              } else {
                                return (
                                  <Space>
                                    <Tooltip title='Edit'>
                                      <FontAwesomeIcon
                                        style={{ fontSize: 20 }}
                                        color={token.colorPrimary}
                                        icon={faEdit}
                                      />
                                    </Tooltip>

                                    <Tooltip title='Complete'>
                                      <FontAwesomeIcon
                                        style={{ fontSize: 20 }}
                                        color={token.colorError}
                                        icon={faXmarkCircle}
                                      />{' '}
                                    </Tooltip>
                                  </Space>
                                )
                              }
                            }
                          }
                        ]}
                      />
                    </Card>
                  </Col>
                </CustomRow>
              )}
            </>
          ) : (
            <CustomRow className='basic-card'>
              <Col span={24}>
                <AgiliteSkeleton
                  skActive
                  spinnerTip={`Loading ${isNurseExamination ? 'patient' : 'your'} medical history...`}
                />
              </Col>
            </CustomRow>
          )}
        </>
      )}
    </>
  )
}

const BasicForm = memo(MedicalHistoryForm)
export default BasicForm
