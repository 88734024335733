import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Card, Col, Row, Space, theme } from 'antd'
import { hexToRGBA } from '../../../../lib/utils'
import { StandardDataPointGraph } from '../../../examination-utils/standard-graph'

/**
 * GeneralStats component for displaying and managing patient general statistics
 * @param {Object} props - Component props
 * @param {Array<Object>} props.dataPoints - Array of data point templates
 * @param {Array<Object>} props.patientDataEntries - Array of patient data entries
 * @returns {JSX.Element} GeneralStats component
 */
const GeneralStats = ({ dataPoints, patientDataEntries }) => {
  const [selectedStat, setSelectedStat] = useState(null)
  const { token } = theme.useToken()

  // Memoized function to filter and sort patient data by key
  const getStatData = useCallback(
    (key) => {
      if (!patientDataEntries?.length) return []
      return patientDataEntries
        .filter((entry) => entry?.key === key)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    },
    [patientDataEntries]
  )

  // Memoized list of general statistics with their metadata
  const generalStatsList = useMemo(
    () => [
      { title: 'Height', key: 'height', data: getStatData('height'), suffix: 'cm' },
      { title: 'Weight', key: 'weight', data: getStatData('weight'), suffix: 'kg' },
      { title: 'Waist', key: 'waist', data: getStatData('waist'), suffix: 'cm' },
      { title: 'BMI', key: 'bmi', data: getStatData('bmi'), suffix: '' }
    ],
    [getStatData]
  )

  // Initialize and update selected stat
  useEffect(() => {
    if (!selectedStat && generalStatsList?.length) {
      setSelectedStat(generalStatsList[0])
    } else if (selectedStat) {
      const updatedStat = generalStatsList.find((stat) => stat.key === selectedStat.key)
      setSelectedStat(updatedStat)
    }
  }, [generalStatsList, selectedStat])

  /**
   * Renders the latest value for a given statistic
   * @param {Object} stat - Statistic object containing data and metadata
   * @returns {string} Formatted statistic value with suffix
   */
  const formatStatValue = (stat) => {
    if (!stat?.data?.length) return 'No data'
    const latestData = stat.data[stat.data.length - 1]
    return `${latestData.value}${stat.suffix ? ` ${stat.suffix}` : ''}`
  }

  return (
    <Card
      title='General Stats'
      size='small'
      styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
      style={{ overflow: 'hidden' }}
    >
      <Row>
        <Col span={24}>
          <Space
            wrap
            style={{
              background: '#f5f5f5',
              gap: 8,
              border: '5px #f5f5f5 solid',
              borderBottom: 'none',
              width: '100%'
            }}
          >
            {generalStatsList.map((stat) => {
              const hasData = stat.data?.length > 0
              const isSelected = selectedStat?.key === stat.key

              return (
                <div
                  key={stat.key}
                  onClick={() => hasData && setSelectedStat(stat)}
                  style={{
                    padding: 12,
                    borderBottom: '5px solid',
                    cursor: hasData ? 'pointer' : 'not-allowed',
                    borderColor: isSelected ? token.colorPrimary : '#f5f5f5',
                    background: isSelected ? '#ffffff' : '#f5f5f5',
                    transition: 'all 0.3s ease',
                    minWidth: 150
                  }}
                >
                  <Space style={{ gap: 4, width: '100%' }} direction='vertical'>
                    <span>{stat.title}</span>
                    <div>{formatStatValue(stat)}</div>
                  </Space>
                </div>
              )
            })}
          </Space>
        </Col>
        <Col span={24}>
          {selectedStat && dataPoints?.length && (
            <StandardDataPointGraph
              data={{ records: selectedStat.data, key: selectedStat.key }}
              dataTemplate={dataPoints.find((point) => point.key === selectedStat.key)}
            />
          )}
        </Col>
      </Row>
    </Card>
  )
}

export default GeneralStats
