import React, { useState } from 'react'
import Profile from '../../../../Profile/components/Profile'
import AdmitAiWrapper from '../../../AI Tools/Admit Ai'
import MobHealthStatsWrapper from '../../../Health Stats/Mobile/Components/MobHealthStatsWrapper'

const useMobileWrapper = () => {
  const [selectedComponent, setSelectedComponent] = useState(null)
  const [currentPage, setCurrentPage] = useState('home')
  const [headerTitle, setHeaderTitle] = useState('Healthcare Reimagined')

  const handleComponentSelect = (component, title, page) => {
    setSelectedComponent(component)
    setHeaderTitle(title)
    setCurrentPage(page)
  }

  const handleNavigation = (page, component) => {
    if (page === 'home') {
      setSelectedComponent(null)
      setHeaderTitle('Healthcare Reimagined')
      setCurrentPage('home')
    } else if (page === 'health') {
      handleComponentSelect(<MobHealthStatsWrapper />, 'My Health', 'health')
    } else if (page === 'profile') {
      handleComponentSelect(<Profile />, 'My Profile', 'profile')
    } else if (page === 'game') {
      handleComponentSelect(<AdmitAiWrapper />, 'AdmitAI', 'game')
    } else {
      setSelectedComponent(component)
      setCurrentPage(page)
    }
  }

  const handleQuickButtonClick = (component, title, page) => {
    handleComponentSelect(component, title, page)
  }
  return { selectedComponent, currentPage, handleQuickButtonClick, headerTitle, handleNavigation }
}

export default useMobileWrapper
