import React from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { Col, theme } from 'antd'
import dayjs from 'dayjs'
import { convertDateTimeSAST } from '../../../lib/utils'

const BookingsCountSummary = ({ todaysBookingsCount, monthsBookingsCount }) => {
  const { token } = theme.useToken()
  const getMonthTitle = () => {
    let todaysDate = convertDateTimeSAST(new Date(), true)
    let month = dayjs(todaysDate).month()
    return dayjs(todaysDate).set('month', month).set('date', 1).format('MMMM')
  }
  return (
    <CustomRow gutter={[0, 24]}>
      <Col span={24}>
        <CustomRow
          className='basic-card'
          style={{ background: token.colorPrimary, color: 'white', marginTop: 10 }}
          justify='space-between'
        >
          <Col>
            Appointments left for today: <br />
            <span style={{ fontSize: 64 }}>{todaysBookingsCount}</span>
          </Col>
          <Col style={{ height: '100%' }}>
            <CustomRow style={{ height: '100%' }}>
              <Col>
                <CustomRow className='basic-card' style={{ height: '100%', background: token.colorPrimaryLight }}>
                  <Col span={24}>
                    <div style={{ margin: 0 }}>
                      <b style={{ fontSize: 16 }}>Total appointments</b>
                    </div>
                    <div>for the month of {getMonthTitle()}</div>
                    <div style={{ fontSize: 36 }}>{monthsBookingsCount}</div>
                  </Col>
                </CustomRow>
              </Col>
            </CustomRow>
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default BookingsCountSummary
