import { assistantCheckRunStatus, assistantReadThread } from './utils'

const generateAIResponse = (tmpData) => {
  return new Promise((resolve, reject) => {
    const intervalId = setInterval(async () => {
      try {
        let response = await checkStatus(tmpData.thread_id, tmpData.run_id)

        if (response.status === 'completed') {
          clearInterval(intervalId)

          response = await assistantReadThread(tmpData.thread_id)

          resolve(response)
        } else if (response.status === 'failed') {
          clearInterval(intervalId)
          reject(new Error('AI processing failed'))
        }
      } catch (error) {
        clearInterval(intervalId)
        reject(error)
      }
    }, 2000)

    // Add timeout after 30 seconds
    setTimeout(() => {
      clearInterval(intervalId)
      reject(new Error('AI response timeout'))
    }, 30000)
  })
}

const checkStatus = async (thread_id, run_id) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let runState = await assistantCheckRunStatus(thread_id, run_id)
        resolve(runState)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export { generateAIResponse }
