import React, { useState, useCallback } from 'react'
import CustomRow from '../../../../reusable-components/CustomRow'

import { Col, Modal } from 'antd'

import ExaminationProceduresPerformed from '../../examination-procedures-performed'
import LabResults from '../Lab Results/lab-results'
import LabOrdersWrapper from '../Lab Orders/LabOrdersWrapper'
import VitalSigns from './VitalSigns'
import GeneralStats from './GeneralStats'
import { useSelector } from 'react-redux'
import ClinicalTesting from './ClinicalTesting'

// Constants
const MODAL_WIDTH = 1920

/**
 * PatientDataDisplay Component
 * Displays and manages patient examination data, lab results, and vital signs
 */
const PatientDataDisplay = ({
  patientDataEntries,
  setPatientDataEntries,
  otherDataEntries,
  websocketConnection,
  userRef,
  bookingData,
  readOnly
}) => {
  // State Management
  const { loading: dataPointsLoading } = useSelector((state) => state.dataPoints)
  const { loading: templatesLoading } = useSelector((state) => state.examTemplates)
  const isLoading = dataPointsLoading || templatesLoading

  const dataPoints = useSelector((state) => state.dataPoints.data)
  const dataTemplates = useSelector((state) => state.examTemplates.data)
  const [labOrders, setLabOrders] = useState([])

  // Modal States
  const [labReportModalOpen, setLabReportModalOpen] = useState(false)
  const [labOrderModalOpen, setLabOrderModalOpen] = useState(false)

  // Memoized helper functions
  const filterPatientEntries = useCallback(
    (type) => {
      return patientDataEntries.filter((entry) => {
        const itemReference = dataPoints.find((point) => entry.key === point.key)
        return itemReference?.category === type
      })
    },
    [patientDataEntries, dataPoints]
  )

  const handleSuccessfulLabOrder = useCallback((data) => {
    setLabOrders((prev) => [...prev, data])
  }, [])

  // Memoized components and values

  return (
    <CustomRow style={{ marginTop: 12 }}>
      <Col span={24}>
        <CustomRow>
          <Col span={24}>
            <VitalSigns
              dataPoints={dataPoints.filter((point) => point.category === 'vitals')}
              otherDataEntries={otherDataEntries}
              patientDataEntries={filterPatientEntries('vitals')}
              setPatientDataEntries={setPatientDataEntries}
            />
          </Col>
          <Col span={24}>
            <GeneralStats
              dataPoints={dataPoints.filter((point) => point.category === 'general')}
              otherDataEntries={otherDataEntries}
              patientDataEntries={filterPatientEntries('general')}
              setPatientDataEntries={setPatientDataEntries}
            />
          </Col>
          <Col span={24}>
            <ClinicalTesting
              setLabReportModalOpen={setLabReportModalOpen}
              dataPoints={dataPoints}
              patientDataEntries={patientDataEntries}
            />
          </Col>
          {!readOnly && (
            <Col span={24}>
              <ExaminationProceduresPerformed
                dataPoints={dataPoints.filter((point) => point.category === 'procedures')}
                otherDataEntries={otherDataEntries}
                patientDataEntries={filterPatientEntries('procedures')}
                setPatientDataEntries={setPatientDataEntries}
                bookingData={bookingData}
              />
            </Col>
          )}
        </CustomRow>
      </Col>

      <Modal width={MODAL_WIDTH} closable={false} maskClosable={false} footer={false} open={labReportModalOpen}>
        <LabResults
          setModalOpen={setLabReportModalOpen}
          userRef={bookingData ? bookingData.userRef : userRef}
          patientDataEntries={patientDataEntries}
          dataPoints={dataPoints}
          otherDataEntries={otherDataEntries}
          socket={null}
          modalOpen={labReportModalOpen}
          dataTemplates={dataTemplates}
          websocketConnection={websocketConnection}
          bookingData={bookingData}
        />
      </Modal>

      <Modal width={MODAL_WIDTH} closable={false} maskClosable={false} footer={false} open={labOrderModalOpen}>
        <LabOrdersWrapper onAfterSuccess={handleSuccessfulLabOrder} setModalOpen={setLabOrderModalOpen} />
      </Modal>
    </CustomRow>
  )
}

export default React.memo(PatientDataDisplay)
