import { Form } from 'antd'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CustomRow from '../../../../../reusable-components/CustomRow'
import { CATEGORIES, CATEGORY_COLORS } from '../utils/enums'
import { categoryIcons } from '../utils/constants'
import FormSection from './FormSection'

const FormSections = ({ selectedDataPoints = [], isAddingTemplate, activeTab, onSubmit, form }) => {
  // Memoize category items to prevent unnecessary recalculations
  const getCategoryItems = useMemo(() => {
    return (category) => {
      if (!Array.isArray(selectedDataPoints)) return []

      return selectedDataPoints
        .map((jsonData) => {
          try {
            return JSON.parse(jsonData)
          } catch (e) {
            console.error('Failed to parse JSON data:', e, jsonData)
            return null
          }
        })
        .filter((selection) => selection && selection.category === category)
    }
  }, [selectedDataPoints])

  // Memoize filtered categories
  const filteredCategories = useMemo(() => {
    return Object.values(CATEGORIES).filter((category) => category !== CATEGORIES.TEMPLATES)
  }, [])

  return (
    <Form layout='vertical' form={form} onFinish={onSubmit} style={{ maxHeight: '65vh', overflow: 'auto' }}>
      <CustomRow wrap gutter={[8, 8]}>
        {filteredCategories.map((category) => (
          <FormSection
            key={category}
            category={category}
            headerColor={CATEGORY_COLORS[category]}
            icon={categoryIcons[category]}
            title={category.charAt(0).toUpperCase() + category.slice(1)}
            selectedItems={getCategoryItems(category)}
            activeTab={activeTab}
            isAddingTemplate={isAddingTemplate}
          />
        ))}
      </CustomRow>
    </Form>
  )
}

FormSections.propTypes = {
  selectedDataPoints: PropTypes.arrayOf(PropTypes.string),
  isAddingTemplate: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
}

export default React.memo(FormSections)
