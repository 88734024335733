import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faCaretRight, faClinicMedical, faVideoCamera, faWarning } from '@fortawesome/free-solid-svg-icons'
import '../styling/MobileBookingShortcut.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { checkSubscriptions } from '../../../../Subscriptions/utils/utils'
import CustomButton from '../../../../reusable-components/CustomButton'
import { beginVirtBooking } from '../../../Virtual Consultation/api'
import coreReducer from '../../../../../core/utils/reducer'
import CoreEnums from '../../../../../core/utils/enums'
import PatClinicConsultBooking from '../../../Clinic Consultation'
import { Col, Row, theme, Button } from 'antd'

const MobBookingShortcuts = ({ showSOS }) => {
  const dispatch = useDispatch()
  const entityState = useSelector((state) => state.core.entity)

  const containerStyle = {
    width: '100%',
    display: 'flex',
    gap: 8
  }

  const buttonStyle = {
    flex: 1,
    height: '50px',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    fontSize: '14px',
    fontWeight: '600',
    border: '1px solid #10B2E1',
    color: '#ffffff',
    background: 'rgba(16, 178, 225, 01)',
    position: 'relative',
    padding: '0 16px',
    lineHeight: '1',
    textTransform: 'uppercase',
    minWidth: 'unset'
  }

  const sosButtonStyle = {
    ...buttonStyle,
    background: 'rgba(233, 30, 99, 0.1)',
    border: '1px solid #E91E63',
    color: '#E91E63'
  }

  const handleClinicBooking = (isReceptionBooking, dispatcher) => {
    dispatcher(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.MY_BOOKINGS}_new_clinic_booking`,
        closable: true,
        label: 'New Clinic Booking',
        children: <PatClinicConsultBooking />
      })
    )
  }
  const { token } = theme.useToken()
  return (
    <Row>
      <Col span={24}>
        <div
          style={{
            textAlign: 'center',
            fontSize: 12,
            color: '#ffffff',
            marginBottom: 12,
            background: `linear-gradient(90deg, ${token.colorPrimary} 0%, ${token.colorSecondary} 100%)`,
            padding: 12,
            borderRadius: 12
          }}
        >
          BOOK ONLINE NOW
        </div>
      </Col>
      <Col span={24}>
        <div style={containerStyle}>
          <div className='mobile-booking-button'>
            <div className='icon-circle' style={{ background: '#ffffff' }}>
              <FontAwesomeIcon icon={faClinicMedical} />
            </div>
            <Button
              style={{
                ...buttonStyle,
                display: 'flex',
                alignItems: 'center',
                fontSize: 20
              }}
              onClick={() => handleClinicBooking(false, dispatch)}
              type='primary'
            >
              Clinic
              <FontAwesomeIcon icon={faCaretRight} />
            </Button>
          </div>

          <div className='mobile-booking-button'>
            <div className='icon-circle' style={{ background: '#ffffff' }}>
              <FontAwesomeIcon icon={faVideoCamera} />
            </div>
            <Button
              style={{
                ...buttonStyle,
                fontSize: 20,
                display: 'flex',
                alignItems: 'center',
                background: !entityState.virtualConsultations?.enabled ? '#ccc' : undefined
              }}
              onClick={() => beginVirtBooking(dispatch)}
              type='primary'
              disabled={!entityState.virtualConsultations?.enabled}
            >
              Virtual
              <FontAwesomeIcon icon={faCaretRight} />
            </Button>
          </div>

          {entityState.vitalLink?.enabled && showSOS && (
            <div className='mobile-booking-button'>
              <div className='icon-circle'>
                <FontAwesomeIcon icon={faWarning} />
              </div>
              <CustomButton
                style={sosButtonStyle}
                onClick={() => window.open('tel:+27')}
                type='danger'
                text='SOS'
                disabled={!checkSubscriptions('vitalLink')}
                tooltip={!checkSubscriptions('vitalLink') ? 'Meta-Link Subscription Required' : undefined}
              />
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default MobBookingShortcuts
