import React from 'react'
import { Button, Card, Space, theme } from 'antd'
import { Cell, Pie, PieChart } from 'recharts'
import { hexToRGBA } from '../../../../../../lib/utils'

const MobileMacroRings = ({ macroData, limits, servings, setServings, onBack, onConfirm, saving, noBottomPadding }) => {
  const renderNutrientRing = (key, color, label) => {
    const value = macroData.macros[key] * servings
    const percentage = (value / limits?.macros[key]) * 100
    const ringData = [
      { value: percentage > 100 ? 100 : percentage },
      { value: percentage > 100 ? 0 : 100 - percentage }
    ]

    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          aspectRatio: '1',
          padding: '12px',
          background: 'rgba(16, 178, 225, 0.05)',
          borderRadius: '8px'
        }}
      >
        <PieChart width={100} height={100}>
          <Pie data={ringData} innerRadius={35} outerRadius={45} paddingAngle={5} dataKey='value' stroke='none'>
            {ringData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={index === 0 ? color : hexToRGBA(color, 0.2)} />
            ))}
          </Pie>
        </PieChart>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '4px'
          }}
        >
          <span style={{ fontSize: '16px', fontWeight: '500' }}>{value.toFixed(1)}g</span>
          <span style={{ fontSize: '12px' }}>{label}</span>
        </div>
      </div>
    )
  }

  const handleServingChange = (action) => {
    if (action === 'decrease' && servings > 1) {
      setServings(servings - 1)
    } else if (action === 'increase') {
      setServings(servings + 1)
    }
  }

  const { token } = theme.useToken()

  return (
    <div
      style={{
        position: 'relative',

        marginBottom: '0',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch'
      }}
    >
      <Card
        style={{
          border: '1px solid rgba(16, 178, 225, 0.15)'
        }}
      >
        <div
          style={{
            marginBottom: '8px',
            padding: '8px',
            background: 'rgba(16, 178, 225, 0.05)',
            borderRadius: '8px'
          }}
        >
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '14px' }}>Servings:</span>
            <Space>
              <Button
                onClick={() => handleServingChange('decrease')}
                style={{ backgroundColor: token.colorPrimary, border: 'none' }}
              >
                -
              </Button>
              <span
                style={{
                  minWidth: '24px',
                  textAlign: 'center',
                  fontWeight: '500',
                  color: 'white'
                }}
              >
                {servings}
              </span>
              <Button
                onClick={() => handleServingChange('increase')}
                style={{ backgroundColor: token.colorPrimary, color: 'white', border: 'none' }}
              >
                +
              </Button>
            </Space>
          </Space>
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'repeat(2, 1fr)',
            gap: '16px',
            margin: '16px 0 8px 0'
          }}
        >
          {renderNutrientRing('prot', '#ff8000', 'Protein')}
          {renderNutrientRing('fat', '#2ecc71', 'Fats')}
          {renderNutrientRing('carb', '#3498db', 'Carbs')}
          {renderNutrientRing('sug', '#ff1493', 'Sugar')}
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '16px',
            marginTop: '8px',
            marginBottom: '0',
            paddingBottom: '0'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              padding: '12px',
              background: 'rgba(16, 178, 225, 0.05)',
              borderRadius: '8px'
            }}
          >
            <strong style={{ marginBottom: '4px', fontSize: '14px' }}>Total Calories</strong>
            <p style={{ color: 'white', fontSize: '16px', fontWeight: '500', margin: '0' }}>
              {(macroData.macros.cal * servings).toFixed(0)} kcal
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              padding: '12px',
              background: 'rgba(16, 178, 225, 0.05)',
              borderRadius: '8px'
            }}
          >
            <strong style={{ marginBottom: '4px', fontSize: '14px' }}>Serving Size</strong>
            <p style={{ fontSize: '16px', fontWeight: '500', margin: '0' }}>
              {(macroData.macros.grams * servings).toFixed(0)} g
            </p>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default MobileMacroRings
