import React, { useState } from 'react'
import { Form, Card, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faXmarkCircle,
  faAllergies,
  faPills,
  faSyringe,
  faVirus,
  faUserGroup,
  faPersonRunning,
  faBrain,
  faStethoscope
} from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import MobLifestyle from './MobLifestyle'
import MobAllergies from './MobAllergies'
import MobMentalHealth from './MobMentalHealth'
import MobChronicIllnesses from './MobChronicIllnesses'
import MobFamilyHistory from './MobFamilyHistory'
import MobCovidInfo from './MobCovidInfo'
import MobGeneralMeds from './MobGeneralMeds'
import MobSurgeries from './MobSurgeries'
import '../style/MobMedicalHistoryForm.css'
import MobMedicalHistoryLoadingOverlay from './LoadingOverlay'
import { useSelector } from 'react-redux'

const MobMedicalHistoryForm = ({ data, fieldValues }) => {
  const [saving, setSaving] = useState(false)
  const [selectedFacet, setSelectedFacet] = useState(null)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const patMedicalHistory = useSelector((state) => state.patMedHist.data)
  const medHistSections = [
    { title: 'Allergies', key: 'allergies', icon: faAllergies, color: '16, 185, 129' },
    { title: 'Chronic Illnesses', key: 'chronicIllnesses', icon: faStethoscope, color: '139, 92, 246' },
    { title: 'General Medications', key: 'meds', icon: faPills, color: '245, 158, 11' },
    { title: 'Covid Information', key: 'covidInfo', icon: faVirus, color: '16, 185, 129' },
    { title: 'Family History', key: 'familyHistory', icon: faUserGroup, color: '245, 158, 11' },
    { title: 'Lifestyle', key: 'lifestyle', icon: faPersonRunning, color: '236, 72, 153' },
    { title: 'Mental Health', key: 'mentalHealth', icon: faBrain, color: '99, 102, 241' },
    { title: 'Surgical History', key: 'surgicalHistory', icon: faSyringe, color: '100, 116, 139' }
  ]

  const handleSave = async () => {
    setLoading(true)
    try {
      // Your save logic here
      setLoading(false)
    } catch (error) {
      console.error('Error saving:', error)
      setLoading(false)
    }
  }

  const renderMainView = () => {
    return (
      <Space direction='vertical' style={{ width: '100%', padding: 12 }}>
        {medHistSections.map((section) => {
          const isComplete = patMedicalHistory[section.key]?.updatedAt
          return (
            <Card
              key={section.key}
              className='section-card'
              onClick={() => setSelectedFacet(section.key)}
              style={{
                '--section-color': section.color,
                boxShadow:
                  '0 4px 20px rgba(0, 0, 0, 0.2), 0 0 15px rgba(0, 0, 0, 0.05), inset 0 2px 2px rgba(0, 0, 0, 0.1)'
              }}
            >
              <Space style={{ marginBottom: 12 }}>
                <div className='section-icon'>
                  <FontAwesomeIcon icon={section.icon} />
                </div>
                <h3 style={{ color: `rgba(${section.color})` }}>{section.title}</h3>
              </Space>
              <div className='section-info' style={{ color: '#000000' }}>
                <p>
                  Updated:{' '}
                  {isComplete ? dayjs(patMedicalHistory[section.key].updatedAt).format('DD MMM YYYY') : 'Never'}
                </p>
              </div>
              <div className='section-status'>
                <FontAwesomeIcon
                  icon={isComplete ? faCheckCircle : faXmarkCircle}
                  style={{
                    color: isComplete ? '#10b981' : '#ef4444'
                  }}
                />
              </div>
            </Card>
          )
        })}
      </Space>
    )
  }
  const renderFacet = () => {
    switch (selectedFacet) {
      case 'lifestyle':
        return <MobLifestyle setSaving={setSaving} clientForm={form} setFacet={setSelectedFacet} />
      case 'allergies':
        return (
          <MobAllergies
            setSaving={setSaving}
            clientForm={form}
            setFacet={setSelectedFacet}
            saveFunction={handleSave}
            loading={loading}
          />
        )
      case 'mentalHealth':
        return <MobMentalHealth setSaving={setSaving} clientForm={form} setFacet={setSelectedFacet} />
      case 'chronicIllnesses':
        return <MobChronicIllnesses setSaving={setSaving} clientForm={form} setFacet={setSelectedFacet} />
      case 'meds':
        return <MobGeneralMeds setSaving={setSaving} clientForm={form} setFacet={setSelectedFacet} />
      case 'covidInfo':
        return <MobCovidInfo setSaving={setSaving} clientForm={form} setFacet={setSelectedFacet} />
      case 'familyHistory':
        return <MobFamilyHistory setSaving={setSaving} clientForm={form} setFacet={setSelectedFacet} />
      case 'surgicalHistory':
        return <MobSurgeries setSaving={setSaving} clientForm={form} setFacet={setSelectedFacet} />
      default:
        return renderMainView()
    }
  }

  return (
    <>
      <MobMedicalHistoryLoadingOverlay
        text='Saving changes...'
        icon={<FontAwesomeIcon icon={faPills} />}
        loading={loading}
        saveLoading={saving}
      />
      <div style={{ paddingBottom: selectedFacet ? 150 : 70 }}>{renderFacet()}</div>
    </>
  )
}

export default MobMedicalHistoryForm
