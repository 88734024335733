import { useEffect, useState } from 'react'

export const DocumentViewer = ({ base64 }) => {
  const [documentUrl, setDocumentUrl] = useState(null)

  useEffect(() => {
    if (base64) {
      // Keep existing MIME type if present, otherwise assume PDF
      const hasDataPrefix = base64.startsWith('data:')
      const formattedBase64 = hasDataPrefix ? base64 : `data:application/pdf;base64,${base64}`
      setDocumentUrl(formattedBase64)
    }
  }, [base64])

  if (!documentUrl) return null

  return <iframe src={documentUrl} width='100%' height={1080} title='Document Viewer' style={{ border: 'none' }} />
}
