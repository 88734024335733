const MedicalHistoryEnums = {
  keys: {
    MEDICAL_HISTORY: 'medical-history'
  },
  titles: {
    MEDICAL_HISTORY: 'Medical History'
  },
  profileKeys: {
    PATIENTS: 'patients'
  },
  routeKeys: {
    READ: 'read',
    UPDATE: 'update',
    UPDATE_ONE: 'update_one',
    CREATE: 'create'
  }
}

export default MedicalHistoryEnums
