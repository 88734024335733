import { Space, Button, Tooltip, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChain, faRefresh } from '@fortawesome/free-solid-svg-icons'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const AssessmentActions = ({ fetchPatientData, connectionStatus, setCaptureModalOpen, setLinktopModalOpen }) => {
  const { token } = theme.useToken()
  const { loading: dataPointsLoading } = useSelector((state) => state.dataPoints)
  const { loading: templatesLoading } = useSelector((state) => state.examTemplates)

  const isLoading = dataPointsLoading || templatesLoading

  const buttonStyles = {
    sync: {
      background: token.colorSuccess
    },
    realtimeStatus: {
      padding: '4px 12px',
      borderRadius: 8,
      alignItems: 'center',
      background: connectionStatus.background
    }
  }

  return (
    <Space style={{ marginTop: 12 }} size='middle'>
      <Button
        disabled={isLoading}
        type='primary'
        onClick={() => setCaptureModalOpen(true)}
        icon={<FontAwesomeIcon icon={faPlus} />}
      >
        New Capture
      </Button>

      <Button
        disabled={isLoading}
        type='primary'
        onClick={() => setLinktopModalOpen(true)}
        icon={<FontAwesomeIcon icon={faChain} />}
      >
        Health Monitor Device
      </Button>

      <Tooltip title='Refresh'>
        <Button
          onClick={fetchPatientData}
          type='primary'
          style={buttonStyles.sync}
          icon={<FontAwesomeIcon icon={faRefresh} />}
        >
          Sync
        </Button>
      </Tooltip>

      <Tooltip title={connectionStatus.title}>
        <Space style={buttonStyles.realtimeStatus}>
          <small>Real Time Updates</small>
          <FontAwesomeIcon
            icon={connectionStatus.icon}
            size='sm'
            color={connectionStatus.color}
            style={{ cursor: 'help' }}
          />
        </Space>
      </Tooltip>
    </Space>
  )
}

AssessmentActions.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fetchPatientData: PropTypes.func.isRequired,
  connectionStatus: PropTypes.shape({
    title: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired
  }).isRequired,
  setCaptureModalOpen: PropTypes.func.isRequired,
  setLinktopModalOpen: PropTypes.func.isRequired
}

export default memo(AssessmentActions)
