import React, { useState, useEffect } from 'react'
import { Button, Card, Slider, message } from 'antd'
import { useSelector } from 'react-redux'
import '../style/MobileMacroOverview.css'
import { updateDailyLimits } from '../utils/macro-tracker-utils'
import '../style/SaveCheckmark.css'

const SaveCheckmark = ({ show, onAnimationEnd }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onAnimationEnd()
      }, 1500) // Reduced duration
      return () => clearTimeout(timer)
    }
  }, [show, onAnimationEnd])

  if (!show) return null

  return (
    <div className='checkmark-container'>
      <svg className='checkmark' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
        <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
      </svg>
    </div>
  )
}

const MobileMacroOverview = ({
  limits,
  setImageUploadModalOpen,
  setShowGraphs,
  setShowHealthyAlternatives,
  onCameraCapture,
  handleGetMetaData
}) => {
  const authState = useSelector((state) => state.auth)
  const [showCheckmark, setShowCheckmark] = useState(false)
  // const fileInputRef = useRef(null)
  const [editMode, setEditMode] = useState(false)
  const [tempLimits, setTempLimits] = useState(
    limits?.macros || {
      prot: 0,
      fat: 0,
      carb: 0,
      sug: 0,
      cal: 0
    }
  )
  const handleLimitChange = (value, key) => {
    setTempLimits((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const calculateProgress = (key) => {
    if (!limits?.macros[key]) return 0

    // Dummy data for testing progress bars
    const dummyConsumed = {
      prot: 450, // 60% of 750g
      fat: 270, // 45% of 600g
      carb: 665, // 70% of 950g
      sug: 240, // 50% of 480g
      cal: 1860 // 62% of 3000 kcal
    }

    // Replace actual API call with dummy data
    const consumed = dummyConsumed[key]
    const max = limits.macros[key]
    const percent = (consumed / max) * 100
    return percent > 100 ? 100 : percent
  }

  // const handleCameraClick = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click()
  //   }
  // }

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0]
  //   if (file && onCameraCapture) {
  //     const reader = new FileReader()
  //     reader.onload = () => {
  //       const base64Data = reader.result
  //       onCameraCapture(base64Data, file)
  //     }
  //     reader.readAsDataURL(file)
  //   }
  // }

  const handleSaveLimits = async () => {
    try {
      await updateDailyLimits({ userRef: authState.agiliteUser._id }, { macros: tempLimits })
      setEditMode(false)
      handleGetMetaData()
      setShowCheckmark(true)
    } catch (error) {
      message.error('Failed to update limits')
    }
  }

  const renderLimitSlider = (key, label, color, max) => (
    <div className='limit-item'>
      <span className='limit-label'>{label}</span>
      <div className='slider-container'>
        <Slider
          value={tempLimits[key]}
          onChange={(value) => handleLimitChange(value, key)}
          min={0}
          max={max}
          step={10}
          tooltip={{ formatter: null }}
          trackStyle={{ backgroundColor: color }}
          railStyle={{ backgroundColor: 'rgba(255,255,255,0.1)' }}
          handleStyle={{
            borderColor: color,
            backgroundColor: color,
            boxShadow: 'none'
          }}
        />
      </div>
      <span className='limit-value'>
        {tempLimits[key]}
        {key === 'cal' ? ' kcal' : 'g'}
      </span>
    </div>
  )

  return (
    <Card
      className='limits-card'
      title={editMode ? 'Daily Limits' : 'Daily Intake'}
      styles={{
        header: {
          color: 'white'
        }
      }}
      extra={
        <Button
          type='text'
          className={editMode ? 'editing' : ''}
          style={{ width: '60px' }}
          onClick={() => {
            if (editMode) {
              handleSaveLimits()
            } else {
              setTempLimits(limits?.macros || tempLimits)
              setEditMode(true)
            }
          }}
        >
          {editMode ? 'Save' : 'Edit'}
        </Button>
      }
    >
      {editMode ? (
        <div className='limits-content-edit'>
          {renderLimitSlider('prot', 'Protein', '#ff8000', 1000)}
          {renderLimitSlider('fat', 'Fats', '#2ecc71', 1000)}
          {renderLimitSlider('carb', 'Carbs', '#3498db', 1000)}
          {renderLimitSlider('sug', 'Sugar', '#ff1493', 1000)}
          {renderLimitSlider('cal', 'Calories', '#f1c40f', 10000)}
        </div>
      ) : (
        <div className='limits-content-view'>
          <div className='limit-item'>
            <span className='limit-label'>Protein</span>
            <div className='progress-container'>
              <span className='consumed-value'>450 of {limits?.macros?.prot} grams</span>
              <div className='progress-bar'>
                <div className='progress protein' style={{ width: `${calculateProgress('prot')}%` }} />
              </div>
            </div>
            <span className='limit-value'>{Math.round(calculateProgress('prot'))}%</span>
          </div>

          <div className='limit-item'>
            <span className='limit-label'>Fats</span>
            <div className='progress-container'>
              <span className='consumed-value'>270 of {limits?.macros?.fat} grams</span>
              <div className='progress-bar'>
                <div className='progress fats' style={{ width: `${calculateProgress('fat')}%` }} />
              </div>
            </div>
            <span className='limit-value'>{Math.round(calculateProgress('fat'))}%</span>
          </div>

          <div className='limit-item'>
            <span className='limit-label'>Carbs</span>
            <div className='progress-container'>
              <span className='consumed-value'>665 of {limits?.macros?.carb} grams</span>
              <div className='progress-bar'>
                <div className='progress carbs' style={{ width: `${calculateProgress('carb')}%` }} />
              </div>
            </div>
            <span className='limit-value'>{Math.round(calculateProgress('carb'))}%</span>
          </div>

          <div className='limit-item'>
            <span className='limit-label'>Sugar</span>
            <div className='progress-container'>
              <span className='consumed-value'>240 of {limits?.macros?.sug} grams</span>
              <div className='progress-bar'>
                <div className='progress sugar' style={{ width: `${calculateProgress('sug')}%` }} />
              </div>
            </div>
            <span className='limit-value'>{Math.round(calculateProgress('sug'))}%</span>
          </div>

          <div className='limit-item'>
            <span className='limit-label'>Calories</span>
            <div className='progress-container'>
              <span className='consumed-value'>1860 of {limits?.macros?.cal} calories</span>
              <div className='progress-bar'>
                <div className='progress calories' style={{ width: `${calculateProgress('cal')}%` }} />
              </div>
            </div>
            <span className='limit-value'>{Math.round(calculateProgress('cal'))}%</span>
          </div>
        </div>
      )}
    </Card>
  )
}

export default MobileMacroOverview
