import React from 'react'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { createPortal } from 'react-dom'
import styles from './JourneyModal.module.css'

const JourneyModal = ({ children, onClose }) => {
  return createPortal(
    <motion.div 
      className={styles.overlay}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles.modal}>
        <div className={styles.header}>
          <h2 className={styles.title}>Meta-Journey</h2>
          <div className={styles.subtitle}>
            Total transparency every step of the way
          </div>
          <button className={styles.closeButton} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        {children}
      </div>
    </motion.div>,
    document.body
  )
}

export default JourneyModal 