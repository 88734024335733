import axios from 'axios'

export const sendWhatsAppMessage = (to, templateName, parameters) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        if (to) {
          const reqBody = {
            messaging_product: 'whatsapp',
            to,
            type: 'template',
            template: {
              name: templateName,
              language: {
                code: 'en'
              },
              components: [
                {
                  type: 'body',
                  parameters
                }
              ]
            }
          }

          const response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/whatsapp/send`, reqBody, {
            headers: {
              'Content-Type': 'application/json',
              'api-key': process.env.REACT_APP_AGILITE_API_KEY
            }
          })

          resolve(response)
        } else {
          resolve(null)
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const sendWhatsAppOTPMessage = (to, templateName, otp) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        if (to) {
          const reqBody = {
            messaging_product: 'whatsapp',
            to,
            type: 'template',
            template: {
              name: templateName,
              language: {
                code: 'en'
              },
              components: [
                {
                  type: 'body',
                  parameters: [
                    {
                      type: 'text',
                      text: otp
                    }
                  ]
                },
                {
                  type: 'button',
                  sub_type: 'url',
                  index: '0',
                  parameters: [
                    {
                      type: 'text',
                      text: otp
                    }
                  ]
                }
              ]
            }
          }

          const response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/whatsapp/send`, reqBody, {
            headers: {
              'Content-Type': 'application/json',
              'api-key': process.env.REACT_APP_AGILITE_API_KEY
            }
          })

          resolve(response)
        } else {
          resolve(null)
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}
