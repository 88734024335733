import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { getPatient } from '../utils/utils'
import { Col, Row, Space, theme, Card, message, Tag } from 'antd'
import templates from '../utils/templates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faExclamationTriangle,
  faPills,
  faUserMd,
  faAllergies,
  faExclamationCircle,
  faBrain,
  faCut,
  faHeartbeat
} from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import CustomButton from '../../../reusable-components/CustomButton'
import { handleError, hexToRGBA } from '../../../lib/utils'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

const PatientMedicalHistory = ({
  userRef,
  data,
  setSummary,
  isPreviousBooking,
  editButton,
  wrapperLoading,
  handleClose
}) => {
  const [loading, setLoading] = useState()
  const chronicIllnessesFromRedux = useSelector((state) => state.medicalHistory.patient?.chronicIllnesses)

  const [medicalHistory, setMedicalHistory] = useState(() => ({
    ...templates.dataTemplate,
    chronicIllnesses: chronicIllnessesFromRedux || {
      ...templates.dataTemplate.chronicIllnesses,
      list: []
    }
  }))
  const [oldMedHistLoading, setOldMedHistLoading] = useState(false)
  useEffect(() => {}, [medicalHistory, data])

  useEffect(() => {
    if (data) {
      getMedicalHistory()
    }
    // eslint-disable-next-line
  }, [data])

  const getMedicalHistory = async () => {
    setLoading(true)
    try {
      if (!data) {
        const tmpData = await getPatient(userRef)

        setMedicalHistory(tmpData)
      } else {
        const mergedData = {
          ...templates.dataTemplate,
          ...data,
          chronicIllnesses: chronicIllnessesFromRedux ||
            data.chronicIllnesses || {
              ...templates.dataTemplate.chronicIllnesses,
              list: []
            }
        }

        setMedicalHistory(mergedData)
      }
    } catch (e) {
      console.error('Error getting medical history:', e)
      message.error(handleError(e))
    }
    setLoading(false)
  }

  useEffect(() => {
    if (chronicIllnessesFromRedux) {
      setMedicalHistory((prev) => ({
        ...prev,
        chronicIllnesses: chronicIllnessesFromRedux
      }))
    }
  }, [chronicIllnessesFromRedux])

  useEffect(() => {}, [chronicIllnessesFromRedux, medicalHistory])

  const { token } = theme.useToken()

  const hasAllergies = () => {
    if (
      medicalHistory?.allergies?.foodList?.length > 0 ||
      medicalHistory?.allergies?.medsList?.length > 0 ||
      medicalHistory?.allergies?.otherList?.length > 0 ||
      medicalHistory?.allergies?.foodAdd?.length > 0 ||
      medicalHistory?.allergies?.medsAdd?.length > 0 ||
      medicalHistory?.allergies?.otherAdd?.length > 0
    ) {
      return true // Patient has at least one allergy
    } else {
      return false // Patient has no allergies
    }
  }
  const isChronic = () => {
    if (medicalHistory?.chronicIllnesses?.list?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const columnSpan = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 8,
    xxl: 8
  }

  const styles = {
    pageWrapper: {
      background: '#f8fafc',
      padding: '16px',
      minHeight: 'auto'
    },
    mainCard: {
      borderRadius: '16px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)',
      border: 'none',
      overflow: 'hidden'
    },
    headerBar: {
      padding: '24px',
      borderBottom: '1px solid #d1d5db',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    sectionCard: {
      minHeight: '500px',
      height: '100%',
      borderRadius: '8px',
      border: '3px solid #cbd5e0',
      background: '#fff',
      marginBottom: '16px',
      transition: 'all 0.2s ease',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      overflow: 'hidden',
      ':hover': {
        boxShadow: '0 6px 16px rgba(0,0,0,0.15)'
      }
    },
    sectionHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '16px',
      borderBottom: '3px solid #cbd5e0',
      background: '#fafbfc',
      textAlign: 'center',
      width: '100%'
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px'
    },
    sectionTitle: {
      fontSize: '20px',
      fontWeight: '600',
      color: '#2d3748',
      letterSpacing: '0.01em'
    },
    updateInfo: {
      fontSize: '13px',
      color: '#718096',
      marginTop: '6px'
    },
    contentWrapper: {
      padding: '16px',
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    listContainer: {
      background: '#f8fafc',
      borderRadius: '6px',
      padding: '12px 16px',
      marginTop: '8px',
      border: '2px solid #cbd5e0',
      boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.05)'
    },
    listItem: {
      padding: '8px 0',
      borderBottom: '2px solid #cbd5e0',
      ':last-child': {
        borderBottom: 'none'
      }
    },
    infoRow: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '8px 0',
      borderBottom: '1px solid #edf2f7'
    },
    label: {
      fontWeight: '500',
      color: '#4a5568',
      width: '150px'
    },
    value: {
      color: '#2d3748',
      flex: 1
    },
    sectionSubtitle: {
      fontSize: '17px',
      fontWeight: '600',
      color: '#4a5568',
      marginBottom: '12px'
    },
    subsection: {
      marginBottom: '16px'
    },
    warningMessage: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: token.colorWarning,
      padding: '8px 12px',
      background: hexToRGBA(token.colorWarning, 0.1),
      borderRadius: '4px'
    },
    infoRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 0',
      borderBottom: '1px solid #edf2f7'
    },
    allergySection: {
      marginBottom: '24px'
    },
    infoGrid: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px'
    },
    infoSection: {
      flex: '1 1 100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px'
    },
    infoLabel: {
      fontWeight: '500',
      color: '#4a5568'
    },
    infoValue: {
      color: '#2d3748',
      flex: 1
    },
    medicationDates: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '13px',
      color: '#718096',
      marginTop: '4px'
    },
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '32px 16px',
      color: '#4a5568',
      background: '#f7fafc',
      borderRadius: '8px'
    },
    illnessCard: {
      background: '#fff',
      borderRadius: '8px',
      border: '1px solid #e2e8f0',
      padding: '16px',
      marginBottom: '16px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      transition: 'all 0.2s ease',
      ':hover': {
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
      }
    },
    medicationList: {
      background: '#f0fff4',
      borderRadius: '8px',
      padding: '12px',
      marginTop: '8px',
      border: '1px solid #c6f6d5'
    },
    medicationItem: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '8px',
      borderBottom: '1px solid #c6f6d5',
      ':last-child': {
        borderBottom: 'none'
      }
    },
    medicationName: {
      fontWeight: '500',
      color: '#2d3748'
    },
    medicationDetails: {
      flex: 1
    },
    medicationDates: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      fontSize: '13px',
      color: '#718096',
      marginTop: '4px'
    },
    nonChronicSection: {
      marginTop: '24px',
      paddingTop: '16px',
      borderTop: '1px solid #edf2f7'
    },
    nonChronicList: {
      listStyle: 'none',
      padding: 0,
      margin: '8px 0 0 0',
      '& li': {
        padding: '4px 0',
        display: 'flex',
        alignItems: 'center'
      }
    },
    updateInfo: {
      fontSize: '13px',
      color: '#666'
    },
    illnessItem: {
      marginBottom: '24px',
      padding: '0 0 24px 0',
      borderBottom: '1px solid #edf2f7',
      ':last-child': {
        borderBottom: 'none',
        marginBottom: 0,
        paddingBottom: 0
      }
    },
    illnessHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginBottom: '4px'
    },
    illnessTitle: {
      fontWeight: '600',
      fontSize: '15px'
    },
    diagnosisDate: {
      color: '#666',
      fontStyle: 'italic',
      fontSize: '14px',
      marginBottom: '16px'
    },
    medicationsSection: {
      marginTop: '12px'
    },
    medicationHeader: {
      fontWeight: '600',
      marginBottom: '8px'
    }
  }

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  // Memoize the chronic illnesses data
  const chronicIllnesses = useMemo(() => {
    return medicalHistory?.chronicIllnesses
  }, [medicalHistory?.chronicIllnesses])

  // Use useCallback for handlers
  const handleStateUpdate = useCallback((data) => {
    // Update logic here
  }, [])

  return (
    <div style={{ ...styles.pageWrapper, ...styles.mainCard }}>
      {loading || wrapperLoading ? (
        <AgiliteSkeleton skActive={false} spinnerTip='Loading Medical History...' />
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            margin: 0,
            padding: 0,
            background: '#fff'
          }}
        >
          <div style={styles.headerBar}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <FontAwesomeIcon icon={faUserMd} style={{ fontSize: '20px' }} />
              <h2 style={{ margin: 0, fontSize: '24px', fontWeight: '600' }}>Medical History Summary</h2>
            </div>
            <CustomButton
              text='Edit Medical History'
              type='primary'
              icon={faEdit}
              onClick={() => setSummary(false)}
              style={{
                background: 'linear-gradient(to right, #6366f1, #8b5cf6)',
                border: 'none'
              }}
            />
          </div>

          <style jsx>{`
            .close-button:hover {
              background: ${hexToRGBA(token.colorError, 0.1)};
              color: ${token.colorError};
            }
          `}</style>

          <Row gutter={[16, 16]} style={{ padding: '16px' }}>
            {/* Chronic Illnesses Section */}
            <Col {...columnSpan}>
              <Card
                style={{
                  ...styles.sectionCard,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                styles={{
                  body: {
                    padding: 0,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                  }
                }}
              >
                <div style={styles.sectionHeader}>
                  <div style={styles.titleContainer}>
                    <FontAwesomeIcon
                      icon={faPills}
                      style={{
                        color: '#6366f1',
                        fontSize: '24px'
                      }}
                    />
                    <span style={styles.sectionTitle}>Chronic Illnesses & Medications</span>
                  </div>
                  <div style={styles.updateInfo}>
                    Updated: {dayjs(medicalHistory.chronicIllnesses.updatedAt).format('DD MMM YYYY')}
                  </div>
                </div>

                <div style={styles.contentWrapper}>
                  {}
                  {medicalHistory?.chronicIllnesses?.list?.length > 0 ? (
                    medicalHistory.chronicIllnesses.list.map((illness) => (
                      <div key={illness.code} style={styles.illnessItem}>
                        <div style={styles.illnessHeader}>
                          <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: token.colorWarning }} />
                          <span style={styles.illnessTitle}>
                            {toTitleCase(`${illness.description} ${illness.code}`)}
                          </span>
                        </div>
                        <div style={styles.diagnosisDate}>
                          {illness.dateDiagnosed && `Diagnosed in ${dayjs(illness.dateDiagnosed).format('YYYY')}`}
                        </div>

                        <div style={styles.medicationsSection}>
                          <div style={styles.medicationHeader}>Associated Medications:</div>
                          {illness.medications?.length > 0 ? (
                            <div style={styles.medicationList}>
                              {illness.medications.map((med) => (
                                <div key={med.med.name} style={styles.medicationItem}>
                                  <FontAwesomeIcon icon={faPills} style={{ color: '#48bb78', marginRight: '8px' }} />
                                  <div style={styles.medicationDetails}>
                                    <div style={styles.medicationName}>
                                      {toTitleCase(med.med.name)}
                                      {med.med.strengthMetric1 && ` ${med.med.strengthMetric1}`}
                                      {med.med.unitOfMeasure1 && ` ${med.med.unitOfMeasure1}`}
                                    </div>
                                    <div style={styles.medicationDates}>
                                      <span>Started: {dayjs(med.startDate).format('DD MMM YYYY')}</span>
                                      {med.endDate && <span>Ended: {dayjs(med.endDate).format('DD MMM YYYY')}</span>}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div style={styles.warningMessage}>
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                              <span>No medications listed for this condition</span>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div style={styles.warningMessage}>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                      <span>No chronic illnesses recorded</span>
                    </div>
                  )}

                  {/* Non Chronic Medications */}
                  {medicalHistory?.meds?.list?.length > 0 && (
                    <div style={styles.nonChronicSection}>
                      <div style={styles.sectionSubtitle}>Non Chronic Medications:</div>
                      <div style={styles.medicationList}>
                        {medicalHistory.meds.list.map((med) => (
                          <div key={med.med.name} style={styles.medicationItem}>
                            <FontAwesomeIcon icon={faPills} style={{ color: '#48bb78', marginRight: '8px' }} />
                            <div style={styles.medicationName}>
                              {toTitleCase(med.med.name)}
                              {med.med.strengthMetric1 && ` ${med.med.strengthMetric1}`}
                              {med.med.unitOfMeasure1 && ` ${med.med.unitOfMeasure1}`}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Family History Section */}
                  <div style={styles.subsection}>
                    <div style={styles.sectionSubtitle}>Family History</div>
                    <div style={styles.listContainer}>
                      {medicalHistory?.familyHistory?.list?.length > 0 ? (
                        medicalHistory?.familyHistory?.list?.map((item, index) => (
                          <div key={index} style={styles.listItem}>
                            <FontAwesomeIcon icon={faUserMd} style={{ color: '#4a5568', marginRight: '8px' }} />
                            {item}
                          </div>
                        ))
                      ) : (
                        <div style={styles.listItem}>No family history captured</div>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>

            {/* Allergies Section */}
            <Col {...columnSpan}>
              <Card
                style={{
                  ...styles.sectionCard,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                styles={{
                  body: {
                    padding: 0,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                  }
                }}
              >
                <div style={styles.sectionHeader}>
                  <div style={styles.titleContainer}>
                    <FontAwesomeIcon
                      icon={faAllergies}
                      style={{
                        color: '#e53e3e',
                        fontSize: '24px'
                      }}
                    />
                    <span style={styles.sectionTitle}>Allergies</span>
                    <Tag color='error'>Has Allergies</Tag>
                  </div>
                  <div style={styles.updateInfo}>
                    Updated: {dayjs(medicalHistory.allergies.updatedAt).format('DD MMM YYYY')}
                  </div>
                </div>

                <div
                  style={{
                    ...styles.contentWrapper,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {hasAllergies() ? (
                    <>
                      {/* Medication Allergies */}
                      {(medicalHistory?.allergies?.medsList?.length > 0 ||
                        medicalHistory?.allergies?.medsAdd?.length > 0) && (
                        <div style={styles.allergySection}>
                          <div style={styles.sectionSubtitle}>Medication Allergies</div>
                          <div style={styles.listContainer}>
                            {medicalHistory?.allergies?.medsList?.map((medAllergy, index) => (
                              <div key={index} style={styles.listItem}>
                                <FontAwesomeIcon icon={faPills} style={{ color: '#e53e3e', marginRight: '8px' }} />
                                <span>{medAllergy}</span>
                              </div>
                            ))}
                            {medicalHistory?.allergies?.medsAdd?.map((medAllergy, index) => (
                              <div key={`add-${index}`} style={styles.listItem}>
                                <FontAwesomeIcon icon={faPills} style={{ color: '#e53e3e', marginRight: '8px' }} />
                                <span>{medAllergy.name}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Food Allergies */}
                      {(medicalHistory?.allergies?.foodList?.length > 0 ||
                        medicalHistory?.allergies?.foodAdd?.length > 0) && (
                        <div style={styles.allergySection}>
                          <div style={styles.sectionSubtitle}>Food Allergies</div>
                          <div style={styles.listContainer}>
                            {medicalHistory?.allergies?.foodList?.map((foodAllergy, index) => (
                              <div key={index} style={styles.listItem}>
                                <FontAwesomeIcon
                                  icon={faExclamationCircle}
                                  style={{ color: '#e53e3e', marginRight: '8px' }}
                                />
                                <span>{foodAllergy}</span>
                              </div>
                            ))}
                            {medicalHistory?.allergies?.foodAdd?.map((foodAllergy, index) => (
                              <div key={`add-${index}`} style={styles.listItem}>
                                <FontAwesomeIcon
                                  icon={faExclamationCircle}
                                  style={{ color: '#e53e3e', marginRight: '8px' }}
                                />
                                <span>{foodAllergy.name}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Other Allergies */}
                      {(medicalHistory?.allergies?.otherList?.length > 0 ||
                        medicalHistory?.allergies?.otherAdd?.length > 0) && (
                        <div style={styles.allergySection}>
                          <div style={styles.sectionSubtitle}>Other Allergies</div>
                          <div style={styles.listContainer}>
                            {medicalHistory?.allergies?.otherList?.map((otherAllergy, index) => (
                              <div key={index} style={styles.listItem}>
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle}
                                  style={{ color: '#e53e3e', marginRight: '8px' }}
                                />
                                <span>{otherAllergy}</span>
                              </div>
                            ))}
                            {medicalHistory?.allergies?.otherAdd?.map((otherAllergy, index) => (
                              <div key={`add-${index}`} style={styles.listItem}>
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle}
                                  style={{ color: '#e53e3e', marginRight: '8px' }}
                                />
                                <span>{otherAllergy.name}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div style={styles.warningMessage}>
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        style={{ color: token.colorWarning, marginRight: '8px' }}
                      />
                      Patient has listed no existing allergies, confirm with patient.
                    </div>
                  )}
                </div>
              </Card>
            </Col>

            {/* Lifestyle Section */}
            <Col {...columnSpan}>
              <Card
                style={{
                  ...styles.sectionCard,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                styles={{
                  body: {
                    padding: 0,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                  }
                }}
              >
                <div style={styles.sectionHeader}>
                  <div style={styles.titleContainer}>
                    <FontAwesomeIcon
                      icon={faHeartbeat}
                      style={{
                        color: '#38a169',
                        fontSize: '24px'
                      }}
                    />
                    <span style={styles.sectionTitle}>Lifestyle & Background</span>
                    <Tag color='success'>Updated</Tag>
                  </div>
                  <div style={styles.updateInfo}>
                    Updated: {dayjs(medicalHistory.lifestyle.updatedAt).format('DD MMM YYYY')}
                  </div>
                </div>

                <div style={styles.contentWrapper}>
                  <div style={styles.infoGrid}>
                    {/* Lifestyle Information */}
                    <div style={styles.infoSection}>
                      <Space style={styles.infoRow}>
                        <div style={styles.infoLabel}>Alcohol Intake:</div>
                        <div style={styles.infoValue}>
                          {medicalHistory?.lifestyle?.alcoholConsumption
                            ? medicalHistory?.lifestyle?.alcoholConsumptionLevel
                            : 'None'}
                        </div>
                      </Space>

                      <Space style={styles.infoRow}>
                        <div style={styles.infoLabel}>Diet:</div>
                        <div style={styles.infoValue}>{medicalHistory?.lifestyle?.diet || 'Not Captured'}</div>
                      </Space>

                      <Space style={styles.infoRow}>
                        <div style={styles.infoLabel}>Physical Activity:</div>
                        <div style={styles.infoValue}>
                          {medicalHistory?.lifestyle?.physicalActivity || 'Not Captured'}
                        </div>
                      </Space>

                      <Space style={styles.infoRow}>
                        <div style={styles.infoLabel}>Screen Time:</div>
                        <div style={styles.infoValue}>{medicalHistory?.lifestyle?.screenTime || 'Not Captured'}</div>
                      </Space>

                      <Space style={styles.infoRow}>
                        <div style={styles.infoLabel}>Smokes:</div>
                        <div style={styles.infoValue}>
                          {medicalHistory?.lifestyle?.smoking === true
                            ? 'Yes'
                            : medicalHistory?.lifestyle?.smoking === false
                            ? 'No'
                            : 'Not Captured'}
                        </div>
                      </Space>

                      <Space style={styles.infoRow}>
                        <div style={styles.infoLabel}>Vapes:</div>
                        <div style={styles.infoValue}>
                          {medicalHistory?.lifestyle?.vaping === true
                            ? 'Yes'
                            : medicalHistory?.lifestyle?.vaping === false
                            ? 'No'
                            : 'Not Captured'}
                        </div>
                      </Space>

                      <Space style={styles.infoRow}>
                        <div style={styles.infoLabel}>Water Intake:</div>
                        <div style={styles.infoValue}>{medicalHistory?.lifestyle?.waterIntake || 'Not Captured'}</div>
                      </Space>

                      <Space style={styles.infoRow}>
                        <div style={styles.infoLabel}>Stress:</div>
                        <div style={styles.infoValue}>{medicalHistory?.lifestyle?.stress || 'Not Captured'}</div>
                      </Space>
                    </div>
                  </div>

                  {/* Mental Health Section */}
                  <div style={styles.subsection}>
                    <div style={styles.sectionSubtitle}>Mental Health</div>
                    <div style={styles.listContainer}>
                      {medicalHistory?.mentalHealth?.list?.length > 0 ? (
                        medicalHistory?.mentalHealth?.list?.map((item, index) => (
                          <div key={index} style={styles.listItem}>
                            <FontAwesomeIcon
                              icon={faBrain}
                              style={{
                                color: token.colorWarning,
                                marginRight: '8px'
                              }}
                            />
                            {item}
                          </div>
                        ))
                      ) : (
                        <div style={styles.listItem}>No mental health issues captured</div>
                      )}
                    </div>
                  </div>

                  {/* Surgeries Section */}
                  <div style={styles.subsection}>
                    <div style={styles.sectionSubtitle}>Surgeries</div>
                    <div style={styles.listContainer}>
                      {medicalHistory?.surgicalHistory?.list?.length > 0 ? (
                        medicalHistory?.surgicalHistory?.list?.map((surgery, index) => (
                          <div key={index} style={styles.listItem}>
                            <FontAwesomeIcon icon={faCut} style={{ color: '#4a5568', marginRight: '8px' }} />
                            <div>
                              <div>{surgery.description}</div>
                              <small style={{ color: '#718096' }}>{dayjs(surgery.date).format('DD MMM YYYY')}</small>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div style={styles.listItem}>No surgery history captured</div>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}

export default PatientMedicalHistory
