// Constants for modal states
export const MODAL_STATES = {
  ASSESSMENT: 'assessment',
  CLINICAL_NOTES_TEMPLATES: 'clinicalNotesTemplates',
  REFERRAL: 'referral',
  SICK_NOTE: 'sickNote',
  FOLLOW_UP: 'followUp',
  VIRTUAL_CONSULT: 'virtualConsult',
  PDF_VIEWER: 'pdfViewer'
}
