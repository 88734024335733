import React, { Suspense } from 'react'
import { Col, Spin, theme } from 'antd'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import CustomRow from '../../../../reusable-components/CustomRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import './admin-dashboard.css'
import store from '../../../../../store'
import coreReducer from '../../../../../core/utils/reducer'
import CoreEnums from '../../../../../core/utils/enums'
import BasicForm from '../../../../Admin/clinics/components/ClinicsForm'
import templates from '../../../../Admin/clinics/utils/templates'

const ClinicsListed = ({ clinics, loading, handleFetchClinicData }) => {
  const { token } = theme.useToken()
  const createRecord = () => {
    store.dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.CLINICS}_new}`,
        closable: true,
        label: 'Register Clinic',
        children: (
          <Suspense fallback={<Spin />}>
            <BasicForm data={templates.dataTemplate()} isNewRecord refreshView={handleFetchClinicData} />
          </Suspense>
        )
      })
    )
  }
  return (
    <>
      {loading ? (
        <AgiliteSkeleton skActive />
      ) : (
        <CustomRow>
          {clinics.map((clinic) => (
            <Col>
              <CustomRow
                className='basic-card'
                style={{ width: 350, maxWidth: '100%', padding: 20, textAlign: 'center' }}
              >
                <Col span={24}>
                  <center>
                    <div
                      style={{
                        width: 175,
                        maxWidth: '100%',
                        height: 175,
                        boxSizing: 'border-box',
                        padding: 20,
                        borderRadius: '50%',
                        boxShadow: '0 0 30px 0 rgb(0,0,0,0.2)'
                      }}
                    >
                      <img style={{ width: '100%' }} src={clinic.clinicLogo} alt='Clinic Logo' />
                    </div>
                  </center>
                </Col>
                <Col span={24}>
                  <h1>{clinic.name}</h1>
                </Col>
                <Col span={24}>
                  <h2>{clinic.bookingData.length} Bookings</h2>
                </Col>
              </CustomRow>
            </Col>
          ))}
          <Col>
            <CustomRow
              style={{ width: 350, maxWidth: '100%', padding: 20, textAlign: 'center' }}
              className='basic-card admin-add-clinic'
              onClick={createRecord}
            >
              <Col span={24}>
                <center>
                  <FontAwesomeIcon color={token.colorSuccess} fontSize={75} icon={faPlus} />
                </center>
              </Col>
            </CustomRow>
          </Col>
        </CustomRow>
      )}
    </>
  )
}

export default ClinicsListed
