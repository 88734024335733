import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faUser, faClock, faCheck } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import '../styling/BookingConfirmation.css'
import { handleError } from '../../../../lib/utils'
import { createBooking } from '../../../../Bookings/utils/utils'
import { readClinics } from '../../../../Admin/clinics/utils/utils'
import { executeBooking, registerBooking } from '../../../../Bookings/utils/bpm-utils'
import bookingsReducer from '../../../../Bookings/utils/booking-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { sendWhatsAppMessage } from '../../../../WhatsApp/utils'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import coreReducer from '../../../../../core/utils/reducer'
import CoreEnums from '../../../../../core/utils/enums'
import MetaClinicDashboard from '../../../../Dashboard/meta-clinic-dashboard'
import MobPatAppointmentsWrapper from '../../../Appointments/Mobile/components/MobPatAppointmentsWapper'
import EmbeddedPayment from '../../../../PeachPayments/components/EmbeddedPayment'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import CancelledPayment from '../../../../PeachPayments/components/CancelledPayment'
import PaymentFailure from '../../../../PeachPayments/components/PaymentFailure'
import { closeTab } from '../../../../../core/utils/controller'

const MobBookingConfirmation = ({ bookingDetails, isVirtualVisit }) => {
  const [renderPayment, setRenderPayment] = useState(false)
  const [loading, setLoading] = useState(false)
  const [bookingConfirmed, setBookingConfirmed] = useState(false)
  const [paymentCancelled, setPaymentCancelled] = useState(false)
  const [paymentError, setPaymentError] = useState(false)
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  const state = useSelector((state) => state)
  const serviceInfo =
    state.services.data.find((item) => item._id === bookingDetails.service) ||
    state.virtualServices.data.find((item) => item._id === bookingDetails.service)

  useEffect(() => {
    validateBooking()
    // eslint-disable-next-line
  }, [])

  const validateBooking = () => {
    // TODO: Here we need to validate symptoms and reason for visit etc, use old logic to see what to do

    if (isVirtualVisit) {
      setRenderPayment(true)
    } else {
      confirmBooking()
    }
  }

  const confirmBooking = async () => {
    // let tmpCheckinData = {}
    // let otp = null
    let tmpBookings = []
    let tmpDashBoardBookings = []
    let processRecord = null
    let processUser = null
    let payload = JSON.parse(JSON.stringify(bookingDetails))
    let clinicData = null
    const bpmKey = clinicData?.bpmKey || 'booking_process'

    if (isVirtualVisit) {
      let tmpResult = null
      tmpResult = await readClinics({ _id: bookingDetails.clinicRef }, isVirtualVisit)
      clinicData = tmpResult[0]
    } else {
      clinicData = state.clinics.data.find((i) => i._id === bookingDetails.clinicRef)
    }

    try {
      setLoading(true)

      payload = { ...payload, status: { checkedIn: true } }

      if (isVirtualVisit) {
        // TODO: Here we need to add the checkin data
        // tmpCheckinData.ailment = currentSelection.doctorsText
        // if (patientSelection.length > 0) {
        //   tmpCheckinData.patientSelection = patientSelection
        // }
        // if (patientInput) {
        //   tmpCheckinData.patientInput = patientInput
        // }
        // payload.checkinData = {
        //   ...tmpCheckinData
        // }
      }

      // TODO: Here we need to add the OTP logic
      // if (!isHomeVisit) {
      //   if (isReceptionBooking) {
      //     otp = await sendOTP({ ...bookingDetails, ...mainPatient }, patient, isDependant, clinicData.name)
      //   } else {
      //     otp = await sendOTP({ ...bookingDetails, ...authState.agiliteUser }, patient, isDependant, clinicData.name)
      //   }

      //   payload.otp = otp
      //   setBookingDetails({ ...bookingDetails, ...payload })
      // }

      if (authState.agiliteUser.phoneNumber) {
        sendWhatsAppMessage(authState.agiliteUser.phoneNumber, 'appointment_confirmation', [
          { type: 'text', text: `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}` },
          { type: 'text', text: `${bookingDetails.doctor.firstName} ${bookingDetails.doctor.lastName}` },
          { type: 'text', text: `${dayjs(bookingDetails.bookingDate).format('MMM D, YYYY')}` },
          { type: 'text', text: `${bookingDetails.startTime}` }
        ])
      }

      if (isVirtualVisit) {
        payload.isVirtualVisit = true
      }

      // BPM
      // if (patient) {
      //   processUser = `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`
      //   payload.userRef = patient._id
      //   setBookingDetails({ ...bookingDetails, ...payload })
      // } else {
      //   processUser = `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`
      // }
      processUser = `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`

      processRecord = await registerBooking(bpmKey, processUser)
      processRecord = await executeBooking(bpmKey, processRecord.recordId, 'submit', processUser, processRecord.key)

      if (!isVirtualVisit) {
        // If it is a Home Visit and not a Virtual Consultation, we need to skip the checkin step
        processRecord = await executeBooking(bpmKey, processRecord.recordId, 'submit', processUser, processRecord.key)
      } else if (isVirtualVisit) {
        // If Virtual Consultation, we need to skip the checkin and Nurse step
        processRecord = await executeBooking(
          bpmKey,
          processRecord.recordId,
          'submit_to_doctor',
          processUser,
          processRecord.key
        )
      }

      let newBooking = await createBooking({
        ...payload,
        processRef: processRecord.recordId,
        status: processRecord.processStage
      })

      // Update Bookings State
      if (state.auth.agiliteUser.extraData.role.type === 'patient') {
        // Set temporary booking states to whatever it was previously plus the new booking
        tmpBookings = [...state.bookings.data, newBooking]
        tmpDashBoardBookings = [...state.bookings.dashboardData, newBooking]
        // Sort Bookings by date
        tmpBookings.sort(
          (a, b) =>
            new Date(dayjs(b.bookingDate).set('hours', b.startTime.slice(0, 2)).set('minutes', b.startTime.slice(-2))) -
            new Date(dayjs(a.bookingDate).set('hours', a.startTime.slice(0, 2)).set('minutes', a.startTime.slice(-2)))
        )
        // update the main bookings state for patient this will be in two plces:
        dispatch(bookingsReducer.actions.setRecords(tmpBookings))
        dispatch(bookingsReducer.actions.setDashboardRecords(tmpDashBoardBookings))
      } else {
        tmpDashBoardBookings = [...state.bookings.dashboardData, newBooking]
        dispatch(bookingsReducer.actions.setDashboardRecords(tmpDashBoardBookings))
      }

      message.success('Booking Successful')
      setBookingConfirmed(true)

      // Show next facet
      // if (isReceptionBooking) {
      //   onAfterSuccessfulBooking()
      // } else {
      //   nextStep()
      // }
    } catch (e) {
      handleError(e)
      message.error('Unable to make booking')
    } finally {
      setLoading(false)
    }
  }

  const resetState = () => {
    setRenderPayment(false)
    setPaymentCancelled(false)
    setPaymentError(false)
    setBookingConfirmed(false)
  }

  return (
    <>
      {renderPayment ? (
        <EmbeddedPayment
          amount={serviceInfo.rate}
          patientRecord={authState.agiliteUser}
          onCompleted={() => {
            setRenderPayment(false)
            confirmBooking()
          }}
          onCancelled={() => {
            setRenderPayment(false)
            setPaymentCancelled(true)
          }}
          onError={() => {
            setRenderPayment(false)
            setPaymentError(true)
          }}
        />
      ) : null}
      {bookingConfirmed ? (
        <motion.div
          className='booking-confirmation'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className='confirmation-header'>
            <div className='success-icon'>
              <FontAwesomeIcon icon={faCheck} />
            </div>
            <h2>Booking Confirmed</h2>
            <p>Your virtual consultation has been scheduled</p>
          </div>

          <div className='booking-details'>
            <div className='detail-item'>
              <FontAwesomeIcon icon={faUser} className='detail-icon' />
              <div className='detail-content'>
                <label>Doctor</label>
                <p>
                  Dr. {bookingDetails.doctor?.firstName} {bookingDetails.doctor?.lastName}
                </p>
              </div>
            </div>

            <div className='detail-item'>
              <FontAwesomeIcon icon={faCalendar} className='detail-icon' />
              <div className='detail-content'>
                <label>Date</label>
                <p>{dayjs(bookingDetails.date).format('MMM D, YYYY')}</p>
              </div>
            </div>

            <div className='detail-item'>
              <FontAwesomeIcon icon={faClock} className='detail-icon' />
              <div className='detail-content'>
                <label>Time</label>
                <p>{bookingDetails.startTime}</p>
              </div>
            </div>
          </div>

          <PrevNextFloatingButtons
            onNext={() => {
              dispatch(
                coreReducer.actions.addTab({
                  key: CoreEnums.tabKeys.PAT_APPOINTMENTS,
                  closable: true,
                  label: CoreEnums.tabTitles.PAT_APPOINTMENTS,
                  children: <MobPatAppointmentsWrapper />
                })
              )
            }}
            onPrev={() => {
              dispatch(
                coreReducer.actions.addTab({
                  key: CoreEnums.tabKeys.HOME,
                  closable: true,
                  label: CoreEnums.tabTitles.HOME,
                  children: <MetaClinicDashboard />
                })
              )
              dispatch(
                coreReducer.actions.closeTab({
                  targetKey: CoreEnums.tabKeys.PAT_VIRTUAL_CONSULT_SESSION,
                  removeBreadcrumb: true
                })
              )
            }}
            prevText='Back to Home'
            nextText='View Bookings'
          />
        </motion.div>
      ) : null}
      {!renderPayment && loading ? <AgiliteSkeleton spinnerTip='Confirming Booking...' /> : null}
      {paymentCancelled ? (
        <CancelledPayment
          reason='Your payment has been cancelled'
          onRetry={() => {
            resetState()
            setRenderPayment(true)
          }}
          onBack={() => {
            dispatch(
              coreReducer.actions.addTab({
                key: CoreEnums.tabKeys.HOME,
                closable: true,
                label: CoreEnums.tabTitles.HOME,
                children: <MetaClinicDashboard />
              })
            )
          }}
        />
      ) : null}
      {paymentError ? (
        <PaymentFailure
          onRetry={() => {
            resetState()
            setRenderPayment(true)
          }}
        />
      ) : null}
    </>
  )
}

export default MobBookingConfirmation
