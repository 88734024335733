import React from 'react'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faReceipt } from '@fortawesome/free-solid-svg-icons'
import styles from './JourneyStep.module.css'

const JourneyStep = ({ step, status, index, totalSteps, children }) => {
  // Calculate step total
  const calculateStepTotal = () => {
    let total = 0
    let medicalAidTotal = 0
    let patientTotal = 0

    Object.values(step.billing).forEach(item => {
      total += item.amount
      if (item.coveredByMedicalAid) {
        medicalAidTotal += item.amount
      } else {
        patientTotal += item.amount
      }
    })

    return { total, medicalAidTotal, patientTotal }
  }

  const { total, patientTotal } = calculateStepTotal()

  return (
    <motion.div 
      className={`${styles.step} ${styles[status]}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
    >
      <div className={styles.stepIconWrapper}>
        <div className={styles.stepIcon}>
          <FontAwesomeIcon icon={step.icon} />
        </div>
        {index < totalSteps - 1 && (
          <motion.div 
            className={styles.connector}
            initial={{ scaleY: 0 }}
            animate={{ scaleY: status === 'completed' ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          />
        )}
      </div>

      <div className={styles.stepContent}>
        <div className={styles.stepHeader}>
          <span className={styles.stepTitle}>{step.title}</span>
          <span className={styles.stepTime}>
            <FontAwesomeIcon icon={faClock} />
            {step.actualTime || step.estimatedTime}
            {!step.actualTime && (
              <small className={styles.estimatedTime}>(Estimated)</small>
            )}
          </span>
        </div>

        <p className={styles.stepDescription}>
          {step.description}
          {step.estimatedDuration && (
            <span className={styles.stepDuration}>
              Duration: {step.actualDuration || step.estimatedDuration}
              {!step.actualDuration && (
                <small className={styles.estimatedDuration}>(Estimated)</small>
              )}
            </span>
          )}
        </p>

        {/* Add billing information */}
        {total > 0 && (
          <div className={styles.stepBilling}>
            <div className={styles.stepBillingAmount}>
              <FontAwesomeIcon icon={faReceipt} />
              <span>R {total.toFixed(2)}</span>
              {patientTotal > 0 && (
                <small className={styles.patientPortion}>
                  (Patient: R {patientTotal.toFixed(2)})
                </small>
              )}
            </div>
          </div>
        )}

        {status === 'current' && (
          <motion.div 
            className={styles.progressPulse}
            animate={{ opacity: [0.5, 1, 0.5] }}
            transition={{ duration: 2, repeat: Infinity }}
          />
        )}

        {children}
      </div>
    </motion.div>
  )
}

export default JourneyStep 