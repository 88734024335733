import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPhone,
  faIdCard,
  faCalendar,
  faVenusMars,
  faUserGroup,
  faMedkit,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import '../styles/MobileDisplayMode.css'
import PrevNextFloatingButtons from '../../Reusable Components/Buttons/PrevNextFloatingButtons'
import { Card, Col } from 'antd'
import CustomRow from '../../../reusable-components/CustomRow'

const InfoItem = ({ icon, label, value }) => (
  <div className='info-item'>
    <div className='info-icon-row'>
      <FontAwesomeIcon icon={icon} className='info-icon' />
      <span className='info-label'>{label}</span>
    </div>
    <span className='info-value'>{formatValue(label, value)}</span>
  </div>
)

const formatValue = (label, value) => {
  if (!value) return 'Not provided'

  switch (label) {
    case 'Date of Birth':
      return dayjs(value).format('DD MMM YYYY')
    case 'Gender':
      return value.charAt(0).toUpperCase() + value.slice(1)
    case 'Phone':
      return value.startsWith('+27') ? `0${value.slice(3).replace(/(\d{2})(\d{3})(\d{4})/, '$1 $2 $3')}` : value
    default:
      return value
  }
}

const MobDisplayMode = ({ userData, image, setIsEditMode }) => {
  return (
    <CustomRow style={{ paddingBottom: 140 }}>
      <Col span={24}>
        <div className='profile-image-container'>
          <img src={image} alt='Profile' className='profile-image' referrerPolicy='no-referrer' />
        </div>
      </Col>
      <Col span={24}>
        <Card size='small'>
          <InfoItem
            icon={faUser}
            label='Name'
            value={`${userData.firstName.charAt(0).toUpperCase() + userData.firstName.slice(1)} ${
              userData.lastName.charAt(0).toUpperCase() + userData.lastName.slice(1)
            }`}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card size='small'>
          <InfoItem icon={faIdCard} label='ID Number' value={userData.idNo} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size='small'>
          <InfoItem icon={faCalendar} label='Date of Birth' value={userData.dateOfBirth} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size='small'>
          <InfoItem icon={faVenusMars} label='Gender' value={userData.gender} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size='small'>
          <InfoItem icon={faUserGroup} label='Race' value={userData.race} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size='small'>
          <InfoItem icon={faPhone} label='Phone' value={userData.phoneNumber} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size='small'>
          <InfoItem icon={faEnvelope} label='Email' value={userData.email} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size='small'>
          <InfoItem icon={faMedkit} label='Medical Aid' value={userData.medicalAid?.name} />
        </Card>
      </Col>
      <PrevNextFloatingButtons onNext={() => setIsEditMode(true)} nextText='Edit Profile' nextOnly={true} />
    </CustomRow>
  )
}

export default MobDisplayMode
