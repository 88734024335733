import React from 'react'
import { Badge } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CATEGORIES, CATEGORY_COLORS } from './enums'
import { categoryIcons } from './constants'

export const getTabItems = ({ token, getUnfilledCount, isAddingTemplate }) => {
  const items = [
    {
      key: CATEGORIES.VITALS,
      label: (
        <span>
          <FontAwesomeIcon
            icon={categoryIcons[CATEGORIES.VITALS]}
            style={{ marginRight: '8px', color: CATEGORY_COLORS[CATEGORIES.VITALS] }}
          />
          Vital Signs
          <Badge
            count={getUnfilledCount(CATEGORIES.VITALS)}
            style={{ marginLeft: '8px', backgroundColor: token.colorError }}
          />
        </span>
      )
    },
    {
      key: CATEGORIES.GENERAL,
      label: (
        <span>
          <FontAwesomeIcon
            icon={categoryIcons[CATEGORIES.GENERAL]}
            style={{ marginRight: '8px', color: CATEGORY_COLORS[CATEGORIES.GENERAL] }}
          />
          General
          <Badge
            count={getUnfilledCount(CATEGORIES.GENERAL)}
            style={{ marginLeft: '8px', backgroundColor: token.colorError }}
          />
        </span>
      )
    },
    {
      key: CATEGORIES.RAPID,
      label: (
        <span>
          <FontAwesomeIcon
            icon={categoryIcons[CATEGORIES.RAPID]}
            style={{ marginRight: '8px', color: CATEGORY_COLORS[CATEGORIES.RAPID] }}
          />
          Rapid Tests
          <Badge
            count={getUnfilledCount(CATEGORIES.RAPID)}
            style={{ marginLeft: '8px', backgroundColor: token.colorError }}
          />
        </span>
      )
    },
    {
      key: CATEGORIES.POC,
      label: (
        <span>
          <FontAwesomeIcon
            icon={categoryIcons[CATEGORIES.POC]}
            style={{ marginRight: '8px', color: CATEGORY_COLORS[CATEGORIES.POC] }}
          />
          Point of Care
          <Badge
            count={getUnfilledCount(CATEGORIES.POC)}
            style={{ marginLeft: '8px', backgroundColor: token.colorError }}
          />
        </span>
      )
    }
  ]

  if (isAddingTemplate) {
    items.unshift({
      key: CATEGORIES.PREVIEW,
      label: (
        <span>
          <FontAwesomeIcon
            icon={categoryIcons[CATEGORIES.PREVIEW]}
            style={{ marginRight: '8px', color: CATEGORY_COLORS[CATEGORIES.PREVIEW] }}
          />
          Preview
        </span>
      )
    })
  } else {
    items.unshift({
      key: CATEGORIES.TEMPLATES,
      label: (
        <span>
          <FontAwesomeIcon
            icon={categoryIcons[CATEGORIES.TEMPLATES]}
            style={{ marginRight: '8px', color: CATEGORY_COLORS[CATEGORIES.TEMPLATES] }}
          />
          Templates
        </span>
      )
    })
  }

  return items
}
