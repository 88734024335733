import React from 'react'
import MobMedicalHistory from './MobMedicalHistory'

const MobMedicalHistoryWrapper = ({ isPatient }) => {
  return (
    <div>
      <MobMedicalHistory isPatient={isPatient} />
    </div>
  )
}

export default MobMedicalHistoryWrapper
