import React, { Suspense, useCallback, useEffect, useState } from 'react'
import coreReducer from '../../../../core/utils/reducer'
import store from '../../../../store'
import CoreEnums from '../../../../core/utils/enums'
import ClinicForm from '../components/ClinicsForm'
import ClinicTemplates from '../utils/templates'
import { message, Spin } from 'antd'
import { debounce } from 'lodash'
import { handleError } from '../../../lib/utils'
import { readClinics } from '../utils/utils'
import clinicsCRUDReducer from '../utils/reducer'
import { useDispatch, useSelector } from 'react-redux'

const useClinics = () => {
  const dispatch = useDispatch()
  const viewData = useSelector((state) => state.clinics.data)
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const refreshView = useCallback(async () => {
    setLoading(true)
    try {
      const filter = searchQuery ? { $text: { $search: searchQuery } } : null
      const data = await readClinics(filter)
      dispatch(clinicsCRUDReducer.actions.setRecords(data))
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [searchQuery])

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 1000),
    []
  )

  const handleSearch = useCallback(
    (query) => {
      setLoading(true)
      debouncedFilter(query)
    },
    [debouncedFilter]
  )

  useEffect(() => {
    refreshView()
  }, [refreshView])

  const renderClinicForm = useCallback(
    (data, isNewRecord) => (
      <Suspense fallback={<Spin />}>
        <ClinicForm data={data} isNewRecord={isNewRecord} refreshView={refreshView} />
      </Suspense>
    ),
    [refreshView]
  )

  const createRecord = useCallback(() => {
    store.dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.CLINICS}_new`,
        closable: true,
        label: 'Register Clinic',
        children: renderClinicForm(ClinicTemplates.dataTemplate(), true)
      })
    )
    // eslint-disable-next-line
  }, [])

  const editRecord = useCallback(
    (record) => {
      store.dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.CLINICS}_${record._id}`,
          closable: true,
          label: `Record: ${record.name}`,
          children: renderClinicForm(record, false)
        })
      )
    },
    // eslint-disable-next-line
    []
  )

  return {
    refreshView,
    createRecord,
    handleSearch,
    loading,
    viewData,
    searchQuery,
    setSearchQuery,
    editRecord
  }
}

export default useClinics
