import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { MedPraxICD10Search } from '../../../MedPrax/ICD10-API'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { handleError } from '../../../lib/utils'
import { MedPraxProductSearch } from '../../../Scripting/utils/utils'
import { updateChronicIllnesses } from '../api/api'
import patMedHistReducer from '../utils/reducer'

const useChronicIllnesses = ({ setSaving }) => {
  const dispatch = useDispatch()
  const patMedHistState = useSelector((state) => state.patMedHist.data)
  const chronicIllnessState = useSelector((state) => state.patMedHist.data.chronicIllnesses)
  const [showIllnessModal, setShowIllnessModal] = useState(false)
  const [medicationModalState, setMedicationModalState] = useState({ show: false, index: 0 })
  const [nappiLoading, setNappiLoading] = useState(false)
  const [icd10Loading, setIcd10Loading] = useState(false)
  const [ICD10SearchFilter, setICD10SearchFilter] = useState('')
  const [nappiSearchFilter, setNappiSearchFilter] = useState('')
  const [icd10Data, setIcd10Data] = useState([])
  const [illnessList, setIllnessList] = useState(chronicIllnessState.list || [])
  const [nappiData, setNappiData] = useState([])

  const [tempMedDates, setTempMedDates] = useState({ startDate: null, endDate: null })
  const [tempDiagnosisDate, setTempDiagnosisDate] = useState(null)

  const [editingIllness, setEditingIllness] = useState(null)

  const handleSubmit = async () => {
    setSaving(true)
    try {
      const newRecord = await updateChronicIllnesses(patMedHistState._id, illnessList)
      dispatch(patMedHistReducer.actions.updateState(newRecord))
    } catch (error) {
      message.error(handleError(error, true))
    }
    setSaving(false)
  }

  useEffect(() => {
    sortIllnessesByDateDiagnosed()
    // eslint-disable-next-line
  }, [])

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await MedPraxProductSearch(searchQuery)
        setNappiData(tmpData)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setNappiLoading(false)
  }
  const getICD10Data = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await MedPraxICD10Search(searchQuery)
        setIcd10Data(tmpData)
      } else {
        setIcd10Data([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setIcd10Loading(false)
  }

  useEffect(() => {
    setIcd10Loading(true)
    debounceICD10Filter(ICD10SearchFilter)

    // eslint-disable-next-line
  }, [ICD10SearchFilter])

  useEffect(() => {
    setNappiLoading(true)
    debouncedNappiFilter(nappiSearchFilter)

    // eslint-disable-next-line
  }, [nappiSearchFilter])

  // eslint-disable-next-line
  const debounceICD10Filter = useCallback(
    debounce((value) => {
      getICD10Data(value)
    }, 1000),
    []
  )

  // eslint-disable-next-line
  const debouncedNappiFilter = useCallback(
    debounce((value) => {
      getNappiData(value)
    }, 1000),
    []
  )

  const handleRemoveIllness = (index) => {
    let tmpIllnesses = [...illnessList]
    tmpIllnesses.splice(index, 1)
    setIllnessList(tmpIllnesses)
  }

  const handleAddIllness = (value) => {
    let tmpIllnesses = [...illnessList]
    let existingIndex = tmpIllnesses.findIndex((i) => i.code === value.code)

    if (existingIndex === -1) {
      tmpIllnesses.unshift({ ...value, dateDiagnosed: tempDiagnosisDate, medications: [] })
    } else {
      return message.error('Illness already added')
    }
    setShowIllnessModal(false)
    setIllnessList(tmpIllnesses)
    setTempDiagnosisDate(null)
    setMedicationModalState((prev) => ({ ...prev, selectedIllness: null }))
  }

  const handleUpdateIllness = () => {
    let tmpIllnessesList = [...illnessList]
    tmpIllnessesList[editingIllness.index] = {
      ...tmpIllnessesList[editingIllness.index],
      dateDiagnosed: tempDiagnosisDate
    }
    setIllnessList(tmpIllnessesList)
    setEditingIllness(null)
    setShowIllnessModal(false)
    setTempDiagnosisDate(null)
  }

  const handleRemoveMedication = (illnessIndex, medIndex) => {
    let tmpIllnesses = JSON.parse(JSON.stringify(illnessList))
    tmpIllnesses[illnessIndex].medications.splice(medIndex, 1)
    setIllnessList(tmpIllnesses)
  }

  const handleAddMedication = (value, tempDates) => {
    let tmpIllnesses = JSON.parse(JSON.stringify(illnessList))
    let tmpMedications = tmpIllnesses[medicationModalState.index]?.medications || []

    tmpMedications.push({
      med: {
        ...value
      },
      startDate: tempDates.startDate,
      endDate: tempDates.endDate || null
    })

    tmpIllnesses[medicationModalState.index].medications = tmpMedications

    setIllnessList(tmpIllnesses)
    setMedicationModalState({ show: false, index: 0 })
    setTempDiagnosisDate(null)
  }

  const handleUpdateMedication = (illnessIndex, medIndex, tempDates) => {
    let tmpIllnesses = JSON.parse(JSON.stringify(illnessList))
    const currentMed = tmpIllnesses[illnessIndex].medications[medIndex]

    tmpIllnesses[illnessIndex].medications[medIndex] = {
      ...currentMed,
      startDate: tempDates.startDate,
      endDate: tempDates.endDate
    }

    setIllnessList(tmpIllnesses)
    setMedicationModalState({ show: false, index: 0 })
    setTempMedDates({ startDate: null, endDate: null })
  }

  const sortIllnessesByDateDiagnosed = () => {
    let tmpArray = [...illnessList]
    tmpArray = tmpArray.sort((a, b) => new Date(b.dateDiagnosed) - new Date(a.dateDiagnosed))
    setIllnessList(tmpArray)
  }

  const [showSearchBar, setShowSearchBar] = useState(false)
  return {
    illnessList,
    setIllnessList,
    handleAddIllness,
    handleRemoveIllness,
    handleUpdateIllness,
    handleRemoveMedication,
    handleAddMedication,
    handleUpdateMedication,
    showSearchBar,
    setShowSearchBar,
    getNappiData,
    getICD10Data,
    nappiData,
    icd10Data,
    nappiLoading,
    icd10Loading,
    handleSubmit,
    ICD10SearchFilter,
    setICD10SearchFilter,
    nappiSearchFilter,
    setNappiSearchFilter,
    chronicIllnessState,
    showIllnessModal,
    setShowIllnessModal: (show) => {
      setShowIllnessModal(show)
      if (!show) {
        setMedicationModalState((prev) => ({ ...prev, selectedIllness: null }))
      }
    },
    medicationModalState,
    setMedicationModalState,
    tempDiagnosisDate,
    setTempDiagnosisDate,
    tempMedDates,
    setTempMedDates,
    editingIllness,
    setEditingIllness
  }
}

export default useChronicIllnesses
