import { message } from 'antd'
import CoreEnums from '../../../../core/utils/enums'
import coreReducer from '../../../../core/utils/reducer'
import PatientExaminationForm from '../../../Bookings/components/ConsultationWrapper'
import { BookingDataTemplate } from '../../../Bookings/utils/booking-data-template'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { handleError } from '../../../lib/utils'
import systemUsersReducer from '../utils/reducer'

const useSystemUsersForm = ({ type, isNewRecord, dataId, refreshView, handleRootTabKey }) => {
  const [viewType, setViewType] = useState(type)
  const activeRecords = useSelector((state) => state.systemUsers.activeRecords)
  const facet = useSelector((state) => state.systemUsers.facet)
  const authState = useSelector((state) => state.auth)
  const state = useSelector((state) => state)
  const [examinationModalOpen, setExaminationModalOpen] = useState(false)
  const [servicesLoading, setServicesLoading] = useState(false)
  const [services, setServices] = useState([])
  const dispatch = useDispatch()

  const dataIndex = isNewRecord
    ? activeRecords.findIndex((item) => item.isNew === true)
    : activeRecords.findIndex((item) => item._id === dataId)

  useEffect(() => {
    handleSetServices()
    // eslint-disable-next-line
  }, [])

  const handleSetServices = () => {
    let services = [
      ...JSON.parse(JSON.stringify(state.services.data)).map((i) => {
        return {
          label: i.name,
          value: i._id
        }
      })
    ]
    let tmpVirtualServices = JSON.parse(JSON.stringify(state.virtualServices.data))
    setServicesLoading(true)

    for (const service of tmpVirtualServices) {
      services.push({
        label: service.name + ' (Virtual)',
        value: service._id
      })
    }

    setServicesLoading(false)
    setServices(services)
  }

  const beginAdhocScreening = async (data) => {
    // setLoading(true)
    const record = BookingDataTemplate(activeRecords[dataIndex], JSON.parse(data.clinic), {
      chiefComplaint: data.ailment,
      ailment: data.ailment,
      patientSelection: data.patientSelection,
      patientInput: data.patientInput,
      paymentMethod: 'No Appointment',
      service: authState.agiliteUser.extraData.services[0]
    })

    try {
      dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${record._id}`,
          closable: true,
          label: `Booking: ${record.patientName}`,
          children: <PatientExaminationForm data={record} noAppointment={true} />
        })
      )
      setExaminationModalOpen(false)
    } catch (e) {
      message.error(handleError(e, true))
    }
    // setLoading(false)
  }

  const closeTab = () => {
    let tmpActiveRecords = activeRecords.concat()

    if (isNewRecord) {
      tmpActiveRecords = tmpActiveRecords.filter((i) => i.isNew === true)
    } else {
      tmpActiveRecords = tmpActiveRecords.filter((i) => i._id !== dataId)
    }

    refreshView()
    batch(() => {
      dispatch(systemUsersReducer.actions.setActiveRecords(tmpActiveRecords))
      dispatch(
        coreReducer.actions.closeTab({
          targetKey: isNewRecord ? `${handleRootTabKey()}_new_${type}_user` : `${handleRootTabKey()}_${dataId}`,
          removeBreadcrumb: true
        })
      )
    })
  }

  return {
    beginAdhocScreening,
    setExaminationModalOpen,
    activeRecords,
    authState,
    dataIndex,
    examinationModalOpen,
    facet,
    viewType,
    services,
    servicesLoading,
    setViewType,
    closeTab
  }
}

export default useSystemUsersForm
