import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

export const useDataPoints = (hasLabReport) => {
  const availableDataPoints = useSelector((state) => state.dataPoints.data)
  const [selectedDataPoints, setSelectedDataPoints] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])

  const toggleDataPoint = useCallback((dataPoint) => {
    setSelectedDataPoints((prev) => {
      const stringified = JSON.stringify(dataPoint)
      const isSelected = prev.includes(stringified)

      if (isSelected) {
        setSelectedKeys((prev) => prev.filter((key) => key !== dataPoint.key))
        return prev.filter((item) => item !== stringified)
      } else {
        setSelectedKeys((prev) => [...prev, dataPoint.key])
        return [...prev, stringified]
      }
    })
  }, [])

  return {
    selectedDataPoints,
    setSelectedDataPoints,
    availableDataPoints,
    selectedKeys,
    setSelectedKeys,
    toggleDataPoint
  }
}
