import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Card, Col, Row, Space, theme } from 'antd'
import { hexToRGBA } from '../../../../lib/utils'
import { StandardDataPointGraph } from '../../../examination-utils/standard-graph'

/**
 * VitalSigns component for displaying and managing vital sign data
 * @param {Object} props - Component props
 * @param {Array<Object>} props.dataPoints - Array of data point templates
 * @param {Array<Object>} props.patientDataEntries - Array of patient vital sign entries
 * @returns {JSX.Element} VitalSigns component
 */
const VitalSigns = ({ dataPoints, patientDataEntries }) => {
  const [selectedVital, setSelectedVital] = useState(null)
  const { token } = theme.useToken()

  // Memoized function to filter and sort vital data by timestamp
  const getVitalDataByKey = useCallback(
    (vitalKey) => {
      if (!patientDataEntries?.length) return []
      return patientDataEntries
        .filter((entry) => entry.key === vitalKey)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    },
    [patientDataEntries]
  )

  // Memoized vital signs configuration
  const vitalsConfig = useMemo(
    () => [
      { title: 'Pressure', key: 'bloodPressure', data: getVitalDataByKey('bloodPressure'), suffix: 'mmHg' },
      { title: 'Pulse', key: 'pulse', data: getVitalDataByKey('pulse'), suffix: 'bpm' },
      { title: 'Temperature', key: 'temperature', data: getVitalDataByKey('temperature'), suffix: '°C' },
      { title: 'Oxygen', key: 'oxygenSaturation', data: getVitalDataByKey('oxygenSaturation'), suffix: '%' }
    ],
    [getVitalDataByKey]
  )

  // Initialize and update selected vital
  useEffect(() => {
    if (!selectedVital && vitalsConfig.length > 0) {
      setSelectedVital(vitalsConfig[0])
      return
    }

    if (selectedVital) {
      const updatedVital = vitalsConfig.find((vital) => vital.key === selectedVital.key)
      setSelectedVital(updatedVital)
    }
  }, [vitalsConfig, selectedVital])

  /**
   * Formats and returns the latest vital sign value with its unit
   * @param {Object} vital - Vital sign configuration object
   * @returns {string} Formatted vital sign value
   */
  const formatVitalValue = (vital) => {
    if (!vital?.data?.length) return 'No data'

    const latestReading = vital.data[vital.data.length - 1]
    if (vital.key === 'bloodPressure') {
      return `${latestReading.value[0].value}/${latestReading.value[1].value} ${vital.suffix}`
    }
    return `${latestReading.value} ${vital.suffix}`
  }

  const handleVitalSelection = (vital) => {
    if (vital.data?.length > 0) {
      setSelectedVital(vital)
    }
  }

  return (
    <Card
      title='Vital Signs'
      size='small'
      style={{
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
      }}
      headStyle={{
        background: hexToRGBA(token.colorPrimary, 0.2),
        fontWeight: 'bold',
        fontSize: '16px'
      }}
    >
      <Row>
        <Col span={24}>
          <Space
            wrap
            style={{
              background: '#f9f9f9',
              gap: 12,
              border: '1px solid #e0e0e0',
              borderBottom: 'none',
              width: '100%',
              padding: '12px'
            }}
          >
            {vitalsConfig.map((vital) => {
              const isSelected = selectedVital?.key === vital.key
              const hasData = vital.data?.length > 0

              return (
                <div
                  key={vital.key}
                  onClick={() => handleVitalSelection(vital)}
                  style={{
                    padding: '16px',
                    borderBottom: '3px solid',
                    cursor: hasData ? 'pointer' : 'not-allowed',
                    borderColor: isSelected ? token.colorPrimary : '#e0e0e0',
                    background: isSelected ? '#ffffff' : '#f9f9f9',
                    transition: 'all 0.3s ease',
                    borderRadius: '4px',
                    boxShadow: isSelected ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
                  }}
                >
                  <Space style={{ gap: 4, width: '100%' }} direction='vertical'>
                    <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{vital.title}</span>
                    <div style={{ fontSize: '12px', color: '#555' }}>{formatVitalValue(vital)}</div>
                  </Space>
                </div>
              )
            })}
          </Space>
        </Col>
        <Col span={24}>
          {selectedVital && dataPoints?.find((point) => point.key === selectedVital.key) && (
            <StandardDataPointGraph
              data={{ records: selectedVital.data, key: selectedVital.key }}
              dataTemplate={dataPoints.find((point) => point.key === selectedVital.key)}
              style={{
                padding: '16px',
                background: '#ffffff',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              }}
            />
          )}
        </Col>
      </Row>
    </Card>
  )
}

export default VitalSigns
