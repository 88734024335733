import React from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { Button, Card, Col, Space, Table, Tooltip, theme } from 'antd'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../../core/utils/reducer'
import CoreEnums from '../../../../core/utils/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faUsers } from '@fortawesome/free-solid-svg-icons'
import CarePlanForm from './CarePlanForm'
import { hexToRGBA } from '../../../lib/utils'

const CarePlansList = ({ carePlansTemplates, dataPoints, handleEnrollmentView, refreshView, isMonitoring }) => {
  const dispatch = useDispatch()

  const openCarePlanForm = (isNew, recordData) => {
    dispatch(
      coreReducer.actions.addTab({
        key: isNew
          ? `${isMonitoring ? CoreEnums.tabKeys.MONITORING_PLANS : CoreEnums.tabKeys.CARE_PLANS}_new`
          : `${isMonitoring ? CoreEnums.tabKeys.MONITORING_PLANS : CoreEnums.tabKeys.CARE_PLANS}_${recordData._id}`,
        closable: true,
        label: 'New Care Plan',
        children: (
          <>
            <CarePlanForm
              isMonitoring={isMonitoring}
              isNew={isNew}
              recordData={recordData}
              dataPoints={dataPoints}
              refreshView={refreshView}
            />
          </>
        )
      })
    )
  }
  const { token } = theme.useToken()
  return (
    <CustomRow>
      <Col span={24}>
        <Card
          headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
          title={isMonitoring ? 'Monitoring Plans' : 'Personal Care Plans'}
          size='small'
          extra={
            <Button
              onClick={() => {
                openCarePlanForm(true)
              }}
            >
              New
            </Button>
          }
        >
          <Table
            size='small'
            bordered
            dataSource={carePlansTemplates}
            columns={[
              {
                title: 'Name',
                key: 'name',
                dataIndex: 'name'
              },
              {
                title: 'Actions',
                render: (record) => {
                  return (
                    <Space wrap>
                      <Tooltip title='Edit'>
                        <FontAwesomeIcon
                          fontSize={20}
                          size='lg'
                          id='cancel-booking'
                          icon={faEdit}
                          onMouseOver={(e) => {
                            e.currentTarget.style.color = token.colorPrimary
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.color = '#ccc'
                          }}
                          style={{ color: '#ccc', transition: 'color 300ms ease' }}
                          onClick={(e) => {
                            e.stopPropagation()
                            openCarePlanForm(false, record)
                          }}
                        />
                      </Tooltip>
                      <Tooltip title='Enrollment'>
                        <FontAwesomeIcon
                          fontSize={20}
                          size='lg'
                          icon={faUsers}
                          onMouseOver={(e) => {
                            e.currentTarget.style.color = token.colorPrimary
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.color = '#ccc'
                          }}
                          style={{ color: '#ccc', transition: 'color 300ms ease' }}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleEnrollmentView(record)
                          }}
                        />
                      </Tooltip>
                    </Space>
                  )
                }
              }
            ]}
          />
        </Card>
      </Col>
      {/* {!isMonitoring ? (
        <Col span={24}>
          <Card
            headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
            title='Meta-Health Approved Plans'
            size='small'
            extra={
              <Button
                onClick={() => {
                  openCarePlanForm(true)
                }}
              >
                New
              </Button>
            }
          >
            <Table
              size='small'
              bordered
              dataSource={carePlansTemplates}
              columns={[
                {
                  title: 'Name',
                  key: 'name',
                  dataIndex: 'name'
                },
                {
                  title: 'Actions',
                  render: (record) => {
                    return (
                      <Space wrap>
                        <Tooltip title='Edit'>
                          <FontAwesomeIcon
                            fontSize={20}
                            size='lg'
                            id='cancel-booking'
                            icon={faEdit}
                            onMouseOver={(e) => {
                              e.currentTarget.style.color = token.colorPrimary
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.color = '#ccc'
                            }}
                            style={{ color: '#ccc', transition: 'color 300ms ease' }}
                            onClick={(e) => {
                              e.stopPropagation()
                              openCarePlanForm(false, record)
                            }}
                          />
                        </Tooltip>
                        <Tooltip title='Enrollment'>
                          <FontAwesomeIcon
                            fontSize={20}
                            size='lg'
                            icon={faUsers}
                            onMouseOver={(e) => {
                              e.currentTarget.style.color = token.colorPrimary
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.color = '#ccc'
                            }}
                            style={{ color: '#ccc', transition: 'color 300ms ease' }}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleEnrollmentView(record)
                            }}
                          />
                        </Tooltip>
                      </Space>
                    )
                  }
                }
              ]}
            />
          </Card>
        </Col>
      ) : undefined} */}
    </CustomRow>
  )
}

export default CarePlansList
