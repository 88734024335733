import React from 'react'
import { Card, Typography, Badge, Space, Spin } from 'antd'
import '../../../styling/AiCareSyncSuggest.css'

const { Title, Text } = Typography

const AiCareSyncSuggest = ({ suggestions, onTestSelect, isLoading, error }) => {
  if (error) {
    return (
      <Card className='cs-ai-suggest'>
        <div className='cs-ai-suggest__error'>
          <Text type='danger'>{error}</Text>
        </div>
      </Card>
    )
  }

  if (isLoading) {
    return (
      <Card className='cs-ai-suggest'>
        <div className='cs-ai-suggest__loading'>
          <Spin size='large' />
          <Text>Analyzing your health data...</Text>
        </div>
      </Card>
    )
  }

  if (!suggestions?.recommendedTests?.length) {
    return (
      <Card className='cs-ai-suggest'>
        <div className='cs-ai-suggest__error'>
          <Text type='secondary'>No test recommendations available</Text>
        </div>
      </Card>
    )
  }

  const { recommendedTests } = suggestions

  const getPriorityColor = (priority) => {
    switch (priority?.toLowerCase()) {
      case 'high':
        return 'error'
      case 'medium':
        return 'warning'
      case 'low':
        return 'default'
      default:
        return 'default'
    }
  }

  return (
    <Card className='cs-ai-suggest'>
      <Title level={4}>Recommended Tests</Title>
      <div className='cs-ai-suggest__tests'>
        {recommendedTests.map((test) => (
          <div
            key={test.testCode || Math.random()}
            className='cs-ai-suggest__test-card'
            // Temporarily remove onClick handler
            // onClick={() => onTestSelect(test)}
          >
            <div className='cs-ai-suggest__test-header'>
              <Text strong>{test.name}</Text>
              <Badge status={getPriorityColor(test.priority)} text={`${test.priority || 'unknown'} priority`} />
            </div>
            <div className='cs-ai-suggest__test-content'>
              <Text type='secondary' className='cs-ai-suggest__timeframe'>
                {test.timeframe}
              </Text>
              <div className='cs-ai-suggest__reasons'>
                {test.reasoning?.map((reason, index) => (
                  <Text key={index} type='secondary' className='cs-ai-suggest__reason'>
                    • {reason}
                  </Text>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}

export default AiCareSyncSuggest
