import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Form,
  Button,
  theme,
  Popconfirm,
  Switch,
  message,
  Input,
  Space,
  Card,
  Select,
  Tabs
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
/* import { UploadOutlined } from '@ant-design/icons' */

import coreReducer from '../../../../core/utils/reducer'
import Theme from '../../../../core/utils/theme'
import Templates from '../utils/templates'
import { createService, updateService } from '../utils/utils'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { allTrim, handleError, specialFieldValidation } from '../../../lib/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import CoreEnums from '../../../../core/utils/enums'
import CheckinForm from './CheckinForm'

const ServicesForm = ({ data, isNewRecord, refreshView }) => {
  const coreState = useSelector((state) => state.core)

  const [additionalScreening, setAdditionalScreening] = useState(data.screeningTemplate)
  const [deactivatePrompt, setDeactivatePrompt] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [checkinQuestions, setCheckinQuestions] = useState(data.checkin ? data.checkin : [])

  const [loading, setLoading] = useState(false)
  const [specialFormState, setSpecialFormState] = useState([])

  const dispatch = useDispatch()
  const { token } = theme.useToken()
  const [clientForm] = Form.useForm()

  useEffect(() => {
    const tmpSpecialFormState = []
    for (const [key, value] of Object.entries(data)) {
      tmpSpecialFormState.push({ key: key, value: value })
    }
    setSpecialFormState(tmpSpecialFormState)
    // eslint-disable-next-line
  }, [])

  const handleSubmit = async () => {
    let record = allTrim(clientForm.getFieldsValue())
    record.checkin = allTrim(checkinQuestions)
    try {
      setLoading(true)

      record = {
        ...record
      }
      if (isNewRecord) {
        await createService(record)
      } else {
        await updateService({ _id: data._id, ...record })
      }

      closeTab()

      if (coreState.tourOpen) {
        dispatch(coreReducer.actions.nextTourStep())
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const closeTab = () => {
    refreshView()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: isNewRecord ? `${CoreEnums.tabKeys.SERVICES}_new` : `${CoreEnums.tabKeys.SERVICES}_${data._id}`,
        removeBreadcrumb: true
      })
    )
  }

  const handleIsActive = (value) => {
    if (data.isActive && !value) setDeactivatePrompt(true)
    else setDeactivatePrompt(false)
  }

  return (
    <ContainerCard title={isNewRecord ? 'Add a New Service' : 'Viewing Service'}>
      <CustomRow>
        <Col span={24}>
          <Tabs
            tabBarExtraContent={{
              left: (
                <Button onClick={closeTab} style={{ marginRight: 12 }}>
                  <FontAwesomeIcon icon={faXmark} color={token.colorBorder} /> Close
                </Button>
              ),
              right: (
                <>
                  {deactivatePrompt ? (
                    <Popconfirm
                      okText='Yes'
                      cancelText='No'
                      okButtonProps={{ style: { background: token.colorSuccess, color: 'white' } }}
                      cancelButtonProps={{ style: { background: token.colorError, color: 'white' } }}
                      title={<h3 style={{ color: token.colorWarning, margin: 0 }}>WARNING</h3>}
                      description='You have switched this service to be inactive. Are you sure you want to proceed?'
                      onConfirm={() => {
                        clientForm.submit()
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: Theme.twitterBootstrap.success,
                          color: 'white',
                          marginRight: 10
                        }}
                        htmlType='submit'
                        loading={loading}
                      >
                        {isNewRecord ? 'Add Service' : 'Save Changes'}
                      </Button>
                    </Popconfirm>
                  ) : (
                    <Button
                      style={{
                        backgroundColor: Theme.twitterBootstrap.success,
                        color: 'white',
                        marginRight: 10
                      }}
                      onClick={() => {
                        clientForm.submit()
                      }}
                      loading={loading}
                    >
                      {isNewRecord ? 'Add Service' : 'Save Changes'}
                    </Button>
                  )}
                </>
              )
            }}
          >
            <Tabs.Pane tab='Service Details' key='service-details'>
              <CustomRow className='basic-card'>
                <Col span={24}>
                  <Form
                    onFinish={handleSubmit}
                    name='form'
                    form={clientForm}
                    initialValues={{
                      ...Templates.dataTemplate(),
                      ...data,
                      type: data.type ? data.type : Templates.dataModel.type.options[0].value
                    }}
                    layout='vertical'
                  >
                    <Row justify='center'>
                      <Col span={24}>
                        <Card style={{ marginBottom: 8 }} title='Service Setup' type='inner' size='small'>
                          {' '}
                          <Space wrap style={{ marginBottom: '20px' }}>
                            {Templates.dataModel.isActive.label}
                            <Form.Item
                              style={{ margin: 0 }}
                              name={Templates.dataModel.isActive.key}
                              valuePropName='checked'
                            >
                              <Switch
                                checkedChildren='Active'
                                unCheckedChildren='Inactive'
                                onChange={(e) => {
                                  handleIsActive(e)
                                }}
                              />
                            </Form.Item>
                          </Space>
                          <Form.Item
                            name={Templates.dataModel.name.key}
                            label={Templates.dataModel.name.label}
                            rules={[
                              {
                                required: Templates.dataModel.name.required,
                                message: Templates.dataModel.name.validationMsg
                              }
                            ]}
                          >
                            <Input
                              maxLength={50}
                              onChange={(e) => {
                                specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                  key: 'name',
                                  event: e,
                                  validationConfig: {
                                    letters: { allowed: true, onlyCaps: false },
                                    numbers: false,
                                    spaces: true
                                  }
                                })
                              }}
                              placeholder={Templates.dataModel.name.placeholder}
                            />
                          </Form.Item>
                          <Form.Item
                            name={Templates.dataModel.rate.key}
                            label={Templates.dataModel.rate.label}
                            rules={[
                              {
                                required: Templates.dataModel.rate.required,
                                message: Templates.dataModel.rate.validationMsg
                              }
                            ]}
                          >
                            <Input
                              maxLength={5}
                              placeholder={Templates.dataModel.rate.placeholder}
                              addonBefore='R'
                              style={{ width: 200 }}
                              onChange={(e) => {
                                specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                  key: Templates.dataModel.rate.key,
                                  event: e,
                                  validationConfig: {
                                    letters: { allowed: false, onlyCaps: false },
                                    numbers: true,
                                    spaces: false
                                  }
                                })
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={Templates.dataModel.timeslotInterval.key}
                            label={Templates.dataModel.timeslotInterval.label}
                            rules={[
                              {
                                required: Templates.dataModel.timeslotInterval.required,
                                message: Templates.dataModel.timeslotInterval.validationMsg
                              }
                            ]}
                          >
                            <Input
                              placeholder={Templates.dataModel.timeslotInterval.placeholder}
                              addonAfter='Minutes'
                              maxLength={3}
                              style={{ width: 200 }}
                              onChange={(e) => {
                                specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                  key: Templates.dataModel.timeslotInterval.key,
                                  event: e,
                                  validationConfig: {
                                    letters: { allowed: false, onlyCaps: false },
                                    numbers: true,
                                    spaces: false
                                  }
                                })
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={Templates.dataModel.type.key}
                            label={Templates.dataModel.type.label}
                            rules={[
                              {
                                required: Templates.dataModel.type.required,
                                message: Templates.dataModel.type.validationMsg
                              }
                            ]}
                          >
                            <Select
                              placeholder={Templates.dataModel.type.placeholder}
                              options={Templates.dataModel.type.options}
                            />
                          </Form.Item>
                        </Card>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </CustomRow>
            </Tabs.Pane>
            <Tabs.Pane tab='Checkin Questions' key='checkin-questions'>
              <CheckinForm
                checkinQuestions={checkinQuestions}
                setCheckinQuestions={setCheckinQuestions}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                data={data}
              />
            </Tabs.Pane>
          </Tabs>
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default ServicesForm
