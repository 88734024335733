import { faPlus, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, DatePicker, Input, Space, theme } from 'antd'
import React, { useCallback, memo } from 'react'
import PropTypes from 'prop-types'

const { RangePicker } = DatePicker

const SearchBox = memo(({ token, value, onChange }) => (
  <div style={{ display: 'grid', gridTemplateColumns: '1fr 35px', width: 450, maxWidth: '100%' }}>
    <Input
      placeholder='Search documents...'
      style={{ borderRadius: 0, boxSizing: 'border-box' }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      allowClear
    />
    <div
      style={{
        background: token.colorPrimary,
        color: 'white',
        height: 35,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <FontAwesomeIcon icon={faSearch} />
    </div>
  </div>
))

const DocumentActions = ({
  handleReadDocuments,
  loadingDocuments,
  searchQuery,
  setSearchQuery,
  dateRange,
  setDateRange,
  setSelectionRequired
}) => {
  const { token } = theme.useToken()

  const handleRefresh = useCallback(() => {
    handleReadDocuments()
  }, [handleReadDocuments])

  const handleDateRangeChange = useCallback(
    (dates) => {
      setDateRange(dates || [])
    },
    [setDateRange]
  )

  return (
    <Space size='middle' wrap>
      <SearchBox token={token} value={searchQuery} onChange={setSearchQuery} />

      <RangePicker value={dateRange} onChange={handleDateRangeChange} disabled={loadingDocuments} />

      <Button
        disabled={loadingDocuments}
        type='primary'
        onClick={() => setSelectionRequired(true)}
        icon={<FontAwesomeIcon icon={faPlus} />}
      >
        New Document
      </Button>

      <Button
        disabled={loadingDocuments}
        type='primary'
        style={{ background: token.colorSuccess }}
        onClick={handleRefresh}
        icon={<FontAwesomeIcon icon={faRefresh} />}
        title='Refresh documents'
      />
    </Space>
  )
}

DocumentActions.propTypes = {
  handleReadDocuments: PropTypes.func.isRequired,
  loadingDocuments: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  dateRange: PropTypes.array.isRequired,
  setDateRange: PropTypes.func.isRequired
}

SearchBox.propTypes = {
  token: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

SearchBox.displayName = 'SearchBox'

export default memo(DocumentActions)
