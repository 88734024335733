import React from 'react'
import AgoraRTC, { AgoraRTCProvider, useRTCClient } from 'agora-rtc-react'

import { PatLiveVideo } from './PatLiveVideo'

const PatLiveVideoWrapper = ({ appointment, channelName, token, uid, disconnectCallback }) => {
  const agoraClient = useRTCClient(AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' }))

  return (
    <AgoraRTCProvider client={agoraClient}>
      <PatLiveVideo
        appointment={appointment}
        channelName={channelName}
        token={token}
        uid={uid}
        disconnectCallback={disconnectCallback}
      />
    </AgoraRTCProvider>
  )
}

export default PatLiveVideoWrapper
