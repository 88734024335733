export const patientData = {
  personalInfo: {
    id: 'PT123456',
    name: 'John Doe',
    age: 45,
    gender: 'Male',
    bloodType: 'A+',
    height: '5\'10"',
    weight: '180 lbs',
    bmi: 25.8
  },
  medicalHistory: {
    conditions: [
      {
        condition: 'Type 2 Diabetes',
        diagnosedDate: '2019-03-15',
        status: 'Active',
        medications: ['Metformin 500mg', 'Glipizide 5mg']
      },
      {
        condition: 'Hypertension',
        diagnosedDate: '2018-06-22',
        status: 'Active',
        medications: ['Lisinopril 10mg']
      }
    ],
    allergies: [
      {
        allergen: 'Penicillin',
        severity: 'Severe',
        reaction: 'Anaphylaxis'
      }
    ],
    surgeries: [
      {
        procedure: 'Appendectomy',
        date: '2010-08-12',
        hospital: 'Memorial Hospital'
      }
    ]
  },
  vitals: {
    recentReadings: [
      {
        date: '2024-03-15',
        bloodPressure: '128/82',
        heartRate: 72,
        temperature: '98.6°F',
        bloodSugar: '142 mg/dL'
      }
    ]
  },
  labResults: {
    recent: [
      {
        testName: 'Complete Blood Count',
        date: '2024-03-01',
        result: '5.7',
        unit: 'mmol/L',
        change: -3.4,
        status: 'Normal',
        trend: 'improving',
        metrics: {
          current: 5.7,
          previous: 5.9,
          change: -3.4,
          unit: 'mmol/L',
          status: 'Normal',
          trend: 'improving'
        },
        history: [
          { date: '2023-12-01', value: 6.1 },
          { date: '2024-01-15', value: 5.9 },
          { date: '2024-03-01', value: 5.7 }
        ],
        components: [
          {
            name: 'Red Blood Cells',
            result: '4.8',
            unit: 'x10^12/L',
            normalRange: '4.5-5.5',
            status: 'Normal'
          },
          {
            name: 'White Blood Cells',
            result: '7.2',
            unit: 'x10^9/L',
            normalRange: '4.0-11.0',
            status: 'Normal'
          },
          {
            name: 'Hemoglobin',
            result: '14.2',
            unit: 'g/dL',
            normalRange: '13.5-17.5',
            status: 'Normal'
          },
          {
            name: 'Hematocrit',
            result: '42',
            unit: '%',
            normalRange: '41-50',
            status: 'Normal'
          },
          {
            name: 'Platelets',
            result: '250',
            unit: 'x10^9/L',
            normalRange: '150-450',
            status: 'Normal'
          }
        ]
      },
      {
        testName: 'Lipid Panel',
        date: '2024-02-15',
        status: 'Attention Needed',
        metrics: {
          current: 210,
          previous: 225,
          change: -6.7,
          unit: 'mg/dL',
          status: 'Borderline High',
          trend: 'improving'
        },
        components: [
          {
            name: 'Total Cholesterol',
            result: '210',
            unit: 'mg/dL',
            normalRange: '<200',
            status: 'Borderline High'
          },
          {
            name: 'LDL',
            result: '130',
            unit: 'mg/dL',
            normalRange: '<100',
            status: 'High'
          },
          {
            name: 'HDL',
            result: '45',
            unit: 'mg/dL',
            normalRange: '>60',
            status: 'Low'
          },
          {
            name: 'Triglycerides',
            result: '150',
            unit: 'mg/dL',
            normalRange: '<150',
            status: 'Borderline High'
          }
        ],
        history: [
          { date: '2023-08-15', value: 240 },
          { date: '2023-11-15', value: 225 },
          { date: '2024-02-15', value: 210 }
        ]
      },
      {
        testName: 'HbA1c',
        date: '2024-02-15',
        result: '5.2',
        unit: '%',
        normalRange: '4.0-5.6',
        status: 'Normal',
        metrics: {
          current: 5.2,
          previous: 5.4,
          change: -3.7,
          unit: '%',
          status: 'Normal',
          trend: 'improving'
        },
        history: [
          { date: '2023-08-15', value: 5.6 },
          { date: '2023-11-15', value: 5.4 },
          { date: '2024-02-15', value: 5.2 }
        ]
      }
    ]
  },
  medications: {
    current: [
      {
        name: 'Metformin',
        dosage: '500mg',
        frequency: 'Twice daily',
        startDate: '2019-03-15'
      },
      {
        name: 'Lisinopril',
        dosage: '10mg',
        frequency: 'Once daily',
        startDate: '2018-06-22'
      },
      {
        name: 'Glipizide',
        dosage: '5mg',
        frequency: 'Once daily',
        startDate: '2019-03-15'
      }
    ]
  },
  preventiveCare: {
    vaccinations: [
      {
        name: 'Influenza',
        date: '2023-10-15',
        dueDate: '2024-10-15'
      },
      {
        name: 'COVID-19',
        date: '2023-05-20',
        type: 'Booster'
      }
    ],
    screenings: [
      {
        name: 'Eye Exam',
        lastDate: '2023-08-10',
        nextDueDate: '2024-08-10',
        result: 'Normal'
      }
    ]
  }
} 