import React, { useMemo } from 'react'

import { Card, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Switch, TimePicker } from 'antd'
import PropTypes from 'prop-types'
import { hexToRGBA } from '../../../../../lib/utils'

/**
 * Renders a form item with various field types and subfields
 * @param {Object} props Component props
 * @param {Object} props.item Form item configuration with field type, label, validation etc
 * @param {string} props.headerColor Color used for card header styling
 * @returns {JSX.Element} Rendered form item





 */
const RenderFormItem = ({ item, headerColor, isAddingTemplate }) => {
  const parseFieldData = (data) => {
    if (!data || typeof data !== 'string') return { options: [] }
    try {
      return JSON.parse(data)
    } catch (e) {
      console.error('Failed to parse field data:', e)
      return { options: [] }
    }
  }

  const renderField = (fieldConfig, suffix) => {
    const { fieldType, placeholder, options, data, label } = fieldConfig
    const parsedData = parseFieldData(data)
    const fieldOptions = options || parsedData.options || []

    const commonProps = {
      placeholder,
      style: { width: '100%' },
      readOnly: isAddingTemplate
    }

    const fieldMap = {
      number: <InputNumber {...commonProps} addonAfter={suffix} />,
      text: <Input {...commonProps} addonAfter={suffix} />,
      textarea: <Input.TextArea {...commonProps} rows={4} addonAfter={suffix} />,
      select: (
        <Select {...commonProps}>
          {fieldOptions.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      ),
      checkbox: <Checkbox>{label}</Checkbox>,
      checkboxgroup: (
        <Checkbox.Group>
          {fieldOptions.map(({ value, label }) => (
            <Checkbox key={value} value={value}>
              {label}
            </Checkbox>
          ))}
        </Checkbox.Group>
      ),
      radio: (
        <Radio.Group>
          {fieldOptions.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Radio.Group>
      ),
      switch: (
        <Switch
          checkedChildren={parsedData.checkedChildren}
          unCheckedChildren={parsedData.unCheckedChildren}
          defaultChecked={parsedData.defaultChecked}
        />
      ),
      date: <DatePicker {...commonProps} />,
      time: <TimePicker {...commonProps} />
    }

    return fieldMap[fieldType] || null
  }

  const renderFormItem = (fieldConfig, name, required) => {
    const formItemProps = {
      label: <span style={{ fontWeight: 'bold' }}>{fieldConfig.label}</span>,
      name,
      style: { marginBottom: fieldConfig.subFields ? 12 : 0 },
      required,
      rules: [
        {
          required,
          message: `Please enter ${fieldConfig.label}`
        }
      ]
    }

    return <Form.Item {...formItemProps}>{renderField(fieldConfig, item.suffix)}</Form.Item>
  }

  const cardContent = useMemo(() => {
    if (item?.subFields?.length > 0) {
      return (
        <Card
          styles={{
            body: { padding: 8, background: hexToRGBA(headerColor, 0.1) },
            header: {
              background: hexToRGBA(headerColor, 0.2),
              fontWeight: 'bold'
            }
          }}
          // title={item.label}
          size='small'
          type='inner'
        >
          <p style={{ opacity: 0.88 }}>
            <b>{item.label}</b>
          </p>
          {item.subFields.map((subfield) =>
            renderFormItem(subfield, [item.key, subfield.key], isAddingTemplate ? false : subfield.required)
          )}
        </Card>
      )
    }

    return (
      <Card styles={{ body: { padding: 8, background: hexToRGBA(headerColor, 0.1) } }}>
        {renderFormItem(item, item.key, isAddingTemplate ? false : item.required)}
      </Card>
    )
    // eslint-disable-next-line
  }, [item, headerColor])

  return cardContent
}

RenderFormItem.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,

    suffix: PropTypes.string,
    subFields: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        required: PropTypes.bool
      })
    )
  }).isRequired,
  headerColor: PropTypes.string.isRequired
}

export default React.memo(RenderFormItem)
