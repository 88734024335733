import React, { useState, useEffect } from 'react'
import { Typography, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { getStatusIcon } from '../utils/statusHelpers'
import '../../../styling/PendingBloodwork.css'

const { Text } = Typography

const PendingBloodwork = () => {
  const [expandedTest, setExpandedTest] = useState(null)
  const [completedTests, setCompletedTests] = useState(new Set())
  const [completedTimes, setCompletedTimes] = useState({})
  const [remainingTimes, setRemainingTimes] = useState({})
  const [activeProgress, setActiveProgress] = useState(new Set())
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [currentTime, setCurrentTime] = useState('--:--')

  const dummyTests = [] // Empty array to simulate no data

  // Update current time every second
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      const hours = now.getHours().toString().padStart(2, '0')
      const minutes = now.getMinutes().toString().padStart(2, '0')
      setCurrentTime(`${hours}:${minutes}`)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  // Sort tests by priority: processing -> overdue -> pending
  const sortedTests =
    dummyTests.length > 0
      ? [...dummyTests].sort((a, b) => {
          const priority = { processing: 0, overdue: 1, pending: 2 }
          return priority[a.status] - priority[b.status]
        })
      : [
          {
            id: 'placeholder',
            name: 'Processing Demo Test',
            status: 'processing',
            startTime: currentTime,
            info: {
              type: 'CareSync',
              provider: 'Meta-Clinic',
              normalRange: '4.5-5.5 mmol/L',
              nextStep: 'Doctor Consult'
            }
          }
        ]

  // Simulate test completion
  useEffect(() => {
    const processingTests = sortedTests.filter((test) => test.status === 'processing')
    let timeouts = []
    let intervals = []

    const runCycle = (testId) => {
      // Reset states at start of cycle
      setCompletedTimes((prev) => {
        const newTimes = { ...prev }
        delete newTimes[testId]
        return newTimes
      })

      // Set initial countdown time and start progress
      setRemainingTimes((prev) => ({
        ...prev,
        [testId]: 10
      }))
      setActiveProgress((prev) => {
        const newSet = new Set(prev)
        newSet.add(testId)
        return newSet
      })

      // Start countdown interval
      const countdownInterval = setInterval(() => {
        setRemainingTimes((prev) => {
          const currentTime = prev[testId]
          // Reset to 10 when it reaches 0
          return {
            ...prev,
            [testId]: currentTime <= 0 ? 10 : currentTime - 1
          }
        })
      }, 1000)

      intervals.push(countdownInterval)

      // For placeholder, we don't want to complete the cycle
      if (testId !== 'placeholder') {
        // Start progress
        const progressTimeout = setTimeout(() => {
          // Clear countdown and progress
          clearInterval(countdownInterval)
          setRemainingTimes((prev) => {
            const newTimes = { ...prev }
            delete newTimes[testId]
            return newTimes
          })
          setActiveProgress((prev) => {
            const newSet = new Set(prev)
            newSet.delete(testId)
            return newSet
          })

          // Show completion time
          setCompletedTimes((prev) => ({
            ...prev,
            [testId]: currentTime
          }))

          // Start glow effect
          const glowTimeout = setTimeout(() => {
            setCompletedTests((prev) => {
              const newSet = new Set(prev)
              newSet.add(testId)
              return newSet
            })

            const removeGlowTimeout = setTimeout(() => {
              setCompletedTests((prev) => {
                const newSet = new Set(prev)
                newSet.delete(testId)
                return newSet
              })

              // Before starting next cycle, wait a bit
              const nextCycleTimeout = setTimeout(() => {
                runCycle(testId)
              }, 2000)

              timeouts.push(nextCycleTimeout)
            }, 500)

            timeouts.push(removeGlowTimeout)
          }, 500)

          timeouts.push(glowTimeout)
        }, 10000)

        timeouts.push(progressTimeout)
      }
    }

    processingTests.forEach((test) => {
      runCycle(test.id)
    })

    return () => {
      timeouts.forEach(clearTimeout)
      intervals.forEach(clearInterval)
    }
  }, [currentTime])

  const toggleExpand = (testId) => {
    setExpandedTest(expandedTest === testId ? null : testId)
  }

  // Separate active and inactive tests
  const activeTests = sortedTests.filter((test) => test.status === 'processing')
  const inactiveTests = sortedTests.filter((test) => test.status !== 'processing')

  const renderTestItem = (test) => (
    <div
      key={test.id}
      className={`
        cs-status-center__test-item 
        cs-status-center__test-item--${test.status}
        ${completedTests.has(test.id) ? 'cs-status-center__test-item--completed' : ''}
        ${activeProgress.has(test.id) ? 'cs-status-center__test-item--progressing' : ''}
      `}
    >
      <div className='cs-status-center__main-content'>
        {getStatusIcon(test.status, completedTimes[test.id])}
        <div className='cs-status-center__test-info'>
          <Text strong className='cs-status-center__test-name'>
            {test.name}
          </Text>
          <div className='cs-status-center__time-row'>
            <div className='cs-status-center__times'>
              <Text type='secondary' className='cs-status-center__time'>
                Started: {test.startTime}
              </Text>
              <Text type='secondary' className='cs-status-center__time cs-status-center__time--remaining'>
                Time remaining: {remainingTimes[test.id] || 10}s
              </Text>
            </div>
            <Button type='link' className='cs-status-center__learn-more' onClick={() => toggleExpand(test.id)}>
              <span className='cs-status-center__more-text'>
                <span style={{ color: '#65D2D8' }}>m</span>
                <span style={{ color: '#B5D777' }}>o</span>
                <span style={{ color: '#FDB462' }}>r</span>
                <span style={{ color: '#EF8D9C' }}>e</span>
              </span>
              <FontAwesomeIcon
                icon={expandedTest === test.id ? faChevronDown : faChevronRight}
                className={expandedTest === test.id ? 'expanded' : ''}
              />
            </Button>
          </div>
        </div>
      </div>
      {expandedTest === test.id && (
        <div className='cs-status-center__info'>
          <div className='cs-status-center__info-grid'>
            <div className='cs-status-center__info-item'>
              <Text type='secondary' className='cs-status-center__info-label'>
                Type
              </Text>
              <Text className='cs-status-center__info-value'>{test.info.type}</Text>
            </div>
            <div className='cs-status-center__info-item'>
              <Text type='secondary' className='cs-status-center__info-label'>
                Provider
              </Text>
              <Text className='cs-status-center__info-value'>{test.info.provider}</Text>
            </div>
            <div className='cs-status-center__info-item'>
              <Text type='secondary' className='cs-status-center__info-label'>
                Normal Range
              </Text>
              <Text className='cs-status-center__info-value'>{test.info.normalRange}</Text>
            </div>
            <div className='cs-status-center__info-item'>
              <Text type='secondary' className='cs-status-center__info-label'>
                Next Step
              </Text>
              <Text className='cs-status-center__info-value'>{test.info.nextStep}</Text>
            </div>
          </div>
        </div>
      )}
    </div>
  )

  return (
    <div className='cs-status-center'>
      <div className='cs-status-center__header'>
        <Text className='cs-status-center__title'>Pending Blood Work</Text>
        {inactiveTests.length > 0 && (
          <Button
            type='link'
            className='cs-status-center__collapse-btn'
            onClick={() => setIsCollapsed(!isCollapsed)}
            title={isCollapsed ? 'Show Results' : 'Hide Results'}
          >
            <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronDown} />
          </Button>
        )}
      </div>
      <div className='cs-status-center__tests'>
        {/* Always show active tests */}
        {activeTests.map(renderTestItem)}

        {/* Show/hide inactive tests based on collapse state */}
        {!isCollapsed && inactiveTests.map(renderTestItem)}
      </div>
      <Text type='secondary' style={{ fontSize: '12px', marginTop: '12px', display: 'block', padding: '0 4px' }}>
        This section displays real-time progress of blood tests being analyzed. You'll see the test name, start time,
        and remaining processing time when tests are running.
      </Text>
    </div>
  )
}

export default PendingBloodwork
