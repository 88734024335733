import React, { useState } from 'react'
import { List, Space, Typography, Tag, Button, Card, theme, Modal } from 'antd'
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  DownloadOutlined,
  ShareAltOutlined,
  RightOutlined
} from '@ant-design/icons'
import { hexToRGBA } from '../../../../lib/utils'

const { Text } = Typography

const MobPatCareSyncResultsPreview = ({ testResults, dataPoints, setFacet, setPreviewResult }) => {
  const { token } = theme.useToken()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRanges, setSelectedRanges] = useState([])
  const [selectedTest, setSelectedTest] = useState('')

  const processTestResults = (results) => {
    return results
      .map((result) => {
        // Find matching dataPoint for the test
        const dataPoint = dataPoints.find((dp) => dp.key === result.key)

        return {
          name: result.key,
          testName: dataPoint?.label || result.key,
          date: new Date(result.dateCreated), // Keep as Date object for sorting
          displayDate: new Date(result.dateCreated).toLocaleDateString(),
          value: result.value,
          unit: dataPoint?.suffix || '',
          referenceRanges: dataPoint?.data ? JSON.parse(dataPoint.data).referenceRangeData : [],
          trend: 'up'
        }
      })
      .sort((a, b) => b.date - a.date) // Sort by date, newest first
      .slice(0, 3) // Take only the latest 3 results
  }

  const showReferenceRanges = (ranges, testName) => {
    setSelectedRanges(ranges)
    setSelectedTest(testName)
    setIsModalOpen(true)
  }

  const renderReferenceRanges = (ranges) => {
    if (!ranges.length) return null
    return (
      <div style={{ marginTop: 4 }}>
        {ranges.map((range, index) => (
          <Tag key={index} style={{ marginBottom: 4 }}>
            {range.label}
          </Tag>
        ))}
      </div>
    )
  }

  return (
    <>
      <Card
        title='Latest Test Results'
        bordered={true}
        styles={{
          header: { background: token.colorPrimary, color: 'white' }
        }}
        size='small'
        extra={
          <Text onClick={() => setFacet('results')} style={{ color: 'white' }}>
            View All <RightOutlined />
          </Text>
        }
      >
        <List
          dataSource={processTestResults(testResults)}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type='primary'
                  onClick={() => {
                    setPreviewResult(item)
                    setFacet('results')
                  }}
                >
                  View <RightOutlined />
                </Button>
              ]}
            >
              <Text style={{ width: '100%', background: hexToRGBA(token.colorPrimary, 0.1), padding: 6 }} strong>
                {item.testName}
              </Text>
              <Space direction='vertical' size={0} style={{ flex: 1, padding: 6 }}>
                <Text type='secondary'>{item.displayDate}</Text>
                <Text style={{ color: item.trend === 'up' ? '#52c41a' : '#f5222d' }}>
                  {item.value} {item.unit}
                </Text>
              </Space>
            </List.Item>
          )}
        />
      </Card>

      <Modal
        title={`Reference Ranges - ${selectedTest}`}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key='close' onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
        ]}
      >
        <Space direction='vertical' style={{ width: '100%' }}>
          {selectedRanges.map((range, index) => (
            <Card key={index} size='small' style={{ width: '100%' }}>
              <Text>{range.label}</Text>
            </Card>
          ))}
        </Space>
      </Modal>
    </>
  )
}

export default MobPatCareSyncResultsPreview
