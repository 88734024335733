import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Switch,
  Table,
  Tooltip,
  message,
  theme
} from 'antd'
import { handleError, hexToRGBA } from '../../lib/utils'
import { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUp,
  faCheck,
  faChevronLeft,
  faEdit,
  faMedkit,
  faQuestionCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import CustomRow from '../../reusable-components/CustomRow'
import { MedPraxICD10Search, ProductSearchAgilite } from '../utils/utils'
import ScriptDescriptionBuilderPanel from './description-builder-panel'
import TextArea from 'antd/es/input/TextArea'
import '../utils/style.css'
import { readMedicalHistory } from '../../Admin/patients/utils/utils'
import { SearchOutlined } from '@ant-design/icons'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import ScriptingTemplatesModal from './scripting-templates-modal'
import FeatureButton from '../../reusable-components/FeatureButton'
import { medAdminTypes } from '../utils/templates'

const ScriptingTool = ({
  script,
  setScript,
  setPatientChronicDetails,
  patientChronicDetails,
  patientId,
  selectedPatient,
  dischemDeliveryApplicable,
  setDischemModalOpen,
  selectedDischem,
  dischemType
}) => {
  const [icd10Loading, setIcd10Loading] = useState(false)
  const [ICD10SearchFilter, setICD10SearchFilter] = useState('')
  const [icd10Data, setIcd10Data] = useState([])

  const [searchFilter, setSearchFilter] = useState('')

  const [medsList, setMedsList] = useState(script?.items ? script.items : [])
  const [currentDescFocus, setCurrentDescFocus] = useState('')
  const [nappiDataLoading, setNappiDataLoading] = useState(false)
  const [nappiData, setNappiData] = useState([])

  const [isAddAction, setIsAddAction] = useState(false)
  const [showClipboard, setShowClipboard] = useState(false)
  const [currentMed, setCurrentMed] = useState(null)
  const [showChronic, setShowChronic] = useState(false)
  const [showAllergies, setShowAllergies] = useState(false)
  const [rptRepeat, setRptRepeat] = useState(true)
  const [rptICD, setRptICD] = useState(true)
  const [rptRepeatCount, setRptRepeatCount] = useState(0)
  const [rptICDValue, setRptICDValue] = useState(null)

  const { token } = theme.useToken()

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await ProductSearchAgilite(searchQuery)
        const finalData = []

        for (const entry of tmpData) {
          finalData.push({
            type: entry.type,
            ...entry.data
          })
        }
        setNappiData(finalData)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setNappiDataLoading(false)
  }

  useEffect(() => {
    getPatientChronicDetails()
    // eslint-disable-next-line
  }, [])

  const getPatientChronicDetails = async () => {
    let tmpData = []
    try {
      tmpData = await readMedicalHistory({ userRef: script.userRef || patientId || selectedPatient?._id })
      setPatientChronicDetails(tmpData[0])
    } catch (e) {
      message.error(handleError(e))
    }
  }

  useEffect(() => {
    debouncedNappiFilter(searchFilter)

    // eslint-disable-next-line
  }, [searchFilter])

  // eslint-disable-next-line
  const debouncedNappiFilter = useCallback(
    debounce((value) => {
      getNappiData(value)
    }, 1000),
    []
  )

  useEffect(() => {
    let tmpScript = { ...script }
    tmpScript.items = [...medsList]
    setScript(tmpScript)
    // eslint-disable-next-line
  }, [medsList])

  const handleAdd = (product) => {
    setIsAddAction(true)
    let tmpMedList = [...medsList]
    if (product) {
      let exists = tmpMedList.find((i) => i.code === product.code)
      if (exists) return message.error('Medication already added.')

      if (rptICD && rptICDValue) {
        product.icd = rptICDValue
      }

      if (rptRepeat && rptRepeatCount) {
        product.rpt = rptRepeatCount
      }
      tmpMedList.push(product)
    }
    // else {
    //   // MANUAL
    //   let exists = tmpMedList.find((i) => i.name === manualAdd)
    //   if (exists) return message.error('Medication already added.')
    //   tmpMedList.push({ name: manualAdd?.toUpperCase(), code: manualAdd, qty: 0, rpt: 0 })
    // }
    setNappiData([])
    setSearchFilter('')
    setCurrentMed(tmpMedList.length - 1)
    setMedsList(tmpMedList)
    // productSearchInput.current.focus()
  }

  const handleRemove = async (index) => {
    setCurrentDescFocus(null)
    let tmpMedList = [...medsList]
    tmpMedList.splice(index, 1)
    setMedsList(tmpMedList)
  }

  const handleScriptDosageChange = (newValue, index) => {
    let tmpMedList = [...medsList]
    tmpMedList[index].scriptingDosage = newValue
    setMedsList(tmpMedList)
  }

  const handleExtraPropertyChange = (value, prop, index) => {
    let tmpMedList = [...medsList]
    if (prop === 'icd') {
      setRptICDValue(value)
    }
    if (prop === 'rpt') {
      setRptRepeatCount(value)
    }
    tmpMedList[index][prop] = value
    setMedsList(tmpMedList)
  }

  // const checkDrugInteractions = async () => {
  //   let thread_id = ''
  //   let tmpTransactionIntervalId = null

  //   setAssistantDrugCheckLoading(true)

  //   try {
  //     thread_id = await AssistantDrugInteractionCheck(medsList)

  //     tmpTransactionIntervalId = setInterval(() => {
  //
  //       handleReadThread(thread_id, tmpTransactionIntervalId)
  //     }, 3500)
  //   } catch (e) {
  //     setAssistantDrugCheckLoading(false)
  //     message.error(handleError(e))
  //   }
  // }

  // const handleReadThread = async (thread_id, intervalId) => {
  //   let response = null

  //   try {
  //     response = await readThread(thread_id)
  //
  //     if (response && response?.data?.data?.length > 1 && response?.data?.data[0].content.length > 0) {
  //       clearInterval(intervalId)
  //       setAssistantDrugCheckResponse(response?.data?.data[0]?.content[0]?.text?.value)
  //       setAssistantDrugCheckLoading(false)
  //     }
  //   } catch (e) {
  //     clearInterval(intervalId)
  //     message.error(handleError(e, true))
  //     setAssistantDrugCheckLoading(false)
  //   }
  // }

  const getICD10Data = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await MedPraxICD10Search(searchQuery)
        setIcd10Data(tmpData)
      } else {
        setIcd10Data([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setIcd10Loading(false)
  }
  useEffect(() => {
    setIcd10Loading(true)
    debounceICD10Filter(ICD10SearchFilter)

    // eslint-disable-next-line
  }, [ICD10SearchFilter])

  // eslint-disable-next-line
  const debounceICD10Filter = useCallback(
    debounce((value) => {
      getICD10Data(value)
    }, 1000),
    []
  )

  useEffect(() => {
    if (isAddAction) {
      // lastMedRef.current.scrollIntoView()
      setIsAddAction(false)
    }
    // eslint-disable-next-line
  }, [medsList])

  const lastMedRef = useRef()
  const getNappiOptions = () => {
    let dataArray = nappiData.map((med) => ({
      search: `${med.name} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
        med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
      } ${med.presentationCode ? med.presentationCode : ''} - ${med.standardPacksize ? med.standardPacksize : ''}`,
      label: (
        <>
          {medAdminTypes.find((i) => i.key === med.presentationCode)?.icon} {med.name}{' '}
          {med.strengthMetric1 ? med.strengthMetric1 : ''} {med.unitOfMeasure1 ? med.unitOfMeasure1 : ''}{' '}
          {med.presentationCode ? med.presentationCode : ''} - {med.standardPacksize ? med.standardPacksize : ''}
        </>
      ),
      value: JSON.stringify(med)
    }))
    if (searchFilter && !nappiDataLoading) {
      dataArray = [
        {
          search: searchFilter,
          label: searchFilter,
          value: JSON.stringify({
            name: searchFilter,
            standardPacksize: 1,
            code: searchFilter,
            nappiCode: searchFilter
          })
        },
        ...dataArray
      ]
    }
    return dataArray
  }

  return (
    <CustomRow gutter={[12, 12]} style={currentDescFocus || currentDescFocus === 0 ? { marginBottom: 300 } : {}}>
      <Col span={24}>
        <center style={{ marginBottom: 12, marginTop: 12 }}>
          <h2 style={{ color: token.colorPrimary }}>Create prescription</h2>
          <p>What are you prescribing today?</p>
        </center>
      </Col>
      {medsList.length > 0 ? undefined : (
        <Col span={24}>
          <center style={{ marginBottom: 12 }}>
            <Select
              value={null}
              placeholder='Search drug by trade name'
              style={{
                width: 600,
                maxWidth: '100%',
                textAlign: 'left',
                height: 50,
                borderRadius: 100, // Adjust the border-radius as needed
                overflow: 'hidden',
                border: `2px ${token.colorPrimary} solid`, // Default border color
                boxShadow: '0 2px 20px 0px rgba(0, 0, 0, 0.1)' // Optional: Add a box-shadow for a more distinct look
              }}
              suffixIcon={<SearchOutlined style={{ fontSize: 20, color: token.colorPrimary }} />}
              showSearch
              onSearch={(value) => {
                if (value) {
                  setNappiDataLoading(true)
                  setSearchFilter(value)
                } else {
                  setSearchFilter('')
                }
              }}
              onSelect={(product) => {
                handleAdd(JSON.parse(product))
              }}
              notFoundContent={nappiDataLoading ? <Skeleton active /> : 'Type to search...'}
              optionFilterProp='search'
              options={getNappiOptions()}
            />
          </center>
        </Col>
      )}
      {/* <Col span={10}>
        <Card
          type='inner'
          size='small'
          title={
            <>
              {isManualCapture ? 'ADD PRODUCT' : 'PRODUCT SEARCH'}{' '}
              <Tooltip
                title={
                  manualAdd
                    ? 'Add medications manually when you are unable to find them by searching.'
                    : 'Search through a comprehensive list of medications to add to patient scripts'
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            </>
          }
          extra={
            <Switch
              onChange={(boolean) => setIsManualCapture(boolean)}
              checked={isManualCapture}
              checkedChildren='Manual'
              style={{ background: isManualCapture ? token.colorSecondary : token.colorPrimary }}
              unCheckedChildren='Search'
            />
          }
          style={{ borderRadius: 0, borderRight: 'none', minHeight: '100%' }}
          headStyle={{ borderRadius: 0 }}
          bodyStyle={{ padding: 0 }}
        >
          <Card
            className='head-items-full-height'
            type='inner'
            size='small'
            title={
              <div style={{ display: 'grid', gridTemplateColumns: 'auto 50px', width: '100%', height: 39 }}>
                {isManualCapture ? (
                  <>
                    <input
                      ref={productSearchInput}
                      placeholder='Product Description'
                      value={manualAdd}
                      onChange={(e) => {
                        setManualAdd(e.target.value)
                      }}
                      style={{ borderRadius: 0, height: '100%', margin: 0, border: 'none', paddingLeft: 8 }}
                    />
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: hexToRGBA(token.colorSuccess, 0.2),
                        borderLeft: '1px #ccc solid',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        if (!manualAdd) return message.error("Product name can't be blank")
                        handleAdd()
                      }}
                    >
                      <FontAwesomeIcon icon={faAdd} />
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      ref={productSearchInput}
                      placeholder='Product name / code'
                      value={searchFilter}
                      onChange={(e) => {
                        setSearchFilter(e.target.value)
                      }}
                      style={{ borderRadius: 0, height: '100%', margin: 0, border: 'none', paddingLeft: 8 }}
                    />
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: hexToRGBA(token.colorSuccess, 0.1),
                        borderLeft: '1px #ccc solid'
                      }}
                    >
                      {nappiLoading ? <Spin /> : <FontAwesomeIcon icon={faSearch} />}
                    </div>
                  </>
                )}
              </div>
            }
            style={{ borderRadius: 0, border: 'none', borderTop: '1px #ccc solid', minHeight: '100%' }}
            headStyle={{ borderRadius: 0, background: 'none', padding: 0 }}
            bodyStyle={{ padding: 0 }}
          >
            {isManualCapture ? (
              <div style={{ height: 78, display: 'flex', justifyContent: 'space-between' }}>
                <small style={{ marginTop: 4, marginLeft: 4 }}>
                  <FontAwesomeIcon icon={faArrowUp} /> Type product description
                </small>
                <small style={{ marginTop: 4, marginRight: 4 }}>
                  Click to add <FontAwesomeIcon icon={faArrowUp} />
                </small>
              </div>
            ) : (
              <>
                {nappiData.length > 0 ? (
                  <Table
                    locale={{
                      emptyText: nappiLoading
                        ? ''
                        : searchFilter
                        ? 'No results. Modify search.'
                        : 'Utilize the search box above to quickly find medications by code or product name.'
                    }}
                    className='no-radius'
                    style={{ borderRadius: 0, marginTop: 1, borderBottom: 0 }}
                    headStyle={{ borderRadius: 0 }}
                    // loading={{
                    //   spinning: nappiLoading,
                    //   tip: 'Searching...'
                    // }}
                    showHeader={nappiData.length > 0 ? true : false}
                    pagination={{
                      current: pageNumber,
                      pageSize: 5,
                      onChange: (page) => {
                        setPageNumber(page)
                      },
                      hideOnSinglePage: true
                    }}
                    dataSource={nappiData}
                    onRow={(product) => {
                      return {
                        onClick: () => {
                          handleAdd(product)
                        }
                      }
                    }}
                    columns={[
                      {
                        title: 'Product',
                        render: (med) => {
                          return (
                            <>{`${med.name} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
                              med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
                            } ${med.presentationCode ? med.presentationCode : ''} - ${
                              med.standardPacksize ? med.standardPacksize : ''
                            }`}</>
                          )
                        }
                      },
                      {
                        title: 'Nappi',
                        dataIndex: 'nappiCode',
                        key: 'nappiCode'
                      },
                      {
                        title: 'S',
                        dataIndex: 'drugSchedule',
                        key: 'drugSchedule'
                      }
                    ]}
                  />
                ) : undefined}
              </>
            )}
          </Card>
          {patientChronicDetails?.chronicIllnesses?.list?.length > 0 ? (
            <Card
              type='inner'
              size='small'
              title={
                <center>
                  PATIENT CHRONIC MEDICATIONS{' '}
                  <Tooltip title="Access a list of the patient's chronic illnesses along with associated medications. Utilize this feature to conveniently add chronic script items to the patient's script without the need for additional searches.">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Tooltip>
                </center>
              }
              style={{ borderRadius: 0, borderRight: 'none', minHeight: '100%' }}
              headStyle={{ borderRadius: 0, background: hexToRGBA(token.colorWarning, 0.5) }}
              bodyStyle={{ padding: 0 }}
            >
              {patientChronicDetails ? (
                <>
                  <Table
                    size='small'
                    dataSource={
                      patientChronicDetails?.chronicIllnesses?.list ? patientChronicDetails?.chronicIllnesses?.list : []
                    }
                    pagination={false}
                    bordered={true}
                    columns={[
                      {
                        title: 'Chronic Illness',
                        render: (record) => `${record.description} - ${record.code}`
                      }
                    ]}
                    expandable={{
                      defaultExpandAllRows: false,
                      expandedRowRender: (record) => (
                        <Table
                          size='small'
                          style={{ borderRadius: 0, marginTop: 1, borderBottom: 0 }}
                          headStyle={{ borderRadius: 0 }}
                          pagination={false}
                          dataSource={record.medications.map((med) => ({
                            ...med.med,
                            isChronic: true,
                            icd: {
                              code: record.code,
                              description: record.description
                            }
                          }))}
                          onRow={(product) => {
                            return {
                              onClick: () => {
                                handleAdd(product)
                              }
                            }
                          }}
                          columns={[
                            {
                              title: 'Medication',
                              render: (med) => {
                                return (
                                  <>{`${med.name ? med.name : ''} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
                                    med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
                                  } ${med.presentationCode ? med.presentationCode : ''} - ${
                                    med.standardPacksize ? med.standardPacksize : ''
                                  }`}</>
                                )
                              }
                            }
                          ]}
                        />
                      ),
                      rowExpandable: (record) => record?.medications?.length > 0
                    }}
                    rowKey={(record, index) => index}
                  />
                  <div style={{ height: 78, display: 'flex', justifyContent: 'space-between' }}>
                    <small style={{ marginTop: 4, marginLeft: 20 }}>
                      <FontAwesomeIcon icon={faArrowUp} /> Click to expand / collapse
                    </small>
                  </div>
                </>
              ) : (
                <CustomRow style={{ padding: 24 }}>
                  <Col span={24}>
                    {' '}
                    <Empty description='Patient has no chronic illnesses captured.' />
                  </Col>
                </CustomRow>
              )}
            </Card>
          ) : undefined}
          {patientChronicDetails?.allergies?.medsList?.length > 0 ? (
            <Card
              type='inner'
              size='small'
              title={<center>ALLERGIES</center>}
              style={{ borderRadius: 0, borderRight: 'none', minHeight: '100%' }}
              headStyle={{ borderRadius: 0, background: hexToRGBA(token.colorWarning, 0.5) }}
              bodyStyle={{ padding: 0 }}
            >
              <Table
                pagination={false}
                style={{ marginTop: 1 }}
                showHeader={false}
                dataSource={
                  patientChronicDetails?.allergies?.medsList ? patientChronicDetails?.allergies?.medsList : []
                }
                columns={[{ render: (entry) => entry }]}
              />
            </Card>
          ) : undefined}
        </Card>
      </Col>*/}
      <Col span={24}>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 24 }}>
          <div>
            <Card
              type='inner'
              size='small'
              title={
                <center>
                  SCRIPT ITEMS{' '}
                  <Tooltip title='List of currently added script items: Specify whether a medication is chronic, the number of repeats needed, and optionally link an ICD code if necessary.'>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Tooltip>
                </center>
              }
              style={{ minHeight: '100%' }}
              headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
              bodyStyle={{ padding: 0 }}
            >
              <Space style={{ padding: 8, borderBottom: '1px #ccc solid', width: '100%' }}>
                <Space>
                  <span>Repeat RPT: </span>
                  <Switch
                    checked={rptRepeat}
                    onChange={(boolean) => {
                      setRptRepeat(boolean)
                    }}
                  />
                </Space>
                <Space>
                  <span>Repeat ICD: </span>
                  <Switch
                    checked={rptICD}
                    onChange={(boolean) => {
                      setRptICD(boolean)
                    }}
                  />
                </Space>
              </Space>
              <CustomRow
                gutter={[12, 12]}
                style={{
                  minHeight: '100%',
                  alignContent: 'flex-start',
                  marginTop: dischemDeliveryApplicable ? 0 : 12,
                  marginBottom: 12
                }}
              >
                {dischemDeliveryApplicable ? (
                  <Col
                    span={24}
                    style={{ background: hexToRGBA(!selectedDischem ? token.colorError : token.colorSuccess, 0.2) }}
                  >
                    <p style={{ paddingTop: 4, paddingBottom: 4 }}>
                      {!selectedDischem ? (
                        <center>
                          Dischem <strong>{dischemType === 'C' ? 'Collection' : 'Delivery'}</strong> requested but no
                          store specified, please review.{' '}
                          <strong
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setDischemModalOpen(true)
                            }}
                          >
                            <u>Click here</u>
                          </strong>{' '}
                          to correct.
                        </center>
                      ) : (
                        <center>
                          {dischemType === 'C' ? (
                            <>
                              Dischem <strong>Collection</strong> by patient at{' '}
                              <strong>{JSON.parse(selectedDischem)?.address}</strong> will be requsted.
                            </>
                          ) : (
                            <>
                              Dischem <strong>Delivery</strong> from{' '}
                              <strong>{JSON.parse(selectedDischem)?.address}</strong> will be requested.
                            </>
                          )}
                        </center>
                      )}
                    </p>
                  </Col>
                ) : undefined}
                {medsList?.length > 0 ? (
                  <>
                    {medsList.map((med, index) => (
                      <>
                        <Col span={24} key={index}>
                          {index !== currentMed ? (
                            <>
                              <Space
                                style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start  ' }}
                              >
                                <div>
                                  <Space style={{ gap: 0 }}>
                                    {medAdminTypes.find((i) => i.key === med.presentationCode)?.icon ? (
                                      <div style={{ color: med.isChronic ? '#F39C12' : token.colorPrimary, width: 22 }}>
                                        {medAdminTypes.find((i) => i.key === med.presentationCode)?.icon}{' '}
                                      </div>
                                    ) : (
                                      <div style={{ color: med.isChronic ? '#F39C12' : token.colorPrimary, width: 22 }}>
                                        <FontAwesomeIcon icon={faMedkit} />
                                      </div>
                                    )}
                                    {`${med.name ? med.name : ''} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
                                      med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
                                    } ${med.presentationCode ? med.presentationCode : ''}`}
                                  </Space>
                                  <div
                                    style={{
                                      paddingLeft: 20,
                                      borderLeft: `2px ${med.isChronic ? token.colorWarning : token.colorPrimary} solid`
                                    }}
                                  >
                                    <small>{med.rpt ? <>Repeat: {med.rpt}</> : <>No Repeat</>}</small>
                                    <br />
                                    <small>
                                      {med.scriptingDosage ? med.scriptingDosage : 'No scripting dosage specified.'}
                                    </small>
                                    <br />
                                    <small>
                                      {med.icd ? (
                                        <>
                                          ICD: {med.icd.code} - {med.icd.description}
                                        </>
                                      ) : (
                                        <>No ICD</>
                                      )}
                                    </small>
                                    <br />

                                    {med.isChronic ? <small style={{ color: '#F39C12' }}>Chronic</small> : undefined}
                                  </div>
                                </div>
                                <p
                                  style={{
                                    color: med.isChronic ? '#F39C12' : token.colorPrimary,
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    setCurrentMed(index)
                                  }}
                                >
                                  EDIT <FontAwesomeIcon icon={faEdit} />
                                </p>
                              </Space>
                            </>
                          ) : (
                            <div
                              style={{
                                overflow: 'hidden',
                                border: `2px ${med.isChronic ? '#F39C12' : token.colorPrimary} solid`,
                                borderRadius: 12
                              }}
                            >
                              <Card
                                ref={index === medsList.length - 1 ? lastMedRef : null}
                                size='small'
                                style={{
                                  borderRadius: 0,
                                  border: 'none',
                                  borderTop: '1px #ccc solid',
                                  borderBottom: '1px #ccc solid'
                                }}
                                headStyle={{
                                  background: med.isChronic
                                    ? hexToRGBA('#F39C12', 0.1)
                                    : hexToRGBA(token.colorPrimary, 0.1),
                                  borderRadius: 0
                                }}
                                bodyStyle={{ padding: 0 }}
                                title={`${med.name ? med.name : ''} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
                                  med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
                                } ${med.presentationCode ? med.presentationCode : ''}`}
                                extra={
                                  <Space>
                                    <Popconfirm
                                      title='Confirm Remove'
                                      description="This can't be undone!"
                                      okText='Remove'
                                      cancelText='Keep'
                                      onConfirm={() => {
                                        handleRemove(index)
                                      }}
                                    >
                                      <Button>
                                        <Space>
                                          <span style={{ color: token.colorError }}>Remove</span>
                                          <FontAwesomeIcon style={{ cursor: 'pointer' }} color='red' icon={faTrash} />
                                        </Space>
                                      </Button>
                                    </Popconfirm>
                                    <Button
                                      onClick={() => {
                                        setCurrentMed(null)
                                      }}
                                    >
                                      <Space>
                                        <FontAwesomeIcon
                                          style={{ cursor: 'pointer' }}
                                          color={token.colorSuccess}
                                          icon={faCheck}
                                        />
                                      </Space>
                                    </Button>
                                  </Space>
                                }
                              >
                                <div
                                  style={{
                                    width: '100%',
                                    display: 'grid',
                                    gridTemplateColumns: 'auto 150px',
                                    background: med.isChronic
                                      ? hexToRGBA('#F39C12', 0.1)
                                      : hexToRGBA(token.colorPrimary, 0.1)
                                  }}
                                >
                                  <TextArea
                                    id={`script-item-${index}`}
                                    style={{
                                      borderRadius: 0,
                                      background: 'white',
                                      border: 'none',
                                      marginTop: 1,
                                      borderRight: '1px #ccc solid'
                                    }}
                                    rows={5}
                                    onFocus={() => {
                                      setCurrentDescFocus(index)
                                    }}
                                    placeholder='Type dosage instructions...'
                                    defaultValue={med.scriptingDosage}
                                    value={medsList[index].scriptingDosage}
                                    onChange={(e) => {
                                      handleScriptDosageChange(e.target.value, index)
                                    }}
                                  />
                                  <Space direction='vertical' style={{ padding: 8 }}>
                                    <Space>
                                      <p style={{ width: 50 }}>
                                        <b>QTY:</b>
                                      </p>
                                      <InputNumber
                                        onChange={(value) => {
                                          handleExtraPropertyChange(value, 'standardPacksize', index)
                                        }}
                                        value={med.standardPacksize}
                                        style={{ width: 75 }}
                                      />
                                    </Space>
                                    <Space>
                                      <p style={{ width: 50 }}>
                                        <b>RPT:</b>
                                      </p>
                                      <InputNumber
                                        onChange={(value) => {
                                          handleExtraPropertyChange(value, 'rpt', index)
                                        }}
                                        value={med.rpt ? med.rpt : 0}
                                        style={{ width: 75 }}
                                      />
                                    </Space>
                                    <Space>
                                      <p style={{ width: 50 }}>
                                        <b>CHR:</b>
                                      </p>
                                      <Input
                                        readOnly
                                        onClick={() => {
                                          let value = false
                                          if (med.isChronic) {
                                            value = false
                                          } else {
                                            value = true
                                          }
                                          handleExtraPropertyChange(value, 'isChronic', index)
                                        }}
                                        value={med.isChronic ? 'Yes' : 'No'}
                                        style={{ width: 75, cursor: 'pointer' }}
                                      />
                                    </Space>
                                  </Space>
                                </div>
                                <div
                                  style={{
                                    borderTop: '1px #ccc solid',
                                    display: 'grid',
                                    gridTemplateColumns: '50px 1fr',
                                    alignItems: 'center',
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    paddingRight: 5,
                                    background: med.isChronic
                                      ? hexToRGBA('#F39C12', 0.1)
                                      : hexToRGBA(token.colorPrimary, 0.1)
                                  }}
                                >
                                  <p style={{ width: 50, paddingLeft: 8 }}>
                                    <b>ICD:</b>
                                  </p>
                                  <Select
                                    allowClear
                                    onClear={() => {
                                      handleExtraPropertyChange(null, 'icd', index)
                                    }}
                                    suffixIcon={<SearchOutlined />}
                                    value={med.icd ? med.icd?.code + ' - ' + med.icd?.description : undefined}
                                    notFoundContent={
                                      icd10Loading ? (
                                        <center>
                                          <AgiliteSkeleton skActive spinnerTip='Searching...' />
                                        </center>
                                      ) : undefined
                                    }
                                    onSearch={(searchValue) => {
                                      setICD10SearchFilter(searchValue)
                                    }}
                                    onSelect={(value) => {
                                      let tmpValue = JSON.parse(value)
                                      handleExtraPropertyChange(tmpValue, 'icd', index)
                                      setIcd10Data([])
                                    }}
                                    placeholder='Type to search for ICD10 if applicable..'
                                    showSearch
                                    loading={icd10Loading}
                                    options={
                                      icd10Loading
                                        ? []
                                        : icd10Data.map((i) => {
                                            return {
                                              label: i.code + ' - ' + i.description,
                                              value: JSON.stringify({ code: i.code, description: i.description })
                                            }
                                          })
                                    }
                                    style={{ width: '100%' }}
                                  />
                                </div>
                                {dischemDeliveryApplicable ? (
                                  <>
                                    <div
                                      style={{
                                        borderTop: '1px #ccc solid',
                                        display: 'grid',
                                        gridTemplateColumns: '135px auto',
                                        alignItems: 'center',
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        paddingRight: 5,
                                        background: med.isChronic
                                          ? hexToRGBA('#F39C12', 0.1)
                                          : hexToRGBA(token.colorPrimary, 0.1)
                                      }}
                                    >
                                      <p style={{ width: 135, paddingLeft: 8 }}>
                                        <b>DAYS SUPPLY:</b>
                                      </p>
                                      <InputNumber
                                        onChange={(number) => {
                                          handleExtraPropertyChange(number, 'daysSupply', index)
                                        }}
                                        placeholder='0'
                                        showSearch
                                      />
                                    </div>
                                    <div
                                      style={{
                                        borderTop: '1px #ccc solid',
                                        display: 'grid',
                                        gridTemplateColumns: '135px auto',
                                        alignItems: 'center',
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        paddingRight: 5,
                                        background: med.isChronic
                                          ? hexToRGBA('#F39C12', 0.1)
                                          : hexToRGBA(token.colorPrimary, 0.1)
                                      }}
                                    >
                                      <p style={{ width: 135, paddingLeft: 8 }}>
                                        <b>DOSAGE:</b>
                                      </p>
                                      <InputNumber
                                        onChange={(number) => {
                                          handleExtraPropertyChange(number, 'dosage', index)
                                        }}
                                        placeholder='0'
                                        showSearch
                                      />
                                    </div>
                                  </>
                                ) : undefined}
                              </Card>
                            </div>
                          )}
                        </Col>
                        <Divider style={{ margin: 0 }} />
                      </>
                    ))}
                  </>
                ) : undefined}
                {medsList.length > 0 ? (
                  <Col span={24}>
                    <div>
                      <Select
                        value={null}
                        placeholder='Search drug by trade name'
                        style={{
                          width: '100%',
                          maxWidth: '100%',
                          textAlign: 'left',
                          height: 50,
                          borderRadius: 100, // Adjust the border-radius as needed
                          overflow: 'hidden',
                          border: `2px ${token.colorPrimary} solid`, // Default border color
                          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' // Optional: Add a box-shadow for a more distinct look
                        }}
                        suffixIcon={<SearchOutlined style={{ fontSize: 20, color: token.colorPrimary }} />}
                        showSearch
                        onSearch={(value) => {
                          if (value) {
                            setNappiDataLoading(true)
                            setSearchFilter(value)
                          } else {
                            setSearchFilter('')
                          }
                        }}
                        onSelect={(product) => {
                          handleAdd(JSON.parse(product))
                        }}
                        notFoundContent={nappiDataLoading ? <Skeleton active /> : 'Type to search...'}
                        optionFilterProp='search'
                        options={getNappiOptions()}
                      />
                    </div>
                  </Col>
                ) : undefined}
              </CustomRow>
            </Card>
          </div>
          <div style={{ maxWidth: 500, minWidth: 250 }}>
            {showChronic || showAllergies ? (
              <Card
                bodyStyle={{ padding: 0 }}
                headStyle={{ background: hexToRGBA(showChronic ? '#F39C12' : '#FF6A88', 0.2) }}
                size='small'
                title={
                  <>
                    <FontAwesomeIcon
                      style={{ cursor: 'pointer' }}
                      icon={faChevronLeft}
                      onClick={() => {
                        setShowChronic(false)
                        setShowAllergies(false)
                      }}
                    />{' '}
                    {showChronic ? ' Chronic Details' : showAllergies ? 'Allergies' : 'Dischem Delivery/Collection'}
                  </>
                }
              >
                {showAllergies ? (
                  <>
                    {patientChronicDetails?.allergies?.medsList?.length > 0 ? (
                      <Table
                        size='small'
                        pagination={false}
                        style={{ marginTop: 1 }}
                        showHeader={false}
                        dataSource={
                          patientChronicDetails?.allergies?.medsList ? patientChronicDetails?.allergies?.medsList : []
                        }
                        columns={[{ render: (entry) => entry }]}
                      />
                    ) : (
                      <CustomRow style={{ padding: 12, minHeight: 200 }}>
                        <Col span={24}>
                          <center>No recorded allergies to date.</center>
                        </Col>
                      </CustomRow>
                    )}
                  </>
                ) : undefined}
                {showChronic ? (
                  <>
                    {patientChronicDetails ? (
                      <>
                        <Table
                          style={{ marginTop: 1 }}
                          showHeader={false}
                          size='small'
                          dataSource={
                            patientChronicDetails?.chronicIllnesses?.list
                              ? patientChronicDetails?.chronicIllnesses?.list
                              : []
                          }
                          pagination={false}
                          columns={[
                            {
                              title: 'Chronic Illness',
                              render: (record) => `${record.description} - ${record.code}`
                            }
                          ]}
                          expandable={{
                            defaultExpandAllRows: false,
                            expandedRowRender: (record) => (
                              <Table
                                size='small'
                                style={{ borderRadius: 0, borderBottom: 0 }}
                                headStyle={{ borderRadius: 0 }}
                                pagination={false}
                                dataSource={record.medications.map((med) => ({
                                  ...med.med,
                                  isChronic: true,
                                  icd: {
                                    code: record.code,
                                    description: record.description
                                  }
                                }))}
                                onRow={(product) => {
                                  return {
                                    onClick: () => {
                                      handleAdd(product)
                                    }
                                  }
                                }}
                                columns={[
                                  {
                                    title: 'Medication',
                                    render: (med) => {
                                      return (
                                        <>{`${med.name ? med.name : ''} ${
                                          med.strengthMetric1 ? med.strengthMetric1 : ''
                                        } ${med.unitOfMeasure1 ? med.unitOfMeasure1 : ''} ${
                                          med.presentationCode ? med.presentationCode : ''
                                        } - ${med.standardPacksize ? med.standardPacksize : ''}`}</>
                                      )
                                    }
                                  }
                                ]}
                              />
                            ),
                            rowExpandable: (record) => record?.medications?.length > 0
                          }}
                          rowKey={(record, index) => index}
                        />
                        <div style={{ height: 78, display: 'flex', justifyContent: 'space-between' }}>
                          <small style={{ marginTop: 4, marginLeft: 20 }}>
                            <FontAwesomeIcon icon={faArrowUp} /> Click to expand / collapse
                          </small>
                        </div>
                      </>
                    ) : (
                      <CustomRow style={{ padding: 12, minHeight: 200 }}>
                        <Col span={24}>
                          <center>No record chronic illnesses to date.</center>
                        </Col>
                      </CustomRow>
                    )}
                  </>
                ) : undefined}
              </Card>
            ) : (
              <Space wrap direction='vertical'>
                <FeatureButton
                  background='linear-gradient(45deg, #2980B9, #6DD5FA)'
                  backgroundHover='#2980B9'
                  text='SCRIPT TEMPLATES'
                  onClick={() => setShowClipboard(true)}
                />
                <FeatureButton
                  background='linear-gradient(45deg, #00A049,  #F3EC1C)'
                  backgroundHover='#00A049'
                  text='DIS-CHEM'
                  onClick={() => setDischemModalOpen(true)}
                />
                <FeatureButton
                  background='linear-gradient(45deg,  #FF6A88, #FF99AC)'
                  backgroundHover='#FF6A88'
                  text='ALLERGIES'
                  onClick={() => setShowAllergies(true)}
                />
                <FeatureButton
                  background='linear-gradient(45deg, #F39C12, #F8D800)'
                  backgroundHover='#F39C12'
                  text='CHRONIC ILLNESSES'
                  onClick={() => setShowChronic(true)}
                />
              </Space>
            )}
          </div>
        </div>
      </Col>

      {currentDescFocus || currentDescFocus === 0 ? (
        <ScriptDescriptionBuilderPanel
          currentDescFocus={currentDescFocus}
          setCurrentDescFocus={setCurrentDescFocus}
          state={medsList}
          setState={setMedsList}
        />
      ) : undefined}
      <ScriptingTemplatesModal
        items={medsList}
        setItems={setMedsList}
        open={showClipboard}
        setOpen={setShowClipboard}
      />
    </CustomRow>
  )
}

export default ScriptingTool
