import React, { useState } from 'react'
import { handleError, hexToRGBA } from '../../../../lib/utils'
import { Button, Col, Divider, message, Space, Table, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faSearch, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import CustomRow from '../../../../reusable-components/CustomRow'
import dayjs from 'dayjs'
import CustomLoadingIcon from '../../../../reusable-components/CustomLoadingIcon'

const LabOrdersWrapper = ({ setModalOpen, onAfterSuccess }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [labOrderSelection, setLabOrderSelection] = useState([])
  const [loading, setLoading] = useState(false)
  const { token } = theme.useToken()

  const tmpSelection = [
    {
      key: 'cholesterol',
      title: 'Cholesterol',
      approxPrice: 475
    },
    {
      key: 'bloodSugar',
      title: 'Blood Sugar',
      approxPrice: 300
    },
    {
      key: 'hemoglobin',
      title: 'Hemoglobin',
      approxPrice: 350
    },
    {
      key: 'kidneyFunction',
      title: 'Kidney Function',
      approxPrice: 600
    },
    {
      key: 'liverFunction',
      title: 'Liver Function',
      approxPrice: 550
    },
    {
      key: 'uricAcid',
      title: 'Uric Acid',
      approxPrice: 400
    },
    {
      key: 'thyroidFunction',
      title: 'Thyroid Function',
      approxPrice: 700
    },
    {
      key: 'vitaminD',
      title: 'Vitamin D',
      approxPrice: 450
    },
    {
      key: 'cbc',
      title: 'Complete Blood Count (CBC)',
      approxPrice: 600
    }
  ]

  const getLabOrderTotal = () => {
    let tmpTotal = 0
    labOrderSelection.forEach((i) => {
      tmpTotal = tmpTotal + JSON.parse(i).approxPrice
    })
    return tmpTotal
  }

  const handleCreateLabOrder = async () => {
    try {
      // Simulate a 2-second delay (2000 milliseconds)
      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 2000))

      // Once the delay is over, you can proceed with the rest of your logic
      message.success('Lab order placed successfully.')
      onAfterSuccess({ selection: labOrderSelection, dateOrdered: dayjs(), status: 'Pending' })
      handleReset()
    } catch (error) {
      // Handle any errors that may occur
      message.error(handleError(error))
    }
    setLoading(false)
  }

  const handleReset = () => {
    setSearchQuery('')
    setLabOrderSelection([])
    setModalOpen(false)
  }
  return (
    <>
      <div
        style={{
          width: '100%',
          padding: 8,
          background: hexToRGBA('#FFA500', 0.2),
          position: 'absolute',
          top: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          left: 0
        }}
      >
        <h1>LAB ORDER</h1>
        <Button
          onClick={() => {
            handleReset()
            setModalOpen(false)
          }}
          style={{ float: 'right', fontSize: 24, background: 'transparent', border: 'none' }}
        >
          <FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />
        </Button>
      </div>
      <CustomRow style={{ marginTop: 48 }}>
        <Col span={24}>
          <CustomLoadingIcon
            loading={loading}
            content={
              <div style={{ display: 'grid', gridTemplateColumns: '350px 1fr', border: '1px grey solid' }}>
                <div style={{ borderRight: '1px grey solid', minHeight: 300, maxHeight: '65vh', overflow: 'auto' }}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', borderBottom: '1px grey solid' }}>
                    <input
                      value={searchQuery}
                      placeholder='Search'
                      style={{ width: '100%', borderRadius: 0, border: 'none', paddingLeft: 10 }}
                      onChange={(e) => {
                        setSearchQuery(e.target.value)
                      }}
                    />
                    <div
                      style={{
                        height: 35,
                        width: 35,
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderLeft: '1px grey solid',
                        background: token.colorPrimary,
                        color: '#ffffff'
                      }}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </div>
                  </div>
                  <div
                    style={{
                      padding: 10,
                      borderBottom: '1px grey solid',
                      background: hexToRGBA(token.colorSecondary, 0.2)
                    }}
                  >
                    LAB TESTS
                  </div>
                  <Space direction='vertical' style={{ width: '100%' }}>
                    {tmpSelection
                      .filter((i) => i.title.toLowerCase().includes(searchQuery.toLowerCase()))
                      .sort((a, b) => {
                        const titleSelection = labOrderSelection.map((i) => JSON.parse(i).title)
                        const aInSelection = titleSelection.includes(a.title)
                        const bInSelection = titleSelection.includes(b.title)

                        if (aInSelection && !bInSelection) return -1
                        if (!aInSelection && bInSelection) return 1

                        // If both are in the selection or both are not in the selection, sort alphabetically
                        return a.title.localeCompare(b.title)
                      })
                      .map((item) => (
                        <>
                          <Space style={{ width: '100%', padding: 10, justifyContent: 'space-between' }}>
                            <p style={{ width: '100%' }}>{item.title}</p>
                            <div
                              style={{
                                background: labOrderSelection.includes(JSON.stringify(item))
                                  ? token.colorError
                                  : token.colorSuccess,
                                width: 20,
                                height: 20,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '50%',
                                color: '#ffffff',
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                if (labOrderSelection.includes(JSON.stringify(item))) {
                                  let tmpLabOrderSelection = JSON.parse(JSON.stringify(labOrderSelection))
                                  const itemIndex = tmpLabOrderSelection.findIndex((i) => i === JSON.stringify(item))
                                  tmpLabOrderSelection.splice(itemIndex, 1)
                                  setLabOrderSelection(tmpLabOrderSelection)
                                } else {
                                  setLabOrderSelection([...labOrderSelection, JSON.stringify(item)])
                                }
                              }}
                            >
                              <FontAwesomeIcon
                                style={{ fontSize: 10 }}
                                icon={labOrderSelection.includes(JSON.stringify(item)) ? faMinus : faPlus}
                              />
                            </div>
                          </Space>
                          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                        </>
                      ))}
                  </Space>
                </div>
                <Table
                  summary={() => (
                    <Table.Summary.Row style={{ background: hexToRGBA(token.colorPrimary, 0.0925) }}>
                      <Table.Summary.Cell>
                        <b>Order Total: </b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>R{getLabOrderTotal()}.00</Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                  dataSource={labOrderSelection.map((i) => JSON.parse(i))}
                  columns={[
                    {
                      title: 'Test',
                      dataIndex: 'title',
                      key: 'title'
                    },
                    {
                      title: 'Price',
                      width: 200,
                      dataIndex: 'approxPrice',
                      key: 'approxPrice',
                      render: (price) => <>R{price}</>
                    }
                  ]}
                />
              </div>
            }
            loadingText='Finalizing Order...'
          />
        </Col>
        <Col span={24}>
          <Button
            type='primary'
            loading={loading}
            disabled={labOrderSelection.length === 0}
            style={{ float: 'right', background: labOrderSelection.length === 0 ? '' : token.colorSuccess }}
            onClick={() => {
              handleCreateLabOrder()
            }}
          >
            Confirm Order
          </Button>
        </Col>
      </CustomRow>
    </>
  )
}

export default LabOrdersWrapper
