import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Button, Card, Empty, Select, Modal, Row, Col, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMedkit, faPills, faTrash, faPlus, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import '../style/MobChronicIllnesses.css'
import useChronicIllnesses from '../../hooks/useChronicIllnesses'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import DatePicker from '../../../Reusable Components/DatePicker'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Modal as AntModal } from 'antd'
import MobSectionHeader from './MobSectionHeader'

const MobChronicIllnesses = ({ clientForm, data, setFacet, setSaving }) => {
  const {
    illnessList,
    handleAddIllness,
    handleRemoveIllness,
    handleUpdateIllness,
    handleRemoveMedication,
    handleAddMedication,
    handleUpdateMedication,
    nappiData,
    icd10Data,
    nappiLoading,
    icd10Loading,
    handleSubmit,
    setICD10SearchFilter,
    setNappiSearchFilter,
    showIllnessModal,
    setShowIllnessModal,
    medicationModalState,
    setMedicationModalState,
    editingIllness,
    setEditingIllness,
    tempDiagnosisDate,
    setTempDiagnosisDate,
    tempMedDates,
    setTempMedDates
  } = useChronicIllnesses({ clientForm, setFacet, setSaving })

  const [showIllnessDatePicker, setShowIllnessDatePicker] = useState(false)
  const [showMedStartDatePicker, setShowMedStartDatePicker] = useState(false)
  const [showMedEndDatePicker, setShowMedEndDatePicker] = useState(false)

  const styles = {
    header: {
      fontSize: '20px',
      fontWeight: 600,
      marginBottom: '24px',
      display: 'flex',
      alignItems: 'center',
      gap: '12px'
    },
    addButton: {
      background: '#00A6E3',
      borderRadius: '8px',
      border: 'none',
      height: '48px',
      fontSize: '16px',
      fontWeight: 500
    },
    illnessCard: {
      borderRadius: '12px',
      border: 'none',
      marginBottom: '12px'
    },
    illnessIcon: {
      color: '#00A6E3',
      fontSize: '24px',
      marginTop: '4px'
    },
    illnessName: {
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      textTransform: 'uppercase'
    },
    diagnosisText: {
      color: '#8F9BB3',
      margin: '4px 0 0 0',
      fontSize: '14px'
    },
    addMedicationBtn: {
      border: '1px dashed #00A6E3',
      color: '#00A6E3',
      background: 'transparent',
      borderRadius: '8px',
      height: '44px'
    },
    medicationCard: {
      borderRadius: '8px',
      border: 'none'
    },
    medicationIcon: {
      color: '#00A6E3',
      fontSize: '20px',
      marginTop: '4px'
    },
    medicationName: {
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    removeButton: {
      color: '#FF4D4F',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      background: 'rgba(255, 77, 79, 0.1)',
      border: '1px solid #FF4D4F',
      borderRadius: '4px',
      padding: '4px 8px',
      fontSize: '14px',
      cursor: 'pointer'
    },
    modalContent: {
      color: '#8F9BB3',
      fontSize: '14px',
      lineHeight: '1.5'
    }
  }

  const showDeleteConfirm = (illness, index, handleRemoveIllness) => {
    AntModal.confirm({
      title: 'Remove Chronic Illness',
      icon: <ExclamationCircleFilled style={{ color: '#FF4D4F' }} />,
      content: (
        <div style={styles.modalContent}>
          <p>Are you sure you want to remove {illness.description}?</p>
          <p>This will also remove all associated medications:</p>
          <ul>
            {illness.medications?.map((med, idx) => (
              <li key={idx}>{med.med.name}</li>
            ))}
          </ul>
          <p>This action cannot be undone.</p>
        </div>
      ),
      okText: 'Remove',
      okButtonProps: {
        danger: true
      },
      cancelText: 'Cancel',
      onOk() {
        handleRemoveIllness(index)
      },
      className: 'dark-modal',
      centered: true
    })
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <MobSectionHeader
          icon={faMedkit}
          title='CHRONIC ILLNESSES'
          subtitle='Manage your chronic conditions and medications'
        />
      </Col>
      <Col span={24}>
        <Button
          type='primary'
          onClick={setShowIllnessModal}
          icon={<FontAwesomeIcon icon={faPlus} />}
          block
          style={styles.addButton}
        >
          Add Chronic Illness
        </Button>
      </Col>

      <Col span={24}>
        {illnessList?.length === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span style={{ color: '#8F9BB3' }}>No chronic illnesses added yet</span>}
          />
        ) : (
          <Space direction='vertical' style={{ width: '100%' }}>
            {illnessList?.map((illness, index) => (
              <Card key={index} style={styles.illnessCard} bodyStyle={{ padding: '16px' }}>
                <Row align='top' justify='space-between'>
                  <Col>
                    <div style={styles.illnessName}>
                      <div
                        onClick={() => {
                          setEditingIllness({
                            ...illness,
                            index
                          })
                          setTempDiagnosisDate(illness.dateDiagnosed)
                          setShowIllnessModal(true)
                        }}
                        style={{ flex: 1, cursor: 'pointer' }}
                      >
                        {illness.description.toUpperCase()}
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div
                      style={styles.diagnosisText}
                      onClick={() => {
                        setShowIllnessModal(true)
                      }}
                    >
                      Diagnosed: {dayjs(illness.dateDiagnosed).format('DD MMM YYYY')}
                    </div>
                  </Col>
                </Row>

                <Row gutter={[0, 12]} style={{ marginTop: 16 }}>
                  <Col span={24}>
                    <Space direction='vertical' style={{ width: '100%' }}>
                      <Button
                        block
                        onClick={(e) => {
                          e.stopPropagation()
                          showDeleteConfirm(illness, index, handleRemoveIllness)
                        }}
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        style={{
                          ...styles.addMedicationBtn,
                          border: '1px dashed #FF4D4F',
                          color: '#FF4D4F',
                          width: '100%'
                        }}
                      >
                        Remove Illness
                      </Button>
                      <Button
                        block
                        onClick={() => setMedicationModalState({ show: true, index })}
                        icon={<FontAwesomeIcon icon={faPills} />}
                        style={{
                          ...styles.addMedicationBtn,
                          width: '100%'
                        }}
                      >
                        Add Medication
                      </Button>
                    </Space>
                  </Col>

                  <Col span={24}>
                    <Space direction='vertical' style={{ width: '100%' }}>
                      {illness.medications?.map((med, medIndex) => (
                        <Card key={medIndex} style={styles.medicationCard} bodyStyle={{ padding: '12px' }}>
                          <Row gutter={[0, 12]} align='top' justify='space-between'>
                            <Col span={24}>
                              <div>
                                <div
                                  style={styles.medicationName}
                                  onClick={() => {
                                    setMedicationModalState({
                                      show: true,
                                      index,
                                      medIndex,
                                      editing: true,
                                      medication: med,
                                      selectedMed: med.med
                                    })
                                    setTempMedDates({
                                      startDate: med.startDate,
                                      endDate: med.endDate
                                    })
                                  }}
                                >
                                  {med.med.name}
                                </div>
                                <div
                                  style={styles.diagnosisText}
                                  onClick={() => {
                                    setMedicationModalState({
                                      show: true,
                                      index,
                                      medIndex,
                                      editing: true,
                                      medication: med,
                                      selectedMed: med.med
                                    })
                                    setTempMedDates({
                                      startDate: med.startDate,
                                      endDate: med.endDate
                                    })
                                  }}
                                >
                                  <p>Started: {dayjs(med.startDate).format('DD MMM YYYY')}</p>
                                  <p>
                                    {med.endDate && <span>Ended: {dayjs(med.endDate).format('DD MMM YYYY')}</span>}
                                    {!med.endDate && <span> - Current</span>}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col span={24}>
                              <Button
                                block
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleRemoveMedication(index, medIndex)
                                }}
                                icon={<FontAwesomeIcon icon={faTrash} />}
                                style={{
                                  ...styles.addMedicationBtn,
                                  border: '1px dashed #FF4D4F',
                                  color: '#FF4D4F',
                                  width: '100%'
                                }}
                              >
                                Remove Medication
                              </Button>
                            </Col>
                          </Row>
                        </Card>
                      ))}
                    </Space>
                  </Col>
                </Row>
              </Card>
            ))}
          </Space>
        )}
      </Col>

      <Modal
        title={
          <div>
            <div>{editingIllness ? 'Edit Chronic Illness' : 'Add Chronic Illness'}</div>
            <div style={{ fontSize: '14px', fontWeight: 'normal', marginTop: '4px' }}>
              {editingIllness ? 'Update diagnosis date' : 'Search our database of conditions'}
            </div>
          </div>
        }
        open={showIllnessModal}
        onCancel={() => {
          setShowIllnessModal(false)
          setEditingIllness(null)
          setTempDiagnosisDate(null)
          setMedicationModalState((prev) => ({ ...prev, selectedIllness: null }))
        }}
        footer={
          <Button
            type='primary'
            disabled={!tempDiagnosisDate}
            onClick={() => {
              if (editingIllness) {
                handleUpdateIllness()
              } else {
                handleAddIllness(medicationModalState.selectedIllness)
              }
            }}
          >
            {editingIllness ? 'Update' : 'Add'} Illness
          </Button>
        }
        className='dark-modal'
      >
        {editingIllness ? (
          <>
            <div style={{ marginBottom: '16px' }}>
              <div style={{ fontSize: '16px', fontWeight: 500 }}>
                {editingIllness.description} ({editingIllness.code})
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ marginBottom: '16px', color: '#8F9BB3' }}>
              <p>Search for your chronic illness by typing its name or ICD-10 code.</p>
              <p>• Start typing at least 3 characters to see suggestions</p>
              <p>• Select the most accurate match from the dropdown</p>
            </div>
            <Select
              className='dark-modal-select'
              showSearch
              placeholder='E.g. Type "Diabetes", "Hypertension", "J45" ...'
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={setICD10SearchFilter}
              onSelect={(value) => {
                const parsedValue = typeof value === 'string' ? JSON.parse(value) : value
                setMedicationModalState((prev) => ({
                  ...prev,
                  selectedIllness: parsedValue
                }))
              }}
              notFoundContent={icd10Loading ? 'Searching...' : 'No results found. Try different keywords.'}
              style={{ width: '100%' }}
              options={icd10Data.map((item) => ({
                value: JSON.stringify(item),
                label: `${item.description} (${item.code})`
              }))}
              value={
                medicationModalState.selectedIllness ? JSON.stringify(medicationModalState.selectedIllness) : undefined
              }
            />
          </>
        )}

        <div style={{ marginTop: '16px' }}>
          <div style={{ color: '#8F9BB3', marginBottom: '8px' }}>Diagnosis Date</div>
          <div
            onClick={() => setShowIllnessDatePicker(true)}
            style={{
              padding: '8px 12px',
              borderRadius: '8px',
              cursor: 'pointer',
              border: '1px solid #8F9BB3'
            }}
          >
            {tempDiagnosisDate
              ? dayjs(tempDiagnosisDate).format('DD MMM YYYY')
              : editingIllness?.dateDiagnosed
              ? dayjs(editingIllness.dateDiagnosed).format('DD MMM YYYY')
              : 'Select diagnosis date'}
          </div>
        </div>

        <DatePicker
          showDatePicker={showIllnessDatePicker}
          setShowDatePicker={setShowIllnessDatePicker}
          pickerValue={
            tempDiagnosisDate
              ? dayjs(tempDiagnosisDate)
              : editingIllness?.dateDiagnosed
              ? dayjs(editingIllness.dateDiagnosed)
              : null
          }
          setPickerValue={(date) => setTempDiagnosisDate(date)}
          handleDateSelect={(date) => {
            setTempDiagnosisDate(date)
            setShowIllnessDatePicker(false)
          }}
        />
      </Modal>

      <Modal
        title={
          <div>
            <div>{medicationModalState.editing ? 'Edit Medication' : 'Add Medication'}</div>
            <div style={{ fontSize: '14px', color: '#8F9BB3', fontWeight: 'normal', marginTop: '4px' }}>
              for {illnessList[medicationModalState.index]?.description}
            </div>
          </div>
        }
        open={medicationModalState.show}
        onCancel={() => {
          setMedicationModalState({ show: false, index: 0 })
          setTempMedDates({ startDate: null, endDate: null })
        }}
        footer={
          <Button
            type='primary'
            disabled={!tempMedDates.startDate || (!medicationModalState.editing && !medicationModalState.selectedMed)}
            onClick={() => {
              if (medicationModalState.editing) {
                handleUpdateMedication(medicationModalState.index, medicationModalState.medIndex, tempMedDates)
              } else {
                handleAddMedication(medicationModalState.selectedMed, tempMedDates)
              }
              setMedicationModalState({ show: false, index: 0 })
              setTempMedDates({ startDate: null, endDate: null })
            }}
          >
            {medicationModalState.editing ? 'Update' : 'Add'} Medication
          </Button>
        }
        className='dark-modal'
      >
        {medicationModalState.editing ? (
          <>
            <div style={{ marginBottom: '16px' }}>
              <div style={{ fontSize: '16px', fontWeight: 500 }}>
                {medicationModalState.selectedMed.name}
                {medicationModalState.selectedMed.dosageForm && ` (${medicationModalState.selectedMed.dosageForm})`}
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ marginBottom: '16px', color: '#8F9BB3' }}>
              <p>Search for your medication by typing its name or brand.</p>
              <p>• Enter the medication name or active ingredient</p>
              <p>• Select from the matching suggestions</p>
              <p>• The dosage form (if available) will be shown in brackets</p>
            </div>
            <Select
              className='dark-modal-select'
              showSearch
              placeholder='E.g. Type "Metformin", "Ventolin", "Aspirin" ...'
              defaultActiveFirstOption={false}
              filterOption={false}
              onSearch={setNappiSearchFilter}
              onSelect={(value) => {
                const parsedValue = typeof value === 'string' ? JSON.parse(value) : value
                setMedicationModalState((prev) => ({
                  ...prev,
                  selectedMed: parsedValue
                }))
              }}
              notFoundContent={nappiLoading ? 'Searching...' : 'No results found. Try different keywords or spelling.'}
              style={{ width: '100%' }}
              options={nappiData.map((item) => ({
                value: JSON.stringify(item),
                label: `${item.name} ${item.dosageForm ? `(${item.dosageForm})` : ''}`
              }))}
              value={medicationModalState.selectedMed ? JSON.stringify(medicationModalState.selectedMed) : undefined}
              disabled={medicationModalState.editing}
            />
          </>
        )}
        <div style={{ marginTop: '16px' }}>
          <div style={{ color: '#8F9BB3', marginBottom: '8px' }}>Start Date</div>
          <div
            onClick={() => setShowMedStartDatePicker(true)}
            style={{
              border: '1px solid #8F9BB3',
              padding: '8px 12px',
              borderRadius: '8px',
              color: tempMedDates.startDate ? '#000000' : '#8F9BB3',
              cursor: 'pointer'
            }}
          >
            {tempMedDates.startDate ? dayjs(tempMedDates.startDate).format('DD MMM YYYY') : 'Select start date'}
          </div>
        </div>
        <div style={{ marginTop: '16px' }}>
          <div style={{ color: '#8F9BB3', marginBottom: '8px' }}>End Date (Optional)</div>
          <div
            onClick={() => setShowMedEndDatePicker(true)}
            style={{
              border: '1px solid #8F9BB3',
              padding: '8px 12px',
              borderRadius: '8px',
              color: tempMedDates.endDate ? '#000000' : '#8F9BB3',
              cursor: 'pointer'
            }}
          >
            {tempMedDates.endDate ? dayjs(tempMedDates.endDate).format('DD MMM YYYY') : 'Select end date'}
          </div>
        </div>

        <DatePicker
          showDatePicker={showMedStartDatePicker}
          setShowDatePicker={setShowMedStartDatePicker}
          pickerValue={tempMedDates.startDate ? dayjs(tempMedDates.startDate) : null}
          setPickerValue={(date) => setTempMedDates((prev) => ({ ...prev, startDate: date }))}
          handleDateSelect={(date) => {
            setTempMedDates((prev) => ({ ...prev, startDate: date }))
            setShowMedStartDatePicker(false)
          }}
        />

        <DatePicker
          showDatePicker={showMedEndDatePicker}
          setShowDatePicker={setShowMedEndDatePicker}
          pickerValue={tempMedDates.endDate ? dayjs(tempMedDates.endDate) : null}
          setPickerValue={(date) => setTempMedDates((prev) => ({ ...prev, endDate: date }))}
          handleDateSelect={(date) => {
            setTempMedDates((prev) => ({ ...prev, endDate: date }))
            setShowMedEndDatePicker(false)
          }}
        />
      </Modal>

      <PrevNextFloatingButtons
        onPrev={() => {
          clientForm.resetFields()
          setFacet(null)
        }}
        onNext={handleSubmit}
        nextText='Save'
        prevText='Cancel'
      />
    </Row>
  )
}

export default MobChronicIllnesses
