import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { initPeachPaymentCheckout } from '../utils/utils'
import { handleError } from '../../lib/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import MobMedicalHistoryLoadingOverlay from '../../Patient/Medical History/Mobile/components/LoadingOverlay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'

/**
 * A React component that renders an embedded Peach Payments form
 * @param {Object} props - Component props
 * @param {Object} props.patientRecord - The patient record data for the payment
 * @param {number} props.amount - The payment amount
 * @param {Function} [props.onCompleted] - Callback function when payment is completed
 * @param {Function} [props.onCancelled] - Callback function when payment is cancelled by user
 * @param {Function} [props.onExpired] - Callback function when payment session expires
 * @returns {JSX.Element} A div container for the payment form or loading skeleton
 */
const EmbeddedPayment = ({
  patientRecord,
  amount,
  onCompleted = () => {},
  onCancelled = () => {},
  onExpired = () => {}
}) => {
  const [checkoutId, setCheckoutId] = useState('')

  useEffect(() => {
    handleInitCheckout()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (checkoutId) {
      const checkout = window.Checkout.initiate({
        key: process.env.REACT_APP_PEACH_PAYMENTS_ENTITY_ID,
        checkoutId,
        events: {
          // Payment & Checkout completed
          onCompleted,
          // Checkout cancelled by the user
          onCancelled,
          // 30 Minutes to complete a payment by default
          onExpired
        }
        // options: {
        //   theme: {
        //     brand: {
        //       primary: token.colorSecondary
        //     },
        //     cards: {
        //       background: token.colorSecondary,
        //       backgroundHover: token.colorPrimary
        //     }
        //   },
        //   ordering: {
        //     // Order of payment methods
        //     CARD: 1
        //   }
        // }
      })
      checkout.render('#payment-form')
    }

    // eslint-disable-next-line
  }, [checkoutId])

  const handleInitCheckout = async () => {
    try {
      const tmpCheckoutId = await initPeachPaymentCheckout(patientRecord, amount)
      setCheckoutId(tmpCheckoutId)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  // const { token } = theme.useToken()

  return (
    <>
      {checkoutId ? (
        <div style={{ backgroundColor: '#fff', width: '100%', height: '100vh' }} id='payment-form'></div>
      ) : (
        <MobMedicalHistoryLoadingOverlay
          loading={true}
          saveLoading={true}
          text='Initializing Payment...'
          loadingText='Initializing Payment...'
          icon={<FontAwesomeIcon icon={faCreditCard} />}
        />
      )}
    </>
  )
}

export default EmbeddedPayment
