import {
  faAllergies,
  faAppleAlt,
  faBed,
  faBrain,
  faDumbbell,
  faDisplay,
  faHeartPulse,
  faPills,
  faSmoking,
  faWineGlass,
  faGlassWater
} from '@fortawesome/free-solid-svg-icons'

export const COMMON_ALLERGIES = [
  { id: 'aspirin', label: 'Aspirin' },
  { id: 'penicillin', label: 'Penicillin' },
  { id: 'ibuprofen', label: 'Ibuprofen' },
  { id: 'insulin', label: 'Insulin' },
  { id: 'novocaine', label: 'Novocaine' }
]

export const COMMON_FOOD_ALLERGIES = [
  { id: 'peanuts', label: 'Peanuts' },
  { id: 'milk', label: 'Milk' },
  { id: 'shellfish', label: 'Shellfish' },
  { id: 'wheat', label: 'Wheat' },
  { id: 'eggs', label: 'Eggs' }
]

export const COMMON_GENERAL_ALLERGIES = [
  { id: 'dust', label: 'Dust' },
  { id: 'pollen', label: 'Pollen' },
  { id: 'latex', label: 'Latex' },
  { id: 'pets', label: 'Pet Dander' },
  { id: 'mold', label: 'Mold' }
]

export const ALLERGY_CATEGORIES = [
  {
    id: 'medications',
    label: 'Medications',
    icon: faPills,
    description: 'Allergies to prescription and over-the-counter medications',
    color: '255, 107, 107'
  },
  {
    id: 'food',
    label: 'Food',
    icon: faAppleAlt,
    description: 'Allergies to foods and ingredients',
    color: '81, 207, 102'
  },
  {
    id: 'general',
    label: 'General',
    icon: faAllergies,
    description: 'Other allergies like dust, pollen, latex etc.',
    color: '51, 154, 240'
  }
]

export const LIFESTYLE_SECTIONS = [
  {
    key: 'diet',
    icon: faHeartPulse,
    title: 'Diet',
    color: '81, 207, 102'
  },
  {
    key: 'physicalActivity',
    icon: faDumbbell,
    title: 'Physical Activity',
    color: '255, 107, 107'
  },
  {
    key: 'sleepHours',
    icon: faBed,
    title: 'Sleep',
    color: '139, 92, 246'
  },
  {
    key: 'waterIntake',
    icon: faGlassWater,
    title: 'Water Intake',
    color: '16, 178, 225'
  },
  {
    key: 'screenTime',
    icon: faDisplay,
    title: 'Screen Time',
    color: '251, 146, 60'
  },
  {
    key: 'stress',
    icon: faBrain,
    title: 'Stress Level',
    color: '236, 72, 153'
  },
  {
    key: 'alcoholConsumption',
    icon: faWineGlass,
    title: 'Alcohol Consumption',
    color: '168, 85, 247'
  },
  {
    key: 'smoking',
    icon: faSmoking,
    title: 'Smoking',
    color: '239, 68, 68'
  },
  {
    key: 'vaping',
    icon: faSmoking,
    title: 'Vaping',
    color: '147, 51, 234'
  }
]
