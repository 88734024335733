import React, { useState, useEffect } from 'react'
import { Slider, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import '../style/MobileMacroOverview.css'
import { updateDailyLimits } from '../utils/macro-tracker-utils'
import '../style/SaveCheckmark.css'
import MobTransparentButton from '../../../../../Reusable Components/Buttons/MobTransparentButton'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import patMacroTrackerReducer from '../utils/reducer'

const SaveCheckmark = ({ show, onAnimationEnd }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onAnimationEnd()
      }, 1500) // Reduced duration
      return () => clearTimeout(timer)
    }
  }, [show, onAnimationEnd])

  if (!show) return null

  return (
    <div className='checkmark-container'>
      <svg className='checkmark' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
        <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
      </svg>
    </div>
  )
}

const calculateTotalConsumed = (trackerData) => {
  return trackerData.reduce(
    (acc, item) => {
      const { prot, sug, fat, carb, cal } = item.macros
      acc.prot += prot
      acc.sug += sug
      acc.fat += fat
      acc.carb += carb
      acc.cal += cal
      return acc
    },
    { prot: 0, sug: 0, fat: 0, carb: 0, cal: 0 }
  )
}

const MobileMacroOverview = () => {
  const limits = useSelector((state) => state.patMacroTracker.limits)
  const trackerData = useSelector((state) => state.patMacroTracker.trackerData)
  const authState = useSelector((state) => state.auth)
  const [showCheckmark, setShowCheckmark] = useState(false)
  // const fileInputRef = useRef(null)
  const [editMode, setEditMode] = useState(false)
  const [tempLimits, setTempLimits] = useState(limits?.macros)
  const handleLimitChange = (value, key) => {
    setTempLimits((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const totalConsumed = calculateTotalConsumed(trackerData)

  const calculateProgress = (key) => {
    if (!limits?.macros[key]) return 0

    const consumed = totalConsumed[key]
    const max = limits.macros[key]
    const percent = (consumed / max) * 100
    return percent > 100 ? 100 : percent
  }

  const dispatch = useDispatch()

  const handleSaveLimits = async () => {
    try {
      const newRecord = await updateDailyLimits({ userRef: authState.agiliteUser._id }, { macros: tempLimits })
      dispatch(patMacroTrackerReducer.actions.setLimits(newRecord))
      setTempLimits(newRecord.macros)
      setEditMode(false)
    } catch (error) {
      message.error('Failed to update limits')
    }
  }

  const renderLimitSlider = (key) => {
    const config = macroConfig[key]
    return (
      <div
        style={{
          background: `linear-gradient(135deg, ${config.color}15, ${config.color}05)`,
          padding: '16px',
          borderRadius: '12px',
          marginBottom: '16px',
          border: `1px solid ${config.color}`
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            alignItems: 'center',
            marginBottom: '12px',
            gap: '8px'
          }}
        >
          <h2
            style={{
              margin: 0,
              fontSize: '18px',
              fontWeight: '600',
              color: '#000'
            }}
          >
            {config.label}
          </h2>
          <div
            style={{
              color: '#000',
              fontSize: '12px'
            }}
          >
            {tempLimits[key]}
            {key === 'cal' ? ' kcal' : 'g'}
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <Slider
            value={tempLimits[key]}
            onChange={(value) => handleLimitChange(value, key)}
            min={0}
            max={key === 'cal' ? 10000 : 1000}
            step={10}
            tooltip={{ formatter: null }}
            styles={{
              rail: {
                backgroundColor: 'rgba(255,255,255,0.1)',
                height: '8px'
              },
              track: {
                background: `linear-gradient(90deg, ${config.color}, ${config.gradientEnd})`,
                height: '8px'
              }
            }}
            // style={{ height: '8px' }}
          />
        </div>
      </div>
    )
  }

  const macroConfig = {
    prot: {
      label: 'Protein',
      color: '#ff8000',
      gradientEnd: '#ff9933'
    },
    fat: {
      label: 'Fats',
      color: '#2ecc71',
      gradientEnd: '#27ae60'
    },
    carb: {
      label: 'Carbs',
      color: '#3498db',
      gradientEnd: '#2980b9'
    },
    sug: {
      label: 'Sugar',
      color: '#ff1493',
      gradientEnd: '#ff69b4'
    },
    cal: {
      label: 'Calories',
      color: '#f1c40f',
      gradientEnd: '#f39c12'
    }
  }

  const renderMacroSection = (key) => {
    const config = macroConfig[key]

    return (
      <div
        key={key}
        style={{
          border: `1px solid ${config.color}`,
          background: `linear-gradient(135deg, ${config.color}15, ${config.color}05)`,
          padding: '16px',
          borderRadius: '12px',
          marginBottom: '16px'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            alignItems: 'center',
            marginBottom: '12px'
          }}
        >
          <h2
            style={{
              margin: 0,
              fontSize: '18px',
              fontWeight: '600',
              color: '#000'
            }}
          >
            {config.label}
          </h2>
          <div
            style={{
              textAlign: 'center',
              color: '#000',
              fontSize: '12px'
            }}
          >
            {totalConsumed[key]} of {limits?.macros?.[key]}
            {key === 'cal' ? 'kcal' : 'g'}
          </div>
          <div style={{ textAlign: 'right' }}>
            <span
              style={{
                color: config.color,
                fontSize: '18px',
                fontWeight: '600'
              }}
            >
              {Math.round(calculateProgress(key))}%
            </span>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div
            style={{
              width: '100%',
              height: '8px',
              borderRadius: '4px',
              backgroundColor: 'rgba(255,255,255,0.1)'
            }}
          >
            <div
              style={{
                width: `${calculateProgress(key)}%`,
                height: '100%',
                borderRadius: '4px',
                background: `linear-gradient(90deg, ${config.color}, ${config.gradientEnd})`,
                transition: 'width 0.3s ease-in-out'
              }}
            ></div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <MobTransparentButton
        style={{ marginBottom: 16 }}
        text={editMode ? 'Save Changes' : 'Set Limits'}
        icon={faEdit}
        functionCall={() => {
          if (editMode) {
            handleSaveLimits()
          } else {
            setTempLimits(limits?.macros || tempLimits)
            setEditMode(true)
          }
        }}
      />
      {editMode ? (
        <div className='limits-content-edit'>{Object.keys(macroConfig).map(renderLimitSlider)}</div>
      ) : (
        <div>{Object.keys(macroConfig).map(renderMacroSection)}</div>
      )}
      <SaveCheckmark show={showCheckmark} onAnimationEnd={() => setShowCheckmark(false)} />
    </div>
  )
}

export default MobileMacroOverview
