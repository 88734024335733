import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React from 'react'

const DictaphoneControls = ({
  buttonStyle,
  listening = false,
  startListening = () => {},
  stopListening = () => {},
  disabled
}) => {
  return (
    <>
      {!listening ? (
        <Button style={buttonStyle} disabled={listening || disabled} onClick={startListening}>
          Dictate <FontAwesomeIcon icon={faMicrophone} />
        </Button>
      ) : (
        <Button style={buttonStyle} disabled={!listening || disabled} danger onClick={stopListening}>
          Dictate <FontAwesomeIcon icon={faMicrophoneSlash} />
        </Button>
      )}
    </>
  )
}

export default DictaphoneControls
