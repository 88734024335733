import React, { useMemo } from 'react'
import { List, Card, Button, Badge, Space, Typography, Collapse, Tag, Col } from 'antd'
import {
  ExperimentOutlined,
  HeartOutlined,
  ExceptionOutlined,
  ThunderboltOutlined,
  CoffeeOutlined,
  FireOutlined,
  BugOutlined,
  MedicineBoxOutlined,
  NodeIndexOutlined,
  ClockCircleOutlined
} from '@ant-design/icons'
import CustomRow from '../../../../reusable-components/CustomRow'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'

const { Text, Title } = Typography
const { Panel } = Collapse

const categoryConfig = {
  tumorMarkers: {
    displayName: 'Tumor Markers',
    icon: <ExperimentOutlined style={{ fontSize: '24px', color: '#ff4757' }} />
  },
  cardiacMarkers: {
    displayName: 'Cardiac Markers',
    icon: <HeartOutlined style={{ fontSize: '24px', color: '#ff6b81' }} />
  },
  diabeticAndRenalInjury: {
    displayName: 'Diabetic & Renal Injury',
    icon: <ExceptionOutlined style={{ fontSize: '24px', color: '#7bed9f' }} />
  },
  thyroidFunction: {
    displayName: 'Thyroid Function',
    icon: <ThunderboltOutlined style={{ fontSize: '24px', color: '#70a1ff' }} />
  },
  gastricFunctionMarkers: {
    displayName: 'Gastric Function Markers',
    icon: <CoffeeOutlined style={{ fontSize: '24px', color: '#ffa502' }} />
  },
  inflammationMarkers: {
    displayName: 'Inflammation Markers',
    icon: <FireOutlined style={{ fontSize: '24px', color: '#ff6348' }} />
  },
  infectiousDisease: {
    displayName: 'Infectious Disease',
    icon: <BugOutlined style={{ fontSize: '24px', color: '#ff4757' }} />
  },
  drugTests: {
    displayName: 'Drug Tests',
    icon: <MedicineBoxOutlined style={{ fontSize: '24px', color: '#2ed573' }} />
  },
  hormoneTests: {
    displayName: 'Hormone Tests',
    icon: <NodeIndexOutlined style={{ fontSize: '24px', color: '#5352ed' }} />
  }
}

const MobPOCTestsList = ({ tests, setFacet }) => {
  const categorizedTests = useMemo(() => {
    // Create an object with all possible categories initialized with empty arrays
    const initialCategories = Object.keys(categoryConfig).reduce((acc, category) => {
      acc[category] = []
      return acc
    }, {})

    // Group tests into their respective categories
    const grouped = tests.reduce((acc, test) => {
      const category = test.category || 'other'
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(test)
      return acc
    }, initialCategories)

    // Convert to array format with all categories included
    return Object.entries(grouped).map(([categoryKey, categoryTests]) => ({
      categoryKey,
      displayName: categoryConfig[categoryKey]?.displayName || 'Other',
      tests: categoryTests,
      count: categoryTests.length,
      icon: categoryConfig[categoryKey]?.icon
    }))
  }, [tests])

  const renderTestCard = (test) => (
    <Card key={test._id} size='small' style={{ marginBottom: 8 }}>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Title level={5} style={{ margin: 0 }}>
            {test.name}
          </Title>
          <Tag color='green'>R {test.price.toFixed(2)}</Tag>
        </Space>

        <Text type='secondary'>{test.description}</Text>

        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Space>
            <ClockCircleOutlined />
            <Text type='secondary'>{test.processingTime} minutes</Text>
          </Space>
          <Button type='primary' size='small'>
            Book Now
          </Button>
        </Space>
      </Space>
    </Card>
  )

  return (
    <>
      <CustomRow style={{ marginBottom: 75 }}>
        {categorizedTests.map((category) => (
          <Col span={24}>
            <Card style={{ width: '100%' }} bodyStyle={{ padding: '12px' }}>
              <Space direction='vertical' style={{ width: '100%' }}>
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Space>
                    {category.icon}
                    <div>
                      <Title level={5} style={{ margin: 0 }}>
                        {category.displayName}
                      </Title>
                      <Badge count={category.count} style={{ backgroundColor: '#52c41a' }} />
                      <Text type='secondary'> tests available</Text>
                    </div>
                  </Space>
                </Space>

                <Collapse ghost style={{ width: '100%' }}>
                  <Panel header='View Tests' key='1'>
                    {category.tests.map((test) => renderTestCard(test))}
                  </Panel>
                </Collapse>
              </Space>
            </Card>
          </Col>
        ))}
      </CustomRow>
      <PrevNextFloatingButtons
        prevOnly
        prevText='Back'
        onPrev={() => {
          setFacet(null)
        }}
      />
    </>
  )
}

export default MobPOCTestsList
