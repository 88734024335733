/**
 * CareSync Diagnostic Tests Data Structure
 * 
 * This file contains the mock data structure for all diagnostic tests offered through CareSync.
 * 
 * Data Usage:
 * - TestPromo.js: Uses subset of tests marked as 'featured: true' for carousel display
 * - TestMenu/index.js: Uses complete test list for searchable catalog
 * - CareSyncAdmin: Uses this structure for test configuration and promotion management
 * 
 * Backend Requirements:
 * 1. Tests must be categorized according to predefined categories
 * 2. Each test needs unique identifier (code)
 * 3. Prices should be stored as integers (cents) to avoid floating-point issues
 * 4. Search should be optimized for multiple fields (name, description, category)
 * 5. Featured tests should be configurable per facility/provider
 * 
 * API Endpoints Needed:
 * GET /api/tests - Full test catalog
 * GET /api/tests/featured - Featured tests for carousel
 * GET /api/tests/categories - List of test categories
 * GET /api/tests/search - Search endpoint with filters
 * PATCH /api/tests/{code}/featured - Toggle featured status
 * 
 * @typedef {Object} DiagnosticTest
 * @property {string} code - Unique test identifier (e.g., "HBA1C-W23F")
 * @property {string} name - Test name
 * @property {string} category - Test category
 * @property {string} shortDescription - Brief description for carousel
 * @property {string} longDescription - Detailed description for test menu
 * @property {Array<string>} keyBenefits - Bullet points of test benefits
 * @property {number} price - Price in cents
 * @property {string} cycleTime - Test duration
 * @property {string} specimen - Required specimen type
 * @property {boolean} featured - Whether test appears in carousel
 * @property {string} testType - "CareSync" or "Lab"
 * @property {boolean} requiresFasting - Whether test requires fasting
 * @property {string} fastingTime - Required fasting duration if applicable
 * @property {string} certification - CE certification if applicable
 * @property {string} range - Normal range for test
 * @property {boolean} available - Current availability status
 * @property {Object} preparationRequirements - Object containing fasting/preparation requirements
 * @typedef {Object} ReferenceRange
 * @property {number} low - Lower limit of normal range
 * @property {number} high - Upper limit of normal range
 * @property {string} unit - Unit of measurement (SI units)
 * @property {string} interpretation - Description of what the ranges mean
 */

import { 
  faHeartPulse,
  faDna,
  faFlask,
  faBowlFood,
  faVial,
  faVirus,
  faCapsules,
  faWaveSquare,
  faVenusMars,
  faFireFlameCurved,
  faGlassWater,
  faPersonRunning
} from '@fortawesome/free-solid-svg-icons'

export const diagnosticTestCategories = [
  {
    id: 'tumor-markers',
    name: 'Tumor Markers',
    icon: faDna,
    color: '#FF6B6B',
    description: 'Early detection and monitoring tests for various types of cancer and tumors',
    tests: [
      {
        code: 'AFP-W23F',
        name: 'Alpha Fetal Protein',
        category: 'tumor-markers',
        shortDescription: 'Liver cancer and fetal development marker',
        longDescription: 'AFP test helps detect liver abnormalities and certain cancers. Also used during pregnancy to screen for developmental problems.',
        keyBenefits: [
          'Early liver cancer detection',
          'Monitor liver disease progression',
          'Pregnancy health monitoring'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: true,
        testType: 'CareSync',
        requiresFasting: true,
        certification: 'CE',
        range: '2.5-400ng/mL',
        referenceRanges: {
          low: 0,
          high: 7,
          unit: 'ng/mL',
          interpretation: 'Adults: 0-7 ng/mL. Pregnancy levels vary: 1st trimester: 10-50, 2nd: 30-100, 3rd: 30-150 ng/mL'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'Morning test preferred. No food or drinks (except water) for 8-12 hours.'
        }
      },
      {
        code: 'PSA-W23F',
        name: 'Prostate-specific Antigen',
        category: 'tumor-markers',
        shortDescription: 'Prostate cancer screening marker',
        longDescription: 'PSA test measures levels of prostate-specific antigen to screen for and monitor prostate cancer.',
        keyBenefits: [
          'Prostate cancer screening',
          'Monitor treatment response',
          'Post-treatment monitoring'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: true,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '2-100ng/mL',
        referenceRanges: {
          low: 0,
          high: 4,
          unit: 'ng/mL',
          interpretation: 'Age <50: <2.5, 50-59: <3.5, 60-69: <4.5, 70+: <6.5 ng/mL. Higher values may indicate prostate conditions.'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No special preparation required. Avoid ejaculation for 48 hours before test.'
        }
      },
      {
        code: 'CEA-W23F',
        name: 'Carcino-embryonic Antigen',
        category: 'tumor-markers',
        shortDescription: 'Colorectal cancer marker',
        longDescription: 'CEA test monitors treatment of colon cancer and checks for recurrence. May be elevated in other cancers and conditions.',
        keyBenefits: [
          'Monitor colorectal cancer',
          'Track treatment response',
          'Detect recurrence'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: true,
        testType: 'CareSync',
        requiresFasting: true,
        certification: 'CE',
        range: '1-500ng/mL',
        referenceRanges: {
          low: 0,
          high: 3,
          unit: 'ng/mL',
          interpretation: 'Non-smokers: <3.0 ng/mL, Smokers: <5.0 ng/mL. Higher values may indicate cancer or other conditions.'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'Morning test preferred. No food or drinks (except water) for 8-12 hours.'
        }
      },
      {
        code: 'FRT-W23F',
        name: 'Ferritin',
        category: 'tumor-markers',
        shortDescription: 'Iron storage marker',
        longDescription: 'Ferritin test measures iron storage levels in the body. Important for anemia diagnosis and monitoring iron status.',
        keyBenefits: [
          'Assess iron status',
          'Diagnose anemia',
          'Monitor iron therapy'
        ],
        price: 54999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        certification: 'CE',
        range: '10-1000ng/mL',
        referenceRanges: {
          male: {
            low: 30,
            high: 300,
            unit: 'ng/mL',
            interpretation: 'Males: 30-300 ng/mL. Lower values suggest iron deficiency.'
          },
          female: {
            low: 15,
            high: 150,
            unit: 'ng/mL',
            interpretation: 'Females: 15-150 ng/mL. Lower values suggest iron deficiency.'
          }
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'Morning test preferred. No food or drinks (except water) for 8-12 hours.'
        }
      },
      {
        code: 'FOB-F23F',
        name: 'Fecal Occult Blood',
        category: 'tumor-markers',
        shortDescription: 'Colorectal bleeding detection',
        longDescription: 'Detects hidden blood in stool, which may indicate colorectal cancer or other GI conditions.',
        keyBenefits: [
          'Colorectal cancer screening',
          'Detect GI bleeding',
          'Monitor GI conditions'
        ],
        price: 39999,
        cycleTime: '15 min',
        specimen: 'F',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '25-1000ng/mL',
        referenceRanges: {
          qualitative: true,
          cutoff: 50,
          unit: 'ng/mL',
          interpretation: 'Positive if >50 ng/mL. Indicates presence of blood in stool.'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'Avoid red meat, vitamin C supplements, and certain medications for 3 days before test. Follow collection instructions carefully.'
        }
      }
    ]
  },
  {
    id: 'cardiac-markers',
    name: 'Cardiac Markers',
    icon: faHeartPulse,
    color: '#EF8D9C',
    description: 'Tests for heart health assessment and cardiac event detection',
    tests: [
      {
        code: 'NTBNP-W23F',
        name: 'NT-ProBNP',
        category: 'cardiac-markers',
        shortDescription: 'Heart failure and cardiac stress marker',
        longDescription: 'NT-ProBNP helps diagnose heart failure and assess the risk of cardiac events. Elevated levels indicate increased cardiac stress.',
        keyBenefits: [
          'Early detection of heart failure',
          'Monitor cardiac treatment effectiveness',
          'Risk assessment for heart disease'
        ],
        price: 89999,
        cycleTime: '15min',
        specimen: 'W/S/P',
        featured: true,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - emergency test',
        certification: 'CE',
        range: '20-30000PG/mL',
        referenceRanges: {
          low: 0,
          high: 300,
          unit: 'pg/mL',
          interpretation: 'Age <75: <300 pg/mL normal, >450 pg/mL suggests heart failure. Age >75: <450 pg/mL normal, >900 pg/mL suggests heart failure'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - emergency test. Test can be performed at any time.'
        }
      },
      {
        code: 'TRO-W23F',
        name: 'Cardiac Troponin I',
        category: 'cardiac-markers',
        shortDescription: 'Specific marker for heart muscle damage',
        longDescription: 'Troponin I is highly specific for cardiac injury. Elevated levels indicate heart muscle damage, commonly seen in heart attacks.',
        keyBenefits: [
          'Detect heart attacks',
          'Monitor heart damage',
          'Guide emergency treatment'
        ],
        price: 79999,
        cycleTime: '15min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '0.1-40ng/mL',
        referenceRanges: {
          low: 0,
          high: 0.04,
          unit: 'ng/mL',
          interpretation: 'Values above 0.04 ng/mL may indicate heart muscle damage'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - emergency test. Test can be performed at any time.'
        }
      },
      {
        code: 'MYO-W23F',
        name: 'Myoglobin',
        category: 'cardiac-markers',
        shortDescription: 'Early marker for muscle damage',
        longDescription: 'Myoglobin rises rapidly after muscle injury, including heart muscle damage. Used in early detection of heart attacks.',
        keyBenefits: [
          'Early heart attack detection',
          'Muscle damage assessment',
          'Rapid results for emergency care'
        ],
        price: 69999,
        cycleTime: '15min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '5-500ng/mL',
        referenceRanges: {
          low: 0,
          high: 500,
          unit: 'ng/mL',
          interpretation: 'Normal adult range: 5-500 ng/mL'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - emergency test. Test can be performed at any time.'
        }
      },
      {
        code: 'CKMB-W23F',
        name: 'Creative Kinase MB',
        category: 'cardiac-markers',
        shortDescription: 'Specific marker for heart muscle stress',
        longDescription: 'CK-MB is released when heart muscle cells are damaged. Helps diagnose heart attacks and monitor recovery.',
        keyBenefits: [
          'Heart attack diagnosis',
          'Monitor heart recovery',
          'Assess treatment effectiveness'
        ],
        price: 74999,
        cycleTime: '15min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '2-100ng/mL',
        referenceRanges: {
          low: 0,
          high: 3.6,
          unit: 'ng/mL',
          interpretation: 'Values >3.6 ng/mL may indicate heart muscle damage. Levels peak 18-24 hours after heart attack'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - emergency test. Test can be performed at any time.'
        }
      },
      {
        code: 'CMC-W23F',
        name: 'Cardiac Troponin I/Myoglobin/Creative Kinase MB',
        category: 'cardiac-markers',
        shortDescription: 'Comprehensive cardiac health assessment',
        longDescription: 'Combined test for Troponin I, Myoglobin, and CK-MB. Provides complete picture of cardiac health and injury.',
        keyBenefits: [
          'Complete cardiac assessment',
          'Multiple marker monitoring',
          'Comprehensive diagnosis'
        ],
        price: 149999,
        cycleTime: '15min',
        specimen: 'W/S/P',
        featured: true,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: 'cTnI 0.05-40ng/mL\nMyo 10-800ng/mL\nCK-MB 0.5-150ng/mL',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - emergency test. Test can be performed at any time.'
        }
      },
      {
        code: 'DDM-W2F',
        name: 'D-Dimer',
        category: 'cardiac-markers',
        shortDescription: 'Blood clot marker',
        longDescription: 'D-Dimer test helps diagnose blood clotting disorders and conditions like deep vein thrombosis or pulmonary embolism.',
        keyBenefits: [
          'Detect blood clots',
          'Screen for clotting disorders',
          'Monitor anticoagulation therapy'
        ],
        price: 89999,
        cycleTime: '10min',
        specimen: 'W/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '4 hours 13 mins',
        certification: 'CE',
        range: '0.1-10ug/mL',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - emergency test. Test can be performed at any time.'
        }
      },
      {
        code: 'cTnT-W23F',
        name: 'cTnT',
        category: 'cardiac-markers',
        shortDescription: 'Cardiac-specific troponin marker',
        longDescription: 'Cardiac Troponin T is highly specific for heart muscle damage. Used in diagnosis and monitoring of heart attacks.',
        keyBenefits: [
          'Specific cardiac damage detection',
          'Monitor heart attack progression',
          'Guide treatment decisions'
        ],
        price: 79999,
        cycleTime: '15min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '0.03-10ng/mL',
        referenceRanges: {
          low: 0,
          high: 0.03,
          unit: 'ng/mL',
          interpretation: 'Normal adult range: 0.03-10 ng/mL'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - emergency test. Test can be performed at any time.'
        }
      }
    ]
  },
  {
    id: 'diabetic-renal',
    name: 'Diabetic & Renal Injury',
    icon: faFlask,
    color: '#B5D777',
    description: 'Tests for diabetes management and kidney function monitoring',
    tests: [
      {
        code: 'HBA1C-W23F',
        name: 'HbA1c',
        category: 'diabetic-renal',
        shortDescription: 'Long-term blood glucose control marker',
        longDescription: 'HbA1c provides information about average blood sugar levels over the past 2-3 months. Essential for diabetes diagnosis and monitoring.',
        keyBenefits: [
          'Diabetes diagnosis and monitoring',
          'Long-term glucose control assessment',
          'Treatment effectiveness evaluation'
        ],
        price: 59999,
        cycleTime: 'Lyses 1m & React 5 min',
        specimen: 'W',
        featured: true,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - measures long-term glucose',
        certification: 'CE',
        range: '4-15%',
        referenceRanges: {
          low: 4,
          high: 15,
          unit: '%',
          interpretation: 'Normal range: 4-15%'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: true,
          duration: '8-12',
          additionalNotes: 'No food, sugary drinks, caffeine, or strenuous exercise for 8-12 hours. Water allowed. Morning test preferred.'
        }
      },
      {
        code: 'ALB-U23F',
        name: 'Albumin',
        category: 'diabetic-renal',
        shortDescription: 'Kidney function and protein loss marker',
        longDescription: 'Albumin in urine indicates kidney damage. Important for early detection of diabetic kidney disease.',
        keyBenefits: [
          'Early kidney damage detection',
          'Diabetes complication monitoring',
          'Protein loss assessment'
        ],
        price: 49999,
        cycleTime: '5 min',
        specimen: 'Urine',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours',
        certification: 'CE',
        range: '5-300μg/mL',
        referenceRanges: {
          low: 5,
          high: 300,
          unit: 'μg/mL',
          interpretation: 'Normal range: 5-300 μg/mL'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: false,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'No food for 8-12 hours. Stay well hydrated - drink plenty of water. Morning test preferred.'
        }
      },
      {
        code: 'CysC-W23F',
        name: 'Cystatin C',
        category: 'diabetic-renal',
        shortDescription: 'Early kidney function marker',
        longDescription: 'Cystatin C is an early and sensitive marker of kidney function changes, often detecting problems before other tests.',
        keyBenefits: [
          'Early kidney disease detection',
          'Accurate kidney function assessment',
          'Treatment monitoring'
        ],
        price: 69999,
        cycleTime: '5 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '4 hours 13 mins',
        certification: 'CE',
        range: '0.2-10μg/mL',
        referenceRanges: {
          low: 0.2,
          high: 10,
          unit: 'μg/mL',
          interpretation: 'Normal range: 0.2-10 μg/mL'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: false,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'No food for 8-12 hours. Stay well hydrated - drink plenty of water. Morning test preferred.'
        }
      },
      {
        code: 'B2-MG-W23F',
        name: 'β2-microglobulin',
        category: 'diabetic-renal',
        shortDescription: 'Kidney filtration marker',
        longDescription: 'β2-microglobulin helps assess kidney filtration function and can indicate kidney disease progression.',
        keyBenefits: [
          'Kidney disease monitoring',
          'Treatment response assessment',
          'Disease progression tracking'
        ],
        price: 79999,
        cycleTime: '10 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '0.3-20μg/mL',
        referenceRanges: {
          low: 0.3,
          high: 20,
          unit: 'μg/mL',
          interpretation: 'Normal adult range: 0.3-20 μg/mL'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: false,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'No food for 8-12 hours. Stay well hydrated - drink plenty of water. Morning test preferred.'
        }
      }
    ]
  },
  {
    id: 'thyroid-function',
    name: 'Thyroid Function',
    icon: faFlask,
    color: '#7FDBDA',
    description: 'Tests for assessing thyroid gland function and related disorders',
    tests: [
      {
        code: 'TT3-W23F',
        name: 'TT3',
        category: 'thyroid-function',
        shortDescription: 'Total T3 thyroid hormone test',
        longDescription: 'Measures total triiodothyronine (T3) levels. Important for assessing thyroid function and metabolism.',
        keyBenefits: [
          'Assess thyroid function',
          'Monitor thyroid treatment',
          'Diagnose thyroid disorders'
        ],
        price: 59999,
        cycleTime: 'Lyses 1min, & React 10min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '0.61-9.23nmol/L',
        referenceRanges: {
          low: 0.61,
          high: 9.23,
          unit: 'nmol/L',
          interpretation: 'Normal range: 0.61-9.23 nmol/L'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'Morning test preferred. Take thyroid medications only after blood draw. No food or drinks (except water) for 8-12 hours.'
        }
      },
      {
        code: 'TT4-W23F',
        name: 'TT4',
        category: 'thyroid-function',
        shortDescription: 'Total T4 thyroid hormone test',
        longDescription: 'Measures total thyroxine (T4) levels. Used to evaluate thyroid function and monitor treatment.',
        keyBenefits: [
          'Evaluate thyroid status',
          'Monitor medication effects',
          'Screen for thyroid disorders'
        ],
        price: 59999,
        cycleTime: 'Lyses 1min, & React 10min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '10-310nmol/L',
        referenceRanges: {
          low: 10,
          high: 310,
          unit: 'nmol/L',
          interpretation: 'Normal range: 10-310 nmol/L'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'Morning test preferred. Take thyroid medications only after blood draw. No food or drinks (except water) for 8-12 hours.'
        }
      },
      {
        code: 'TSH-W23F',
        name: 'TSH',
        category: 'thyroid-function',
        shortDescription: 'Thyroid stimulating hormone test',
        longDescription: 'Measures TSH levels to assess thyroid function. Primary screening test for thyroid disorders.',
        keyBenefits: [
          'Primary thyroid screening',
          'Monitor thyroid treatment',
          'Diagnose thyroid disorders'
        ],
        price: 69999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: true,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours (water allowed, take medications as prescribed)',
        certification: 'CE',
        range: '0.1-100mIU/L',
        referenceRanges: {
          low: 0.4,
          high: 4.0,
          unit: 'mIU/L',
          interpretation: 'Values <0.4 suggest hyperthyroidism, >4.0 suggest hypothyroidism'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'Morning test preferred. Take thyroid medications only after blood draw. No food or drinks (except water) for 8-12 hours.'
        }
      },
      {
        code: 'FT3-W23F',
        name: 'FT3',
        category: 'thyroid-function',
        shortDescription: 'Free T3 thyroid hormone test',
        longDescription: 'Measures free (unbound) T3 levels. More accurate indicator of thyroid hormone availability.',
        keyBenefits: [
          'Accurate thyroid assessment',
          'Monitor treatment response',
          'Evaluate thyroid function'
        ],
        price: 64999,
        cycleTime: 'Lyses 5min, & React 15min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours',
        certification: 'CE',
        range: '0.4-50pmol/L',
        referenceRanges: {
          low: 3.1,
          high: 6.8,
          unit: 'pmol/L',
          interpretation: 'Normal range: 3.1-6.8 pmol/L. Best indicator of T3 status'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'Morning test preferred. Take thyroid medications only after blood draw. No food or drinks (except water) for 8-12 hours.'
        }
      },
      {
        code: 'FT4-W23F',
        name: 'FT4',
        category: 'thyroid-function',
        shortDescription: 'Free T4 thyroid hormone test',
        longDescription: 'Measures free (unbound) T4 levels. Important for assessing true thyroid hormone status.',
        keyBenefits: [
          'True hormone status',
          'Treatment monitoring',
          'Thyroid function assessment'
        ],
        price: 64999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours',
        certification: 'CE',
        range: '12-22pmol/L',
        referenceRanges: {
          low: 12,
          high: 22,
          unit: 'pmol/L',
          interpretation: 'Normal range: 12-22 pmol/L. Key indicator of thyroid function'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'Morning test preferred. Take thyroid medications only after blood draw. No food or drinks (except water) for 8-12 hours.'
        }
      },
      {
        code: 'A-TPO-W23F',
        name: 'Anti-Thyroid peroxidase',
        category: 'thyroid-function',
        shortDescription: 'Thyroid antibody test',
        longDescription: 'Detects antibodies against thyroid peroxidase. Used to diagnose autoimmune thyroid disorders.',
        keyBenefits: [
          'Autoimmune diagnosis',
          'Risk assessment',
          'Treatment guidance'
        ],
        price: 74999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: '/',
        range: '10-600IU/mL',
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'Morning test preferred. Take thyroid medications only after blood draw. No food or drinks (except water) for 8-12 hours.'
        }
      },
      {
        code: 'TG-W23F',
        name: 'Thyroglobulin',
        category: 'thyroid-function',
        shortDescription: 'Thyroid cancer monitoring marker',
        longDescription: 'Measures thyroglobulin levels. Used to monitor thyroid cancer treatment and recurrence.',
        keyBenefits: [
          'Cancer monitoring',
          'Treatment response',
          'Recurrence detection'
        ],
        price: 79999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: '/',
        range: '5-500ng/mL',
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'Morning test preferred. Take thyroid medications only after blood draw. No food or drinks (except water) for 8-12 hours.'
        }
      }
    ]
  },
  {
    id: 'gastric-function',
    name: 'Gastric Function Markers',
    icon: faBowlFood,
    color: '#FFB347',
    description: 'Tests for digestive health and gastric function assessment',
    tests: [
      {
        code: 'PG I-W23F',
        name: 'Propepsin I',
        category: 'gastric-function',
        shortDescription: 'Gastric function marker',
        longDescription: 'Propepsin I is a precursor of pepsin and indicates gastric function. Used to assess gastric health.',
        keyBenefits: [
          'Assess gastric function',
          'Monitor gastric health',
          'Guide treatment decisions'
        ],
        price: 69999,
        cycleTime: '15min',
        specimen: 'W/S/P',
        featured: true,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours (no food, drinks, or medications)',
        certification: 'CE',
        range: '2-300ng/mL',
        referenceRanges: {
          low: 30,
          high: 160,
          unit: 'ng/mL',
          interpretation: 'Normal: 30-160 ng/mL. Low values may indicate atrophic gastritis'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'No food, drinks, or medications for 8-12 hours. Morning test preferred. Avoid antacids for 24 hours before test.'
        }
      },
      {
        code: 'PG II-W23F',
        name: 'Propepsin II',
        category: 'gastric-function',
        shortDescription: 'Complementary gastric marker',
        longDescription: 'Propepsin II works together with Propepsin I to evaluate gastric health. The ratio between them provides important diagnostic information.',
        keyBenefits: [
          'Complete gastric assessment',
          'Disease monitoring',
          'Treatment guidance'
        ],
        price: 69999,
        cycleTime: '15min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours (no food, drinks, or medications)',
        certification: 'CE',
        range: '1-100ng/mL',
        referenceRanges: {
          low: 3,
          high: 15,
          unit: 'ng/mL',
          interpretation: 'Normal: 3-15 ng/mL. PG I/II ratio <3 suggests atrophic gastritis'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'No food, drinks, or medications for 8-12 hours. Morning test preferred. Avoid antacids for 24 hours before test.'
        }
      }
    ]
  },
  {
    id: 'inflammation-markers',
    name: 'Inflammation Markers',
    icon: faFireFlameCurved,
    color: '#FF9F7A',
    description: 'Tests for detecting and monitoring inflammatory conditions',
    tests: [
      {
        code: 'CRP-W23F',
        name: 'C-Reactive Protein',
        category: 'inflammation-markers',
        shortDescription: 'General inflammation marker',
        longDescription: 'CRP levels rise in response to inflammation. Used to detect infections and monitor inflammatory conditions.',
        keyBenefits: [
          'Monitor inflammation',
          'Track infection progress',
          'Assess treatment response'
        ],
        price: 49999,
        cycleTime: '10 min',
        specimen: 'W/S/P',
        featured: true,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours',
        certification: 'CE',
        range: '0-10mg/L',
        referenceRanges: {
          low: 0,
          high: 5,
          unit: 'mg/L',
          interpretation: '<1 mg/L: Low risk, 1-3 mg/L: Moderate risk, >3 mg/L: High risk of cardiovascular disease'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: true,
          duration: '8-12',
          additionalNotes: 'Avoid food, drinks (except water), and strenuous exercise for 8-12 hours. Avoid anti-inflammatory medications if possible.'
        }
      },
      {
        code: 'PCT-W23F',
        name: 'Procalcitonin',
        category: 'inflammation-markers',
        shortDescription: 'Bacterial infection marker',
        longDescription: 'Procalcitonin helps distinguish bacterial from viral infections. Guides antibiotic therapy decisions.',
        keyBenefits: [
          'Guide antibiotic use',
          'Monitor sepsis',
          'Differentiate infection types'
        ],
        price: 89999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours',
        certification: 'CE',
        range: '0-10ng/mL',
        referenceRanges: {
          low: 0,
          high: 0.5,
          unit: 'ng/mL',
          interpretation: '<0.5 ng/mL: Local infection likely, >2 ng/mL: Systemic infection likely'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: true,
          duration: '8-12',
          additionalNotes: 'Avoid food, drinks (except water), and strenuous exercise for 8-12 hours. Avoid anti-inflammatory medications if possible.'
        }
      },
      {
        code: 'IL6-W23F',
        name: 'Interleukin-6',
        category: 'inflammation-markers',
        shortDescription: 'Early inflammatory response marker',
        longDescription: 'IL-6 is an early marker of inflammation and infection. Important in monitoring severe infections and inflammatory conditions.',
        keyBenefits: [
          'Early infection detection',
          'Monitor disease severity',
          'Guide treatment timing'
        ],
        price: 79999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours',
        certification: 'CE',
        range: '0-7pg/mL',
        referenceRanges: {
          low: 0,
          high: 5.9,
          unit: 'pg/mL',
          interpretation: 'Normal: <5.9 pg/mL. Higher values suggest inflammation or infection'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: true,
          duration: '8-12',
          additionalNotes: 'Avoid food, drinks (except water), and strenuous exercise for 8-12 hours. Avoid anti-inflammatory medications if possible.'
        }
      },
      {
        code: 'SAA-W23F',
        name: 'Serum Amyloid A',
        category: 'inflammation-markers',
        shortDescription: 'Acute phase inflammation marker',
        longDescription: 'SAA is a sensitive marker of acute inflammation. Helps monitor inflammatory conditions and infection severity.',
        keyBenefits: [
          'Monitor acute inflammation',
          'Track disease progression',
          'Assess treatment effectiveness'
        ],
        price: 69999,
        cycleTime: '10 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours',
        certification: 'CE',
        range: '0-6.4mg/L',
        referenceRanges: {
          low: 0,
          high: 6.4,
          unit: 'mg/L',
          interpretation: 'Normal: <6.4 mg/L. Higher values indicate acute inflammation'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: true,
          duration: '8-12',
          additionalNotes: 'Avoid food, drinks (except water), and strenuous exercise for 8-12 hours. Avoid anti-inflammatory medications if possible.'
        }
      }
    ]
  },
  {
    id: 'infectious-disease',
    name: 'Infectious Disease',
    icon: faVirus,
    color: '#FF7676',
    description: 'Tests for detecting various infectious diseases and pathogens',
    tests: [
      {
        code: 'COV-S23F',
        name: 'COVID-19 Ag',
        category: 'infectious-disease',
        shortDescription: 'Rapid COVID-19 antigen detection',
        longDescription: 'Direct detection of SARS-CoV-2 viral proteins in respiratory specimens. Helps identify active COVID-19 infections.',
        keyBenefits: [
          'Rapid results in 15 minutes',
          'Direct virus detection',
          'Guide isolation decisions'
        ],
        price: 39999,
        cycleTime: '15 min',
        specimen: 'NP/OP',
        featured: true,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - respiratory test',
        certification: 'CE',
        range: 'Positive/Negative',
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in respiratory specimen. Detection window: up to 90 days'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - respiratory test. Avoid nasal sprays or decongestants for 4 hours before test if possible. Do not eat, drink, smoke, or chew gum for 30 minutes before test.'
        }
      },
      {
        code: 'COV-W23F',
        name: 'COVID-19 Nab',
        category: 'infectious-disease',
        shortDescription: 'COVID-19 neutralizing antibodies',
        longDescription: 'Measures neutralizing antibodies against SARS-CoV-2. Helps assess immune response to infection or vaccination.',
        keyBenefits: [
          'Assess immune protection',
          'Monitor vaccine response',
          'Guide booster decisions'
        ],
        price: 49999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'No food or drinks (except water) for 8-12 hours. Take regular medications with small sips of water. Morning test preferred.'
        }
      },
      {
        code: 'RSV-S23F',
        name: 'RSV',
        category: 'infectious-disease',
        shortDescription: 'Respiratory syncytial virus detection',
        longDescription: 'Detects RSV antigens in respiratory specimens. Important for diagnosing respiratory infections, especially in children.',
        keyBenefits: [
          'Rapid RSV detection',
          'Guide isolation measures',
          'Appropriate treatment selection'
        ],
        price: 44999,
        cycleTime: '15 min',
        specimen: 'Nasal Secretion',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - respiratory test',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - respiratory test. Avoid nasal sprays or decongestants for 4 hours before test if possible. Do not eat, drink, smoke, or chew gum for 30 minutes before test.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates presence of RSV. Negative suggests no active RSV infection.'
        }
      },
      {
        code: 'STA-S23F',
        name: 'Strep A',
        category: 'infectious-disease',
        shortDescription: 'Strep throat infection detection',
        longDescription: 'Rapid test for detecting Group A Streptococcus bacteria. Helps diagnose strep throat infections.',
        keyBenefits: [
          'Quick strep diagnosis',
          'Guide antibiotic use',
          'Prevent complications'
        ],
        price: 39999,
        cycleTime: '5 min',
        specimen: 'Throat swab',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - throat swab',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - throat swab. Do not eat, drink, use mouthwash, or brush teeth for 2 hours before test.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates presence of Group A Streptococcus. Negative suggests no strep infection.'
        }
      },
      {
        code: 'ASV-F23F',
        name: 'Astrovirus',
        category: 'infectious-disease',
        shortDescription: 'Gastroenteritis virus detection',
        longDescription: 'Detects Astrovirus in stool samples. Important for diagnosing viral gastroenteritis, especially in children and immunocompromised patients.',
        keyBenefits: [
          'Rapid virus detection',
          'Guide isolation measures',
          'Appropriate treatment planning'
        ],
        price: 44999,
        cycleTime: '15 min',
        specimen: 'Feces',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - stool sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - stool sample. Avoid antibiotics and antacids for 2 weeks before test if possible. Follow collection instructions carefully.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates presence of Astrovirus. Negative suggests no viral infection.'
        }
      },
      {
        code: 'ADV-F23F',
        name: 'Adenovirus',
        category: 'infectious-disease',
        shortDescription: 'Gastrointestinal adenovirus detection',
        longDescription: 'Detects Adenovirus in stool samples. Helps diagnose viral gastroenteritis and monitor infection spread.',
        keyBenefits: [
          'Quick virus identification',
          'Guide infection control',
          'Monitor outbreak situations'
        ],
        price: 44999,
        cycleTime: '15 min',
        specimen: 'Feces',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - stool sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - stool sample. Avoid antibiotics and antacids for 2 weeks before test if possible. Follow collection instructions carefully.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates presence of Adenovirus in stool. Negative suggests no GI adenovirus infection.'
        }
      },
      {
        code: 'ADV-S23F',
        name: 'Adenovirus',
        category: 'infectious-disease',
        shortDescription: 'Respiratory adenovirus detection',
        longDescription: 'Detects Adenovirus in respiratory specimens. Important for diagnosing respiratory infections and conjunctivitis.',
        keyBenefits: [
          'Rapid respiratory diagnosis',
          'Guide treatment decisions',
          'Infection control measures'
        ],
        price: 44999,
        cycleTime: '15 min',
        specimen: 'Nasal secretion',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - respiratory test. Avoid nasal sprays or decongestants for 4 hours before test if possible. Do not eat, drink, smoke, or chew gum for 30 minutes before test.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates presence of Adenovirus in respiratory tract. Negative suggests no respiratory adenovirus infection.'
        }
      },
      {
        code: 'FLU-S23F',
        name: 'Influenza A/B',
        category: 'infectious-disease',
        shortDescription: 'Rapid flu virus detection',
        longDescription: 'Detects and differentiates between Influenza A and B viruses. Essential for rapid flu diagnosis and treatment decisions.',
        keyBenefits: [
          'Quick flu diagnosis',
          'Distinguish flu types',
          'Guide antiviral treatment'
        ],
        price: 44999,
        cycleTime: '15 min',
        specimen: 'Nasal secretion',
        featured: true,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - respiratory test. Avoid nasal sprays or decongestants for 4 hours before test if possible. Do not eat, drink, smoke, or chew gum for 30 minutes before test.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates presence of Influenza A and/or B virus. Negative suggests no flu infection.'
        }
      },
      {
        code: 'HIV-W23F',
        name: 'HIV',
        category: 'infectious-disease',
        shortDescription: 'HIV infection screening',
        longDescription: 'Detects HIV antibodies and antigens. Important for early HIV diagnosis and monitoring.',
        keyBenefits: [
          'Early HIV detection',
          'Treatment monitoring',
          'Prevention guidance'
        ],
        price: 69999,
        cycleTime: '20 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours (water allowed, take medications as prescribed)',
        certification: '/',
        range: 'Positive/Negative',
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in blood. Detection window: up to 90 days'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'No food or drinks (except water) for 8-12 hours. Take regular medications with small sips of water. Morning test preferred.'
        }
      },
      {
        code: 'SYP-W23F',
        name: 'Syphilis',
        category: 'infectious-disease',
        shortDescription: 'Syphilis infection detection',
        longDescription: 'Detects antibodies against T. pallidum. Used for screening and monitoring syphilis infection.',
        keyBenefits: [
          'Early detection',
          'Treatment monitoring',
          'Prevention guidance'
        ],
        price: 49999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'No food or drinks (except water) for 8-12 hours. Take regular medications with small sips of water. Morning test preferred.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates current or past syphilis infection. Confirmatory testing required.'
        }
      },
      {
        code: 'HCV-W23F',
        name: 'HCV',
        category: 'infectious-disease',
        shortDescription: 'Hepatitis C virus screening',
        longDescription: 'Detects antibodies against Hepatitis C virus. Essential for early diagnosis and monitoring of HCV infection.',
        keyBenefits: [
          'Early HCV detection',
          'Treatment monitoring',
          'Prevention guidance'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours (water allowed, take medications as prescribed)',
        certification: '/',
        range: 'Positive/Negative',
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in blood. Detection window: up to 90 days'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'No food or drinks (except water) for 8-12 hours. Take regular medications with small sips of water. Morning test preferred.'
        }
      },
      {
        code: 'HBsAg-W23F',
        name: 'HBsAg',
        category: 'infectious-disease',
        shortDescription: 'Hepatitis B surface antigen',
        longDescription: 'Detects Hepatitis B surface antigen. Used to diagnose active HBV infection and monitor carrier status.',
        keyBenefits: [
          'Active HBV detection',
          'Monitor carrier status',
          'Guide treatment decisions'
        ],
        price: 54999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: true,
        fastingTime: '8-12 hours (water allowed, take medications as prescribed)',
        certification: '/',
        range: 'Positive/Negative',
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in blood. Detection window: up to 90 days'
        },
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'No food or drinks (except water) for 8-12 hours. Take regular medications with small sips of water. Morning test preferred.'
        }
      },
      {
        code: 'CDA-F23F',
        name: 'C. difficile Toxin A',
        category: 'infectious-disease',
        shortDescription: 'C. diff Toxin A detection',
        longDescription: 'Detects C. difficile Toxin A in stool samples. Important for diagnosing C. difficile infection.',
        keyBenefits: [
          'Rapid C. diff detection',
          'Guide isolation measures',
          'Treatment monitoring'
        ],
        price: 49999,
        cycleTime: '15 min',
        specimen: 'Feces',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - stool sample. Avoid antibiotics and antacids for 2 weeks before test if possible. Follow collection instructions carefully.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates presence of C. difficile Toxin A. Negative suggests no C. diff infection.'
        }
      },
      {
        code: 'CDB-F23F',
        name: 'C. difficile Toxin B',
        category: 'infectious-disease',
        shortDescription: 'C. diff Toxin B detection',
        longDescription: 'Detects C. difficile Toxin B in stool samples. More sensitive marker for C. difficile infection.',
        keyBenefits: [
          'Sensitive C. diff detection',
          'Infection confirmation',
          'Guide treatment'
        ],
        price: 49999,
        cycleTime: '15 min',
        specimen: 'Feces',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - stool sample. Avoid antibiotics and antacids for 2 weeks before test if possible. Follow collection instructions carefully.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates presence of C. difficile Toxin B. Negative suggests no C. diff infection.'
        }
      },
      {
        code: 'GDH-F23F',
        name: 'C. difficile GDH',
        category: 'infectious-disease',
        shortDescription: 'C. diff enzyme screening',
        longDescription: 'Detects Glutamate Dehydrogenase enzyme. Used as initial screening test for C. difficile presence.',
        keyBenefits: [
          'Rapid screening',
          'High sensitivity',
          'Cost-effective testing'
        ],
        price: 44999,
        cycleTime: '15 min',
        specimen: 'Feces',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - stool sample. Avoid antibiotics and antacids for 2 weeks before test if possible. Follow collection instructions carefully.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates presence of C. difficile. Toxin testing recommended if positive.'
        }
      },
      {
        code: 'HP-W23F',
        name: 'H.Pylori Ab',
        category: 'infectious-disease',
        shortDescription: 'H. pylori antibody detection',
        longDescription: 'Detects antibodies against H. pylori in blood. Used for diagnosis and monitoring of H. pylori infection.',
        keyBenefits: [
          'H. pylori screening',
          'Treatment monitoring',
          'Risk assessment'
        ],
        price: 49999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: true,
          noDrink: true,
          noActivity: false,
          duration: '8-12',
          additionalNotes: 'No food or drinks (except water) for 8-12 hours. Avoid antibiotics and antacids for 2 weeks before test if possible.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates current or past H. pylori infection. Negative suggests no infection.'
        }
      },
      {
        code: 'HP-F23F',
        name: 'H.Pylori Ag',
        category: 'infectious-disease',
        shortDescription: 'H. pylori antigen detection',
        longDescription: 'Detects H. pylori antigens in stool. Direct detection method for active infection.',
        keyBenefits: [
          'Active infection detection',
          'Treatment success monitoring',
          'Non-invasive testing'
        ],
        price: 49999,
        cycleTime: '15 min',
        specimen: 'Feces',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - stool sample. Avoid antibiotics and antacids for 2 weeks before test if possible. Follow collection instructions carefully.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates active H. pylori infection. Negative suggests no current infection.'
        }
      },
      {
        code: 'ROTA-F23F',
        name: 'Rotavirus',
        category: 'infectious-disease',
        shortDescription: 'Rotavirus detection in stool',
        longDescription: 'Detects Rotavirus antigens in stool samples. Important for diagnosing viral gastroenteritis, especially in children.',
        keyBenefits: [
          'Rapid virus detection',
          'Guide treatment approach',
          'Infection control measures'
        ],
        price: 44999,
        cycleTime: '15 min',
        specimen: 'Feces',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - stool sample. Avoid antibiotics and antacids for 2 weeks before test if possible. Follow collection instructions carefully.'
        },
        referenceRanges: {
          qualitative: true,
          interpretation: 'Positive indicates presence of Rotavirus. Negative suggests no rotavirus infection.'
        }
      }
    ]
  },
  {
    id: 'drug-tests',
    name: 'Drug Tests',
    icon: faCapsules,
    color: '#A78CE3',
    description: 'Tests for detecting various drugs and monitoring drug levels',
    tests: [
      {
        code: 'AMP-H23F',
        name: 'Amphetamine',
        category: 'drug-tests',
        shortDescription: 'Amphetamine detection test',
        longDescription: 'Detects amphetamine and its metabolites. Used for drug screening and monitoring.',
        keyBenefits: [
          'Rapid screening',
          'High sensitivity',
          'Treatment monitoring'
        ],
        price: 34999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: 'BAR-H23F',
        name: 'Barbiturate',
        category: 'drug-tests',
        shortDescription: 'Barbiturate detection',
        longDescription: 'Screens for barbiturate class drugs. Important for monitoring prescription drug use and abuse.',
        keyBenefits: [
          'Prescription monitoring',
          'Abuse detection',
          'Treatment compliance'
        ],
        price: 34999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 200,
          unit: 'pg/mg',
          interpretation: 'Positive if >200 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: 'BZO-H23F',
        name: 'Benzodiazepine',
        category: 'drug-tests',
        shortDescription: 'Benzodiazepine detection',
        longDescription: 'Detects benzodiazepine class medications. Used for monitoring prescription compliance and abuse.',
        keyBenefits: [
          'Medication monitoring',
          'Abuse screening',
          'Treatment assessment'
        ],
        price: 34999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 200,
          unit: 'pg/mg',
          interpretation: 'Positive if >200 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: 'COC-H23F',
        name: 'Cocaine',
        category: 'drug-tests',
        shortDescription: 'Cocaine metabolite detection',
        longDescription: 'Detects cocaine and its metabolites. Used for drug screening and monitoring.',
        keyBenefits: [
          'Drug screening',
          'Abuse detection',
          'Treatment monitoring'
        ],
        price: 34999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: 'FYL-H23F',
        name: 'Fentanyl',
        category: 'drug-tests',
        shortDescription: 'Fentanyl detection',
        longDescription: 'Detects fentanyl and its analogs. Critical for monitoring opioid use and abuse.',
        keyBenefits: [
          'Opioid monitoring',
          'Abuse prevention',
          'Treatment compliance'
        ],
        price: 39999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: 'KET-H23F',
        name: 'Ketamine',
        category: 'drug-tests',
        shortDescription: 'Ketamine detection',
        longDescription: 'Detects ketamine and its metabolites. Used for drug screening and abuse monitoring.',
        keyBenefits: [
          'Drug screening',
          'Abuse detection',
          'Treatment monitoring'
        ],
        price: 34999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: 'K2-H23F',
        name: 'K2 Synthetic Marijuana',
        category: 'drug-tests',
        shortDescription: 'Synthetic cannabinoid detection',
        longDescription: 'Detects synthetic marijuana compounds. Important for identifying use of these dangerous substances.',
        keyBenefits: [
          'Synthetic drug detection',
          'Abuse screening',
          'Risk assessment'
        ],
        price: 44999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: 'THC-H23F',
        name: 'Marijuana',
        category: 'drug-tests',
        shortDescription: 'Cannabis detection',
        longDescription: 'Detects THC metabolites. Used for drug screening and monitoring cannabis use.',
        keyBenefits: [
          'Drug screening',
          'Use monitoring',
          'Compliance checking'
        ],
        price: 29999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 100,
          unit: 'pg/mg',
          interpretation: 'Positive if >100 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: 'MDMA-H23F',
        name: 'MDMA',
        category: 'drug-tests',
        shortDescription: 'Ecstasy detection',
        longDescription: 'Detects MDMA (ecstasy) and related compounds. Used for drug screening and abuse monitoring.',
        keyBenefits: [
          'Drug screening',
          'Abuse detection',
          'Risk assessment'
        ],
        price: 39999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: 'MET-H23F',
        name: 'Methamphetamine',
        category: 'drug-tests',
        shortDescription: 'Methamphetamine detection',
        longDescription: 'Detects methamphetamine and its metabolites. Used for drug screening and abuse monitoring.',
        keyBenefits: [
          'Drug screening',
          'Abuse detection',
          'Treatment monitoring'
        ],
        price: 34999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: 'MOR-H23F',
        name: 'Morphine',
        category: 'drug-tests',
        shortDescription: 'Morphine detection',
        longDescription: 'Detects morphine and related opiates. Used for monitoring both medical use and potential abuse.',
        keyBenefits: [
          'Medical monitoring',
          'Abuse detection',
          'Treatment compliance'
        ],
        price: 34999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 200,
          unit: 'pg/mg',
          interpretation: 'Positive if >200 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: 'TML-H23F',
        name: 'Tramadol',
        category: 'drug-tests',
        shortDescription: 'Tramadol detection',
        longDescription: 'Detects tramadol and its metabolites. Used for monitoring prescription compliance and potential abuse.',
        keyBenefits: [
          'Prescription monitoring',
          'Abuse detection',
          'Treatment compliance'
        ],
        price: 34999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in hair. Detection window: up to 90 days'
        }
      },
      {
        code: '6-MAM-H23F',
        name: '6-Acetylmorphine',
        category: 'drug-tests',
        shortDescription: 'Heroin metabolite detection',
        longDescription: 'Detects 6-MAM, a specific metabolite of heroin. Provides definitive evidence of heroin use.',
        keyBenefits: [
          'Specific heroin detection',
          'Abuse monitoring',
          'Treatment assessment'
        ],
        price: 44999,
        cycleTime: '10 min',
        specimen: 'Hair',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        fastingNote: 'No fasting required - hair sample',
        certification: 'CE',
        range: 'Positive/Negative',
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No preparation required - hair sample. Avoid hair treatments (dyes, bleach) for 2 weeks before test if possible.'
        },
        referenceRanges: {
          cutoff: 500,
          unit: 'pg/mg',
          interpretation: 'Positive if >500 pg/mg in hair. Detection window: up to 90 days'
        }
      }
    ]
  },
  {
    id: 'hormone',
    name: 'Hormone Tests',
    icon: faVenusMars,
    color: '#FFB347',
    description: 'Tests for hormone level assessment and monitoring',
    tests: [
      {
        code: 'B-HCG-W23F',
        name: 'B-HCG',
        category: 'hormone',
        shortDescription: 'Pregnancy hormone test',
        longDescription: 'Beta human chorionic gonadotropin (B-HCG) test measures pregnancy hormone levels.',
        keyBenefits: [
          'Pregnancy detection',
          'Monitor pregnancy progress',
          'Screen for pregnancy complications'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: true,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '5-200000U/mL',
        referenceRanges: {
          low: 5,
          high: 200000,
          unit: 'U/mL',
          interpretation: 'Levels vary by pregnancy stage. Early pregnancy: >25 U/mL. Values double every 48-72 hours if pregnancy is viable.'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No special preparation required.'
        }
      },
      {
        code: 'AMH-W23F',
        name: 'Anti-Mullerian Hormone',
        category: 'hormone',
        shortDescription: 'Ovarian reserve marker',
        longDescription: 'Anti-Mullerian hormone (AMH) test measures ovarian reserve levels.',
        keyBenefits: [
          'Ovarian reserve assessment',
          'Monitor fertility',
          'Guide treatment decisions'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '0.0-4.0ng/mL',
        referenceRanges: {
          low: 0.0,
          high: 4.0,
          unit: 'ng/mL',
          interpretation: 'Normal range: 0.0-4.0ng/mL'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No special preparation required.'
        }
      },
      {
        code: 'LH-W23F',
        name: 'Luteinizing Hormone',
        category: 'hormone',
        shortDescription: 'Fertility and ovulation marker',
        longDescription: 'Measures LH levels to assess reproductive function and ovulation.',
        keyBenefits: [
          'Ovulation tracking',
          'Fertility assessment',
          'Reproductive health monitoring'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '1-100mIU/mL',
        referenceRanges: {
          follicular: {
            low: 1.68,
            high: 15,
            unit: 'mIU/mL'
          },
          luteal: {
            low: 0.61,
            high: 16.3,
            unit: 'mIU/mL'
          },
          interpretation: 'Varies by menstrual phase. Peaks at ovulation (>20 mIU/mL)'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No special preparation required.'
        }
      },
      {
        code: 'FSH-W23F',
        name: 'Follicle-Stimulating Hormone',
        category: 'hormone',
        shortDescription: 'Reproductive function marker',
        longDescription: 'Measures FSH levels to assess fertility and reproductive function.',
        keyBenefits: [
          'Fertility assessment',
          'Menopause evaluation',
          'Reproductive health monitoring'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '1-100mIU/mL',
        referenceRanges: {
          follicular: {
            low: 3.5,
            high: 12.5,
            unit: 'mIU/mL'
          },
          luteal: {
            low: 1.7,
            high: 7.7,
            unit: 'mIU/mL'
          },
          interpretation: 'Varies by menstrual phase. Elevated in menopause (>30 mIU/mL)'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No special preparation required.'
        }
      },
      {
        code: 'PROG-W23F',
        name: 'Progestin',
        category: 'hormone',
        shortDescription: 'Female hormone test',
        longDescription: 'Measures progesterone levels to assess menstrual cycle and fertility.',
        keyBenefits: [
          'Ovulation confirmation',
          'Pregnancy monitoring',
          'Menstrual cycle assessment'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '1.4-60ng/mL',
        referenceRanges: {
          follicular: {
            low: 0.2,
            high: 1.4,
            unit: 'ng/mL'
          },
          luteal: {
            low: 4.0,
            high: 25.0,
            unit: 'ng/mL'
          },
          interpretation: 'Varies by cycle phase. Peaks in luteal phase.'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No special preparation required.'
        }
      },
      {
        code: 'PRL-W23F',
        name: 'Prolactin',
        category: 'hormone',
        shortDescription: 'Milk production hormone',
        longDescription: 'Measures prolactin levels for lactation and reproductive health assessment.',
        keyBenefits: [
          'Lactation assessment',
          'Fertility evaluation',
          'Pituitary function check'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '1-200ng/mL',
        referenceRanges: {
          nonpregnant: {
            low: 2,
            high: 29,
            unit: 'ng/mL'
          },
          pregnant: {
            low: 10,
            high: 209,
            unit: 'ng/mL'
          },
          interpretation: 'Varies by pregnancy status. Elevated during lactation.'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No special preparation required.'
        }
      },
      {
        code: 'TES-W23F',
        name: 'Testosterone',
        category: 'hormone',
        shortDescription: 'Male hormone test',
        longDescription: 'Measures testosterone levels for male hormone assessment.',
        keyBenefits: [
          'Male hormone assessment',
          'Reproductive health check',
          'Development monitoring'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '0.2-15ng/mL',
        referenceRanges: {
          male: {
            low: 280,
            high: 1100,
            unit: 'ng/dL',
            interpretation: 'Adult males: 280-1100 ng/dL'
          },
          female: {
            low: 15,
            high: 70,
            unit: 'ng/dL',
            interpretation: 'Adult females: 15-70 ng/dL'
          }
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No special preparation required.'
        }
      },
      {
        code: 'E2-W23F',
        name: 'E2',
        category: 'hormone',
        shortDescription: 'Estradiol hormone test',
        longDescription: 'Measures estradiol levels for female hormone assessment.',
        keyBenefits: [
          'Female hormone assessment',
          'Fertility evaluation',
          'Menopause monitoring'
        ],
        price: 59999,
        cycleTime: '15 min',
        specimen: 'W/S/P',
        featured: false,
        testType: 'CareSync',
        requiresFasting: false,
        certification: 'CE',
        range: '9-300pg/mL',
        referenceRanges: {
          follicular: {
            low: 20,
            high: 150,
            unit: 'pg/mL'
          },
          luteal: {
            low: 30,
            high: 450,
            unit: 'pg/mL'
          },
          postmenopausal: {
            low: 0,
            high: 30,
            unit: 'pg/mL'
          },
          interpretation: 'Varies by menstrual phase and menopausal status'
        },
        available: true,
        preparationRequirements: {
          noFood: false,
          noDrink: false,
          noActivity: false,
          duration: '0',
          additionalNotes: 'No special preparation required.'
        }
      }
    ]
  }
]

/**
 * Search Weights for Relevance Scoring
 * These values help prioritize search results based on field matches
 */
export const searchWeights = {
  name: 1.0,
  category: 0.7,
  shortDescription: 0.5,
  longDescription: 0.3,
  keyBenefits: 0.4
}

// Add specimen type definitions
export const specimenTypes = {
  'W': 'Whole Blood',
  'S': 'Serum',
  'P': 'Plasma',
  'U': 'Urine',
  'H': 'Hair',
  'F': 'Stool',
  'SW': 'Swab'
} 

// Patient Health Data Schema with Mock Data
export const patientHealthData = {
  personalInfo: {
    id: 'PAT123456',
    firstName: 'John',
    lastName: 'Doe',
    dateOfBirth: '1985-06-15',
    age: 65,
    gender: 'male',
    ethnicity: 'Caucasian',
    occupation: 'Software Developer',
    contact: {
      email: 'john.doe@email.com',
      phone: '+27123456789'
    }
  },
  physicalMetrics: {
    height: {
      value: 180,
      unit: 'cm'
    },
    weight: {
      value: 80,
      unit: 'kg'
    },
    bmi: 24.7,
    bloodType: 'O+',
    lastUpdated: '2024-03-15'
  },
  medicalHistory: {
    allergies: [
      {
        allergen: 'Penicillin',
        severity: 'moderate',
        reaction: 'Rash and hives',
        diagnosed: '2010-03-15'
      },
      {
        allergen: 'Peanuts',
        severity: 'severe',
        reaction: 'Anaphylaxis',
        diagnosed: '2005-08-22'
      }
    ],
    medications: [
      {
        name: 'Lisinopril',
        dosage: '10mg',
        frequency: 'daily',
        startDate: '2022-01-15',
        purpose: 'Hypertension'
      },
      {
        name: 'Metformin',
        dosage: '500mg',
        frequency: 'twice daily',
        startDate: '2021-11-30',
        purpose: 'Type 2 Diabetes'
      }
    ],
    chronicIllnesses: [
      {
        condition: 'Type 2 Diabetes',
        diagnosed: '2021-11-15',
        status: 'controlled',
        treatments: ['Metformin', 'Diet modification']
      },
      {
        condition: 'Hypertension',
        diagnosed: '2022-01-10',
        status: 'controlled',
        treatments: ['Lisinopril', 'Lifestyle changes']
      }
    ]
  },
  familyHistory: [
    {
      relation: 'Father',
      conditions: [
        {
          condition: 'Type 2 Diabetes',
          diagnosedAge: 45
        },
        {
          condition: 'Coronary Artery Disease',
          diagnosedAge: 60
        }
      ]
    },
    {
      relation: 'Mother',
      conditions: [
        {
          condition: 'Hypertension',
          diagnosedAge: 50
        }
      ]
    }
  ],
  diagnosticHistory: [
    {
      diagnosis: 'Type 2 Diabetes',
      date: '2021-11-15',
      severity: 'moderate',
      treatment: 'Medication and lifestyle changes',
      status: 'ongoing'
    },
    {
      diagnosis: 'Hypertension',
      date: '2022-01-10',
      severity: 'mild',
      treatment: 'Medication and diet modification',
      status: 'ongoing'
    }
  ],
  surgicalHistory: [
    {
      procedure: 'Appendectomy',
      date: '2015-03-20',
      hospital: 'City General Hospital',
      complications: 'none'
    },
    {
      procedure: 'Knee Arthroscopy',
      date: '2019-08-15',
      hospital: 'Sports Medicine Center',
      complications: 'minor infection'
    }
  ],
  lifestyle: {
    diet: {
      type: 'Mixed',
      restrictions: ['Low sugar', 'Low salt'],
      dailyCalories: 2200,
      macronutrients: {
        protein: {
          grams: 110,
          calories: 440
        },
        carbohydrates: {
          grams: 220,
          calories: 880
        },
        fats: {
          grams: 98,
          calories: 880
        },
        sugar: {
          grams: 45,
          calories: 180
        }
      }
    },
    activityLevel: {
      level: 'moderate',
      exerciseFrequency: 3,
      exerciseTypes: ['Walking', 'Swimming', 'Light weights'],
      averageDailySteps: 8000
    },
    smoking: {
      status: 'former',
      quitDate: '2019-01-01',
      previouslyPerDay: 10,
      yearsSmoked: 5
    },
    alcohol: {
      status: 'social',
      drinksPerWeek: 2,
      preferredTypes: ['Wine']
    },
    sleep: {
      averageHours: 7,
      quality: 'good',
      issues: ['Occasional insomnia']
    },
    stress: {
      level: 'moderate',
      managementTechniques: ['Meditation', 'Exercise']
    }
  },
  vitalTrends: {
    bloodPressure: [
      {
        date: '2024-03-15',
        systolic: 128,
        diastolic: 82
      }
    ],
    bloodSugar: [
      {
        date: '2024-03-15',
        value: 5.9,
        unit: 'mmol/L',
        timing: 'fasting'
      }
    ],
    weight: [
      {
        date: '2024-03-15',
        value: 80,
        unit: 'kg'
      }
    ]
  }
} 