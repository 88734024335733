import { message } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { handleError } from '../../../lib/utils'
import { useDispatch, useSelector } from 'react-redux'
import { MedPraxProductSearch } from '../../../Scripting/utils/utils'
import { debounce } from 'lodash'
import patMedHistReducer from '../utils/reducer'
import { updateAllergies } from '../api/api'

const useAllergies = ({ clientForm, setSaving }) => {
  const dispatch = useDispatch()
  const { _id: recordId } = useSelector((state) => state.patMedHist.data)
  const { allergies } = useSelector((state) => state.patMedHist.data)

  // Refs
  const generalInputRef = useRef(null)
  const inputRef = useRef(null)

  // State for Nappi data
  const [nappiData, setNappiData] = useState([])
  const [nappiLoading, setNappiLoading] = useState(false)

  // State for selected allergies
  const [selectedAllergies, setSelectedAllergies] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedFoodAllergies, setSelectedFoodAllergies] = useState([])
  const [selectedGeneralAllergies, setSelectedGeneralAllergies] = useState([])

  // State for additional allergies
  const [additionalFoodAllergies, setAdditionalFoodAllergies] = useState(allergies.foodAdd || [])
  const [additionalGeneralAllergies, setAdditionalGeneralAllergies] = useState(allergies.otherAdd || [])
  const [additionalMedications, setAdditionalMedications] = useState(allergies.medsAdd || [])

  // State for UI
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    setSelectedAllergies(allergies.medsList || [])
    setSelectedFoodAllergies(allergies.foodList || [])
    setSelectedGeneralAllergies(allergies.otherList || [])

    // eslint-disable-next-line
  }, [allergies])

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery) {
        setNappiLoading(true)
        const tmpData = await MedPraxProductSearch(searchQuery)
        setNappiData(tmpData)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setNappiLoading(false)
  }

  // eslint-disable-next-line
  const debouncedNappiFilter = useCallback(
    debounce((value) => getNappiData(value), 500),
    []
  )

  const handleOptionClick = (allergy) => {
    setSelectedAllergies((prev) => {
      const isSelected = prev.includes(allergy.id)
      const newArray = isSelected ? prev.filter((id) => id !== allergy.id) : [...prev, allergy.id]

      // Update form values
      const currentValues = clientForm.getFieldsValue()
      clientForm.setFieldsValue({
        allergies: {
          ...currentValues.allergies,
          meds: newArray
        }
      })

      return newArray
    })
  }

  const handleFoodOptionClick = (allergy) => {
    setSelectedFoodAllergies((prev) => {
      const isSelected = prev.includes(allergy.id)
      const newArray = isSelected ? prev.filter((id) => id !== allergy.id) : [...prev, allergy.id]

      // Update form values
      const currentValues = clientForm.getFieldsValue()
      clientForm.setFieldsValue({
        allergies: {
          ...currentValues.allergies,
          food: newArray
        }
      })

      return newArray
    })
  }

  const handleGeneralOptionClick = (allergy) => {
    setSelectedGeneralAllergies((prev) => {
      const isSelected = prev.includes(allergy.id)
      const newArray = isSelected ? prev.filter((id) => id !== allergy.id) : [...prev, allergy.id]

      // Update form values
      const currentValues = clientForm.getFieldsValue()
      clientForm.setFieldsValue({
        allergies: {
          ...currentValues.allergies,
          general: newArray
        }
      })

      return newArray
    })
  }

  const addFoodAllergy = (allergy) => {
    setAdditionalFoodAllergies((prev) => [...prev, allergy])
  }

  const addGeneralAllergy = (allergy) => {
    setAdditionalGeneralAllergies((prev) => [...prev, allergy])
  }

  const handleSave = async (continueToNext = false) => {
    try {
      setSaving(true)
      const updatedAllergies = {
        medsAdd: additionalMedications,
        foodAdd: additionalFoodAllergies,
        otherAdd: additionalGeneralAllergies,
        medsList: selectedAllergies,
        foodList: selectedFoodAllergies,
        otherList: selectedGeneralAllergies
      }
      const newData = await updateAllergies(recordId, updatedAllergies)
      dispatch(patMedHistReducer.actions.updateState(newData))
      if (continueToNext) {
        // Logic to continue to the next step if needed
      }
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setSaving(false)
    }
  }

  return {
    addFoodAllergy,
    addGeneralAllergy,
    additionalFoodAllergies,
    additionalGeneralAllergies,
    additionalMedications,
    debouncedNappiFilter,
    generalInputRef,
    handleFoodOptionClick,
    handleGeneralOptionClick,
    handleOptionClick,
    handleSave,
    inputRef,
    isModalVisible,
    nappiData,
    nappiLoading,
    selectedAllergies,
    selectedCategory,
    selectedFoodAllergies,
    selectedGeneralAllergies,
    setAdditionalFoodAllergies,
    setAdditionalGeneralAllergies,
    setAdditionalMedications,
    setIsModalVisible,
    setNappiData,
    setSelectedCategory
  }
}

export default useAllergies
