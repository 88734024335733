import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { readSystemUsers } from '../utils/utils'
import { Col, Empty, Space, Table, message, theme } from 'antd'
import { handleError } from '../../../lib/utils'
import coreReducer from '../../../../core/utils/reducer'
import CoreEnums from '../../../../core/utils/enums'
import DependantForm from '../../../Profile/components/dependants/dependant-form'
import CustomRow from '../../../reusable-components/CustomRow'
import { deviceDetect } from 'react-device-detect'
import CustomButton from '../../../reusable-components/CustomButton'
import { faRefresh, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { columnTemplate } from '../../../Profile/components/dependants/utils/templates'
import AddPatient from './AddPatient'

const PatientDependants = ({ userRef, mainPatientInfo, mainMemeberDataIndex }) => {
  const dispatch = useDispatch()
  const [receptionData, setReceptionData] = useState([])
  const [loading, setLoading] = useState(false)
  const [editTarget, setEditTarget] = useState(null)
  const [adding, setAdding] = useState(false)

  useEffect(() => {
    handleGetDependants()
    // eslint-disable-next-line
  }, [])

  const handleGetDependants = async (dependants) => {
    let tmpDependants = []

    setLoading(true)

    try {
      tmpDependants = await readSystemUsers({ mainMemberId: userRef }, null, null, null, true)
      setReceptionData(tmpDependants)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleOpenDependantForm = (record, isNew) => {
    dispatch(
      coreReducer.actions.addTab({
        key: isNew ? `${CoreEnums.tabKeys.DEPENDANTS}_new` : `${CoreEnums.tabKeys.DEPENDANTS}_${record._id}`,
        closable: true,
        label: isNew ? 'New Dependant' : `Dependant: ${record.firstName} ${record.lastName}`,
        children: (
          <DependantForm
            isNew={isNew}
            data={record}
            token={token}
            key={record._id}
            handleGetDependants={handleGetDependants}
            // setFormData={setFormData}
            // mainPatient={mainPatient}
            // isReception={isReception}
            // refreshView={refreshView}
          />
        )
      })
    )
  }

  const { token } = theme.useToken()

  const handleReset = () => {
    setAdding(false)
    setEditTarget(false)
  }

  return (
    <>
      {adding || editTarget ? (
        <AddPatient
          isDependant
          mainPatientInfo={mainPatientInfo}
          resetFunction={handleReset}
          refreshView={handleGetDependants}
        />
      ) : (
        <CustomRow justify='center' style={deviceDetect().isMobile ? { textAlign: 'center' } : {}}>
          <Col span={24}>
            <CustomRow className='basic-card'>
              <Col span={24}>
                <Space wrap>
                  <CustomButton
                    text='Add Family Account'
                    size='small'
                    icon={faUserPlus}
                    loading={loading}
                    type='primary'
                    onClick={() => {
                      setAdding(true)
                    }}
                  />
                  {deviceDetect().isMobile ? undefined : (
                    <CustomButton
                      size='small'
                      text='Refresh'
                      icon={faRefresh}
                      iconPosition='end'
                      type='primary'
                      onClick={() => handleGetDependants()}
                    />
                  )}
                </Space>
              </Col>
              <Col span={24}>
                <Table
                  showHeader={!deviceDetect().isMobile}
                  pagination={false}
                  locale={{ emptyText: <Empty description='No Family Accounts to show' /> }}
                  columns={columnTemplate(token)}
                  rowKey={(record) => record._id}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        handleOpenDependantForm(record, false)
                      }
                    }
                  }}
                  dataSource={receptionData}
                  loading={loading}
                />
              </Col>
            </CustomRow>
          </Col>
        </CustomRow>
      )}
    </>
  )
}

export default PatientDependants
