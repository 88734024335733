import React, { useState } from 'react'
import { Button, Card, Empty, Select, Modal, Row, Col, Space, Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPills, faTrash, faPlus, faVirus } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import '../style/MobGeneralMeds.css'
import useGeneralMeds from '../../hooks/useGeneralMeds'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import DatePicker from '../../../Reusable Components/DatePicker'
import MobSectionHeader from './MobSectionHeader'

const MobGeneralMeds = ({ clientForm, data, setFacet, setSaving }) => {
  const {
    medsList,
    nappiData,
    nappiLoading,
    setNappiSearchFilter,
    handleAddMedication,
    handleUpdateMedication,
    handleRemoveMedication,
    handleSubmit,
    setNappiData,
    showMedicationModal,
    setShowMedicationModal,
    editingMedication,
    setEditingMedication,
    tempMedDates,
    setTempMedDates
  } = useGeneralMeds({ clientForm, setFacet, setSaving })

  const [showMedStartDatePicker, setShowMedStartDatePicker] = useState(false)
  const [showMedEndDatePicker, setShowMedEndDatePicker] = useState(false)

  const styles = {
    header: {
      fontSize: '20px',
      fontWeight: 600,
      marginBottom: '24px',
      display: 'flex',
      alignItems: 'center',
      gap: '12px'
    },
    addButton: {
      background: '#00A6E3',
      borderRadius: '8px',
      border: 'none',
      height: '48px',
      fontSize: '16px',
      fontWeight: 500
    },
    medicationCard: {
      borderRadius: '12px',
      border: 'none',
      marginBottom: '12px'
    },
    medicationIcon: {
      color: '#00A6E3',
      fontSize: '24px',
      marginTop: '4px'
    },
    medicationName: {
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    dateText: {
      color: '#8F9BB3',
      margin: '4px 0 0 0',
      fontSize: '14px'
    },
    removeButton: {
      border: '1px dashed #FF4D4F',
      color: '#FF4D4F',
      background: 'transparent',
      borderRadius: '8px',
      height: '44px',
      width: '100%'
    }
  }
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <MobSectionHeader icon={faPills} title='GENERAL MEDICATIONS' subtitle='Update your current medications' />
      </Col>
      <Col span={24}>
        <Button
          type='primary'
          onClick={() => setShowMedicationModal(true)}
          icon={<FontAwesomeIcon icon={faPlus} />}
          block
          style={styles.addButton}
        >
          Add Medication
        </Button>
      </Col>

      <Col span={24}>
        {medsList?.length === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span style={{ color: '#8F9BB3' }}>No medications added yet</span>}
          />
        ) : (
          <Space direction='vertical' style={{ width: '100%' }}>
            {medsList?.map((medication, index) => (
              <Card key={index} style={styles.medicationCard} bodyStyle={{ padding: '16px' }}>
                <Row gutter={[0, 12]}>
                  <Col span={24}>
                    <div
                      style={styles.medicationName}
                      onClick={() => {
                        setEditingMedication({
                          ...medication,
                          index
                        })
                        setTempMedDates({
                          startDate: medication.startDate,
                          endDate: medication.endDate
                        })
                        setShowMedicationModal(true)
                      }}
                    >
                      {medication.med.name}
                    </div>
                    <div style={styles.dateText}>
                      <p>Started: {dayjs(medication.startDate).format('DD MMM YYYY')}</p>
                      <p>
                        {medication.endDate && <span>Ended: {dayjs(medication.endDate).format('DD MMM YYYY')}</span>}
                        {!medication.endDate && <span> - Current</span>}
                      </p>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      block
                      onClick={() => handleRemoveMedication(index)}
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      style={styles.removeButton}
                    >
                      Remove Medication
                    </Button>
                  </Col>
                </Row>
              </Card>
            ))}
          </Space>
        )}
      </Col>

      <Modal
        title={
          <div>
            <div>{editingMedication ? 'Edit Medication' : 'Add Medication'}</div>
            <div style={{ fontSize: '14px', fontWeight: 'normal', marginTop: '4px' }}>
              {editingMedication ? 'Update medication dates' : 'Search our database of medications'}
            </div>
          </div>
        }
        open={showMedicationModal}
        onCancel={() => {
          setShowMedicationModal(false)
          setEditingMedication(null)
          setTempMedDates({ startDate: null, endDate: null })
        }}
        footer={
          <Button
            type='primary'
            disabled={!tempMedDates.startDate || (!editingMedication && !nappiData.length)}
            onClick={() => {
              if (editingMedication) {
                handleUpdateMedication(editingMedication.index, tempMedDates)
              } else {
                handleAddMedication(nappiData[0], tempMedDates)
              }
            }}
          >
            {editingMedication ? 'Update' : 'Add'} Medication
          </Button>
        }
        className='dark-modal'
      >
        {editingMedication ? (
          <>
            <div style={{ marginBottom: '16px' }}>
              <div style={{ fontSize: '16px', fontWeight: 500 }}>
                {editingMedication.med.name}
                {editingMedication.med.dosageForm && ` (${editingMedication.med.dosageForm})`}
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ marginBottom: '16px', color: '#8F9BB3' }}>
              <p>Search for your medication by typing its name or brand.</p>
              <p>• Enter the medication name or active ingredient</p>
              <p>• Select from the matching suggestions</p>
            </div>
            <Select
              className='dark-modal-select'
              showSearch
              placeholder='E.g. Type "Metformin", "Ventolin", "Aspirin" ...'
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={setNappiSearchFilter}
              notFoundContent={nappiLoading ? 'Searching...' : 'No results found. Try different keywords.'}
              style={{ width: '100%' }}
              options={nappiData.map((item) => ({
                value: JSON.stringify(item),
                label: `${item.name} ${item.dosageForm ? `(${item.dosageForm})` : ''}`
              }))}
            />
          </>
        )}

        <div style={{ marginTop: '16px' }}>
          <div style={{ color: '#8F9BB3', marginBottom: '8px' }}>Start Date</div>
          <div
            onClick={() => setShowMedStartDatePicker(true)}
            style={{
              border: '1px solid #8F9BB3',
              padding: '8px 12px',
              borderRadius: '8px',
              color: tempMedDates.startDate ? '#000000' : '#8F9BB3',
              cursor: 'pointer'
            }}
          >
            {tempMedDates.startDate ? dayjs(tempMedDates.startDate).format('DD MMM YYYY') : 'Select start date'}
          </div>
        </div>

        <div style={{ marginTop: '16px' }}>
          <div style={{ color: '#8F9BB3', marginBottom: '8px' }}>End Date (Optional)</div>
          <div
            onClick={() => setShowMedEndDatePicker(true)}
            style={{
              border: '1px solid #8F9BB3',
              padding: '8px 12px',
              borderRadius: '8px',
              color: tempMedDates.endDate ? '#000000' : '#8F9BB3',
              cursor: 'pointer'
            }}
          >
            {tempMedDates.endDate ? dayjs(tempMedDates.endDate).format('DD MMM YYYY') : 'Select end date'}
          </div>
        </div>

        <DatePicker
          showDatePicker={showMedStartDatePicker}
          setShowDatePicker={setShowMedStartDatePicker}
          pickerValue={tempMedDates.startDate ? dayjs(tempMedDates.startDate) : null}
          setPickerValue={(date) => setTempMedDates((prev) => ({ ...prev, startDate: date }))}
          handleDateSelect={(date) => {
            setTempMedDates((prev) => ({ ...prev, startDate: date }))
            setShowMedStartDatePicker(false)
          }}
        />

        <DatePicker
          showDatePicker={showMedEndDatePicker}
          setShowDatePicker={setShowMedEndDatePicker}
          pickerValue={tempMedDates.endDate ? dayjs(tempMedDates.endDate) : null}
          setPickerValue={(date) => setTempMedDates((prev) => ({ ...prev, endDate: date }))}
          handleDateSelect={(date) => {
            setTempMedDates((prev) => ({ ...prev, endDate: date }))
            setShowMedEndDatePicker(false)
          }}
        />
      </Modal>

      <PrevNextFloatingButtons
        onPrev={() => {
          clientForm.resetFields()
          setFacet(null)
        }}
        onNext={handleSubmit}
        nextText='Save'
        prevText='Cancel'
      />
    </Row>
  )
}

export default MobGeneralMeds
