import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Button, Col, DatePicker, Select, Space, message, theme } from 'antd'
import dayjs from 'dayjs'
import GoogleAddressMobile from '../../../../reusable-components/GoogleAddressMobile'
import CoreEnums from '../../../../../core/utils/enums'
import CustomRow from '../../../../reusable-components/CustomRow'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import coreReducer from '../../../../../core/utils/reducer'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'react-lottie'
import searchingDoctorsAnimation from '../../../../../assets/Animations/SearchingDoctors.json'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt,
  faChevronRight,
  faUser,
  faUserDoctor,
  faLocationDot,
  faKitMedical
} from '@fortawesome/free-solid-svg-icons'
import { Picker, Popup } from 'antd-mobile'

const MobClinicBookingDetailsCapture = ({
  loading,
  setBooking,
  booking,
  setupServiceValue,
  setupServices,
  location,
  setLocation,
  setPatient,
  patient,
  dependants,
  isHomeVisit,
  isVirtualVisit,
  isReceptionBooking,
  handleSubmit,
  prevStep,
  clinics,
  noLocation,
  handleLocationChange,
  careSyncBooking,
  careSyncTests,
  careSyncTestsLoading,
  setTestKeys,
  testKeys,
  testsTotal,
  setTestsTotal
}) => {
  console.log('Dependants', dependants)
  const [provinces, setProvinces] = useState([])
  const [provincesLoading, setProvincesLoading] = useState(false)
  const { token } = theme.useToken()
  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day')
  }

  useEffect(() => {
    setUpProvinceOptions()
    if (careSyncTests && testKeys) {
      const total = careSyncTests
        .filter((test) => testKeys.includes(test.key))
        .reduce((sum, test) => sum + test.price, 0)
      setTestsTotal(total)
    } else {
      setTestsTotal(0)
    }
    // eslint-disable-next-line
  }, [clinics, testKeys, careSyncTests])

  const setUpProvinceOptions = () => {
    let tmpProvinces = [{ label: '- Select Province -', value: '' }]

    setProvincesLoading(true)
    Object.keys(CoreEnums.provinces).forEach((key) => {
      const province = CoreEnums.provinces[key]
      const hasProvince = clinics.find((clinic) => clinic.province === province)
      if (hasProvince) {
        tmpProvinces.push({ label: province, value: province })
      }
    })
    setProvinces(tmpProvinces)
    setProvincesLoading(false)
  }
  const dispatch = useDispatch()
  const tabNavigationState = useSelector((state) => state.core.tabNavigation)
  const handleClose = () => {
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: tabNavigationState.activeKey,
        removeBreadcrumb: true
      })
    )
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: searchingDoctorsAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showServicePicker, setShowServicePicker] = useState(false)
  const [showDependantPicker, setShowDependantPicker] = useState(false)
  const [showProvincePicker, setShowProvincePicker] = useState(false)
  const [pickerValue, setPickerValue] = useState(() => {
    const dateToUse = new Date()
    return [dateToUse.getDate(), dateToUse.getMonth() + 1, dateToUse.getFullYear()]
  })

  const generateDateColumns = () => {
    const today = new Date()
    const currentDay = today.getDate()
    const currentMonth = today.getMonth() + 1
    const currentYear = today.getFullYear()

    const [_, selectedMonth, selectedYear] = pickerValue

    // Generate days based on the selected month
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate()

    // Generate days
    const days = Array.from({ length: daysInMonth }, (_, i) => ({
      label: (i + 1).toString(),
      value: i + 1
    })).filter((day) => {
      // If it's current year and current month, filter days before today
      if (selectedYear === currentYear && selectedMonth === currentMonth) {
        return day.value > currentDay + 1
      }
      // If it's current year but past month, filter all days
      if (selectedYear === currentYear && selectedMonth < currentMonth) {
        return false
      }
      return true
    })

    // Generate months
    const months = Array.from({ length: 12 }, (_, i) => ({
      label: dayjs().month(i).format('MMMM'),
      value: i + 1
    })).filter((month) => {
      if (selectedYear === currentYear) {
        return month.value >= currentMonth
      }
      return true
    })

    // Generate years - only current year and next year if we're in the last month
    const shouldShowNextYear = currentMonth === 12
    const years = Array.from({ length: shouldShowNextYear ? 2 : 1 }, (_, i) => ({
      label: (currentYear + i).toString(),
      value: currentYear + i
    }))

    return [days, months, years]
  }

  const handleDateSelect = (val) => {
    const [day, month, year] = val
    const newDate = new Date(year, month - 1, day)
    setPickerValue(val)
    setBooking({
      ...booking,
      date: dayjs(newDate)
    })
    setShowDatePicker(false)
  }

  const handleQuickDateSelect = (daysToAdd = 0) => {
    const date = new Date()
    const newDate = new Date(date)
    newDate.setDate(date.getDate() + daysToAdd)

    setBooking({
      ...booking,
      date: dayjs(newDate)
    })
    setShowDatePicker(false)
  }

  const generateServiceColumns = () => {
    return [
      setupServices().map((service) => ({
        label: service.label,
        value: service.value
      }))
    ]
  }

  const handleServiceSelect = (val) => {
    const [serviceId] = val
    setBooking({ ...booking, service: serviceId })
    setShowServicePicker(false)
  }

  // Helper function to safely parse JSON
  const safeJSONParse = (value) => {
    try {
      return JSON.parse(value)
    } catch (error) {
      console.error('JSON Parse Error:', error)
      return null
    }
  }

  const generateDependantColumns = () => {
    try {
      if (!dependants || !dependants.length) {
        console.log('No dependants available')
        return [[]]
      }

      console.log('Generating columns from dependants:', dependants)

      const columns = isReceptionBooking
        ? [{ label: `${patient.firstName} ${patient.lastName}`, value: JSON.stringify(patient) }]
        : dependants.map((dep) => ({
            label: dep.label,
            value: dep.value // Keep the value as is, it should already be stringified
          }))

      return [columns]
    } catch (error) {
      console.error('Error generating dependant columns:', error)
      return [[]]
    }
  }

  const handleDependantSelect = (val) => {
    const [dependantValue] = val
    if (dependantValue) {
      const selectedPatient = safeJSONParse(dependantValue)
      if (selectedPatient) {
        setLocation(null)
        setPatient(selectedPatient)
        console.log('Selected patient:', selectedPatient)
      } else {
        message.error('Error selecting patient')
      }
    } else {
      setLocation(null)
      setPatient(null)
    }
    setShowDependantPicker(false)
  }

  const generateProvinceColumns = () => {
    return [
      provinces.map((province) => ({
        label: province.label,
        value: province.value
      }))
    ]
  }

  const handleProvinceSelect = (val) => {
    const [provinceValue] = val
    setBooking({ ...booking, province: provinceValue })
    setShowProvincePicker(false)
  }

  const pickerPopupStyle = {
    '--adm-color-primary': '#00b6d8',
    '--adm-color-border': '#333333',
    '--adm-font-size-main': '16px'
  }

  console.log('BOoking Details', booking)

  const CustomOptionPicker = ({ visible, onClose, options, onSelect, title, icon }) => {
    return (
      <Popup
        visible={visible}
        onClose={onClose}
        position='bottom'
        bodyStyle={{
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          maxHeight: '70vh'
        }}
      >
        <div style={{ padding: '16px' }}>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              marginBottom: '16px',
              fontSize: '16px'
            }}
          >
            {title}
          </div>
          <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            {options.map((option) => (
              <div
                key={option.value}
                onClick={() => {
                  onSelect(option.value)
                  onClose()
                }}
                style={{
                  padding: '12px',
                  borderBottom: '1px solid #eee',
                  width: '100%',
                  textAlign: 'left',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px'
                }}
              >
                <FontAwesomeIcon icon={icon} style={{ color: '#00b6d8', width: '20px', height: '20px' }} />
                {option.label}
              </div>
            ))}
          </div>
          <div style={{ marginTop: '16px' }}>
            <Button block onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Popup>
    )
  }

  // Add ref for the search input
  const searchInputRef = useRef(null)

  // Modify the showAddressPicker state to include a useEffect
  const [showAddressPicker, setShowAddressPicker] = useState(false)

  // Add effect to focus input when picker opens
  useEffect(() => {
    if (showAddressPicker && searchInputRef.current) {
      // Small delay to ensure the popup is fully rendered
      setTimeout(() => {
        searchInputRef.current?.focus()
      }, 100)
    }
  }, [showAddressPicker])

  // Modify the location change handler
  const handleLocationSelect = (address) => {
    handleLocationChange(address)
    setShowAddressPicker(false)
  }

  // Add state for tests picker
  const [showTestsPicker, setShowTestsPicker] = useState(false)
  const [searchTest, setSearchTest] = useState('')

  // Filter tests based on search and remove already selected tests
  const filteredTests = useMemo(() => {
    if (!careSyncTests) return []
    return careSyncTests.filter(
      (test) =>
        !testKeys?.includes(test.key) && // Remove selected tests
        test.name.toLowerCase().includes(searchTest.toLowerCase())
    )
  }, [careSyncTests, searchTest, testKeys])

  const handleRemoveTest = (testKey) => {
    setTestKeys(testKeys.filter((key) => key !== testKey))
  }

  return (
    <>
      <style>
        {`
          @keyframes shimmer {
            0% { opacity: 0.6; }
            50% { opacity: 1; }
            100% { opacity: 0.6; }
          }
          .shimmer-text {
            animation: shimmer 3s infinite;
          }
        `}
      </style>
      <CustomRow style={{ alignItems: 'center' }}>
        <Col span={24}>
          <div
            style={{
              textAlign: 'center',
              fontSize: 12,
              marginTop: 12,
              fontWeight: 500,
              color: '#000000'
            }}
          >
            Configure your booking and find an available doctor near you
            <b
              className='shimmer-text'
              style={{
                color: '#000000',
                opacity: 0.8
              }}
            >
              {' '}
              in seconds.
            </b>
          </div>
        </Col>
        <Col span={24}>
          <CustomRow style={{ color: '#000000' }} gutter={[12, 8]}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <p style={{ color: '#000000', marginBottom: '8px' }}>Date</p>
              <div
                onClick={() => setShowDatePicker(true)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  padding: '12px',
                  border: '1px solid #6B7280',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <FontAwesomeIcon icon={faCalendarAlt} style={{ color: '#00b6d8', width: '20px', height: '20px' }} />
                  <span>{booking.date ? dayjs(booking.date).format('MMM D, YYYY') : 'Select Date'}</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} style={{ color: '#6B7280', width: '14px', height: '14px' }} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <p style={{ color: '#000000', marginBottom: '8px' }}>Appointment for</p>
              <div
                onClick={() => setShowDependantPicker(true)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  padding: '12px',
                  border: '1px solid #6B7280',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <FontAwesomeIcon icon={faUser} style={{ color: '#00b6d8', width: '20px', height: '20px' }} />
                  <span>
                    {patient
                      ? dependants?.find((dep) => {
                          const parsedDep = safeJSONParse(dep.value)
                          return parsedDep && parsedDep._id === patient._id
                        })?.label || 'Select Patient'
                      : 'Myself'}
                  </span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} style={{ color: '#6B7280', width: '14px', height: '14px' }} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <p style={{ color: '#000000', marginBottom: '8px' }}>Type</p>
              <div
                onClick={() => setShowServicePicker(true)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  padding: '12px',
                  border: '1px solid #6B7280',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <FontAwesomeIcon icon={faKitMedical} style={{ color: '#00b6d8', width: '20px', height: '20px' }} />
                  <span>{setupServices().find((s) => s.value === booking.service)?.label || 'Select Service'}</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} style={{ color: '#6B7280', width: '14px', height: '14px' }} />
              </div>
            </Col>
            {!isVirtualVisit ? (
              <Col xs={24} sm={24} md={24} lg={12}>
                <p style={{ color: '#000000', marginBottom: '8px' }}>Province</p>
                <div
                  onClick={() => setShowProvincePicker(true)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    padding: '12px',
                    border: '1px solid #6B7280',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <FontAwesomeIcon icon={faLocationDot} style={{ color: '#00b6d8', width: '20px', height: '20px' }} />
                    <span>{booking.province || 'Select Province'}</span>
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} style={{ color: '#6B7280', width: '14px', height: '14px' }} />
                </div>
              </Col>
            ) : undefined}
            <Col span={24}>
              <p style={{ color: '#000000', marginBottom: '8px' }}>Location</p>
              <div
                onClick={() => setShowAddressPicker(true)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  padding: '12px',
                  border: '1px solid #6B7280',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <FontAwesomeIcon icon={faLocationDot} style={{ color: '#00b6d8', width: '20px', height: '20px' }} />
                  <span>{location?.address || 'Search Location'}</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} style={{ color: '#6B7280', width: '14px', height: '14px' }} />
              </div>
            </Col>
            {careSyncBooking && (
              <Col xs={24} sm={24} md={24} lg={12}>
                <p style={{ color: '#000000', marginBottom: '8px' }}>Select Tests</p>
                <div
                  onClick={() => setShowTestsPicker(true)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    padding: '12px',
                    border: '1px solid #6B7280',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <FontAwesomeIcon icon={faKitMedical} style={{ color: '#00b6d8', width: '20px', height: '20px' }} />
                    <span>{testKeys?.length ? `${testKeys.length} tests selected` : 'Select Tests'}</span>
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} style={{ color: '#6B7280', width: '14px', height: '14px' }} />
                </div>
                {testKeys?.length > 0 && (
                  <div style={{ marginTop: '8px' }}>
                    {careSyncTests
                      .filter((test) => testKeys.includes(test.key))
                      .map((test) => (
                        <div
                          key={test.key}
                          style={{
                            padding: '12px',
                            background: '#f5f5f5',
                            borderRadius: '8px',
                            marginBottom: '8px'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'flex-start',
                              marginBottom: '4px'
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <div style={{ fontWeight: '500' }}>{test.name}</div>
                              <div
                                style={{
                                  fontSize: '14px',
                                  color: '#666',
                                  marginTop: '4px'
                                }}
                              >
                                {test.description}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                gap: '4px'
                              }}
                            >
                              <span style={{ color: '#00b6d8', fontWeight: '500' }}>R{test.price.toFixed(2)}</span>
                              <Button
                                type='text'
                                size='small'
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleRemoveTest(test.key)
                                }}
                                style={{ padding: '0', color: '#ff4d4f' }}
                              >
                                Remove
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </Col>
            )}
          </CustomRow>
        </Col>
        <Col span={24}></Col>
        {/* <Col xs={24} sm={24} md={24} lg={8}>
          {noLocation ? (
            <Empty description='No location specified. Please search for an Address' />
          ) : (
            <div style={{ height: 300, maxHeight: '100%', width: '100%', overflow: 'hidden', borderRadius: 16 }}>
              <GoogleMapReact
                center={{
                  lat: location?.coords?.latitude,
                  lng: location?.coords?.longitude
                }}
                zoom={17}
                bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY }}
              >
                <div lat={location?.coords?.latitude} lng={location?.coords?.longitude} text='My Marker'>
                  <FontAwesomeIcon icon={faLocationDot} style={{ color: token.colorError, marginTop: 0 }} size='3x' />
                </div>
              </GoogleMapReact>
            </div>
          )}
        </Col> */}
      </CustomRow>
      {loading ? (
        <>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1001,
              background: 'white'
            }}
          >
            <Lottie options={defaultOptions} height={300} width={300} style={{ marginBottom: 20 }} />
            <div
              style={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 500
              }}
            >
              Searching for available doctors...
            </div>
          </div>
        </>
      ) : (
        <PrevNextFloatingButtons
          loading={loading}
          onNext={() => {
            if (!booking.date) return message.error('Please select a date.')
            if (!booking.province && !isVirtualVisit) return message.error('Please select a province.')
            return handleSubmit()
          }}
          onPrev={() => {
            return handleClose()
          }}
        />
      )}

      <Picker
        visible={showDatePicker}
        onClose={() => setShowDatePicker(false)}
        value={pickerValue}
        onConfirm={handleDateSelect}
        columns={generateDateColumns()}
        onSelect={(val) => setPickerValue(val)}
        title={
          <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', padding: '8px 0' }}>
            <Button
              size='small'
              type='primary'
              onClick={() => handleQuickDateSelect(0)}
              style={{
                fontSize: '14px',
                height: '28px',
                padding: '0 12px',
                background: token.colorPrimary,
                borderColor: token.colorPrimary
              }}
            >
              Today
            </Button>
            <Button
              size='small'
              type='primary'
              onClick={() => handleQuickDateSelect(1)}
              style={{
                fontSize: '14px',
                height: '28px',
                padding: '0 12px',
                background: token.colorPrimary,
                borderColor: token.colorPrimary
              }}
            >
              Tomorrow
            </Button>
          </div>
        }
        confirmText='Confirm'
        cancelText='Cancel'
        popupStyle={pickerPopupStyle}
      />

      <CustomOptionPicker
        visible={showDependantPicker}
        onClose={() => setShowDependantPicker(false)}
        options={generateDependantColumns()[0]}
        onSelect={(value) => handleDependantSelect([value])}
        title='Select Patient'
        icon={faUser}
      />

      <CustomOptionPicker
        visible={showServicePicker}
        onClose={() => setShowServicePicker(false)}
        options={generateServiceColumns()[0]}
        onSelect={(value) => handleServiceSelect([value])}
        title='Select Service'
        icon={faKitMedical}
      />

      {!isVirtualVisit && (
        <CustomOptionPicker
          visible={showProvincePicker}
          onClose={() => setShowProvincePicker(false)}
          options={generateProvinceColumns()[0]}
          onSelect={(value) => handleProvinceSelect([value])}
          title='Select Province'
          icon={faLocationDot}
        />
      )}

      {/* Add the address picker popup */}
      <Popup
        visible={showAddressPicker}
        onClose={() => setShowAddressPicker(false)}
        position='bottom'
        bodyStyle={{
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          maxHeight: '70vh'
        }}
      >
        <div style={{ padding: '16px' }}>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              marginBottom: '16px',
              fontSize: '16px'
            }}
          >
            Search Location
          </div>
          <div style={{ marginBottom: '16px' }}>
            <GoogleAddressMobile
              id='map'
              ref={searchInputRef}
              selectProps={{
                placeholder: 'Search for an address',
                onChange: handleLocationSelect,
                autoFocus: true
              }}
            />
          </div>
          <Button block onClick={() => setShowAddressPicker(false)}>
            Cancel
          </Button>
        </div>
      </Popup>

      {/* Add the tests picker popup */}
      <Popup
        visible={showTestsPicker}
        onClose={() => {
          setShowTestsPicker(false)
          setSearchTest('')
        }}
        position='bottom'
        bodyStyle={{
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          maxHeight: '70vh'
        }}
      >
        <div style={{ padding: '16px' }}>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              marginBottom: '16px',
              fontSize: '16px'
            }}
          >
            Select Tests
          </div>
          <input
            type='text'
            placeholder='Search tests...'
            value={searchTest}
            onChange={(e) => setSearchTest(e.target.value)}
            style={{
              width: '100%',
              padding: '8px 12px',
              border: '1px solid #d9d9d9',
              borderRadius: '8px',
              marginBottom: '16px'
            }}
          />
          <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            {filteredTests.length === 0 && (
              <div
                style={{
                  textAlign: 'center',
                  padding: '20px',
                  color: '#666'
                }}
              >
                {searchTest ? 'No matching tests found' : 'No more tests available'}
              </div>
            )}
            {filteredTests.map((test) => (
              <div
                key={test.key}
                onClick={() => {
                  setTestKeys([...(testKeys || []), test.key])
                }}
                style={{
                  padding: '12px',
                  borderBottom: '1px solid #eee',
                  cursor: 'pointer'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                  <FontAwesomeIcon
                    icon={faKitMedical}
                    style={{
                      color: '#00b6d8',
                      width: '20px',
                      height: '20px',
                      marginTop: '4px'
                    }}
                  />
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: '500' }}>{test.name}</div>
                    <div
                      style={{
                        fontSize: '14px',
                        color: '#666',
                        marginTop: '4px'
                      }}
                    >
                      {test.description}
                    </div>
                    <div
                      style={{
                        color: '#00b6d8',
                        marginTop: '4px',
                        fontWeight: '500'
                      }}
                    >
                      R{test.price.toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div style={{ marginTop: '16px' }}>
            <Button
              block
              onClick={() => {
                setShowTestsPicker(false)
                setSearchTest('')
              }}
            >
              Done
            </Button>
          </div>
        </div>
      </Popup>
    </>
  )
}

export default MobClinicBookingDetailsCapture
