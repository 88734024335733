import React, { useCallback } from 'react'
import { Col, Button } from 'antd'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import { faBookMedical } from '@fortawesome/free-solid-svg-icons'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'
import BeginExaminationModal from './BeginExaminationModal.js'
import useSystemUsersForm from '../hooks/useSystemUsersForm'
import SystemUsersGerneral from './form-sections/SystemUsersGerneral.js'

// Extracted to a separate component for better readability and reusability
const ExaminationSection = ({ handleExaminationModalOpen }) => (
  <Col span={24}>
    <CustomRow className='basic-card'>
      <Col span={24}>
        <center>
          If you need to screen a patient without a scheduled appointment you can begin the screening process using the
          "Begin Examination" button below.
        </center>
      </Col>
      <Col span={24}>
        <center>
          <CustomButton
            type='success'
            text='Begin Examination'
            icon={faBookMedical}
            onClick={handleExaminationModalOpen}
          />
        </center>
      </Col>
    </CustomRow>
  </Col>
)

// Extracted to a separate component for better readability and reusability
const TabPaneSection = ({
  isNewRecord,
  type,
  dataId,
  activeRecords,
  dataIndex,
  viewType,
  closeTab,
  entities,
  setViewType,
  services,
  servicesLoading
}) => (
  <>
    <SystemUsersGerneral
      closeTab={closeTab}
      entities={entities}
      type={type}
      isNewRecord={isNewRecord}
      dataId={dataId}
      activeRecords={activeRecords}
      dataIndex={dataIndex}
      viewType={viewType}
      setViewType={setViewType}
      services={services}
      servicesLoading={servicesLoading}
    />
  </>
)

const SystemUsersForm = React.memo(({ dataId, isNewRecord, refreshView, type, handleRootTabKey, entities }) => {
  const {
    beginAdhocScreening,
    closeTab,
    setExaminationModalOpen,
    activeRecords,
    authState,
    dataIndex,
    examinationModalOpen,
    facet,
    viewType,
    services,
    servicesLoading,
    setViewType
  } = useSystemUsersForm({ type, isNewRecord, dataId, refreshView, handleRootTabKey })

  const handleExaminationModalOpen = useCallback(() => {
    setExaminationModalOpen(true)
  }, [setExaminationModalOpen])

  return (
    <>
      {!facet && (
        <ContainerCard
          customBackComponent={
            <Col span={6}>
              <Button style={{ marginLeft: 4 }} danger onClick={closeTab}>
                {isNewRecord ? 'Cancel' : `Close ${viewType} File`}
              </Button>
            </Col>
          }
          title={isNewRecord ? `Invite ${viewType}` : `Viewing ${viewType}`}
        >
          <CustomRow>
            {authState.agiliteUser.extraData.role.type === 'medical_professional' && (
              <ExaminationSection handleExaminationModalOpen={handleExaminationModalOpen} />
            )}

            <Col span={24}>
              <TabPaneSection
                isNewRecord={isNewRecord}
                type={type}
                dataId={dataId}
                activeRecords={activeRecords}
                dataIndex={dataIndex}
                viewType={viewType}
                closeTab={closeTab}
                entities={entities}
                setViewType={setViewType}
                services={services}
                servicesLoading={servicesLoading}
                authState={authState}
              />
            </Col>
          </CustomRow>
        </ContainerCard>
      )}
      <BeginExaminationModal
        examinationModalOpen={examinationModalOpen}
        setExaminationModalOpen={setExaminationModalOpen}
        beginAdhocExamination={beginAdhocScreening}
      />
    </>
  )
})

export default SystemUsersForm
