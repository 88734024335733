import React from 'react'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCog } from '@fortawesome/free-solid-svg-icons'
import '../../styling/Header.css'

const Header = ({ onBack, title, titleIcon, titleIconColor, onAdminClick, showAdmin, fontSize }) => {
  return (
    <div className="cs-header">
      <Button 
        type="text" 
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
        onClick={onBack}
        className="cs-header__back-btn"
      >
      
      </Button>
      <div className="cs-header__title-container">
        <h1 className="cs-header__title" style={{ fontSize: fontSize || '24px' }}>
          {titleIcon && (
            <FontAwesomeIcon 
              icon={titleIcon} 
              className="cs-header__title-icon"
              style={{ 
                fontSize: fontSize ? `${fontSize - 4}px` : '20px',
                color: titleIconColor
              }}
            />
          )}
          {title}
        </h1>
      </div>
      {showAdmin && (
        <Button
          type="text"
          icon={<FontAwesomeIcon icon={faCog} />}
          onClick={onAdminClick}
          className="cs-header__admin-btn"
        />
      )}
    </div>
  )
}

export default Header 