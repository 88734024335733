import React, { useState, useEffect } from 'react'
import { dashboardData } from '../../../mockdata/dashboardData'
import { patientHealthData } from '../../../mockdata/diagnosticTestsData'
import PromotionalBanner from './components/PromotionalBanner'
import PendingBloodwork from './components/PendingBloodwork'
import QuickActions from './components/QuickActions'
import RecentResults from './components/RecentResults'
import TestPromo from './components/TestPromo'
import AiCareSyncSuggest from './components/AiCareSyncSuggest'
import '../../styling/CareSyncOverview.css'
import { Button, Modal, Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRocket, faLightbulb, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { assistantInitiate } from '../../AI CareSync/CareSync Suggest AI/utils'
import { generateAIResponse } from '../../AI CareSync/CareSync Suggest AI/assistants-lib'
// import WebRTCAssistant from '../../../../../P AI Voice Assistant/lib/components/WebRTCAssistant'
import { App as AntApp } from 'antd'
import InitialModal from '../../AI CareSync/CareSync Suggest AI/InitialModal'
import TestRecommendationModal from '../../AI CareSync/CareSync Suggest AI/TestRecommendationModal'
import RecommendedTests from './components/RecommendedTests'
import HealthWarnings from './components/HealthWarnings'
import WelcomeModal from '../WelcomeModal'
import ComingSoonBanner from './components/ComingSoonBanner'
import Lottie from 'react-lottie'
import comingSoonAnimation from '../../../../../../assets/Animations/Coming Soon.json'
import ResultDetail from '../Results/ResultDetail/ResultDetail'

const { Text } = Typography

const CareSyncContent = ({ onTabChange, showBookingSuccess, setActiveTab }) => {
  const { recentResults } = dashboardData
  const user = useSelector((state) => state.auth.agiliteUser)
  const firstName = user?.firstName || 'there'
  const [showInitialModal, setShowInitialModal] = useState(false)
  const [showRecommendationModal, setShowRecommendationModal] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [currentWarning, setCurrentWarning] = useState(null)
  const [healthWarnings, setHealthWarnings] = useState([])
  const [recommendation, setRecommendation] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [recommendedTests, setRecommendedTests] = useState([])
  const [showWelcomeTour, setShowWelcomeTour] = useState(true)

  useEffect(() => {
    const isFirstVisit = localStorage.getItem('caresync_tour_completed') !== 'true'

    if (isFirstVisit) {
      setShowWelcomeTour(true)
    }
  }, [])

  // Load warnings from localStorage on mount
  useEffect(() => {
    const savedWarnings = localStorage.getItem('healthWarnings')
    if (savedWarnings) {
      setHealthWarnings(JSON.parse(savedWarnings))
    }
  }, [])

  // Save warnings when they change
  useEffect(() => {
    if (healthWarnings.length > 0) {
      localStorage.setItem('healthWarnings', JSON.stringify(healthWarnings))
    }
  }, [healthWarnings])

  const addWarning = (warningText) => {
    const newWarning = {
      text: warningText,
      timestamp: Date.now()
    }
    setHealthWarnings((prev) => [...prev, newWarning])
    setCurrentWarning(newWarning)
    setShowWarningModal(true)
  }

  const addNewRecommendation = (test) => {
    // Check if test with same name already exists
    const exists = recommendedTests.some(
      (existingTest) => existingTest.testName.toLowerCase() === test.name.toLowerCase()
    )

    if (!exists) {
      setRecommendedTests((prevTests) => [
        ...prevTests,
        {
          id: Date.now(),
          testName: test.name,
          reason: test.reason,
          priority: 'high',
          dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
          preparation: 'Fasting may be required'
        }
      ])
    }
  }

  const handleAIAnalysis = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const threadData = await assistantInitiate()

      const response = await generateAIResponse(threadData)

      if (response?.data?.content) {
        try {
          let contentString = response.data.content

          // Extract the value from the content object
          if (typeof contentString === 'object' && contentString.value) {
            contentString = contentString.value
          }

          // Clean up any remaining markdown or whitespace
          contentString = contentString
            .replace(/```json\n?/, '')
            .replace(/\n?```/, '')
            .trim()

          // Only try to parse if we have content
          if (!contentString) {
            throw new Error('Empty response from AI')
          }

          let parsedData = null

          // Parse the outer JSON if needed
          if (contentString.includes('"test"')) {
            parsedData = JSON.parse(contentString)

            if (parsedData.test) {
              setRecommendation(parsedData.test)
              addNewRecommendation(parsedData.test)
              setShowInitialModal(false)
              setShowRecommendationModal(true)
            }
          } else {
            // Handle double-wrapped JSON
            const parsedOuter = JSON.parse(contentString)
            if (parsedOuter.value) {
              parsedData = JSON.parse(parsedOuter.value)

              if (parsedData.test) {
                setRecommendation(parsedData.test)
                addNewRecommendation(parsedData.test)
                setShowInitialModal(false)
                setShowRecommendationModal(true)
              }
            }
          }

          // Add warning detection after parsing
          if (parsedData && (parsedData.warning || parsedData.test?.warning)) {
            const warningText = parsedData.warning || parsedData.test.warning
            addWarning(warningText)
          }
        } catch (parseError) {
          console.error('Error parsing AI response:', parseError)
          setError(`Unable to parse AI recommendations: ${parseError.message}`)
        }
      } else {
        console.error('Response structure:', response)
        throw new Error('No content in AI response')
      }
    } catch (error) {
      console.error('AI Analysis error:', error)
      setError(error.message || 'Failed to get AI recommendations')
    } finally {
      setIsLoading(false)
    }
  }

  const handleActionClick = (result) => {
    setSelectedResult(result)
  }

  // Optionally: Save recommendations to localStorage to persist across page reloads
  useEffect(() => {
    // Load saved recommendations on mount
    const savedTests = localStorage.getItem('recommendedTests')
    if (savedTests) {
      setRecommendedTests(JSON.parse(savedTests))
    }
  }, [])

  useEffect(() => {
    // Save recommendations whenever they change
    if (recommendedTests.length > 0) {
      localStorage.setItem('recommendedTests', JSON.stringify(recommendedTests))
    }
  }, [recommendedTests])

  const handleTourComplete = () => {
    localStorage.setItem('caresync_tour_completed', 'true')
    setShowWelcomeTour(false)
  }

  const comingSoonOptions = {
    loop: true,
    autoplay: true,
    animationData: comingSoonAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  const [selectedResult, setSelectedResult] = useState(null)
  return (
    <>
      {selectedResult ? (
        <ResultDetail
          result={selectedResult}
          onBack={() => {
            setSelectedResult(null)
          }}
        />
      ) : (
        <div className='cs-overview'>
          {/* Promotional Banner - Temporarily disabled */}
          {/* <section className='cs-overview__section'>
      <PromotionalBanner />
    </section> */}

          {/* Test Promotions */}
          <section className='cs-overview__section'>
            <TestPromo
              onViewAll={() => {
                setActiveTab('tests')
              }}
              onBookTest={(test) => handleActionClick('book', test)}
            />
          </section>

          {/* New Status Center */}
          {/* <section className='cs-overview__section'>
      <PendingBloodwork />
    </section> */}

          {/* Recent Results */}
          <section className='cs-overview__section'>
            <RecentResults results={recentResults} onViewDetails={handleActionClick} />
          </section>

          {/* Recommended Tests */}
          {/* <section className='cs-overview__section'>
      <RecommendedTests
        tests={recommendedTests}
        onSchedule={(test) =>
          handleActionClick('book', {
            id: test.id,
            name: test.testName,
            description: test.reason,
            preparation: test.preparation
          })
        }
      />
    </section> */}

          {/* Coming Soon Animation */}
          <section className='cs-overview__section'>
            <Lottie
              options={comingSoonOptions}
              height={160}
              width={300}
              style={{ margin: '0 auto', maxWidth: '100%' }}
            />
            <Text
              type='secondary'
              style={{
                fontSize: '13px',
                textAlign: 'center',
                display: 'block',
                marginTop: '16px',
                color: 'rgba(28, 37, 54, 0.65)',
                maxWidth: '280px',
                margin: '16px auto 0'
              }}
            >
              More features coming soon to enhance your healthcare experience
            </Text>
          </section>

          {/* Add Warnings Section */}
          <section className='cs-overview__section'>
            <HealthWarnings warnings={healthWarnings} />
          </section>

          {/* Quick Actions and Suggest AI Buttons */}
          <section className='cs-overview__section cs-overview__bottom-actions'>
            <Button type='primary' className='cs-overview__action-btn cs-overview__quick-actions-btn' disabled>
              Quick Actions
            </Button>

            <Button type='primary' className='cs-overview__action-btn cs-overview__ai-suggest-btn' disabled>
              Suggest AI
            </Button>
          </section>

          <InitialModal
            isVisible={showInitialModal}
            onClose={() => {
              setShowInitialModal(false)
              setError(null)
            }}
            onProceed={handleAIAnalysis}
            isLoading={isLoading}
            error={error}
          />

          <TestRecommendationModal
            isVisible={showRecommendationModal}
            onClose={() => setShowRecommendationModal(false)}
            recommendation={recommendation}
          />

          {/* Add Warning Modal */}
          <Modal
            title={
              <span style={{ color: '#ff4d4f' }}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{ marginRight: 8 }} />
                Health Warning
              </span>
            }
            open={showWarningModal}
            onOk={() => setShowWarningModal(false)}
            onCancel={() => setShowWarningModal(false)}
            okText='Got it'
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <Text strong style={{ fontSize: '16px' }}>
              {currentWarning?.text}
            </Text>
          </Modal>

          {/* <WelcomeModal isVisible={showWelcomeTour} onClose={handleTourComplete} firstName={firstName} /> */}
        </div>
      )}
    </>
  )
}

// Create a wrapper component that provides the App context
const CareSyncOverview = (props) => {
  return (
    <AntApp>
      <CareSyncContent {...props} />
    </AntApp>
  )
}

export default CareSyncOverview
