import React from 'react'
import { Typography, Radio } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import '../../../styling/TimeSlotPicker.css'

const { Text } = Typography

const TimeSlotPicker = ({ onSelect }) => {
  const timeSlots = [
    {
      date: '2024-03-20',
      slots: ['9:00 AM', '10:30 AM', '2:00 PM']
    },
    {
      date: '2024-03-21',
      slots: ['8:30 AM', '11:00 AM', '3:30 PM']
    },
    {
      date: '2024-03-22',
      slots: ['9:30 AM', '1:00 PM', '4:00 PM']
    }
  ]

  return (
    <div className="cs-time-picker">
      {timeSlots.map(day => (
        <div key={day.date} className="cs-time-picker__day">
          <div className="cs-time-picker__date">
            <Text className="cs-time-picker__date-text">
              {new Date(day.date).toLocaleDateString('en-US', {
                weekday: 'short',
                month: 'short',
                day: 'numeric'
              })}
            </Text>
          </div>
          <div className="cs-time-picker__slots">
            <Radio.Group 
              className="cs-time-picker__radio-group"
              onChange={e => onSelect({ date: day.date, time: e.target.value })}
            >
              {day.slots.map(time => (
                <Radio 
                  key={time} 
                  value={time} 
                  className="cs-time-picker__radio"
                >
                  <div className="cs-time-picker__slot">
                    <FontAwesomeIcon icon={faClock} />
                    <Text className="cs-time-picker__time">{time}</Text>
                  </div>
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TimeSlotPicker 