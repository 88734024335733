import React from 'react'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faImage } from '@fortawesome/free-solid-svg-icons'
import '../style/MobileCameraCapture.css'
import useMobileCameraCapture from '../hooks/useMobileCameraCapture'
import PhotoTips from './PhotoTips'

const MobileCameraCapture = ({ onAfterCapture }) => {
  const { handleFileChange, fileInputRef, handleCameraClick } = useMobileCameraCapture({ onAfterCapture })

  return (
    <div className='mobile-camera-capture'>
      <PhotoTips />
      <input
        type='file'
        accept='image/*'
        capture='environment'
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      
      <Button 
        type='primary' 
        onClick={handleCameraClick} 
        className='action-button'
      >
        <FontAwesomeIcon icon={faCamera} /> From Camera
      </Button>

      <input
        type='file'
        accept='image/*'
        onChange={handleFileChange}
        id="gallery-upload"
        style={{ display: 'none' }}
      />
      
      <Button 
        type='primary' 
        onClick={() => document.getElementById('gallery-upload').click()}
        className='action-button'
      >
        <FontAwesomeIcon icon={faImage} /> From Gallery
      </Button>
    </div>
  )
}

export default MobileCameraCapture
