export const CATEGORIES = {
  TEMPLATES: 'templates',
  PREVIEW: 'preview', // Template selection category
  VITALS: 'vitals', // Vital signs measurements
  GENERAL: 'general', // General examination findings
  RAPID: 'rapid', // Rapid diagnostic tests
  POC: 'poc' // Point of Care tests
}

export const CATEGORY_COLORS = {
  [CATEGORIES.PREVIEW]: '#4A90E2',
  [CATEGORIES.TEMPLATES]: '#4A90E2', // Blue for templates
  [CATEGORIES.VITALS]: '#F5222D', // Red for vital signs
  [CATEGORIES.GENERAL]: '#52C41A', // Green for general
  [CATEGORIES.RAPID]: '#722ED1', // Purple for rapid tests
  [CATEGORIES.POC]: '#FA8C16' // Orange for point of care
}
