import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, Row, Select, Space, Switch, message, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClose, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { readClinicDataPoints } from '../Data Points/data-points-utils/data-point-utils'
import { generateFormItem, handleError, hexToRGBA } from '../../lib/utils'
import { createClinicalReportTemplate, updateClinicalReportTemplate } from './utils/clinical-reporting-utils'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import CustomRow from '../../reusable-components/CustomRow'

const ClinicalReportingTemplatesForm = ({ isNew, record, handleCloseForm }) => {
  const [loading, setLoading] = useState(false)
  const [dataPointsLoading, setDataPointsLoading] = useState(false)
  const [dataPoints, setDataPoints] = useState([])
  const [hiddenKeys, setHiddenKeys] = useState(record.hiddenKeys || [])
  const [previewData, setPreviewData] = useState({
    reportSpecific: [],
    reference: []
  })
  const { token } = theme.useToken()

  useEffect(() => {
    handleGetDataPoints()
  }, [])

  useEffect(() => {
    handleSetPreviewData()
    // eslint-disable-next-line
  }, [dataPoints])

  const handleSetPreviewData = (array, isReference) => {
    let tmpPreviewData = {
      reportSpecific: isReference ? [...previewData?.reportSpecific] : [],
      reference: isReference ? [] : [...previewData?.reference]
    }
    if (array) {
      array.forEach((item) => {
        const target = dataPoints.find((dataPoint) => dataPoint.key === item)
        if (isReference) {
          tmpPreviewData.reference.push(target)
        } else {
          tmpPreviewData.reportSpecific.push(target)
        }
      })
    } else {
      if (!dataPointsLoading) {
        record.keys.forEach((item) => {
          const target = dataPoints.find((dataPoint) => dataPoint.key === item)
          if (target) {
            tmpPreviewData.reportSpecific.push(target)
          }
        })
        record?.references?.forEach((item) => {
          const target = dataPoints.find((dataPoint) => dataPoint.key === item)
          if (target) {
            tmpPreviewData.reference.push(target)
          }
        })
      }
    }
    setPreviewData(tmpPreviewData)
  }

  const handleGetDataPoints = async () => {
    let tmpData = null

    setDataPointsLoading(true)
    try {
      tmpData = await readClinicDataPoints()
      setDataPoints(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setDataPointsLoading(false)
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      if (isNew) {
        await createClinicalReportTemplate({ ...form.getFieldsValue(), hiddenKeys })
      } else {
        await updateClinicalReportTemplate(record._id, { ...record, ...form.getFieldsValue(), hiddenKeys })
      }

      handleCloseForm(isNew, record)
      form.resetFields()
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const [form] = Form.useForm()

  const isHidden = (key) => {
    return hiddenKeys.includes(key)
  }

  const handleHiddenKeys = (key) => {
    let tmpHiddenKeys = JSON.parse(JSON.stringify(hiddenKeys))
    const hiddenKeyIndex = tmpHiddenKeys.findIndex((i) => i === key)

    if (hiddenKeyIndex === -1) {
      tmpHiddenKeys.push(key)
    } else {
      tmpHiddenKeys.splice(hiddenKeyIndex, 1)
    }
    setHiddenKeys(tmpHiddenKeys)
  }
  return (
    <ContainerCard title={isNew ? 'New Data Point Template' : `Data Point Template: ${record.name}`}>
      <CustomRow justify='center'>
        <Col span={24}>
          <Card>
            <Row>
              <Col span={24}>
                <Form layout='vertical' initialValues={record} form={form} onFinish={() => handleSave()}>
                  <Form.Item label='Is Active' name='isActive' valuePropName='checked'>
                    <Switch checkedChildren='Yes' unCheckedChildren='No' />
                  </Form.Item>
                  <Form.Item
                    label='Template Name'
                    name='name'
                    rules={[{ required: true, message: 'Please provide a name' }]}
                  >
                    <Input placeholder='Template Name' />
                  </Form.Item>
                  <Form.Item
                    label='Data Points'
                    name='keys'
                    rules={[{ required: true, message: 'Please select at least 1 Data Point' }]}
                  >
                    <Select
                      loading={dataPointsLoading}
                      placeholder='- Select Data Points -'
                      onChange={(array) => {
                        handleSetPreviewData(array)
                      }}
                      options={dataPoints.map((i) => {
                        return { label: i.label, value: i.key }
                      })}
                      mode='multiple'
                    />
                  </Form.Item>
                  <Form.Item
                    label='PDF Generation ID'
                    name='pdfGenerationId'
                    rules={[{ required: true, message: 'Please select at least 1 Data Point' }]}
                  >
                    <Input placeholder='PDF ID' />
                  </Form.Item>
                  {/* <Form.Item label='Data References' name='references'>
                    <Select
                      loading={dataPointsLoading}
                      placeholder='- Select Data Points -'
                      onChange={(array) => {
                        handleSetPreviewData(array, true)
                      }}
                      options={dataPoints.map((i) => {
                        return { label: i.label, value: i.key }
                      })}
                      mode='multiple'
                    />
                  </Form.Item> */}
                </Form>
              </Col>
            </Row>
            <Row justify='space-around'>
              <Col>
                <Button onClick={() => form.submit()} type='primary' disabled={loading} loading={loading}>
                  <Space>
                    <FontAwesomeIcon icon={faCheck} />
                    Save
                  </Space>
                </Button>
              </Col>
              <Col>
                <Button onClick={() => handleCloseForm(isNew, record)} danger disabled={loading}>
                  <Space>
                    <FontAwesomeIcon icon={faClose} color={token.colorError} />
                    Close
                  </Space>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card
            title={<center>REPORT PREVIEW</center>}
            size='small'
            headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
          >
            <CustomRow>
              <Col span={24}>
                <CustomRow wrap gutter={[8, 8]}>
                  {previewData?.reportSpecific.map((selection) => {
                    if (selection?.subFields?.length > 0) {
                      return (
                        <Col span={12}>
                          <Card title={selection.label} size='small' type='inner'>
                            {selection?.subFields
                              ?.sort((a, b) => (isHidden(a.key) === isHidden(b.key) ? 0 : isHidden(a.key) ? 1 : -1))
                              .map((subfield) => {
                                return (
                                  <Card size='small' style={{ width: '100%', marginBottom: 12 }}>
                                    <div style={{ position: 'absolute', right: 7.5, top: 7.5, zIndex: 2 }}>
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          handleHiddenKeys(subfield.key)
                                        }}
                                        icon={isHidden(subfield.key) ? faEyeSlash : faEye}
                                      />
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                      {isHidden(subfield.key) ? (
                                        <div
                                          style={{
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            zIndex: 1,
                                            height: '100%',
                                            width: '100%',
                                            background: hexToRGBA('#ffffff', 0.8)
                                          }}
                                        >
                                          HIDDEN
                                        </div>
                                      ) : undefined}
                                      {generateFormItem({
                                        ...subfield,
                                        key: [selection.key, subfield.key],
                                        formProps: { style: { marginBottom: 0 } }
                                      })}
                                    </div>
                                  </Card>
                                )
                              })}
                          </Card>
                        </Col>
                      )
                    } else {
                      return (
                        <Col span={12}>
                          <Card size='small' style={{ width: '100%' }}>
                            {generateFormItem({ ...selection, formProps: { style: { marginBottom: 0 } } })}
                          </Card>
                        </Col>
                      )
                    }
                  })}
                </CustomRow>
              </Col>
              {/* <Col span={8}>
                <Card title='Data References:'>
                  {previewData?.reference.map((entry) => {
                    return (
                      <>
                        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                          <p>{entry?.label}</p>{' '}
                          <p>
                            {entry?.delimiter ? `X${entry?.delimiter}X` : 'X'} {entry?.suffix}
                          </p>
                        </Space>
                        <Divider style={{ margin: '8px 0' }} />
                      </>
                    )
                  })}
                </Card>
              </Col> */}
            </CustomRow>
          </Card>
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default ClinicalReportingTemplatesForm
