import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ListView from './ClinicsList'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import useClinics from '../hooks/useClinics'

const ClinicsWrapper = () => {
  const { loading, viewData, createRecord, editRecord, refreshView, handleSearch } = useClinics()

  return (
    <ContainerCard title='Clinics'>
      <ListView
        loading={loading}
        data={viewData}
        createRecord={createRecord}
        editRecord={editRecord}
        refreshView={refreshView}
        handleSearch={handleSearch}
      />
    </ContainerCard>
  )
}

ClinicsWrapper.propTypes = {
  loading: PropTypes.bool,
  viewData: PropTypes.array,
  createRecord: PropTypes.func,
  editRecord: PropTypes.func,
  refreshView: PropTypes.func,
  handleSearch: PropTypes.func
}

export default memo(ClinicsWrapper)
