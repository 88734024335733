import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPills } from '@fortawesome/free-solid-svg-icons'
import '../Styling/MobileChronicMedications.css'
import usePatChronicMeds from '../../hooks/usePatChronicMeds'

const MobileChronicMedicationsCarousel = () => {
  const { activeIndex, chronicMeds, listRef, handleScroll } = usePatChronicMeds()

  return (
    <div>
      <div className='chronic-meds-container'>
        <div className='mobile-chronic-meds'>
          <div className='chronic-meds-list' ref={listRef} onScroll={handleScroll}>
            {chronicMeds.map((med, index) => (
              <div key={index} className={`med-card ${index === activeIndex ? 'active' : ''}`}>
                <div style={{ color: 'white' }}>
                  <FontAwesomeIcon icon={faPills} />
                </div>
                <div className='med-info'>
                  <div className='med-header'>
                    <h3>{med.med.name}</h3>
                    <span className='dosage'>{`${med.med.strengthMetric1} ${med.med.unitOfMeasure1}`}</span>
                  </div>
                  <p className='frequency'>{med.med.scriptingDosage || 'As prescribed'}</p>
                  <div className='refill-info' style={{ marginTop: -10 }}>
                    <div className='days-left'>
                      <div className='label'>Standard Pack Size:</div>
                      <span className='value'>{med.med.standardPacksize}</span>
                    </div>
                    <button className='request-btn'>Request Refill</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileChronicMedicationsCarousel
