import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  message,
  theme
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons'
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import { generateStatus, handleCancelBooking, updateAllBookingsState } from '../../../lib/booking-utils'
import { updateBooking } from '../../utils/utils'
import templates from '../../../Admin/bookings/utils/templates'
import { handleError } from '../../../lib/utils'
import PatientExaminationForm from '../ConsultationWrapper'
import CoreEnums from '../../../../core/utils/enums'
import coreReducer from '../../../../core/utils/reducer'
import { executeBooking, getBookingState } from '../../utils/bpm-utils'
import { countBookings, readAllBookings } from '../../../Admin/bookings/utils/utils'
import { readSystemUsers } from '../../../Admin/system-users/utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import CustomRow from '../../../reusable-components/CustomRow'
import EmployeeBookingsReducer from '../../utils/employee-bookings-reducer'
import ReassignmentModal from './re-assignment-modal'
import { readMedicalProfessionalUsers } from '../../../Admin/medical-professionals/utils/utils'
import { readPaymentMethods } from '../../../Admin/entities/utils/utils'
import TeamChatForm from '../../../TeamChat/components/team-chat-form'
import CustomLoadingIcon from '../../../reusable-components/CustomLoadingIcon'
import BillingReducer from '../../utils/billing-reducer'

const EmployeeBookingsView = ({ userRef, isBilling, viewingPatientProfile }) => {
  const state = useSelector((state) => state)
  const authState = useSelector((state) => state.auth)
  const billingState = useSelector((state) => state.billing.data)
  const employeeBookingsState = useSelector((state) => state.employeeBookings.data)
  const dispatch = useDispatch()
  const [paymentMethodChange, setPaymentMethodChange] = useState(false)
  const [currentRecord, setCurrentRecord] = useState(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [total, setTotal] = useState(0)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [rangeFilter, setRangeFilter] = useState([dayjs(), dayjs()])
  const [reAssignment, setReAssignment] = useState({ record: '', profession: '', professionals: [], open: false })
  const [services, setServices] = useState([])
  const [teamChatProps, setTeamChatProps] = useState({ isOpen: false, userRef: '' })
  const [filteredValues, setFilteredValues] = useState({})
  const [showFilters, setShowFilters] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [additionalFilters, seetAdditionalFilters] = useState({
    status: [
      CoreEnums.bookingStatuses.checkin,
      CoreEnums.bookingStatuses.screening,
      CoreEnums.bookingStatuses.procedure,
      CoreEnums.bookingStatuses.diagnosis,
      CoreEnums.bookingStatuses.billing,
      CoreEnums.bookingStatuses.data_capture
    ],
    type: ['virtual', 'standard'],
    service: [],
    hideCompleted: false,
    hideCancelled: true
  })
  const [currentUserData, setCurrentUserData] = useState([])

  const statusesOptions = [
    CoreEnums.bookingStatuses.checkin,
    CoreEnums.bookingStatuses.screening,
    CoreEnums.bookingStatuses.procedure,
    CoreEnums.bookingStatuses.diagnosis,
    CoreEnums.bookingStatuses.billing,
    CoreEnums.bookingStatuses.data_capture
  ]

  const { RangePicker } = DatePicker
  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().add(-7, 'd'), dayjs()]
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().add(-14, 'd'), dayjs()]
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().add(-30, 'd'), dayjs()]
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().add(-90, 'd'), dayjs()]
    }
  ]

  useEffect(() => {
    handleGetAllBookings()
    // eslint-disable-next-line
  }, [page, pageSize, filteredValues])

  useEffect(() => {
    setServices([...state.services.data, ...state.virtualServices.data])
    handleGetAllBookings()

    // eslint-disable-next-line
  }, [searchQuery, rangeFilter, state.bookings.data, state.bookings.dashboardData, additionalFilters])

  const handleGetAllBookings = async () => {
    let tmpBookings = []
    let tmpUsers = []
    let tmpUserIds = []
    let qry = {}
    let userQuery = null
    let tmpTotal = 0
    let tmpFilteredValues = JSON.parse(JSON.stringify(filteredValues))

    setLoading(true)

    try {
      if (searchQuery) {
        const searchTerms = searchQuery.trim().toLowerCase().split(/\s+/)

        // Prepare regex patterns for partial matching
        const regexPatterns = searchTerms.map((term) => new RegExp(term, 'i'))
        userQuery = {
          $or: [
            {
              $expr: {
                $regexMatch: {
                  input: {
                    $concat: [{ $toLower: '$firstName' }, ' ', { $toLower: '$lastName' }]
                  },
                  regex: searchTerms.map((term) => `.*${term}.*`).join(''),
                  options: 'i'
                }
              }
            },
            // Match individually against firstName and lastName with partial regexes
            {
              $or: [
                {
                  $expr: {
                    $regexMatch: {
                      input: { $toLower: '$firstName' },
                      regex: regexPatterns.join('|'),
                      options: 'i'
                    }
                  }
                },
                {
                  $expr: {
                    $regexMatch: {
                      input: { $toLower: '$lastName' },
                      regex: regexPatterns.join('|'),
                      options: 'i'
                    }
                  }
                }
              ]
            },
            { phoneNumber: { $regex: searchQuery, $options: 'i' } },
            { email: { $regex: searchQuery, $options: 'i' } },
            { physicalAddress: { $regex: searchQuery, $options: 'i' } },
            { idNo: { $regex: searchQuery, $options: 'i' } }
          ]
        }

        tmpUsers = await readSystemUsers(userQuery, null, null, '_id', true)

        for (const user of tmpUsers) {
          tmpUserIds.push(user._id)
        }
      }

      if (rangeFilter[0] && rangeFilter[1]) {
        qry.bookingDate = {
          $gt: dayjs(rangeFilter[0]).format('YYYY-MM-DDT00:00:00.000Z'),
          $lt: dayjs(rangeFilter[1]).add(1, 'day').format('YYYY-MM-DDT00:00:00.000Z')
        }
      }

      if (!viewingPatientProfile) {
        // Med Profs Roles
        if (authState.agiliteUser?.extraData.role.type === 'medical_professional') {
          // Doctor
          // All Bookings where they are the doctor
          if (authState.agiliteUser?.extraData.profession === 'doctor') {
            qry.medicalProfRef = authState.agiliteUser._id
          }
          // Nurse
          // All Bookings at their clinic
          if (authState.agiliteUser?.extraData.profession === 'nurse') {
            qry.clinicRef = { $in: authState.agiliteUser.extraData.clinics }
          }
        }

        // Reception Roles
        // All bookings at their clinic
        if (authState.agiliteUser?.extraData.role.type === 'reception') {
          qry.clinicRef = { $in: authState.agiliteUser.extraData.clinics }
        }
      }

      // Finalize Query for bookings based on user ids
      if (tmpUserIds.length > 0) {
        qry.$or = [{ userRef: { $in: tmpUserIds } }, { medicalProfRef: { $in: tmpUserIds } }]
      }

      // Table Filters
      for (const prop in tmpFilteredValues) {
        if (tmpFilteredValues[prop] && tmpFilteredValues[prop].length > 0) {
          const noShowIndex = tmpFilteredValues[prop].indexOf('No Show')

          if (noShowIndex > -1) {
            qry.wasNoShow = true
            tmpFilteredValues[prop].splice(noShowIndex, 1)
          }
        }

        if (tmpFilteredValues[prop] && tmpFilteredValues[prop].length > 0) {
          qry[prop] = { $in: tmpFilteredValues[prop] }
        }
      }

      // Additional Filters
      if (additionalFilters?.status?.length > 0) {
        let finalStatuses = [...additionalFilters.status]
        if (!additionalFilters.hideCancelled) {
          finalStatuses.push(CoreEnums.bookingStatuses.cancelled)
        }
        if (!additionalFilters.hideCompleted) {
          finalStatuses.push(CoreEnums.bookingStatuses.completed)
        }
        qry.status = { $in: finalStatuses }
      } else {
        let finalStatuses = [...statusesOptions]
        if (!additionalFilters.hideCancelled) {
          finalStatuses.push(CoreEnums.bookingStatuses.cancelled)
        }
        if (!additionalFilters.hideCompleted) {
          finalStatuses.push(CoreEnums.bookingStatuses.completed)
        }
        qry.status = { $in: finalStatuses }
      }

      if (additionalFilters?.type?.length > 0) {
        if (!additionalFilters.type.includes('standard')) {
          qry.isVirtual = true
        }
      }
      if (additionalFilters?.service?.length > 0) {
        qry.service = { $in: additionalFilters.service }
      }
      if (userRef) {
        qry.userRef = userRef
      }
      if (isBilling) {
        qry.status = CoreEnums.bookingStatuses.billing
      }
      tmpTotal = await countBookings(qry)
      tmpBookings = await readAllBookings(qry, page, pageSize, {
        sort: {
          bookingDate: -1,
          startTime: 1
        }
      })

      // Payment Methods
      const tmpPaymentMethods = await readPaymentMethods()
      setPaymentMethods(tmpPaymentMethods)

      // Service
      for (const entry of tmpBookings) {
        if (entry.isVirtualVisit) {
          entry.serviceRecord = state.virtualServices.data.find((service) => service._id === entry.service)
        } else {
          entry.serviceRecord = state.services.data.find((service) => service._id === entry.service)
        }
      }
      if (userRef) {
        setCurrentUserData(tmpBookings)
      } else {
        if (isBilling) {
          dispatch(BillingReducer.actions.setRecords(tmpBookings))
        } else {
          dispatch(EmployeeBookingsReducer.actions.setRecords(tmpBookings))
        }
      }
      setTotal(tmpTotal)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleSearch = (value) => {
    setLoading(true)
    debouncedFilter(value)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      if (query) {
        setPage(1)
        setSearchQuery(query)
      } else {
        if (searchQuery) {
          setSearchQuery('')
        } else {
          setSearchQuery('')
          handleGetAllBookings()
        }
      }
    }, 1000),
    []
  )

  const handleOpenBooking = async (record, skipScreening) => {
    let mainMemberProfile = null
    let workflowHistory = {}
    let processRecord = null
    let newRecord = null

    setLoading(true)

    try {
      if (skipScreening) {
        workflowHistory = await getBookingState([record.processRef])
        processRecord = await executeBooking(
          record.clinicRecord.bpmKey,
          record.processRef,
          'submit',
          `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`,
          workflowHistory.key
        )
        newRecord = await updateBooking({ status: processRecord.processStage }, { _id: record._id })
        workflowHistory = processRecord
        workflowHistory.history.splice(0, 1)
        updateAllBookingsState(newRecord)
      } else {
        if (record.processRef) {
          workflowHistory = await getBookingState([record.processRef])
          workflowHistory.history.splice(0, 1)
        }
      }
      if (record.patientRecord.mainMemberId) {
        mainMemberProfile = await readSystemUsers({ _id: record.patientRecord.mainMemberId })
      }
      dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${record._id}`,
          closable: true,
          label: `Booking: ${record.patientName}`,
          children: (
            <PatientExaminationForm
              data={{
                ...record,
                ...newRecord,
                mainMemberProfile: mainMemberProfile ? mainMemberProfile[0] : undefined
              }}
              refreshView={handleGetAllBookings}
              workflowHistory={workflowHistory}
            />
          )
        })
      )
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const { token } = theme.useToken()

  // const handleSkipScreening = (record) => {
  //   Modal.confirm({
  //     title: 'Action Required',
  //     content: 'This booking is still at screening. Would you like to bypass screening?',
  //     okButtonProps: { style: { background: token.colorSuccess, color: 'white' } },
  //     cancelButtonProps: { style: { background: token.colorPrimary, color: 'white' } },
  //     onOk: () => handleOpenBooking(record, true),
  //     onCancel: () => {
  //       handleOpenBooking(record)
  //     },
  //     okText: 'Yes, skip screening.',
  //     cancelText: 'No, just view the record.',
  //     className: token.themeControl
  //   })
  // }

  const handleReassignment = async (record, profession) => {
    let professionals = []

    setLoading(true)

    try {
      professionals = await readMedicalProfessionalUsers({
        'extraData.profession': profession === 'doctor' ? 'doctor' : 'nurse',
        'extraData.clinics': record.clinicRef
      })

      setReAssignment({ record, professionals, open: true, profession })
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleSetPaymentMethod = (record) => {
    setPaymentMethodChange(true)
    setCurrentRecord(record)
  }

  const handleChangePaymentMethod = async () => {
    setLoading(true)

    try {
      await updateBooking({ paymentMethod: selectedPaymentMethod }, { _id: currentRecord._id })
      message.success('Payment Method Changed Successfully')
      setPaymentMethodChange(false)
      setCurrentRecord(null)
      setSelectedPaymentMethod('')
      handleGetAllBookings()
    } catch (e) {
      setLoading(false)
      message.error(handleError(e, true))
    }
  }

  const [additionalFiltersForm] = Form.useForm()

  function sortByDateTime(arr, dateKey, timeKey) {
    let tmpArry = JSON.parse(JSON.stringify(arr))
    return tmpArry.sort((a, b) => {
      // Parse the dates
      const dateA = dayjs(new Date(a[dateKey])).format('YYYY MM DD')
      const dateB = dayjs(new Date(b[dateKey])).format('YYYY MM DD')

      // Compare dates
      if (dateA < dateB) return -1
      if (dateA > dateB) return 1

      // If dates are equal, compare times
      const [hoursA, minutesA] = a[timeKey].split(':').map(Number)
      const [hoursB, minutesB] = b[timeKey].split(':').map(Number)

      if (hoursA < hoursB) return -1
      if (hoursA > hoursB) return 1

      if (minutesA < minutesB) return -1
      if (minutesA > minutesB) return 1

      return 0 // Dates and times are equal
    })
  }

  return (
    <>
      <Modal
        open={paymentMethodChange}
        title='Change Payment Method'
        okText='Submit'
        cancelText='Cancel'
        onOk={() => {
          handleChangePaymentMethod()
        }}
        onCancel={() => {
          setPaymentMethodChange(false)
          setCurrentRecord(null)
          setSelectedPaymentMethod('')
        }}
        closable={false}
        maskClosable={false}
        destroyOnClose
      >
        <Row gutter={[12, 12]}>
          {paymentMethods.map((method) => {
            return (
              <Col span={24} key={method.value}>
                <Card
                  style={{
                    border: method.value === selectedPaymentMethod ? '2px solid blue' : '',
                    cursor: 'pointer',
                    boxShadow: method.value === selectedPaymentMethod ? '0 0 3px 0 black' : undefined
                  }}
                  onClick={() => setSelectedPaymentMethod(method.value)}
                  size='small'
                >
                  {method.label}
                </Card>
              </Col>
            )
          })}
        </Row>
      </Modal>
      <CustomRow className='basic-card'>
        <Col span={24}>
          <Space wrap style={{ width: '100%' }}>
            <Input
              style={{ width: 400 }}
              placeholder='Search bookings...'
              suffix={<FontAwesomeIcon icon={faSearch} />}
              onChange={(e) => handleSearch(e.target.value)}
              allowClear
            />
            <Button
              disabled={loading}
              onClick={() => {
                if (
                  rangeFilter.length === 2 &&
                  dayjs(rangeFilter[0]).format('DD MMMM YYYY') === dayjs().format('DD MMMM YYYY') &&
                  dayjs(rangeFilter[0]).format('DD MMMM YYYY') === dayjs().format('DD MMMM YYYY')
                )
                  return
                setRangeFilter([dayjs(), dayjs()])
              }}
              type={
                rangeFilter.length === 2 &&
                dayjs(rangeFilter[0]).format('DD MMMM YYYY') === dayjs().format('DD MMMM YYYY') &&
                dayjs(rangeFilter[0]).format('DD MMMM YYYY') === dayjs().format('DD MMMM YYYY')
                  ? 'primary'
                  : ''
              }
            >
              Today
            </Button>
            <RangePicker
              disabled={loading}
              value={rangeFilter}
              presets={rangePresets}
              onChange={(value) => {
                if (!value) {
                  setRangeFilter([])
                } else {
                  setRangeFilter(value)
                }
              }}
              allowClear
            />
            {isBilling ? undefined : (
              <Button
                onClick={() => {
                  setShowFilters(!showFilters)
                }}
                disabled={loading}
              >
                <FontAwesomeIcon icon={faFilter} />
              </Button>
            )}
            <Button
              type='primary'
              style={{ background: token.colorSuccess }}
              onClick={() => {
                handleGetAllBookings()
              }}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faRefresh} />
            </Button>
          </Space>
        </Col>

        <Col span={24}>
          <CustomLoadingIcon
            loading={loading}
            content={
              <Table
                className={loading ? 'custom-loading-mask' : ''}
                size='small'
                bordered
                dataSource={
                  userRef
                    ? currentUserData
                    : isBilling
                    ? sortByDateTime(billingState, 'bookingDate', 'startTime')
                    : sortByDateTime(employeeBookingsState, 'bookingDate', 'startTime')
                }
                locale={{
                  emptyText: <Empty description='No Bookings found' />
                }}
                onChange={(pagination, filters, sorter) => {
                  setFilteredValues(filters)
                }}
                columns={templates
                  .columnTemplate(
                    token,
                    userRef ? currentUserData : isBilling ? billingState : employeeBookingsState,
                    false,
                    (record) => handleCancelBooking(authState, record, updateBooking, handleGetAllBookings, token),
                    authState,
                    filteredValues,
                    handleReassignment,
                    rangeFilter,
                    handleSetPaymentMethod,
                    services,
                    setTeamChatProps,
                    handleOpenBooking,
                    isBilling
                  )
                  .filter((record) => record.hidden !== true)}
                pagination={{
                  current: page,
                  pageSize,
                  total: total,
                  onChange: (page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  },
                  showSizeChanger: true,
                  hideOnSinglePage: true,
                  position: ['bottomCenter']
                }}
                scroll={{ x: 1000 }}
              />
            }
            loadingText='Loading appointments...'
          />
        </Col>
      </CustomRow>
      <ReassignmentModal
        reAssignment={reAssignment}
        setReAssignment={setReAssignment}
        handleRefresh={handleGetAllBookings}
      />
      <TeamChatForm modalProps={teamChatProps} setModalProps={setTeamChatProps} />
      <Modal
        onOk={() => {
          let formValues = additionalFiltersForm.getFieldsValue()
          seetAdditionalFilters(formValues)
          setShowFilters(false)
        }}
        open={showFilters}
        width={900}
        onCancel={() => setShowFilters(false)}
        destroyOnClose
      >
        <Col span={24}>
          <Form form={additionalFiltersForm} initialValues={additionalFilters}>
            <Card type='inner' title='Additional Filters' size='small'>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Space>
                    <p style={{ width: 75 }}>
                      <b>Status:</b>
                    </p>
                    <Form.Item style={{ margin: 0 }} name='status'>
                      <Select
                        mode='multiple'
                        placeholder='- Select -'
                        options={statusesOptions.map((status) => ({
                          value: status,
                          label: generateStatus(status, token, null, true)
                        }))}
                        style={{ minWidth: 250 }}
                      />
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={24}>
                  <Space>
                    <p style={{ width: 75 }}>
                      <b>Type:</b>
                    </p>
                    <Form.Item style={{ margin: 0 }} name='type'>
                      <Select
                        mode='multiple'
                        placeholder='- Select -'
                        options={[
                          { value: 'standard', label: 'Standard' },
                          { value: 'virtual', label: 'Virtual' }
                        ]}
                        style={{ minWidth: 250 }}
                      />
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={24}>
                  <Space>
                    <p style={{ width: 75 }}>
                      <b>Service:</b>
                    </p>
                    <Form.Item style={{ margin: 0 }} name='service'>
                      <Select
                        mode='multiple'
                        placeholder='- Select -'
                        options={state.services.data.map((service) => ({ value: service._id, label: service.name }))}
                        style={{ minWidth: 250 }}
                      />
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={24}>
                  <Space>
                    <p>
                      <b>Hide Completed:</b>
                    </p>
                    <Form.Item style={{ margin: 0 }} name='hideCompleted' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={24}>
                  <Space>
                    <p>
                      <b>Hide Canceled:</b>
                    </p>

                    <Form.Item style={{ margin: 0 }} name='hideCancelled' valuePropName='checked'>
                      <Checkbox value={additionalFilters.hideCancelled} />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Form>
        </Col>
      </Modal>
    </>
  )
}

export default EmployeeBookingsView
