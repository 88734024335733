import React, { useState, useMemo } from 'react'
import { Card, Typography, Input, Select, Space, Empty } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faFilter } from '@fortawesome/free-solid-svg-icons'
import ResultDetail from '../ResultDetail/ResultDetail'
import ResultListItem from './components/ResultListItem'
import { dashboardData } from '../../../../mockdata/dashboardData'
import { SearchOutlined } from '@ant-design/icons'
import '../../../styling/ResultsHistory.css'
import { useSelector } from 'react-redux'

const { Text, Title } = Typography
const { Option } = Select

const ResultsHistory = () => {
  // CARESYNC IMPORTANT HOW TO REFERENCE AVAILABLE TESTS RESULTS
  const careSyncTestResults = useSelector((state) => state.careSyncTestResults.data)
  // CARESYNC IMPORTANT HOW TO REFERENCE AVAILABLE DATA POINTS (TEMPLATES FOR TESTS)
  const careSyncDataPoints = useSelector((state) => state.careSyncDataPoints.data)
  // CARESYNC IMPORTANT HOW TO REFERENCE AVAILABLE TESTS - NOTE KEY WILL MATCH THE DATA POINT
  const careSyncTests = useSelector((state) => state.careSyncTests.data)

  const [selectedResult, setSelectedResult] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [dateFilter, setDateFilter] = useState('all')

  const filteredResults = useMemo(() => {
    if (!careSyncTestResults || !careSyncDataPoints) return []

    return [...careSyncTestResults]
      .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
      .filter((result) => {
        const dataPoint = careSyncDataPoints.find((dp) => dp.key === result.key)
        if (!dataPoint) return false

        const matchesSearch = dataPoint.label.toLowerCase().includes(searchQuery.toLowerCase())
        const matchesDate = dateFilter === 'all' || filterByDate(result.dateCreated, dateFilter)
        return matchesSearch && matchesDate
      })
      .map((result) => ({
        ...result,
        dataPoint: careSyncDataPoints.find((dp) => dp.key === result.key),
        name: careSyncDataPoints.find((dp) => dp.key === result.key)?.label,
        value: result.value,
        unit: careSyncDataPoints.find((dp) => dp.key === result.key)?.suffix
      }))
  }, [careSyncTestResults, careSyncDataPoints, searchQuery, dateFilter])

  const filterByDate = (date, filter) => {
    const resultDate = new Date(date)
    const now = new Date()
    const monthDiff = (now.getFullYear() - resultDate.getFullYear()) * 12 + now.getMonth() - resultDate.getMonth()

    switch (filter) {
      case '1month':
        return monthDiff <= 1
      case '3months':
        return monthDiff <= 3
      case '6months':
        return monthDiff <= 6
      default:
        return true
    }
  }

  const handleViewDetail = (result, index) => {
    setSelectedResult(result)
    setSelectedIndex(index)
  }

  const handleNext = () => {
    if (selectedIndex < filteredResults.length - 1) {
      const nextIndex = selectedIndex + 1
      setSelectedIndex(nextIndex)
      setSelectedResult(filteredResults[nextIndex])
    }
  }

  const handlePrevious = () => {
    if (selectedIndex > 0) {
      const prevIndex = selectedIndex - 1
      setSelectedIndex(prevIndex)
      setSelectedResult(filteredResults[prevIndex])
    }
  }

  if (selectedResult) {
    return (
      <ResultDetail
        result={selectedResult}
        onBack={() => {
          setSelectedResult(null)
          setSelectedIndex(null)
        }}
        onNext={handleNext}
        onPrevious={handlePrevious}
        hasNext={selectedIndex < filteredResults.length - 1}
        hasPrevious={selectedIndex > 0}
      />
    )
  }

  return (
    <div className='caresync-results'>
      <div className='results-container'>
        <div className='results-history-header'>
          <Title level={4}>Test Results</Title>
          <Text type='secondary'>View and manage your test results</Text>
        </div>

        {/* <div className='results-filters'>
          <Input
            className='results-history-search'
            placeholder='Search results...'
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Space className='filter-options'>
            <Select
              defaultValue='all'
              onChange={setDateFilter}
              suffixIcon={<FontAwesomeIcon icon={faFilter} />}
              dropdownMatchSelectWidth={false}
            >
              <Option value='all'>All Time</Option>
              <Option value='1month'>Last Month</Option>
              <Option value='3months'>Last 3 Months</Option>
              <Option value='6months'>Last 6 Months</Option>
            </Select>
          </Space>
        </div> */}

        <div className='results-list'>
          {filteredResults?.length > 0 ? (
            filteredResults.map((result, index) => (
              <ResultListItem
                key={result._id}
                result={result}
                dataPoint={result.dataPoint}
                onViewDetail={() => handleViewDetail(result, index)}
              />
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={searchQuery ? 'No matching results found' : 'No test results available yet'}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ResultsHistory
