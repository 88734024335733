import React from 'react'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHospital, faVideo, faCalendarXmark } from '@fortawesome/free-solid-svg-icons'
import '../styling/UpcomingAppointments.css'
import MobUpcomingAppointmentCard from './MobUpcomingAppointmentCard'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import CoreEnums from '../../../../../core/utils/enums'
import coreReducer from '../../../../../core/utils/reducer'
import VirConsultWraper from '../../../Virtual Consultation'

const UpcomingAppointments = ({ data }) => {
  const hasVirtual = true
  const hasClinic = false
  const dispatch = useDispatch()
  const handleVirtualConsultation = () => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.SCHEDULE_VIRTUAL_VISIT}`,
        closable: true,
        label: 'Virtual Consultation',
        children: <VirConsultWraper />
      })
    )
  }

  if (!data?.length) {
    return (
      <div style={{ paddingBottom: 60 }}>
        <motion.div
          className='no-appointments-placeholder'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          style={{
            textAlign: 'center',
            background: 'rgba(255, 255, 255, 0.6)',
            borderRadius: '16px',
            margin: '16px',
            border: '1px solid rgba(16, 178, 225, 0.1)',
            overflow: 'hidden'
          }}
        >
          <div
            className='placeholder-header'
            style={{
              padding: '20px 24px',
              background: 'linear-gradient(180deg, rgba(16, 178, 225, 0.1), rgba(255, 255, 255, 0.1))',
              borderBottom: '1px solid rgba(16, 178, 225, 0.1)'
            }}
          >
            <h3
              style={{
                fontSize: '16px',
                fontWeight: '500',
                margin: '0',
                letterSpacing: '0.2px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <FontAwesomeIcon
                icon={faCalendarXmark}
                className='header-icon'
                style={{ fontSize: '16px', color: 'rgba(16, 178, 225, 0.8)', marginRight: '8px' }}
              />
              No Upcoming Appointments
            </h3>
          </div>

          <div className='booking-section' style={{ padding: '24px' }}>
            <span
              className='section-label'
              style={{
                display: 'block',
                textAlign: 'left',
                fontSize: '12px',
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
                marginBottom: '16px'
              }}
            >
              Available Options
            </span>
            {/* <div className='booking-buttons' style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              {hasClinic ? (
                <button
                  className='booking-button clinic'
                  style={{
                    width: '100%',
                    padding: '16px',
                    borderRadius: '12px',
                    border: '1px solid rgba(16, 178, 225, 0.1)',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    background: 'linear-gradient(135deg, rgba(16, 178, 225, 0.15), rgba(28, 100, 242, 0.15))',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    textAlign: 'left'
                  }}
                >
                  <div
                    className='button-icon'
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '18px',
                      flexShrink: '0',
                      background: 'rgba(16, 178, 225, 0.2)',
                      color: '#10b2e1'
                    }}
                  >
                    <FontAwesomeIcon icon={faHospital} />
                  </div>
                  <div className='button-content' style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <span className='button-label' style={{ fontSize: '15px', fontWeight: '500' }}>
                      In-Person Visit
                    </span>
                    <span className='button-description' style={{ fontSize: '13px' }}>
                      Schedule a clinic appointment
                    </span>
                  </div>
                </button>
              ) : undefined}

              {hasVirtual ? (
                <button
                  onClick={() => {
                    handleVirtualConsultation()
                  }}
                  className='booking-button virtual'
                  style={{
                    width: '100%',
                    padding: '16px',
                    borderRadius: '12px',
                    border: '1px solid rgba(255, 59, 154, 0.2)',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    background: 'linear-gradient(135deg, rgba(255, 59, 154, 0.15), rgba(255, 59, 154, 0.15))',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    textAlign: 'left'
                  }}
                >
                  <div
                    className='button-icon'
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '18px',
                      flexShrink: '0',
                      background: 'rgba(255, 59, 154, 0.2)',
                      color: '#FF3B9A'
                    }}
                  >
                    <FontAwesomeIcon icon={faVideo} />
                  </div>
                  <div className='button-content' style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <span className='button-label' style={{ fontSize: '15px', fontWeight: '500' }}>
                      Virtual Visit
                    </span>
                    <span className='button-description' style={{ fontSize: '13px' }}>
                      Connect with doctor online
                    </span>
                  </div>
                </button>
              ) : undefined}
            </div> */}
          </div>
        </motion.div>
      </div>
    )
  }

  return (
    <Row gutter={[16, 16]} style={{ height: '100%', overflow: 'auto', paddingBottom: '100px' }}>
      {data.map((appointment) => (
        <Col span={24}>
          <MobUpcomingAppointmentCard key={appointment.id} appointment={appointment} />
        </Col>
      ))}
    </Row>
  )
}

export default UpcomingAppointments
