import React, { useEffect, useState } from 'react'
import { Button, Col, Collapse, Space, Spin, message, theme } from 'antd'
import { deviceDetect } from 'react-device-detect'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faClock, faUserDoctor } from '@fortawesome/free-solid-svg-icons'
import CollapsePanel from 'antd/es/collapse/CollapsePanel'
import CustomRow from '../../../../reusable-components/CustomRow'
import { handleError, hexToRGBA } from '../../../../lib/utils'
import ProfileEnums from '../../../../Profile/utils/enums'
import { handleVerifyAvailability } from '../../../../Bookings/utils/lib'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import Lottie from 'react-lottie'
import searchingDoctorsAnimation from '../../../../../assets/Animations/verifying-clinic-booking.json'
import { motion } from 'framer-motion'

const MobClinicBookingResults = ({
  result,
  isVirtualVisit,
  active,
  nextStep,
  handleMakeBooking,
  bookingDetails,
  setDataResults
}) => {
  const { token } = theme.useToken()
  const [verifying, setVerifying] = useState(false)
  const [sortedResult, setSortedResult] = useState(null)

  useEffect(() => {
    handleSetSortedResult()
    // eslint-disable-next-line
  }, [])

  const handleSetSortedResult = () => {
    let finalSortedDays = []
    let tmpSortedResult = JSON.parse(JSON.stringify(result))
    let tmpSortedDays = tmpSortedResult.availabilityEntries
    tmpSortedDays.forEach((day) => {
      let daysEntries = JSON.parse(JSON.stringify(day.entries))
      let sortedDaysEntries = daysEntries.sort((a, b) => {
        let aSorted = a.availability.sort((a, b) => {
          return a.localeCompare(b)
        })
        let bSorted = b.availability.sort((a, b) => {
          return a.localeCompare(b)
        })
        return aSorted[0].localeCompare(bSorted[0])
      })
      finalSortedDays.push({ ...day, entries: sortedDaysEntries })
    })

    setSortedResult({ ...tmpSortedResult, availabilityEntries: finalSortedDays })
  }

  const handleSorting = (availabilityArray) => {
    let sortedArray = []
    let unsortedArray = JSON.parse(JSON.stringify(availabilityArray))
    let timePeriodSorted = [
      { title: 'Morning Slots', timeSlots: [] },
      { title: 'Afternoon Slots', timeSlots: [] },
      { title: 'Evening Slots', timeSlots: [] }
    ]

    sortedArray = unsortedArray.sort((a, b) => {
      return a.localeCompare(b)
    })

    sortedArray.forEach((item) => {
      if (item.localeCompare('12:00') === -1 && item.localeCompare('05:59' === 1)) {
        timePeriodSorted[0].timeSlots.push(item)
      } else if (item.localeCompare('11:59') === 1 && item.localeCompare('18:00') === -1) {
        timePeriodSorted[1].timeSlots.push(item)
      } else {
        timePeriodSorted[2].timeSlots.push(item)
      }
    })

    return timePeriodSorted
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: searchingDoctorsAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      {verifying ? (
        <>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1001,
              background: 'white'
            }}
          >
            <Lottie options={defaultOptions} height={300} width={300} style={{ marginBottom: 20 }} />
            <div
              style={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 500
              }}
            >
              Verying slot availability...
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className='results-header'
            style={{
              padding: '12px',
              background: hexToRGBA(token.colorSecondary, 0.1),
              borderRadius: '8px',
              marginBottom: '16px'
            }}
          >
            <h2 style={{ margin: 0 }}>
              {sortedResult?.clinicName}
              <span
                style={{
                  fontSize: '0.9em',
                  color: token.colorTextSecondary,
                  marginLeft: '8px'
                }}
              >
                ({(result.distance / 1000).toFixed(2)} km away)
              </span>
            </h2>
          </div>

          <Collapse
            defaultActiveKey={[0]}
            style={{
              border: 'none',
              background: 'transparent'
            }}
          >
            {sortedResult?.availabilityEntries.map((dataResult, index) => (
              <CollapsePanel
                header={dayjs(dataResult.date).format('MMM D, YYYY')}
                key={index}
                style={{
                  marginBottom: '12px',
                  background: 'white',
                  borderRadius: '8px',
                  border: `1px solid ${token.colorBorderSecondary}`
                }}
              >
                {dataResult.entries.map((availabilityEntry) => (
                  <div
                    className='doctor-card'
                    style={{
                      padding: '16px',
                      marginBottom: '12px',
                      borderRadius: '8px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                      background: 'white'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '16px',
                        alignItems: 'center',
                        marginBottom: '16px'
                      }}
                    >
                      <div
                        style={{
                          height: 64,
                          width: 64,
                          borderRadius: '50%',
                          overflow: 'hidden',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: token.colorBgContainer,
                          border: `1px solid ${token.colorBorderSecondary}`
                        }}
                      >
                        {availabilityEntry.profileImage ? (
                          <img
                            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                            src={availabilityEntry.profileImage}
                            alt='Profile'
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faUserDoctor}
                            style={{
                              fontSize: '24px',
                              color: token.colorPrimary
                            }}
                          />
                        )}
                      </div>
                      <div>
                        <h3 style={{ margin: 0 }}>
                          {availabilityEntry.profession === 'doctor'
                            ? 'Dr.'
                            : availabilityEntry.profession === 'nurse'
                            ? 'Sr.'
                            : ''}{' '}
                          {availabilityEntry.firstName} {availabilityEntry.lastName}
                        </h3>
                        <p
                          style={{
                            margin: '4px 0 0 0',
                            color: token.colorTextSecondary
                          }}
                        >
                          {availabilityEntry.availability.length} slots available
                        </p>
                      </div>
                    </div>

                    <Collapse ghost style={{ marginTop: '8px' }}>
                      {handleSorting(availabilityEntry.availability).map(
                        (period, index) =>
                          period.timeSlots.length > 0 && (
                            <CollapsePanel header={period.title} key={index} style={{ padding: '8px 0' }}>
                              <div
                                className='time-slots'
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '8px'
                                }}
                              >
                                {period.timeSlots.map((item, index) => (
                                  <Button
                                    key={index}
                                    style={{
                                      minWidth: 90,
                                      backgroundColor:
                                        active.timeslot === item &&
                                        active.key ===
                                          dayjs(dataResult.date).format('DD MMMM YYYY') +
                                            availabilityEntry._id +
                                            result.clinicRef
                                          ? token.colorPrimary
                                          : token.colorBgContainer,
                                      color:
                                        active.timeslot === item &&
                                        active.key ===
                                          dayjs(dataResult.date).format('DD MMMM YYYY') +
                                            availabilityEntry._id +
                                            result.clinicRef
                                          ? 'white'
                                          : token.colorText,
                                      borderColor: token.colorBorderSecondary
                                    }}
                                    onClick={() =>
                                      handleMakeBooking(
                                        dataResult.date,
                                        availabilityEntry,
                                        item,
                                        dayjs(dataResult.date).format('DD MMMM YYYY') +
                                          availabilityEntry._id +
                                          result.clinicRef,
                                        result.clinicRef,
                                        result.clinicPaymentMethods
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faClock} style={{ marginRight: '6px' }} />
                                    {item}
                                  </Button>
                                ))}
                              </div>
                            </CollapsePanel>
                          )
                      )}
                    </Collapse>
                  </div>
                ))}
              </CollapsePanel>
            ))}
          </Collapse>

          <PrevNextFloatingButtons
            onNext={async () => {
              try {
                await handleVerifyAvailability(bookingDetails, setVerifying)
                nextStep()
              } catch (e) {
                message.error(handleError(e))
              }
            }}
            onPrev={() => {
              setDataResults(null)
            }}
          />
        </>
      )}
    </motion.div>
  )
}

export default MobClinicBookingResults
