import React, { useState } from 'react'
import { Button, Input, message, Modal, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faSyringe, faTrash, faPlus, faCalendar } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import '../style/MobSurgeries.css'
import DatePicker from '../../../Reusable Components/DatePicker'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import { updateSurgeries } from '../../api/api'
import patMedHistReducer from '../../utils/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { handleError } from '../../../../lib/utils'
import MobSectionHeader from './MobSectionHeader'

const MobSurgeries = ({ setFacet, setSaving }) => {
  const patMedHist = useSelector((state) => state.patMedHist.data)
  const [surgeryList, setSurgeryList] = useState(patMedHist.surgicalHistory?.list || [])
  const [inputValue, setInputValue] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedYear, setSelectedYear] = useState(null)
  const [showYearPicker, setShowYearPicker] = useState(false)
  const dispatch = useDispatch()
  const styles = {
    header: {
      fontSize: '20px',
      fontWeight: 600,
      marginBottom: '24px',
      display: 'flex',
      alignItems: 'center',
      gap: '12px'
    },
    addButton: {
      background: '#00A6E3',
      borderRadius: '8px',
      border: 'none',
      height: '48px',
      fontSize: '16px',
      fontWeight: 500
    },
    medicationCard: {
      borderRadius: '12px',
      border: 'none',
      marginBottom: '12px'
    },
    medicationIcon: {
      color: '#00A6E3',
      fontSize: '24px',
      marginTop: '4px'
    },
    medicationName: {
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    dateText: {
      color: '#8F9BB3',
      margin: '4px 0 0 0',
      fontSize: '14px'
    },
    removeButton: {
      border: '1px dashed #FF4D4F',
      color: '#FF4D4F',
      background: 'transparent',
      borderRadius: '8px',
      height: '44px',
      width: '100%'
    }
  }
  const handleSubmit = async () => {
    setSaving(true)
    try {
      const newRecord = await updateSurgeries(patMedHist._id, surgeryList)
      dispatch(patMedHistReducer.actions.updateState(newRecord))
    } catch (error) {
      message.error(handleError(error))
    } finally {
      setSaving(false)
    }
  }

  const handleAddSurgery = () => {
    if (!inputValue.trim()) {
      message.error('Please provide a surgery description')
      return
    }

    setSurgeryList([
      ...surgeryList,
      {
        description: inputValue.trim().toUpperCase(),
        date: selectedYear
      }
    ])
    setInputValue('')
    setSelectedYear(null)
    setShowModal(false)
  }

  const handleUpdateSurgery = (date, surgeryIndex) => {
    const updatedList = [...surgeryList]
    updatedList[surgeryIndex].date = date
    setSurgeryList(updatedList)
  }

  const handleRemoveSurgery = (index) => {
    const updatedList = [...surgeryList]
    updatedList.splice(index, 1)
    setSurgeryList(updatedList)
  }

  return (
    <>
      <MobSectionHeader icon={faSyringe} title='Surgical History' subtitle='Add surgeries you have undergone' />
      <Row gutter={[16, 16]}>
        {/* Header */}
        <Col span={24}></Col>
        {/* Add Surgery Modal */}
        <Modal
          title='Add New Surgery'
          visible={showModal}
          onOk={handleAddSurgery}
          onCancel={() => setShowModal(false)}
          okText='Add'
          cancelText='Cancel'
          className='dark-modal'
        >
          <p style={{ marginBottom: '16px' }}>Please enter the surgery description and select the year.</p>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder='Enter surgery description...'
            onPressEnter={handleAddSurgery}
            autoFocus
            style={{
              marginBottom: '16px',
              border: '1px solid #8F9BB3',
              borderRadius: '8px',
              padding: '8px 12px'
            }}
          />
          <div
            onClick={() => setShowYearPicker(true)}
            style={{
              border: '1px solid #8F9BB3',
              padding: '8px 12px',
              borderRadius: '8px',
              color: selectedYear ? '#000000' : '#8F9BB3',
              cursor: 'pointer'
            }}
          >
            {selectedYear ? dayjs(selectedYear).format('YYYY') : 'Select year'}
          </div>
          <DatePicker
            showDatePicker={showYearPicker}
            setShowDatePicker={setShowYearPicker}
            pickerValue={selectedYear ? dayjs(selectedYear) : null}
            setPickerValue={(date) => setSelectedYear(date)}
            handleDateSelect={(date) => {
              setSelectedYear(date)
              setShowYearPicker(false)
            }}
            picker='year'
          />
        </Modal>
        {/* Add Surgery Button */}
        <Col span={24}>
          <Button
            type='primary'
            onClick={() => setShowModal(true)}
            icon={<FontAwesomeIcon icon={faPlus} />}
            block
            style={styles.addButton}
          >
            Add Medication
          </Button>
        </Col>
        {/* Surgery List */}
        <Col span={24}>
          {surgeryList.length === 0 ? (
            <div className='empty-state' style={{ textAlign: 'center', padding: '40px 20px' }}>
              <FontAwesomeIcon
                icon={faSyringe}
                className='empty-icon'
                style={{ fontSize: '32px', marginBottom: '12px', opacity: '0.5' }}
              />
              <p style={{ fontSize: '15px', margin: '0' }}>No surgeries added yet</p>
            </div>
          ) : (
            surgeryList.map((surgery, index) => (
              <div
                key={index}
                className='surgery-card'
                style={{
                  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  borderRadius: '12px',
                  padding: '16px',
                  marginBottom: '12px',
                  transition: 'all 0.2s ease'
                }}
              >
                <Row gutter={[0, 12]}>
                  <Col span={24}>
                    <div
                      className='surgery-description'
                      style={{ fontSize: '15px', fontWeight: '500', lineHeight: '1.4' }}
                    >
                      {surgery.description}
                    </div>
                  </Col>
                  <Col span={24}>
                    {surgery.date ? (
                      <div
                        className='year-badge'
                        style={{
                          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                          padding: '6px 12px',
                          borderRadius: '6px',
                          fontSize: '14px',
                          minWidth: '70px',
                          textAlign: 'center'
                        }}
                      >
                        {dayjs(surgery.date).format('YYYY')}
                      </div>
                    ) : (
                      <span className='year-pending' style={{ fontSize: '14px' }}>
                        Year not set
                      </span>
                    )}
                  </Col>
                  <Col span={24}>
                    <Button
                      block
                      onClick={() => handleRemoveSurgery(index)}
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      style={styles.removeButton}
                    >
                      Remove Surgery
                    </Button>
                  </Col>
                </Row>
              </div>
            ))
          )}
        </Col>
        {/* Bottom Actions */}
        <PrevNextFloatingButtons
          onPrev={() => {
            setSurgeryList([]) // Reset the local state
            setFacet(null)
          }}
          onNext={handleSubmit}
          nextText='Save'
          prevText='Cancel'
        />
      </Row>
    </>
  )
}

export default MobSurgeries
