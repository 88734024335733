import React from 'react'
import { Empty, Row, Col, Space, Button, Spin } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faRefresh } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { useHistoricalNotes } from '../hooks/useHistoricalNotes'
import { useSelector } from 'react-redux'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'

const HistoricalNotes = ({ noteState, bookingData, userRef }) => {
  const { agiliteUser } = useSelector((state) => state.auth)
  const {
    historicalNotes,
    historicalNotesLoading,
    currentHistoricalNoteIndex,
    setCurrentHistoricalNoteIndex,
    handleReadHistoricalNotes
  } = useHistoricalNotes({ bookingData, userRef, noteState })

  return (
    <div>
      <>
        <div style={{ padding: '12px 15px', border: '1px solid #ccc', borderBottom: 0, position: 'relative' }}>
          <Button
            type='primary'
            loading={historicalNotesLoading}
            onClick={() => handleReadHistoricalNotes()}
            style={{ position: 'absolute', right: 12, top: historicalNotesLoading ? 6 : 9 }}
          >
            <FontAwesomeIcon icon={faRefresh} />
          </Button>
          <center>
            <Space>
              {historicalNotesLoading ? (
                <Spin></Spin>
              ) : (
                <>
                  {' '}
                  <FontAwesomeIcon
                    style={{
                      cursor: currentHistoricalNoteIndex === historicalNotes.length - 1 ? 'not-allowed' : 'pointer'
                    }}
                    fontSize={16}
                    icon={faChevronLeft}
                    onClick={() => {
                      setCurrentHistoricalNoteIndex((prev) => (prev === 0 ? historicalNotes.length - 1 : prev - 1))
                    }}
                  />
                  {currentHistoricalNoteIndex + 1} / {historicalNotes.length}
                  <FontAwesomeIcon
                    fontSize={16}
                    icon={faChevronRight}
                    style={{
                      cursor: currentHistoricalNoteIndex === historicalNotes.length - 1 ? 'not-allowed' : 'pointer'
                    }}
                    onClick={() => {
                      setCurrentHistoricalNoteIndex((prev) => (prev === historicalNotes.length - 1 ? 0 : prev + 1))
                    }}
                  />
                </>
              )}
            </Space>
          </center>
        </div>
        <Row
          gutter={[0, 24]}
          style={{
            height: 600,
            overflow: 'auto',
            alignContent: 'baseline',
            border: '1px solid #ccc',
            padding: '12px 15px'
          }}
        >
          <Col span={24}>
            {historicalNotesLoading ? (
              <AgiliteSkeleton skActive spinnerTip='Loading historical notes..' />
            ) : (
              <>
                {' '}
                {historicalNotes.length === 0 ? (
                  <Empty description='There are currently no historical notes to show. Use the sync notes button to check for any changes.' />
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      <h4>
                        Recorded by{' '}
                        {historicalNotes[currentHistoricalNoteIndex]?.createdBy === agiliteUser._id
                          ? ' You '
                          : historicalNotes[currentHistoricalNoteIndex]?.createdByFullName}
                        {dayjs(historicalNotes[currentHistoricalNoteIndex]?.createdAt).format('DD MMMM YYYY') ===
                        dayjs().format('DD MMMM YYYY')
                          ? ' Today '
                          : ` on ${dayjs(historicalNotes[currentHistoricalNoteIndex]?.createdAt).format(
                              'DD MMMM YYYY'
                            )} `}
                        at {dayjs(historicalNotes[currentHistoricalNoteIndex]?.createdAt).format('HH:mm')}
                      </h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: historicalNotes[currentHistoricalNoteIndex]?.content
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </>
    </div>
  )
}

export default HistoricalNotes
