// src/components/BluetoothManager.js
import React, { useState, useEffect, useRef } from 'react'
import BluetoothContext from '../BluetoothContext'
import Engine from '../lib/hc03lib'
import { handleError } from '../../../lib/utils'
import { message } from 'antd'

const PATH_WORKER = `${process.env.PUBLIC_URL}/work.bundle.js`

const BluetoothManager = ({ children }) => {
  const [bleDevice, setBleDevice] = useState(null)
  const [bleCommandCharacteristic, setBleCommandCharacteristic] = useState(null)
  const [bleNotifyCharacteristic, setBleNotifyCharacteristic] = useState(null)

  // useRef to store the engine instance
  const engineRef = useRef(null)

  useEffect(() => {
    // Initialize the engine only once
    if (!engineRef.current) {
      engineRef.current = Engine.getEngine(PATH_WORKER)
    }

    // Cleanup function to terminate engine if needed
    return () => {
      if (engineRef.current && engineRef.current.terminate) {
        engineRef.current.terminate()
      }
    }
  }, [])

  const requestDevice = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ namePrefix: 'HC0' }],
        optionalServices: ['0000ff27-0000-1000-8000-00805f9b34fb', '0000180a-0000-1000-8000-00805f9b34fb']
      })
      setBleDevice(device)
      device.addEventListener('gattserverdisconnected', onDisconnected)
    } catch (error) {
      console.error('Device Request Error:', error)
    }
  }

  const connectDevice = async () => {
    if (!bleDevice) {
      console.warn('No device selected')
      return
    }
    try {
      const server = await bleDevice.gatt.connect()

      const service = await server.getPrimaryService('0000ff27-0000-1000-8000-00805f9b34fb')

      const characteristics = await service.getCharacteristics()

      characteristics.forEach((char) => {
        if (char.uuid === '0000fff1-0000-1000-8000-00805f9b34fb') {
          setBleCommandCharacteristic(char)
        } else if (char.uuid === '0000fff4-0000-1000-8000-00805f9b34fb') {
          setBleNotifyCharacteristic(char)
          setupNotification(char)
        }
      })
    } catch (error) {
      console.error('Connection Error:', error)
    }
  }

  const setupNotification = async (characteristic) => {
    try {
      await characteristic.startNotifications()

      // Remove existing listener to prevent duplicates
      characteristic.removeEventListener('characteristicvaluechanged', handleCharacteristicValueChanged)

      // Add the new listener
      characteristic.addEventListener('characteristicvaluechanged', handleCharacteristicValueChanged)
    } catch (error) {
      message.error(handleError(error))
    }
  }

  const handleCharacteristicValueChanged = (event) => {
    const value = event.target.value // DataView

    // Create a copy of the buffer to prevent detachment
    const bufferCopy = value.buffer.slice(0)

    // Send the copy to the engine
    if (engineRef.current) {
      try {
        engineRef.current.pushRawData(bufferCopy)
      } catch (error) {
        message.error(handleError(error))
      }
    } else {
      console.warn('Engine is not initialized')
    }
  }

  const onDisconnected = () => {
    setBleDevice(null)
    setBleCommandCharacteristic(null)
    setBleNotifyCharacteristic(null)
  }

  // **New: Disconnect Function**
  const disconnectDevice = () => {
    if (bleDevice && bleDevice.gatt.connected) {
      bleDevice.gatt.disconnect()
      // Cleanup state
      setBleDevice(null)
      setBleCommandCharacteristic(null)
      setBleNotifyCharacteristic(null)
    } else {
      console.warn('No device is connected')
    }
  }

  return (
    <BluetoothContext.Provider
      value={{
        bleDevice,
        bleCommandCharacteristic,
        bleNotifyCharacteristic,
        requestDevice,
        connectDevice,
        disconnectDevice, // **Expose disconnect function via context**
        engine: engineRef.current
      }}
    >
      {children}
    </BluetoothContext.Provider>
  )
}

export default BluetoothManager
