import React from 'react'
import { Card, Empty, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import '../style/MobileHealthyAlternatives.css'
import useMobileHealthAlternatives from '../hooks/useMobileHealthAlternatives'
import MobMedicalHistoryLoadingOverlay from '../../../../../Medical History/Mobile/components/LoadingOverlay'
import PrevNextFloatingButtons from '../../../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import HealthAlternativeItem from './HealthAlternativeItem'
import { useSelector } from 'react-redux'

const MobHealthyAlternatives = ({ userRef, onBack }) => {
  const recipes = useSelector((state) => state.patMacroTracker.healthyAlternatives)
  const { currentRecipe, setCurrentRecipe, searchQuery, loading, setSearchQuery } = useMobileHealthAlternatives({
    userRef
  })

  const renderRecipesList = () => (
    <>
      <div>
        <div className='list-header' style={{ padding: '12px' }}>
          <Input
            placeholder='Search recipes...'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className='search-input'
            suffix={<FontAwesomeIcon icon={faSearch} />}
            allowClear
          />
        </div>

        {loading ? (
          <MobMedicalHistoryLoadingOverlay loading text='Loading Recipes...' />
        ) : recipes.length === 0 ? (
          <Card className='empty-card' style={{ textAlign: 'center', padding: '20px' }}>
            <Empty description={<span style={{ fontSize: '1.2rem' }}>No recipes found</span>} />
          </Card>
        ) : (
          <div className='recipes-container'>
            <div className='recipes-list'>
              {recipes
                .filter(
                  (recipe) =>
                    recipe.recipeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    recipe.recipeDescription?.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((recipe) => (
                  <Card key={recipe._id} className='recipe-card' onClick={() => setCurrentRecipe(recipe)}>
                    <h3>{recipe.recipeName}</h3>
                    <p>{recipe.recipeDescription}</p>
                  </Card>
                ))}
            </div>
          </div>
        )}
      </div>

      <PrevNextFloatingButtons onPrev={onBack} prevOnly={true} prevText='Back' />
    </>
  )

  return (
    <>
      {currentRecipe ? (
        <HealthAlternativeItem
          healthyAlternative={currentRecipe}
          handleBack={() => {
            setCurrentRecipe(null)
          }}
        />
      ) : (
        renderRecipesList()
      )}
    </>
  )
}

export default MobHealthyAlternatives
