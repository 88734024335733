import { useState } from 'react'
import { handleError } from '../../../../lib/utils'
import { message, Upload } from 'antd'
import { pushNewVisit } from '../../../utils/utils'

const usePatientOverview = ({ currentPatient, handleRefresh }) => {
  const [addingVisit, setAddingVisit] = useState(false)
  const [newVisitDate, setNewVisitDate] = useState(null)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [pushingNewVisit, setPushingNewVisit] = useState(false)
  const [viewingPDF, setViewingPDF] = useState(false)

  const handleFileUpload = {
    beforeUpload: (file) => {
      const isPDF = file.type === 'application/pdf'
      if (!isPDF) {
        message.error(`${file.name} is not a PDF file`)
        return Upload.LIST_IGNORE
      }
      return false // Prevent automatic upload
    },
    onChange: async (info) => {
      const processedFiles = await Promise.all(
        info.fileList.map(async (file) => {
          // Convert file to base64
          const base64 = await new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(file.originFileObj)
            reader.onload = () => resolve(reader.result.split(',')[1])
          })

          return {
            name: file.name,
            file: base64
          }
        })
      )
      setUploadedFiles(processedFiles)
    }
  }

  const handleSaveVisit = async () => {
    const payload = {
      visitKey: addingVisit.key,
      visitDate: newVisitDate,
      files: uploadedFiles
    }
    try {
      let newRecord = null
      setPushingNewVisit(true)
      newRecord = await pushNewVisit(currentPatient._id, payload)
      // setCurrentPatient(newRecord)
      handleRefresh()
    } catch (e) {
      message.error(handleError(e))
    } finally {
      setPushingNewVisit(false)
    }
  }
  return {
    handleSaveVisit,
    handleFileUpload,
    addingVisit,
    setAddingVisit,
    newVisitDate,
    setNewVisitDate,
    uploadedFiles,
    pushingNewVisit,
    setUploadedFiles,
    viewingPDF,
    setViewingPDF
  }
}

export default usePatientOverview
