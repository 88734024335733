import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Space } from 'antd'
import React from 'react'

const MobTransparentButton = ({
  functionCall = () => {},
  style = {},
  text = 'NO TEXT',
  icon = false,
  additionalProps,
  type = 'primary'
}) => {
  const buttonStyle = {
    width: '100%',
    height: '56px',
    borderRadius: '12px',
    fontSize: '16px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(16, 178, 225, 0.1)',
    border: '1px solid #10b2e1',
    color: '#10b2e1'
  }

  switch (type) {
    case 'primary':
      buttonStyle.backgroundColor = 'rgba(16, 178, 225, 0.1)'
      buttonStyle.borderColor = '#10b2e1'
      buttonStyle.color = '#10b2e1'
      break
    case 'danger':
      buttonStyle.backgroundColor = 'rgba(255, 75, 75, 0.1)'
      buttonStyle.borderColor = '#ff4d4f'
      buttonStyle.color = '#ff4d4f'
      break
    default:
      buttonStyle.backgroundColor = 'rgba(16, 178, 225, 0.1)'
      buttonStyle.borderColor = '#10b2e1'
      buttonStyle.color = '#10b2e1'
      break
  }
  return (
    <Button style={{ ...buttonStyle, ...style }} size='large' onClick={functionCall} {...additionalProps}>
      <Space>
        {text}
        {icon ? <FontAwesomeIcon icon={icon} /> : null}
      </Space>
    </Button>
  )
}

export default MobTransparentButton
