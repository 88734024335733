// src/components/Detection/OXDetection.js
import React, { useContext, useEffect, useState, useRef } from 'react'
import BluetoothContext from '../../BluetoothContext'
import { Col, Space, Spin, theme } from 'antd'
import CustomRow from '../../../../reusable-components/CustomRow'
import CustomButton from '../../../../reusable-components/CustomButton'

const Process = {
  START: 0,
  END: 4
}

const Detection = {
  OX: 'Blood Oxygen'
}

const OXDetection = () => {
  const { engine, bleCommandCharacteristic } = useContext(BluetoothContext)
  const [spo2, setSpo2] = useState(null)
  const [hr, setHr] = useState(null)
  const [error, setError] = useState(null)
  const [isDetecting, setIsDetecting] = useState(false)

  // Use useRef for writeQueue to maintain its state across renders
  const writeQueue = useRef(Promise.resolve())

  useEffect(() => {
    if (!engine || !bleCommandCharacteristic) {
      console.warn('Engine or bleCommandCharacteristic not available for OXDetection')
      return
    }

    engine.registOXListener({
      onProcess: (type, data) => {
        if (bleCommandCharacteristic) {
          writeQueue.current = writeQueue.current
            .then(() => bleCommandCharacteristic.writeValue(data))
            .catch((err) => {
              console.error('Error writing to bleCommandCharacteristic:', err)
            })
        }
        if (type === Process.START) {
          setIsDetecting(true)
          // Removed waveList reset
        } else if (type === Process.END) {
          setSpo2(null)
          setHr(null)
          setIsDetecting(false)
        }
      },
      onResult: (spo2Value, hrValue) => {
        if (spo2Value !== undefined && hrValue !== undefined) {
          setSpo2(Math.round(spo2Value))
          setHr(Math.round(hrValue))
          setError(null)
          setIsDetecting(false)
        } else {
          console.warn('Invalid OX result received:', { spo2Value, hrValue })
          setError('Invalid result received from device.')
        }
      },
      onRawResult: (red) => {
        // Removed waveList handling since canvas is removed
      },
      onException: (err) => {
        console.error('OX Detection Exception:', err)
        setError(`OX Detection Error: ${err.message || 'Unknown error'}`)
        // Optionally stop detection to prevent further errors
        if (typeof engine.stopDetect === 'function') {
          engine.stopDetect(Detection.OX)
        } else {
          console.error('engine.stopDetect is not a function')
        }
      }
    })

    return () => {
      if (typeof engine.unregistOXListener === 'function') {
        engine.unregistOXListener()
      } else {
        console.error('engine.unregistOXListener is not a function')
      }
    }
  }, [engine, bleCommandCharacteristic])

  const startDetection = () => {
    if (engine && bleCommandCharacteristic) {
      setError(null)
      setIsDetecting(true)
      engine.startDetect(Detection.OX)
    } else {
      console.warn('Engine or bleCommandCharacteristic is not available')
    }
  }

  const stopDetection = () => {
    if (engine && bleCommandCharacteristic) {
      setIsDetecting(false)
      engine.stopDetect(Detection.OX)
    } else {
      console.warn('Engine or bleCommandCharacteristic is not available')
    }
  }

  const { token } = theme.useToken()
  return (
    <>
      {!engine || !bleCommandCharacteristic ? (
        <p>Device is not ready. Please wait...</p>
      ) : (
        <CustomRow>
          <Col span={24}>{error && <p style={{ color: 'red' }}>{error}</p>}</Col>
          <Col span={24}>
            <p>SpO₂: {isDetecting ? <Spin size='small' /> : spo2 !== null ? `${spo2}%` : '--'}</p>
            <p>Heart Rate: {isDetecting ? <Spin size='small' /> : hr !== null ? `${hr} bpm` : '--'}</p>
          </Col>
          <Col span={24}>
            <Space>
              <CustomButton type='primary' onClick={startDetection} text='Start' disabled={isDetecting} size='small' />
              <CustomButton
                text='Stop'
                type='primary'
                style={{ background: !isDetecting ? '' : token.colorError }}
                onClick={stopDetection}
                disabled={!isDetecting}
                size='small'
              />
            </Space>
          </Col>
        </CustomRow>
      )}
    </>
  )
}

export default OXDetection
