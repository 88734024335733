import { Button, Card, Form, Input, message, Select, Space, theme, Tag } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { generateFormItem, hexToRGBA, handleError } from '../../../../lib/utils'
import MedicalHistoryFormTemplates from '../../utils/templates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPills, faAppleAlt, faAllergies } from '@fortawesome/free-solid-svg-icons'
import { MedPraxProductSearch } from '../../../../Scripting/utils/utils'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash'

const MedHistAllergies = ({ clientForm }) => {
  const [nappiLoading, setNappiLoading] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [nappiData, setNappiData] = useState([])
  const { token } = theme.useToken()
  const [foodInput, setFoodInput] = useState('')
  const [generalInput, setGeneralInput] = useState('')

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await MedPraxProductSearch(searchQuery)
        setNappiData(tmpData)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setNappiLoading(false)
  }

  useEffect(() => {
    setNappiLoading(true)
    debouncedNappiFilter(searchFilter)

    // eslint-disable-next-line
  }, [searchFilter])

  // eslint-disable-next-line
  const debouncedNappiFilter = useCallback(
    debounce((value) => {
      getNappiData(value)
    }, 1000),
    []
  )

  const sectionColors = {
    medications: {
      icon: '#FF6B6B'
    },
    food: {
      icon: '#51CF66'
    },
    general: {
      icon: '#339AF0'
    }
  }

  const cardStyle = {
    marginBottom: '24px',
    borderRadius: '12px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
    border: '2px solid #e6e6e6',
    background: '#fff'
  }

  const cardHeaderStyle = (sectionColor) => ({
    borderBottom: '2px solid #e6e6e6',
    padding: '16px 24px',
    background: hexToRGBA(sectionColor, 0.05),
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  })

  const iconStyle = {
    fontSize: '28px'
  }

  const titleStyle = {
    fontSize: '20px',
    fontWeight: 600,
    margin: 0,
    color: token.colorTextHeading
  }

  const iconContainerStyle = (color) => ({
    fontSize: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  })

  const tagStyle = {
    borderRadius: '6px',
    padding: '4px 10px',
    background: hexToRGBA(token.colorPrimary, 0.08),
    border: 'none',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.06)',
    margin: '4px'
  }

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px'
    },
    mainCard: {
      marginBottom: '24px',
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
      border: '2px solid #e6e6e6',
      background: '#fff'
    },
    mainCardHeader: {
      borderBottom: '2px solid #e6e6e6',
      padding: '16px 24px',
      background: hexToRGBA(sectionColors.medications.icon, 0.05),
      borderRadius: '10px 10px 0 0',
      display: 'flex',
      alignItems: 'center',
      gap: '12px'
    },
    mainCardTitle: {
      fontSize: '20px',
      fontWeight: 600,
      margin: 0,
      color: token.colorTextHeading
    },
    mainCardIconContainer: {
      fontSize: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    tag: {
      borderRadius: '6px',
      padding: '4px 10px',
      background: hexToRGBA(token.colorPrimary, 0.08),
      border: 'none',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.06)',
      margin: '4px'
    }
  }

  return (
    <div style={styles.container}>
      <Card style={styles.mainCard}>
        <div style={styles.mainCardHeader}>
          <FontAwesomeIcon icon={faPills} style={{ ...iconStyle, color: sectionColors.medications.icon }} />
          <span style={titleStyle}>Medication Allergies</span>
        </div>
        <div style={{ padding: '20px' }}>
          <div style={{ marginBottom: '20px' }}>
            {generateFormItem(MedicalHistoryFormTemplates.dataModel.allergiesMeds, clientForm)}
          </div>

          <Form.List name={['allergies', 'medsAdd']}>
            {(fields, { add, remove }) => (
              <>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px' }}>
                  {fields.map(({ key, name, ...restField }) => (
                    <Form.Item {...restField} name={[name, 'name']} style={{ margin: '4px' }}>
                      <Tag closable onClose={() => remove(name)} style={tagStyle}>
                        {clientForm.getFieldValue(['allergies', 'medsAdd', name, 'name'])}
                      </Tag>
                    </Form.Item>
                  ))}
                </div>

                <Space.Compact style={{ width: '100%' }}>
                  <Select
                    showSearch
                    placeholder='Search for medication...'
                    notFoundContent={
                      searchFilter && nappiLoading ? (
                        <div
                          style={{
                            padding: '20px',
                            textAlign: 'center',
                            background: '#f5f7fa',
                            borderRadius: '8px'
                          }}
                        >
                          <div style={{ marginBottom: '8px' }}>
                            <FontAwesomeIcon
                              icon={faPills}
                              style={{
                                fontSize: '24px',
                                color: token.colorPrimary
                              }}
                            />
                          </div>
                          <div style={{ color: token.colorTextSecondary }}>Searching...</div>
                        </div>
                      ) : null
                    }
                    onSearch={setSearchFilter}
                    onSelect={(value) => {
                      add({ name: value })
                      setNappiData([])
                      setSearchFilter('')
                    }}
                    loading={nappiLoading}
                    options={
                      nappiLoading
                        ? []
                        : uniqBy(nappiData, 'name').map((i) => ({
                            value: i.name,
                            label: i.name
                          }))
                    }
                    style={{
                      width: '100%',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.04)'
                    }}
                    value={null}
                  />
                </Space.Compact>
              </>
            )}
          </Form.List>
        </div>
      </Card>

      <Card style={cardStyle} bodyStyle={{ padding: '24px' }}>
        <div style={cardHeaderStyle(sectionColors.food.icon)}>
          <FontAwesomeIcon icon={faAppleAlt} style={{ ...iconStyle, color: sectionColors.food.icon }} />
          <span style={titleStyle}>Food Allergies</span>
        </div>
        <div style={{ padding: '20px' }}>
          <div style={{ marginBottom: '20px' }}>
            {generateFormItem(MedicalHistoryFormTemplates.dataModel.allergiesFood, clientForm)}
          </div>

          <Form.List name={['allergies', 'foodAdd']}>
            {(fields, { add, remove }) => (
              <>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px' }}>
                  {fields.map(({ key, name, ...restField }) => (
                    <Form.Item {...restField} name={[name, 'name']} style={{ margin: '4px' }}>
                      <Tag closable onClose={() => remove(name)} style={tagStyle}>
                        {clientForm.getFieldValue(['allergies', 'foodAdd', name, 'name'])}
                      </Tag>
                    </Form.Item>
                  ))}
                </div>

                <Space.Compact style={{ width: '100%' }}>
                  <Input
                    placeholder='Add other food allergies not listed above'
                    value={foodInput}
                    onChange={(e) => setFoodInput(e.target.value)}
                    style={{
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.04)',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px'
                    }}
                    prefix={<FontAwesomeIcon icon={faPlus} style={{ color: token.colorTextSecondary }} />}
                  />
                  <Button
                    type='primary'
                    onClick={() => {
                      if (foodInput.trim()) {
                        add({ name: foodInput.trim() })
                        setFoodInput('')
                      }
                    }}
                    style={{
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px'
                    }}
                  >
                    Add
                  </Button>
                </Space.Compact>
              </>
            )}
          </Form.List>
        </div>
      </Card>

      <Card style={cardStyle} bodyStyle={{ padding: '24px' }}>
        <div style={cardHeaderStyle(sectionColors.general.icon)}>
          <FontAwesomeIcon icon={faAllergies} style={{ ...iconStyle, color: sectionColors.general.icon }} />
          <span style={titleStyle}>General Allergies</span>
        </div>
        <div style={{ padding: '20px' }}>
          <div style={{ marginBottom: '20px' }}>
            {generateFormItem(MedicalHistoryFormTemplates.dataModel.allergiesOther, clientForm)}
          </div>

          <Form.List name={['allergies', 'otherAdd']}>
            {(fields, { add, remove }) => (
              <>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px' }}>
                  {fields.map(({ key, name, ...restField }) => (
                    <Form.Item {...restField} name={[name, 'name']} style={{ margin: '4px' }}>
                      <Tag closable onClose={() => remove(name)} style={tagStyle}>
                        {clientForm.getFieldValue(['allergies', 'otherAdd', name, 'name'])}
                      </Tag>
                    </Form.Item>
                  ))}
                </div>

                <Space.Compact style={{ width: '100%' }}>
                  <Input
                    placeholder='Add other general allergies not listed above'
                    value={generalInput}
                    onChange={(e) => setGeneralInput(e.target.value)}
                    style={{
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.04)',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px'
                    }}
                    prefix={<FontAwesomeIcon icon={faPlus} style={{ color: token.colorTextSecondary }} />}
                  />
                  <Button
                    type='primary'
                    onClick={() => {
                      if (generalInput.trim()) {
                        add({ name: generalInput.trim() })
                        setGeneralInput('')
                      }
                    }}
                    style={{
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px'
                    }}
                  >
                    Add
                  </Button>
                </Space.Compact>
              </>
            )}
          </Form.List>
        </div>
      </Card>
    </div>
  )
}

export default MedHistAllergies
