import { Col, Radio, Select, Space, Table, Tabs, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import Templates from '../../utils/templates'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { generateAgoraRtcToken, getBookings, updateBooking } from '../../utils/utils'
import { generateUniqueUIDFromString, handleError } from '../../../lib/utils'
import coreReducer from '../../../../core/utils/reducer'
import DiagnosisOverview from '../../../Medical Vault/diagnosis-history/components/diagnosis-overview'
import { handleCancelBooking } from '../../../lib/booking-utils'
import BookingSummaryModal from '../booking-summary-modal'
import CoreEnums from '../../../../core/utils/enums'
import CustomRow from '../../../reusable-components/CustomRow'
import BookingShortcut from '../../../reusable-components/BookingShortcut'
import { deviceDetect } from 'react-device-detect'
import adminBookingsReducer from '../../../Admin/bookings/utils/reducer'
import CustomButton from '../../../reusable-components/CustomButton'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'

const PatientBookingsView = ({ userRef }) => {
  const state = useSelector((state) => state)
  const dependantState = useSelector((state) => state.dependents.data)
  const adminBookingsState = useSelector((state) => state.adminBookings.data)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [booking, setBooking] = useState(null)
  const [bookingOpen, setBookingOpen] = useState()
  const [currentUser, setCurrentUser] = useState(state.auth.agiliteUser._id)
  const [filterValue, setFilterValue] = useState('all')
  const [dependentData, setDependentData] = useState([])

  // Agora
  const [joined, setJoined] = useState(false)
  const [agoraToken, setAgoraToken] = useState(null)
  const [agoraChannel, setAgoraChannel] = useState(null)
  const [agoraUid, setAgoraUid] = useState(null)

  const { token } = theme.useToken()

  useEffect(() => {
    handleGetData()
    // eslint-disable-next-line
  }, [
    state.clinics.data,
    state.services.data,
    state.virtualServices.data,
    filterValue,
    state.bookings.data,
    currentUser
  ])

  const handleGetData = () => {
    if (state.auth.agiliteUser) {
      handleGetBookings()
    }
  }

  const handleGetBookings = async () => {
    setLoading(true)

    try {
      const qry = getQuery(filterValue)
      const tmpBookings = await getBookings(state, qry, userRef || currentUser)
      const sortedBookings = sortBookings(tmpBookings)
      if (userRef) {
        setDependentData(sortedBookings)
      } else {
        dispatch(adminBookingsReducer.actions.setRecords(sortedBookings))
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const getQuery = (filterValue) => {
    const today = dayjs(new Date()).format('YYYY-MM-DDT00:00:00.000Z')
    const tomorrow = dayjs(new Date()).add(1, 'day').format('YYYY-MM-DDT00:00:00.000Z')
    const upcoming = dayjs(new Date()).add(1, 'day').format('YYYY-MM-DD')
    const past = dayjs(new Date()).format('YYYY-MM-DDT00:00:00.000Z')

    switch (filterValue) {
      case 'all':
        return {}
      case 'today':
        return {
          bookingDate: {
            $gt: today,
            $lt: tomorrow
          }
        }
      case 'upcoming':
        return {
          bookingDate: {
            $gte: upcoming
          }
        }
      case 'past':
        return {
          bookingDate: {
            $lt: past
          }
        }
      default:
        return {}
    }
  }

  const sortBookings = (bookings) => {
    return bookings.sort(
      (a, b) =>
        new Date(dayjs(b.bookingDate).set('hours', b.startTime.slice(0, 2)).set('minutes', b.startTime.slice(-2))) -
        new Date(dayjs(a.bookingDate).set('hours', a.startTime.slice(0, 2)).set('minutes', a.startTime.slice(-2)))
    )
  }

  const handleOpenBooking = (record) => {
    if (record.status !== CoreEnums.bookingStatuses.completed) {
      setBookingOpen(true)
      setBooking(record)
    } else {
      dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${record._id}`,
          closable: true,
          // TODO: Why is this label test?
          label: 'test',
          children: <DiagnosisOverview data={record} />
        })
      )
    }
  }

  const joinVirtualConsult = async (record) => {
    let agoraCreds = null

    try {
      agoraCreds = await generateAgoraRtcToken(record._id, generateUniqueUIDFromString(state.auth.agiliteUser._id))
      setAgoraToken(agoraCreds.token)
      setAgoraChannel(agoraCreds.channelName)
      setAgoraUid(agoraCreds.uid)
      setJoined(true)

      setBooking(record)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const bookingFilterOptions = [
    {
      value: 'past',
      label: 'Previous'
    },
    {
      value: 'today',
      label: 'Today'
    },
    {
      value: 'upcoming',
      label: 'Upcoming'
    },
    {
      value: 'all',
      label: 'All'
    }
  ]

  return (
    <>
      <CustomRow justify='center'>
        {userRef ? undefined : (
          <Col span={24}>
            <BookingShortcut showSOS={false} />
          </Col>
        )}
        <Col span={24}>
          <CustomRow className='basic-card'>
            <Col span={24}>
              <Space wrap>
                {deviceDetect().isMobile ? undefined : (
                  <CustomButton
                    text='Refresh'
                    size='small'
                    icon={faRefresh}
                    onClick={handleGetBookings}
                    type='primary'
                    disabled={loading}
                  />
                )}
                {deviceDetect().isMobile ? (
                  <Select
                    style={{ width: 350, maxWidth: '100%' }}
                    onChange={(value) => {
                      setFilterValue(value)
                    }}
                    value={filterValue}
                    options={bookingFilterOptions}
                  />
                ) : (
                  <Radio.Group
                    disabled={loading}
                    style={{
                      width: '100%'
                    }}
                    onChange={(e) => {
                      setFilterValue(e.target.value)
                    }}
                    value={filterValue}
                  >
                    {bookingFilterOptions.map((option) => (
                      <Radio.Button value={option.value}>{option.label}</Radio.Button>
                    ))}
                  </Radio.Group>
                )}
              </Space>
            </Col>
            {dependantState.length > 0 ? (
              <Col span={24}>
                {deviceDetect().isMobile ? undefined : (
                  <Tabs
                    onChange={(key) => {
                      setCurrentUser(key)
                    }}
                  >
                    <Tabs.TabPane tab='Myself' key={state.auth.agiliteUser._id}></Tabs.TabPane>
                    {dependantState.map((dependant) => {
                      return <Tabs.TabPane tab={dependant.firstName} key={dependant._id}></Tabs.TabPane>
                    })}
                  </Tabs>
                )}
              </Col>
            ) : undefined}
            <Col span={24}>
              <Table
                showHeader={deviceDetect().isMobile ? false : true}
                dataSource={userRef ? dependentData : adminBookingsState}
                columns={Templates.columnTemplate(
                  token,
                  (record) => {
                    handleCancelBooking(state.auth, record, updateBooking, handleGetData, token)
                  },
                  null,
                  null,
                  null,
                  null,
                  (record) => {
                    joinVirtualConsult(record)
                  }
                ).filter((col) => !col.hidden)}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      handleOpenBooking(record)
                    }
                  }
                }}
                rowKey={(record) => record._id}
                pagination={false}
                loading={loading}
                bordered={false}
                scroll={deviceDetect().isMobile ? {} : { x: 1000 }}
              />
            </Col>
          </CustomRow>
        </Col>
      </CustomRow>
      <BookingSummaryModal
        joinVirtualConsult={joinVirtualConsult}
        data={booking}
        setData={setBooking}
        modalOpen={bookingOpen}
        setModalOpen={setBookingOpen}
        joined={joined}
        setJoined={setJoined}
        agoraToken={agoraToken}
        agoraChannel={agoraChannel}
        agoraUid={agoraUid}
      />
    </>
  )
}

export default PatientBookingsView
