import React from 'react'
import { Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import '../../../styling/LocationPicker.css'

const { Text } = Typography

const LocationPicker = ({ onSelect }) => {
  const locations = [
    {
      id: 'lab1',
      name: 'Downtown Lab',
      address: '123 Main St, Downtown',
      distance: '0.8 miles away'
    },
    {
      id: 'lab2',
      name: 'Westside Medical',
      address: '456 West Ave',
      distance: '1.2 miles away'
    },
    {
      id: 'lab3',
      name: 'Central Hospital',
      address: '789 Center Blvd',
      distance: '2.5 miles away'
    }
  ]

  return (
    <div className="cs-location-picker">
      <div className="cs-location-picker__list">
        {locations.map(location => (
          <div
            key={location.id}
            className="cs-location-picker__item"
            onClick={() => onSelect(location)}
          >
            <div className="cs-location-picker__item-content">
              <div className="cs-location-picker__icon">
                <FontAwesomeIcon icon={faLocationDot} />
              </div>
              <div className="cs-location-picker__info">
                <Text className="cs-location-picker__title">{location.name}</Text>
                <Text className="cs-location-picker__address">{location.address}</Text>
                <Text className="cs-location-picker__distance">{location.distance}</Text>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LocationPicker 