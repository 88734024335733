import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Space, Table, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { documentLabels } from '../utils/enums'

const DocumentsTable = ({ setSelectedDocument, documents, loadingDocuments }) => {
  const { token } = theme.useToken()
  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (value) => {
          switch (value) {
            case 'sickNote':
              return documentLabels.SICK_NOTE
            case 'script':
              return documentLabels.SCRIPT
            case 'referral':
              return documentLabels.REFERRAL
            case 'labReport':
              return documentLabels.LAB_REPORT
            default:
              return value
          }
        }
      },
      // {
      //   title: 'Created By',
      //   dataIndex: ['audit', 'createdBy'],
      //   key: 'createdBy',
      //   ellipsis: true
      // },
      {
        title: 'Created At',
        dataIndex: ['audit', 'createdAt'],
        key: 'createdAt',
        render: (value) => dayjs(value).format('DD MMM YYYY')
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              onClick={() => {
                setSelectedDocument(record.value)
              }}
            >
              <FontAwesomeIcon color={token.colorPrimary} icon={faEye} />
            </Button>
            <Button
              onClick={() => {
                const base64Data = record.value.split(',')[1]
                const binaryData = atob(base64Data)
                const bytes = new Uint8Array(binaryData.length)
                for (let i = 0; i < binaryData.length; i++) {
                  bytes[i] = binaryData.charCodeAt(i)
                }
                const blob = new Blob([bytes], { type: 'application/pdf' })

                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = `${record.name}.pdf`
                link.click()

                URL.revokeObjectURL(link.href)
              }}
            >
              <FontAwesomeIcon icon={faDownload} color={token.colorSuccess} />
            </Button>
            <Button
              onClick={() => {
                setSelectedDocument(record.value)
              }}
            >
              <FontAwesomeIcon color={token.colorError} icon={faTrash} />
            </Button>
          </Space>
        )
      }
    ],
    // eslint-disable-next-line
    [documents, setSelectedDocument]
  )

  return (
    <Table
      size='small'
      bordered
      columns={columns}
      dataSource={documents}
      rowKey={(record) => record.id || record._id}
      loading={loadingDocuments}
      pagination={{
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} documents`
      }}
    />
  )
}

DocumentsTable.propTypes = {
  setSelectedDocument: PropTypes.func.isRequired,
  toggleModalState: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      _id: PropTypes.string,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      audit: PropTypes.shape({
        createdBy: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  loadingDocuments: PropTypes.bool.isRequired
}

export default DocumentsTable
