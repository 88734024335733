import { CATEGORIES } from './enums'
import { faFileAlt, faEye, faHeartbeat, faStethoscope, faVial, faFlask } from '@fortawesome/free-solid-svg-icons'

export const MODAL_WIDTH = 1920

export const FORM_LAYOUT = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
}

export const LIST_GRID_BREAKPOINTS = {
  xs: 24,
  sm: 24,
  md: 10,
  lg: 8,
  xl: 8,
  xxl: 6
}

export const FORM_GRID_BREAKPOINTS = {
  xs: 24,
  sm: 24,
  md: 10,
  lg: 16,
  xl: 16,
  xxl: 18
}

export const categoryIcons = {
  [CATEGORIES.TEMPLATES]: faFileAlt,
  [CATEGORIES.PREVIEW]: faEye,
  [CATEGORIES.VITALS]: faHeartbeat,
  [CATEGORIES.GENERAL]: faStethoscope,
  [CATEGORIES.RAPID]: faVial,
  [CATEGORIES.POC]: faFlask
}
