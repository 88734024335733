import React, { useState } from 'react'
import { Button, Card, Col, Row, Space, Switch } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faStar, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import MobileMacroRings from './MobileMacroRings'
import MobileCameraCapture from './MobCameraCapture'
import useMobileNewImage from '../hooks/useMobileNewImage'
import MobileHealthyAlternatives from './MobHealthyAlternatives'
import CustomRow from '../../../../../../reusable-components/CustomRow'
import PrevNextFloatingButtons from '../../../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import MobMedicalHistoryLoadingOverlay from '../../../../../Medical History/Mobile/components/LoadingOverlay'
import { useSelector } from 'react-redux'
import HealthAlternativeItem from './HealthAlternativeItem'

const MobileNewImageUpload = ({ setModalOpen, onBack }) => {
  const limits = useSelector((state) => state.patMacroTracker.limits)

  const {
    contactMacroAssistantAi,
    contactingAi,
    macroData,
    servings,
    setServings,
    aiError,
    setSaveToFavourites,
    saveToFavourites,
    uploadedImage,
    handleCreateNewMacroEntry,
    setSaveHealthyAlternative,
    saveHealthyAlternative,
    saving
  } = useMobileNewImage({
    setModalOpen
  })

  // Handle healthy alternative view
  const [showHealthyAlternative, setShowHealthyAlternative] = useState(false)

  return (
    <Row gutter={[16, 16]} style={{ paddingBottom: 100 }}>
      {contactingAi ? (
        <MobMedicalHistoryLoadingOverlay loading={true} saveLoading={false} text='Analysing meal...' />
      ) : (
        <>
          {showHealthyAlternative ? (
            <HealthAlternativeItem
              handleBack={() => setShowHealthyAlternative(false)}
              healthyAlternative={macroData.healthyAlternative}
            />
          ) : (
            <>
              {macroData ? (
                <>
                  <Col span={24}>
                    <Card
                      style={{
                        border: '1px solid rgba(16, 178, 225, 0.15)',
                        padding: '8px'
                      }}
                    >
                      <p
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold'
                        }}
                      >
                        Please note:
                      </p>
                      <p style={{ fontSize: '13px', lineHeight: '1.4', margin: '0' }}>
                        The nutrient data provided by this AI tool is intended for informational purposes only and may
                        not be fully accurate. It should not be used as a substitute for professional dietary advice. We
                        recommend consulting a qualified healthcare provider for personalized guidance.
                      </p>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card
                      style={{
                        border: '1px solid rgba(16, 178, 225, 0.15)',
                        padding: '8px'
                      }}
                    >
                      <img
                        src={uploadedImage}
                        alt='Meal preview'
                        style={{ width: '100%', borderRadius: '8px', display: 'block', marginBottom: '0' }}
                      />
                      <p
                        style={{
                          marginTop: 12,
                          fontSize: '14px',
                          lineHeight: '1.5'
                        }}
                      >
                        {macroData.description || 'No description available'}
                      </p>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Button
                      type='primary'
                      style={{ width: '100%' }}
                      onClick={() => {
                        setShowHealthyAlternative(true)
                      }}
                    >
                      Show Healthy Alternative <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Card
                      style={{
                        border: '1px solid rgba(16, 178, 225, 0.15)'
                      }}
                    >
                      <Space>
                        Save to favourite meals?
                        <Switch
                          onChange={setSaveToFavourites}
                          value={saveToFavourites}
                          checkedChildren={<FontAwesomeIcon icon={faStar} />}
                          unCheckedChildren='No'
                        />
                      </Space>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card
                      style={{
                        border: '1px solid rgba(16, 178, 225, 0.15)'
                      }}
                    >
                      <Space>
                        Save healthy alternative?
                        <Switch
                          onChange={setSaveHealthyAlternative}
                          value={saveHealthyAlternative}
                          checkedChildren={<FontAwesomeIcon icon={faStar} />}
                          unCheckedChildren='No'
                        />
                      </Space>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <MobileMacroRings
                      macroData={macroData}
                      servings={servings}
                      setServings={setServings}
                      limits={limits}
                      noBottomPadding={true}
                    />
                  </Col>
                  <PrevNextFloatingButtons
                    onPrev={() => setModalOpen(false)}
                    onNext={handleCreateNewMacroEntry}
                    onPrevDisabled={saving}
                    onNextLoading={saving}
                    prevText='Back'
                    nextText='Confirm'
                  />
                </>
              ) : (
                <Col>
                  <CustomRow>
                    <Col span={24}>
                      <p></p>
                    </Col>
                    <Col span={24}>
                      <MobileCameraCapture onBack={onBack} onAfterCapture={contactMacroAssistantAi} />
                    </Col>

                    <Col span={24}>
                      {aiError && (
                        <Card
                          style={{
                            backgroundColor: 'rgba(233, 30, 99, 0.1)',
                            border: '1px solid #e91e63',
                            color: '#e91e63',
                            textAlign: 'center'
                          }}
                        >
                          {aiError}. Please try again.
                        </Card>
                      )}
                    </Col>
                  </CustomRow>
                </Col>
              )}
            </>
          )}
        </>
      )}
    </Row>
  )
}

export default MobileNewImageUpload
