import React from 'react'
import { Typography, Button, Card, Carousel } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVial, faHeartPulse, faLungs, faDna, faVirus } from '@fortawesome/free-solid-svg-icons'
import '../../../styling/TestPromo.css'
import { useDispatch, useSelector } from 'react-redux'
import PatClinicConsultBooking from '../../../../../Clinic Consultation'
import CoreEnums from '../../../../../../../core/utils/enums'
import coreReducer from '../../../../../../../core/utils/reducer'

const { Title, Text } = Typography

// Get featured tests from all categories

const TestPromo = ({ onViewAll, onBookTest }) => {
  const dispatch = useDispatch()
  const handleClinicBooking = (key) => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.MY_BOOKINGS}_new_clinic_booking`,
        closable: true,
        label: CoreEnums.tabKeys.MY_BOOKINGS,
        children: <PatClinicConsultBooking isCaresync={true} testKey={key} />
      })
    )
  }
  const tests = useSelector((state) => state.careSyncTests.data)
  console.log('Tets', tests)
  const getFeaturedTests = () => {
    // Update icon mappings for test categories
    const testIcons = {
      tumorMarkers: { icon: faDna, color: '#FF6B6B' },
      cardiology: { icon: faHeartPulse, color: '#EF8D9C' },
      poc: { icon: faVial, color: '#65D2D8' },
      respiratory: { icon: faLungs, color: '#B5D777' },
      virology: { icon: faVirus, color: '#FF7676' }
    }

    // Filter featured tests from the tests array
    return tests
      .filter((test) => test.featured)
      .map((test) => ({
        id: test._id,
        name: test.name,
        description: test.description,
        price: `R${test.price.toFixed(2)}`,
        icon: testIcons[test.category]?.icon || faVial,
        iconColor: testIcons[test.category]?.color || '#65D2D8',
        test
      }))
  }
  const featuredTests = React.useMemo(() => getFeaturedTests(), [tests])

  console.log(featuredTests)
  return (
    <div className='cs-test-promo'>
      <div className='cs-test-promo__header'>
        <Title level={4}>Top Diagnostic Tests</Title>
        <Button type='link' onClick={onViewAll}>
          View All <RightOutlined />
        </Button>
      </div>

      <div className='cs-test-promo__carousel-wrapper'>
        <Carousel
          dots={false}
          infinite={true}
          speed={500}
          slidesToShow={2}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={5000}
          className='cs-test-promo__carousel'
        >
          {featuredTests.map((test) => (
            <div key={test.id}>
              <div className='cs-test-promo__slide'>
                <Card className='cs-test-promo__card'>
                  <div className='cs-test-promo__icon' style={{ backgroundColor: `${test.iconColor}20` }}>
                    <FontAwesomeIcon icon={test.icon} style={{ color: test.iconColor }} />
                  </div>
                  <div className='cs-test-promo__content'>
                    <Text strong className='cs-test-promo__name'>
                      {test.name}
                    </Text>
                    <Text type='secondary' className='cs-test-promo__description'>
                      {test.description}
                    </Text>
                    <Text strong className='cs-test-promo__price'>
                      {test.price}
                    </Text>
                    <Button type='primary' block onClick={() => handleClinicBooking(test.test.key)}>
                      Book Now
                    </Button>
                  </div>
                </Card>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default TestPromo
