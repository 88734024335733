import React, { useState } from 'react'
import { Card, Typography, Tag, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown, faMinus, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import '../../../../styling/StatsCard.css'

const { Text } = Typography

const StatsCard = ({ metrics, result, components }) => {
  const [expanded, setExpanded] = useState(false)

  if (!metrics || !metrics.current) return null

  const getTrendIcon = () => {
    if (metrics?.change?.trend === 'improving') return faArrowUp
    if (metrics?.change?.trend === 'worsening') return faArrowDown
    return faMinus
  }

  const getTrendClass = () => {
    if (metrics?.change?.trend === 'improving') return 'improving'
    if (metrics?.change?.trend === 'worsening') return 'worsening'
    return ''
  }

  return (
    <Card className='cs-stats-card'>
      <div className='cs-stats-card__current'>
        <Text type='secondary' className='cs-stats-card__label'>
          Current Value {result.isDemo && '(Demo)'}
        </Text>
        <div className='cs-stats-card__value-display'>
          <Text className='cs-stats-card__value'>{metrics.current?.value || '-'}</Text>
          <Text className='cs-stats-card__unit'>{metrics.current?.unit || ''}</Text>
          {/* <div className={`cs-stats-card__change cs-stats-card__change--${getTrendClass()}`}>
            <FontAwesomeIcon icon={getTrendIcon()} />
            <span>{metrics?.change?.value ? `${Math.abs(metrics.change.value).toFixed(1)}%` : '0%'}</span>
          </div> */}
        </div>
        {/* <div className='cs-stats-card__previous'>
          <Text type='secondary' className='cs-stats-card__previous-label'>
            Previous
          </Text>
          <div className='cs-stats-card__previous-value'>
            <Text>{metrics.previous?.value || '-'}</Text>
            <Text className='cs-stats-card__unit'>{metrics.previous?.unit || ''}</Text>
          </div>
        </div> */}
        {/* <Tag className={`cs-stats-card__status-tag ${metrics.status?.toLowerCase()}`}>
          {metrics?.status || 'Normal'}
        </Tag> */}
      </div>

      {result.isDemo && (
        <Text type='secondary' style={{ fontSize: '11px', margin: '12px 0', display: 'block', textAlign: 'center' }}>
          Demo data shown. Actual results will display your test details and components.
        </Text>
      )}
    </Card>
  )
}

export default StatsCard
