import React from 'react'
import MobMacroTracker from './Components/Mobile/components/MobMacroTracker'
import { deviceDetect } from 'react-device-detect'

const MacroTrackerWrapper = ({ userRef, isDoctor }) => {
  return deviceDetect().isMobile ? (
    <MobMacroTracker userRef={userRef} isDoctor={isDoctor} />
  ) : (
    // <DesktopMacroTracker userRef={userRef} isDoctor={isDoctor} />
    <></>
  )
}

export default MacroTrackerWrapper
