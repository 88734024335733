import React from 'react'
import { Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import TestCard from './TestCard'
import '../../styling/CategorySection.css'

const { Text } = Typography

const CategoryHeader = ({ title, count, expanded, onClick }) => (
  <button className='cs-category__header' onClick={onClick}>
    <div className='cs-category__title'>
      <h2 className='cs-category__name'>{title}</h2>
    </div>
    <div className='cs-category__actions'>
      <span className='cs-category__count-tag'>{count}</span>
      <FontAwesomeIcon
        icon={expanded ? faChevronDown : faChevronRight}
        className='cs-category__chevron'
        style={{ fontSize: '16px' }}
      />
    </div>
  </button>
)

const CategorySection = ({ category, tests, onTestSelect, onViewDetails, isExpanded, onToggleExpand }) => {
  return (
    <div className='cs-category'>
      <CategoryHeader
        title={category.name}
        count={tests.length}
        expanded={isExpanded}
        onClick={() => onToggleExpand(category.id)}
      />

      {isExpanded && (
        <div className='cs-category__content'>
          <div className='cs-category__tests'>
            {tests.map((test) => (
              <TestCard
                key={test.key}
                test={{
                  id: test._id,
                  code: test.key,
                  name: test.name,
                  description: test.description,
                  price: test.price,
                  processingTime: test.processingTime,
                  availability: test.availability,
                  featured: test.featured
                }}
                onClick={() => onTestSelect(test)}
                onViewDetails={() => onViewDetails(test)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default CategorySection
