import { useState } from 'react'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { saveMacroTrackerRecipe } from '../utils/macro-tracker-utils'
import { handleError } from '../../../../../../lib/utils'
import patMacroTrackerReducer from '../utils/reducer'

const useMobileHealthAlternatives = () => {
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  const [currentRecipe, setCurrentRecipe] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [saving, setSaving] = useState(false)
  const [cookingMode, setCookingMode] = useState(false)

  const handleSaveRecipe = async (recipe) => {
    setSaving(true)
    try {
      const newRecord = await saveMacroTrackerRecipe({
        ...recipe,
        userRef: authState.agiliteUser._id,
        createdAt: new Date()
      })
      dispatch(patMacroTrackerReducer.actions.addHealthyAlternativeEntry(newRecord))
      message.success('Recipe saved successfully')
      setCurrentRecipe(null)
    } catch (error) {
      message.error(handleError(error))
    }
    setSaving(false)
  }
  return {
    currentRecipe,
    handleSaveRecipe,
    saving,
    setCurrentRecipe,
    searchQuery,
    cookingMode,
    setCookingMode,
    setSearchQuery
  }
}

export default useMobileHealthAlternatives
