import { faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, DatePicker, Form, Row, Space, Switch } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import PropTypes from 'prop-types'
import { useSickNote } from './hooks/useSickNote'
import React from 'react'

const SickNoteWrapper = ({ setModalOpen, bookingData, userRef, patient, handleAfterSuccess }) => {
  const {
    loading,
    familyResponsibility,
    sickLeaveForm,
    handleGenerateSickLeaveTemplate,
    handleFamilyResponsibilityChange,
    formValues,
    handleFieldChange
  } = useSickNote({ setModalOpen, bookingData, patient, handleAfterSuccess })

  const [currentStep, setCurrentStep] = React.useState(0)

  const steps = [
    {
      title: 'Sick Note Name',
      content: (
        <Form.Item
          name='name'
          label='Sick Note Name'
          rules={[{ required: true, message: 'Please enter a name for this sick note' }]}
        >
          <TextArea
            placeholder='Enter a descriptive name (e.g. Soccer Injury, Flu, etc.)'
            maxLength={100}
            rows={2}
            showCount
          />
        </Form.Item>
      )
    },
    {
      title: 'Start Date',
      content: (
        <Form.Item name='startDate' rules={[{ required: true, message: 'Please select a start date' }]}>
          <DatePicker
            style={{ width: '100%' }}
            format='DD MMMM YYYY'
            placeholder='Select Start Date'
            className='date-picker'
          />
        </Form.Item>
      )
    },
    {
      title: 'End Date',
      content: (
        <Form.Item name='endDate' rules={[{ required: true, message: 'Please select an end date' }]}>
          <DatePicker
            style={{ width: '100%' }}
            format='DD MMMM YYYY'
            placeholder='Select End Date'
            className='date-picker'
          />
        </Form.Item>
      )
    },
    {
      title: 'Family Responsibility',
      content: (
        <>
          <Form.Item
            label='Do you need family responsibility leave?'
            name='familyResponsibility'
            valuePropName='checked'
          >
            <Switch
              checkedChildren='Yes'
              unCheckedChildren='No'
              onChange={handleFamilyResponsibilityChange}
              value={familyResponsibility}
            />
          </Form.Item>
          {familyResponsibility && (
            <Form.Item label='Comments' name='comments' help={`Maximum ${80} characters`}>
              <TextArea placeholder='Please provide additional details...' maxLength={80} rows={4} showCount />
            </Form.Item>
          )}
        </>
      )
    },
    {
      title: 'Summary',
      content: (
        <div className='summary-section'>
          <h3>Review Your Sick Note Details</h3>
          <div>
            <p>
              <strong>Sick Note Name:</strong> {formValues.name}
            </p>
            <p>
              <strong>Start Date:</strong> {formValues.startDate?.format('DD MMMM YYYY')}
            </p>
            <p>
              <strong>End Date:</strong> {formValues.endDate?.format('DD MMMM YYYY')}
            </p>
            <p>
              <strong>Family Responsibility:</strong> {formValues.familyResponsibility ? 'Yes' : 'No'}
            </p>
            {formValues.familyResponsibility && formValues.comments && (
              <p>
                <strong>Comments:</strong> {formValues.comments}
              </p>
            )}
          </div>
        </div>
      )
    }
  ]

  const next = async () => {
    try {
      let fieldsToValidate = []

      switch (currentStep) {
        case 0:
          fieldsToValidate = ['name']
          break
        case 1:
          fieldsToValidate = ['startDate']
          break
        case 2:
          fieldsToValidate = ['endDate']
          break
        case 3:
          fieldsToValidate = ['familyResponsibility']
          if (familyResponsibility) {
            fieldsToValidate.push('comments')
          }
          break
        default:
          fieldsToValidate = []
      }

      await sickLeaveForm.validateFields(fieldsToValidate)
      setCurrentStep(currentStep + 1)
    } catch (error) {
      // Validation failed
      console.error('Validation failed:', error)
    }
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  return (
    <Card style={{ width: '100%', margin: '0 auto' }}>
      <Form
        onFinish={handleGenerateSickLeaveTemplate}
        form={sickLeaveForm}
        layout='vertical'
        className='sick-note-form'
        onFieldsChange={handleFieldChange}
        style={{ width: '100%' }}
      >
        <div className='steps-content' style={{ padding: '20px 0' }}>
          <h2
            style={{
              marginBottom: '24px',
              fontSize: '1.5rem',
              borderBottom: '1px solid #f0f0f0',
              paddingBottom: '12px'
            }}
          >
            {steps[currentStep].title}
          </h2>
          <div style={{ maxWidth: '600px', margin: '0 auto' }}>{steps[currentStep].content}</div>
        </div>

        <Row justify='end' gutter={16} style={{ marginTop: 32, borderTop: '1px solid #f0f0f0', paddingTop: '20px' }}>
          <Col>
            <Space size='middle'>
              {currentStep > 0 && <Button onClick={prev}>Previous</Button>}
              {currentStep < steps.length - 1 ? (
                <Button type='primary' onClick={next}>
                  Next <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 8 }} />
                </Button>
              ) : (
                <Button
                  type='primary'
                  onClick={() => sickLeaveForm.submit()}
                  loading={loading}
                  icon={<FontAwesomeIcon icon={faCheck} />}
                >
                  Generate Sick Note
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

SickNoteWrapper.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  bookingData: PropTypes.object.isRequired,
  userRef: PropTypes.string,
  patient: PropTypes.object.isRequired
}

export default React.memo(SickNoteWrapper)
