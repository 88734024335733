import React from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { Button, Card, Col, Space, Table, theme } from 'antd'
import { hexToRGBA } from '../../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import CustomLoadingIcon from '../../../reusable-components/CustomLoadingIcon'
import PatientOverview from './PatientCarePlanOverview'
import { EnrolledPatientsTemplates } from './utils/templates'

const EnrolledPatients = ({
  setNewEnrollment,
  enrolledUsers,
  template,
  setCurrentTemplate,
  handleFetchEnrolledUsers,
  loadingPatients,
  currentPatient,
  setCurrentPatient,
  handleCancelEnrollment,
  handleRefresh
}) => {
  const { token } = theme.useToken()

  return (
    <Card
      style={{ marginTop: 12 }}
      title={
        template?.name +
          (currentPatient
            ? `: ${currentPatient.patientProfile.firstName} ${currentPatient.patientProfile.lastName}`
            : '') || 'NO NAME FOUND'
      }
      size='small'
      headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
    >
      <CustomRow>
        {currentPatient ? (
          <Col span={24}>
            <PatientOverview
              setCurrentPatient={setCurrentPatient}
              currentPatient={currentPatient}
              template={template}
              handleRefresh={handleRefresh}
            />
          </Col>
        ) : (
          <>
            <Col span={8}>
              <Card
                headStyle={{ background: hexToRGBA(token.colorSecondary, 0.2) }}
                style={{ marginBottom: 8 }}
                title='Visits'
                size='small'
              >
                <Space direction='vertical'>
                  {template?.tracking
                    ?.filter((i) => i.trackerType === 'visits')
                    ?.map((tracker) => (
                      <p>{tracker.dataLabel}</p>
                    ))}
                </Space>
              </Card>
              <Card
                style={{ marginBottom: 8 }}
                headStyle={{ background: hexToRGBA(token.colorSecondary, 0.2) }}
                title='Trackers'
                size='small'
              >
                <Space direction='vertical'>
                  {template?.tracking
                    ?.filter((i) => i.trackerType === 'data')
                    ?.map((tracker) => (
                      <p>{tracker.dataLabel}</p>
                    ))}
                </Space>
              </Card>
            </Col>
            <Col span={16}>
              <Card
                extra={
                  <Space>
                    <Button
                      type='primary'
                      style={{ background: token.colorSuccess }}
                      onClick={() => {
                        setNewEnrollment(true)
                      }}
                    >
                      Enroll Patient
                    </Button>
                    <Button
                      type='primary'
                      onClick={() => {
                        handleFetchEnrolledUsers()
                      }}
                    >
                      <FontAwesomeIcon icon={faRefresh} />
                    </Button>
                  </Space>
                }
                bodyStyle={{ padding: 0, overflow: 'scroll', maxHeight: 450 }}
                headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
                title='Enrolled Patients'
                size='small'
              >
                <CustomLoadingIcon
                  loadingText='Loading Patients...'
                  loading={loadingPatients}
                  content={
                    <Table
                      size='small'
                      bordered
                      dataSource={enrolledUsers}
                      columns={EnrolledPatientsTemplates(handleCancelEnrollment, setCurrentPatient).columnTemplate}
                    />
                  }
                />
              </Card>
            </Col>
            <Col span={24}>
              <Space style={{ float: 'right' }}>
                <Button
                  onClick={() => {
                    setCurrentTemplate(null)
                  }}
                >
                  Close
                </Button>
              </Space>
            </Col>
          </>
        )}
      </CustomRow>
    </Card>
  )
}

export default EnrolledPatients
