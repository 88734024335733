import State from './state'
import { createSlice } from '@reduxjs/toolkit'

const authReducer = createSlice({
  name: 'auth',
  initialState: State,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setAgiliteUser: (state, action) => {
      state.agiliteUser = action.payload
    },
    setConfig: (state, action) => {
      state.config = action.payload
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    },
    setProfessions: (state, action) => {
      state.professions = action.payload
    },
    setTerms: (state, action) => {
      state.terms = action.payload
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload
    },
    setCompleteEmailSignUp: (state, action) => {
      state.completeEmailSignUp = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export default authReducer
