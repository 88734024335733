import React, { useState, useEffect } from 'react'
import { Col, Form, Button, theme, Popconfirm, Tabs, message } from 'antd'
import Theme from '../../../../core/utils/theme'
import Templates from '../utils/templates'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import CustomRow from '../../../reusable-components/CustomRow'
import BillingMacroForm from './BillingMacroForm'
import useClinicsForm from '../hooks/useClinicsForm'
import { clinicFormTabs } from '../utils/constants'

const ClinicsForm = ({ data, isNewRecord, refreshView, isWizard, handleWizardNextPrevious }) => {
  const {
    clientForm,
    handleSubmit,
    handleFileChange,
    handleIsActive,
    handleGetAddress,
    handleUploadClick,
    inputRef,
    image,
    loading,
    specialFormState,
    setSpecialFormState,
    bpmProfiles,
    physicalAddress,
    macros,
    setMacros,
    macro,
    setMacro,
    macroIndex,
    macroFormOpen,
    setMacroFormOpen,
    isNewMacro,
    deactivatePrompt,
    confirmDeactivation,
    closeTab,
    handleNewMacro,
    handleMacroDelete,
    handleMacroClick
  } = useClinicsForm({ data, isNewRecord, refreshView, isWizard, handleWizardNextPrevious })

  const { token } = theme.useToken()

  const [unfilledFields, setUnfilledFields] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 })

  const validateFields = () => {
    const fieldsToValidate = {
      1: [Templates.dataModel.name.key, Templates.dataModel.clinicType.key, Templates.dataModel.paymentMethods.key],
      2: [
        Templates.dataModel.province.key,
        Templates.dataModel.address.key,
        Templates.dataModel.email.key,
        Templates.dataModel.phoneNumber.key
      ],
      3: [
        Templates.dataModel.bpmKey.key,
        Templates.dataModel.practiceName.key,
        Templates.dataModel.practiceRegNumber.key,
        Templates.dataModel.practiceNumber.key
      ],
      4: [
        ...(clientForm.getFieldValue(Templates.dataModel.cgmEnabled.key) ? [Templates.dataModel.cgmPracticeId.key] : [])
      ],
      5: [] // Assuming no required fields in Billing Macros
    }

    const newUnfilledFields = { ...unfilledFields }

    Object.keys(fieldsToValidate).forEach((tabKey) => {
      const fields = fieldsToValidate[tabKey]
      newUnfilledFields[tabKey] = fields.reduce((count, field) => {
        const value = clientForm.getFieldValue(field)
        return count + (value ? 0 : 1)
      }, 0)
    })

    setUnfilledFields(newUnfilledFields)
  }

  useEffect(() => {
    validateFields()
    clientForm.validateFields().then(() => validateFields())

    // eslint-disable-next-line
  }, [clientForm])

  const handleValuesChange = () => {
    validateFields()
  }

  const tabItems = clinicFormTabs({
    token,
    unfilledFields,
    handleNewMacro,
    handleMacroDelete,
    handleMacroClick,
    inputRef,
    handleFileChange,
    handleUploadClick,
    image,
    handleIsActive,
    loading,
    clientForm,
    specialFormState,
    setSpecialFormState,
    data,
    handleGetAddress,
    physicalAddress,
    macros,
    bpmProfiles,
    setMacros,
    macro,
    setMacro,
    macroIndex,
    macroFormOpen,
    setMacroFormOpen,
    isNewMacro
  })

  return (
    <ContainerCard title={isNewRecord ? 'Register a New Clinic' : 'Viewing Clinic'}>
      <CustomRow className='basic-card' id='clinic_form'>
        <Col span={24}>
          <Form
            onFinish={handleSubmit}
            onFinishFailed={() => {
              message.error('Required fields left unfilled. Please revise and resubmit.')
            }}
            name='form'
            form={clientForm}
            initialValues={{
              ...Templates.dataTemplate(),
              ...data
            }}
            layout='vertical'
            onValuesChange={handleValuesChange}
          >
            <Tabs
              defaultActiveKey='1'
              items={tabItems}
              tabBarExtraContent={{
                left: (
                  <Form.Item style={{ margin: 0 }}>
                    <Button onClick={closeTab} style={{ marginRight: 12 }}>
                      <FontAwesomeIcon icon={faXmark} color={token.colorBorder} />
                      Close
                    </Button>
                  </Form.Item>
                ),
                right: (
                  <Form.Item style={{ margin: 0 }}>
                    {deactivatePrompt ? (
                      <Popconfirm
                        okText='Yes'
                        cancelText='No'
                        okButtonProps={{ style: { background: token.colorSuccess, color: 'white' } }}
                        cancelButtonProps={{ style: { background: token.colorError, color: 'white' } }}
                        title={<h3 style={{ color: token.colorWarning, margin: 0 }}>WARNING</h3>}
                        description='You have switched this clinic to be inactive. Are you sure you want to proceed?'
                        onConfirm={confirmDeactivation}
                      >
                        <Button
                          style={{
                            backgroundColor: Theme.twitterBootstrap.success,
                            color: 'white',
                            margin: 0,
                            marginRight: 10
                          }}
                          htmlType='submit'
                          loading={loading}
                        >
                          {isNewRecord ? 'Add Clinic' : 'Save Changes'}
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: Theme.twitterBootstrap.success,
                          color: 'white',
                          marginRight: 10
                        }}
                        htmlType='submit'
                        loading={loading}
                      >
                        {isNewRecord ? 'Add Clinic' : 'Save Changes'}
                      </Button>
                    )}
                  </Form.Item>
                )
              }}
            />
          </Form>
        </Col>
      </CustomRow>
      <BillingMacroForm
        visible={macroFormOpen}
        setVisible={setMacroFormOpen}
        isNew={isNewMacro}
        macro={macro}
        setMacro={setMacro}
        macros={macros}
        setMacros={setMacros}
        index={macroIndex}
      />
    </ContainerCard>
  )
}

export default ClinicsForm
