import { useState } from 'react'
import { createMacroTrackers, saveMacroTrackerMeal, saveMacroTrackerRecipe } from '../utils/macro-tracker-utils'
import { useDispatch, useSelector } from 'react-redux'
import { uploadFile } from '../../../../../../Admin/config/utils/utils'
import { assistantInitiate } from '../../../../../../AI Tools/utils'
import { AssistantEnums } from '../../../../../../AI Tools/assitants-enums'
import { generateAIResponse } from '../../../../../../AI Tools/assistants-lib'
import { message } from 'antd'
import { handleError } from '../../../../../../lib/utils'
import patMacroTrackerReducer from '../utils/reducer'
import { uploadFileTEMP } from '../../../utils/file-api'

const useMobileNewImage = ({ setModalOpen }) => {
  const authState = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [contactingAi, setContactingAi] = useState(false)
  const [macroData, setMacroData] = useState(null)
  const [saveToFavourites, setSaveToFavourites] = useState(false)
  const [saving, setSaving] = useState(false)
  const [servings, setServings] = useState(1)
  const [uploadedImage, setUploadedImage] = useState(null)
  const [aiError, setAiError] = useState(null)
  const [saveHealthyAlternative, setSaveHealthyAlternative] = useState(false)
  const contactMacroAssistantAi = async (base64, file) => {
    setContactingAi(true)
    setAiError(null)
    try {
      const contentType = file.type
      const uploadResponse = await uploadFileTEMP('Test', contentType, file, false, true)
      const fileId = uploadResponse?._id
      const fileUrl = `https://nodered-metahealth-dev.agilite.io/api/images/${fileId}.jpeg`

      const aiInitiateResponse = await assistantInitiate(
        [
          {
            type: 'image_url',
            image_url: { url: fileUrl }
          }
        ],
        AssistantEnums.assistant_ids.MACRO_TRACKER
      )

      const aiContentResponse = await generateAIResponse(aiInitiateResponse)
      const tmpMacroData = JSON.parse(aiContentResponse?.data?.data[0]?.content[0]?.text?.value)

      if (!tmpMacroData.macros) {
        setAiError(tmpMacroData.error)
      } else {
        setUploadedImage(base64)
        setMacroData(tmpMacroData)
      }
    } catch (error) {
      message.error('Failed to analyze image. Please try again.')
    }
    setContactingAi(false)
  }

  const handleCreateNewMacroEntry = async () => {
    const payload = JSON.parse(JSON.stringify(macroData))

    for (let key in payload.macros) {
      if (payload.macros.hasOwnProperty(key)) {
        payload.macros[key] = payload.macros[key] * servings
      }
    }

    if (payload._id) {
      delete payload._id
      delete payload.createdAt
      delete payload.createdBy
      delete payload.userRef
    }

    payload.createdBy = authState.agiliteUser._id
    payload.userRef = authState.agiliteUser._id
    payload.servings = servings
    payload.image = uploadedImage

    setSaving(true)
    try {
      const newRecord = await createMacroTrackers(payload)
      if (saveToFavourites) {
        const newMealFavourite = await saveMacroTrackerMeal({
          ...payload,
          ...macroData,
          servings: 1
        })
        dispatch(patMacroTrackerReducer.actions.addSavedMealEntry(newMealFavourite))
      }
      if (saveHealthyAlternative) {
        const newHealthyAlternative = await saveMacroTrackerRecipe(macroData.healthyAlternative)
        dispatch(patMacroTrackerReducer.actions.addHealthyAlternativeEntry(newHealthyAlternative))
      }
      dispatch(patMacroTrackerReducer.actions.addTrackerDataEntry(newRecord))
      setModalOpen(false)
    } catch (error) {
      message.error(handleError(error))
    }
    setSaving(false)
  }

  return {
    contactMacroAssistantAi,
    contactingAi,
    macroData,
    servings,
    setServings,
    aiError,
    setSaveToFavourites,
    saveToFavourites,
    uploadedImage,
    handleCreateNewMacroEntry,
    saving,
    setSaveHealthyAlternative,
    saveHealthyAlternative
  }
}

export default useMobileNewImage
