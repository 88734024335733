import PatMedHistState from './state'
import { createSlice } from '@reduxjs/toolkit'

const patMedHistReducer = createSlice({
  name: 'patMedHist',
  initialState: PatMedHistState,
  reducers: {
    setState: (state, action) => {
      state.data = action.payload
    },

    updateState: (state, action) => {
      state.data = action.payload
    }
  }
})

export default patMedHistReducer
