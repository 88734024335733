import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCamera,
  faCheck,
  faPaperPlane,
  faEnvelope,
  faCircleCheck,
  faArrowRight,
  faSpinner,
  faEdit,
  faSignature,
  faMagicWandSparkles,
  faRobot,
  faBolt
} from '@fortawesome/free-solid-svg-icons'
import '../styling/MobileAdmitAI.css'
import { Button, Input, Radio, Space, message } from 'antd'
import SignaturePad from 'react-signature-canvas'

const MobileAdmitAI = () => {
  const [currentStep, setCurrentStep] = useState('intro') // intro, capture, review, check, sign, submit
  const [formImage, setFormImage] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [missingFields, setMissingFields] = useState([])
  const [showEmailInput, setShowEmailInput] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(1)
  const fileInputRef = useRef(null)
  const [answers, setAnswers] = useState({})
  const [signature, setSignature] = useState(null)
  const signaturePadRef = useRef(null)
  const [formType, setFormType] = useState(null)
  const [hospitalGroup, setHospitalGroup] = useState(null)
  const [selectedOption, setSelectedOption] = useState(null)

  // Dummy patient data
  const patientData = {
    name: 'Grayson Schoeman',
    dob: '25-08-2016',
    address: '41 Dolphin Drive',
    phone: '067 426 4553',
    insurance: 'Discovery Health'
  }

  // Dummy missing fields that AI couldn't complete
  const dummyMissingFields = [
    {
      id: 1,
      question: 'Have you had any surgeries in the past 5 years?',
      type: 'choice',
      options: ['Yes', 'No']
    },
    {
      id: 2,
      question: 'Do you have any allergies?',
      type: 'choice',
      options: ['Yes', 'No']
    },
    {
      id: 3,
      question: 'Are you currently taking any medications?',
      type: 'choice',
      options: ['Yes', 'No']
    },
    {
      id: 4,
      question: 'Do you smoke?',
      type: 'choice',
      options: ['Yes', 'No', 'Former smoker']
    },
    {
      id: 5,
      question: 'Please list any current medications and dosages',
      type: 'input',
      placeholder: 'E.g., Aspirin 81mg daily'
    },
    {
      id: 6,
      question: 'Emergency contact name and relationship',
      type: 'input',
      placeholder: 'E.g., Jane Doe (Sister)'
    }
  ]

  const handleCapture = (event) => {
    const file = event.target.files?.[0]
    if (file) {
      setProcessing(true)
      const reader = new FileReader()
      reader.onload = () => {
        setFormImage(reader.result)
        // Simulate AI processing
        setTimeout(() => {
          setProcessing(false)
          setCurrentStep('review')
          setMissingFields(dummyMissingFields)
        }, 2000)
      }
      reader.readAsDataURL(file)
    }
  }

  const renderIntroStep = () => (
    <div className='intro-step'>
      <div className='intro-card'>
        <div className='intro-icons'>
          <FontAwesomeIcon icon={faRobot} className='intro-icon robot' />
          <FontAwesomeIcon icon={faMagicWandSparkles} className='intro-icon wand' />
          <FontAwesomeIcon icon={faBolt} className='intro-icon bolt' />
        </div>
        <h2>Welcome to AdmitAI</h2>
        <div className='intro-features'>
          <div className='feature-item'>
            <FontAwesomeIcon icon={faCamera} className='feature-icon' />
            <p>Snap a photo of any medical form</p>
          </div>
          <div className='feature-item'>
            <FontAwesomeIcon icon={faMagicWandSparkles} className='feature-icon' />
            <p>AI automatically fills it out</p>
          </div>
          <div className='feature-item'>
            <FontAwesomeIcon icon={faCheck} className='feature-icon' />
            <p>Review and submit in seconds</p>
          </div>
        </div>
        <p className='intro-tagline'>Never spend time filling in forms again!</p>
      </div>
      <Button
        type='primary'
        onClick={() => setCurrentStep('form-selection')}
        icon={<FontAwesomeIcon icon={faArrowRight} />}
        className='get-started-button'
      >
        Get Started
      </Button>
    </div>
  )

  const renderFormTypeSelection = () => (
    <div className='selection-step'>
      <div className='selection-card'>
        <h2>Select Form Type</h2>
        <div className='selection-options'>
          <Button className='selection-button' onClick={() => setCurrentStep('hospital-selection')}>
            <span>Hospital</span>
          </Button>
          <Button
            className='selection-button'
            onClick={() => {
              setFormType('doctor')
              setCurrentStep('capture')
            }}
          >
            <span>Doctor's Practice</span>
          </Button>
          <Button
            className='selection-button'
            onClick={() => {
              setFormType('physio')
              setCurrentStep('capture')
            }}
          >
            <span>Physiotherapy</span>
          </Button>
        </div>
      </div>
    </div>
  )

  const renderHospitalSelection = () => (
    <div className='selection-step'>
      <div className='selection-card'>
        <h2>Select Hospital Group</h2>
        <div className='selection-options'>
          <Button
            className={`selection-button ${selectedOption === 'life' ? 'selected' : ''}`}
            onClick={() => {
              setSelectedOption('life')
              setTimeout(() => {
                setFormType('hospital')
                setHospitalGroup('life')
                setCurrentStep('capture')
              }, 300)
            }}
          >
            <span>Life Healthcare</span>
          </Button>
          <Button
            className={`selection-button ${selectedOption === 'mediclinic' ? 'selected' : ''}`}
            onClick={() => {
              setSelectedOption('mediclinic')
              setTimeout(() => {
                setFormType('hospital')
                setHospitalGroup('mediclinic')
                setCurrentStep('capture')
              }, 300)
            }}
          >
            <span>Mediclinic</span>
          </Button>
          <Button
            className={`selection-button ${selectedOption === 'netcare' ? 'selected' : ''}`}
            onClick={() => {
              setSelectedOption('netcare')
              setTimeout(() => {
                setFormType('hospital')
                setHospitalGroup('netcare')
                setCurrentStep('capture')
              }, 300)
            }}
          >
            <span>Netcare</span>
          </Button>
        </div>
      </div>
    </div>
  )

  const renderCaptureStep = () => (
    <div className='capture-step'>
      <div className='capture-card'>
        <FontAwesomeIcon icon={faCamera} className='step-icon' />
        <h2>Snap Your Form</h2>
        <p>Take a clear photo of your admission form</p>
        <input
          type='file'
          accept='image/*'
          capture='environment'
          onChange={handleCapture}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />
        <Button type='primary' onClick={() => fileInputRef.current?.click()} icon={<FontAwesomeIcon icon={faCamera} />}>
          Take Photo
        </Button>
      </div>
    </div>
  )

  const renderReviewStep = () => (
    <div className='review-step'>
      <div className='review-card'>
        <h2>AI Analysis Complete</h2>
        <div className='detected-fields'>
          {Object.entries(patientData).map(([key, value]) => (
            <div key={key} className='detected-field'>
              <FontAwesomeIcon icon={faCheck} className='check-icon' />
              <span className='field-value'>{value}</span>
            </div>
          ))}
        </div>
        <Button type='primary' onClick={() => setCurrentStep('check')} icon={<FontAwesomeIcon icon={faArrowRight} />}>
          Continue to Check
        </Button>
      </div>
    </div>
  )

  const handleNextQuestion = () => {
    if (currentQuestion < missingFields.length) {
      setCurrentQuestion((prev) => prev + 1)
    } else {
      setCurrentStep('sign')
    }
  }

  const handlePreviousQuestion = () => {
    if (currentQuestion > 1) {
      setCurrentQuestion((prev) => prev - 1)
    }
  }

  const handleAnswerSelect = (value) => {
    setAnswers((prev) => ({
      ...prev,
      [currentQuestion]: value
    }))

    setTimeout(() => {
      if (currentQuestion === missingFields.length) {
        setCurrentStep('sign')
      } else {
        handleNextQuestion()
      }
    }, 300)
  }

  const renderCheckStep = () => (
    <div className='check-step'>
      <div className='check-header'>
        <h2>Just a Few More Details</h2>
        <div className='progress-text'>
          Question {currentQuestion} of {missingFields.length}
        </div>
        <div className='progress-dots'>
          {missingFields.map((_, index) => (
            <div
              key={index}
              className={`progress-dot ${index === currentQuestion - 1 ? 'active' : ''} 
                         ${index < currentQuestion - 1 ? 'completed' : ''}`}
            />
          ))}
        </div>
      </div>
      <div className='check-card'>
        {missingFields[currentQuestion - 1] && (
          <div className='field-item'>
            <h3>{missingFields[currentQuestion - 1].question}</h3>
            {missingFields[currentQuestion - 1].type === 'choice' ? (
              <Radio.Group
                className='choice-group'
                value={answers[currentQuestion]}
                onChange={(e) => handleAnswerSelect(e.target.value)}
              >
                {missingFields[currentQuestion - 1].options.map((option) => (
                  <Radio.Button key={option} value={option}>
                    {option}
                  </Radio.Button>
                ))}
              </Radio.Group>
            ) : (
              <div className='input-with-continue'>
                <Input.TextArea
                  placeholder={missingFields[currentQuestion - 1].placeholder}
                  autoSize={{ minRows: 2, maxRows: 4 }}
                />
                <Button
                  type='primary'
                  className='theme-button primary'
                  onClick={currentQuestion === missingFields.length ? () => setCurrentStep('sign') : handleNextQuestion}
                  icon={<FontAwesomeIcon icon={faArrowRight} />}
                >
                  Continue
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )

  const clearSignature = () => {
    signaturePadRef.current.clear()
    setSignature(null)
  }

  const saveSignature = () => {
    if (signaturePadRef.current.isEmpty()) {
      message.error('Please sign before continuing')
      return
    }
    setSignature(signaturePadRef.current.getTrimmedCanvas().toDataURL('image/png'))
    setCurrentStep('submit')
  }

  const renderSignStep = () => (
    <div className='sign-step'>
      <div className='sign-card'>
        <FontAwesomeIcon icon={faSignature} className='step-icon' />
        <h2>Sign Your Form</h2>
        <div className='signature-pad-container'>
          <SignaturePad
            ref={signaturePadRef}
            canvasProps={{
              className: 'signature-canvas'
            }}
            backgroundColor='rgba(16, 178, 225, 0.05)'
            penColor='#10B2E1'
            dotSize={2}
            velocityFilterWeight={0.7}
          />
        </div>
        <Space>
          <Button onClick={clearSignature} icon={<FontAwesomeIcon icon={faEdit} />}>
            Clear
          </Button>
          <Button type='primary' onClick={saveSignature} icon={<FontAwesomeIcon icon={faPaperPlane} />}>
            Continue to Submit
          </Button>
        </Space>
      </div>
    </div>
  )

  const handleSubmit = () => {
    setProcessing(true)
    setTimeout(() => {
      setProcessing(false)
      setSubmitted(true)

      // Reset the form after 3 seconds
      setTimeout(() => {
        // Reset all states to initial values
        setCurrentStep('intro')
        setFormImage(null)
        setMissingFields([])
        setShowEmailInput(false)
        setEmailAddress('')
        setSubmitted(false)
        setCurrentQuestion(1)
        setAnswers({})
        setSignature(null)
        if (signaturePadRef.current) {
          signaturePadRef.current.clear()
        }
      }, 3000)
    }, 2000)
  }

  const handleEmail = () => {
    if (!emailAddress) {
      message.error('Please enter an email address')
      return
    }
    setProcessing(true)
    setTimeout(() => {
      setProcessing(false)
      message.success('Form sent to ' + emailAddress)
      setShowEmailInput(false)
    }, 2000)
  }

  const renderSubmitStep = () => (
    <div className='submit-step'>
      <div className='submit-card'>
        {submitted ? (
          <div className='success-animation'>
            <FontAwesomeIcon icon={faCircleCheck} className='success-icon' />
            <h2>All Done!</h2>
            <p>Your form has been submitted successfully</p>
          </div>
        ) : (
          <>
            <h2>Ready to Submit</h2>
            <Space direction='vertical' style={{ width: '100%' }}>
              <Button
                type='primary'
                block
                onClick={handleSubmit}
                icon={<FontAwesomeIcon icon={faPaperPlane} />}
                loading={processing}
              >
                Submit Form
              </Button>
              <Button block onClick={() => setShowEmailInput(true)} icon={<FontAwesomeIcon icon={faEnvelope} />}>
                Email Form
              </Button>
            </Space>
            {showEmailInput && (
              <div className='email-input'>
                <Input
                  className='input-field'
                  placeholder='Enter email address'
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
                <Button type='primary' onClick={handleEmail} loading={processing}>
                  Send
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 'intro':
        return renderIntroStep()
      case 'form-selection':
        return renderFormTypeSelection()
      case 'hospital-selection':
        return renderHospitalSelection()
      case 'capture':
        return renderCaptureStep()
      case 'review':
        return renderReviewStep()
      case 'check':
        return renderCheckStep()
      case 'sign':
        return renderSignStep()
      case 'submit':
        return renderSubmitStep()
      default:
        return renderCaptureStep()
    }
  }

  return (
    <div className='form-completion-container'>
      {processing && (
        <div className='processing-overlay'>
          <FontAwesomeIcon icon={faSpinner} spin className='processing-icon' />
          <p>Processing your form...</p>
        </div>
      )}
      {renderCurrentStep()}
    </div>
  )
}

export default MobileAdmitAI
