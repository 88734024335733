import { useState, useCallback } from 'react'
import { message } from 'antd'
import { createDataPointTemplate } from '../../../../../Data-Point-Templates/utils'
import { handleError } from '../../../../../lib/utils'
import { useDispatch } from 'react-redux'
import { ExamTemplatesReducer } from '../../reducers/reducers'

export const useTemplates = () => {
  const dispatch = useDispatch()
  const [activeTemplate, setActiveTemplate] = useState(null)
  const [isAddingTemplate, setIsAddingTemplate] = useState(false)
  const [newTemplateName, setNewTemplateName] = useState('')

  const handleSaveNewTemplate = useCallback(
    async (selectedKeys) => {
      if (!newTemplateName) {
        message.error('Please enter a template name')
        return
      }

      if (!selectedKeys.length) {
        message.error('Please select at least one data point')
        return
      }

      try {
        const newTemplate = await createDataPointTemplate({
          isActive: true,
          name: newTemplateName,
          keys: selectedKeys,
          isGlobal: false
        })
        setActiveTemplate(newTemplate)
        dispatch(ExamTemplatesReducer.actions.submitRecord(newTemplate))
        message.success('Template created successfully')
        return newTemplate
      } catch (error) {
        message.error(handleError(error))
        throw error
      }
    },
    [newTemplateName, dispatch]
  )

  return {
    activeTemplate,
    setActiveTemplate,
    isAddingTemplate,
    setIsAddingTemplate,
    newTemplateName,
    setNewTemplateName,
    handleSaveNewTemplate
  }
}
