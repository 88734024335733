import React from 'react'
import { Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVial } from '@fortawesome/free-solid-svg-icons'
import '../../../styling/TestSelection.css'

const { Text } = Typography

const TestSelection = ({ onSelect }) => {
  const commonTests = [
    {
      id: 'cbc',
      name: 'Complete Blood Count',
      description: 'Basic blood screening test',
      preparation: 'Fast for 8 hours before the test'
    },
    {
      id: 'metabolic',
      name: 'Metabolic Panel',
      description: 'Checks kidney and liver function',
      preparation: 'Fast for 12 hours before the test'
    },
    {
      id: 'lipid',
      name: 'Lipid Panel',
      description: 'Measures cholesterol levels',
      preparation: 'Fast for 12 hours before the test'
    }
  ]

  return (
    <div className='cs-test-selection'>
      <div className='cs-test-selection__list'>
        {commonTests.map((test) => (
          <div 
            key={test.id} 
            className='cs-test-selection__item' 
            onClick={() => onSelect(test)}
          >
            <div className='cs-test-selection__item-content'>
              <div className='cs-test-selection__icon'>
                <FontAwesomeIcon icon={faVial} />
              </div>
              <div className='cs-test-selection__info'>
                <Text className='cs-test-selection__title'>{test.name}</Text>
                <Text className='cs-test-selection__description'>{test.description}</Text>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TestSelection
