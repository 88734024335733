import Agilite from 'agilite'
import CoreEnums from '../../../core/utils/enums'
import { ExaminationEnums } from './examination-enums'
import store from '../../../store'
import { readSystemUsers } from '../../Admin/system-users/utils/utils'
import dayjs from 'dayjs'
import axios from 'axios'
import { isDoctor } from '../../lib/profile-utils'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const createClinicDataRecords = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_DATA_RECORDS,
          ExaminationEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const invalidateClinicDataRecords = (recordId, reason) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_DATA_RECORDS,
          ExaminationEnums.routeKeys.UPDATE,
          {
            recordId,
            data: JSON.stringify({
              invalidatedReason: reason,
              invalid: true,
              invalidatedAt: dayjs(),
              invalidatedBy: store.getState().auth.agiliteUser._id
            })
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
export const updateClinicDataRecords = (recordId, data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_DATA_RECORDS,
          ExaminationEnums.routeKeys.UPDATE,
          {
            recordId,
            data: JSON.stringify({ value: data })
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const bulkWriteClinicalEntries = (bulkWriteArray) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_DATA_RECORDS,
          ExaminationEnums.routeKeys.BULK_WRITE,
          {
            data: JSON.stringify(bulkWriteArray)
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const readClinicalEntries = (filter, options, projection, page, pageLimit, includeInvalid) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_DATA_RECORDS,
          ExaminationEnums.routeKeys.READ,
          {
            filter: JSON.stringify(includeInvalid ? { ...filter } : { ...filter, invalid: { $ne: true } }),
            options: JSON.stringify(options),
            projection,
            page,
            pageLimit
          }
        )
        resolve(response.data)
        resolve([])
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const createClinicalNote = (note, bookingData, userId) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    const userRef = bookingData ? bookingData.userRef : userId
    const createdBy = store.getState().auth.agiliteUser._id
    const createdAt = new Date()
    const bookingRef = bookingData?._id || null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_NOTES,
          ExaminationEnums.routeKeys.CREATE,
          {
            data: JSON.stringify({
              createdBy,
              createdAt,
              userRef,
              bookingRef,
              isDoctor: isDoctor(),
              content: note
            })
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
export const updateClinicalNote = (recordId, note) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    const updatedAt = new Date()

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_NOTES,
          ExaminationEnums.routeKeys.UPDATE,
          {
            recordId,
            data: JSON.stringify({
              updatedAt,
              content: note
            })
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const readClinicalNotes = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    let users = []

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_NOTES,
          ExaminationEnums.routeKeys.READ,
          {
            filter: JSON.stringify(filter)
          }
        )
        users = await readSystemUsers(
          { _id: { $in: response.data.map((note) => note.createdBy) } },
          null,
          null,
          null,
          true
        )
        response.data.forEach((entry) => {
          const user = users.find((i) => i._id === entry.createdBy)
          entry.createdByFullName = `${user?.firstName} ${user?.lastName}`
          entry.profession = user?.extraData?.profession
        })
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const createClinicalReportRecord = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_REPORT_RECORDS,
          ExaminationEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const updateClinicalReportRecord = (filter, data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_REPORT_RECORDS,
          ExaminationEnums.routeKeys.UPDATE,
          {
            filter: JSON.stringify(filter),
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const readClinicalReportRecords = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_REPORT_RECORDS,
          ExaminationEnums.routeKeys.READ,
          {
            filter: JSON.stringify(filter)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const deleteClinicalReportRecord = (reportId) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_REPORT_RECORDS,
          ExaminationEnums.routeKeys.CREATE,
          {
            recordId: reportId
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const saveSickNote = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.SICK_NOTES,
          ExaminationEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const readSickNotes = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.SICK_NOTES,
          ExaminationEnums.routeKeys.READ,
          {
            filter: JSON.stringify(filter)
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const deleteSickNote = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.SICK_NOTES,
          ExaminationEnums.routeKeys.DELETE,
          {
            recordId
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const groupData = (data) => {
  let tmpData = JSON.parse(JSON.stringify(data))

  tmpData = tmpData.reduce((acc, record) => {
    const { key } = record
    if (!acc[key]) {
      acc[key] = { key, records: [] }
    }
    acc[key].records.push(record)
    return acc
  }, {})

  tmpData = Object.values(tmpData).map((item) => item)

  tmpData.forEach((dataPoint) => {
    dataPoint.records.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
  })

  return tmpData
}

export const readClinicalNotesTemplates = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_NOTES_TEMPLATES,
          ExaminationEnums.routeKeys.READ,
          {
            filter: JSON.stringify(filter)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const createCinicalNotesTemplates = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    data.createdAt = new Date()
    data.createdBy = store.getState().auth.agiliteUser._id
    data.updatedAt = new Date()
    data.updatedBy = store.getState().auth.agiliteUser._id
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_NOTES_TEMPLATES,
          ExaminationEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const updateCinicalNotesTemplates = (recordId, data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    data.updatedAt = new Date()
    data.updatedBy = store.getState().auth.agiliteUser._id
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.CLINICAL_NOTES_TEMPLATES,
          ExaminationEnums.routeKeys.UPDATE,
          {
            recordId,
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const findBookingByIDUpdate = (recordId, data, options) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('bookings', 'by_id_update', {
          recordId,
          data: JSON.stringify(data),
          options
        })
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const createReferralLetter = (data) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    data.createdAt = new Date()
    data.createdBy = store.getState().auth.agiliteUser._id
    data.updatedAt = new Date()
    data.updatedBy = store.getState().auth.agiliteUser._id
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.REFERRAL_LETTERS,
          ExaminationEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
export const readReferralLetter = (qry) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ExaminationEnums.profileKeys.REFERRAL_LETTERS,
          ExaminationEnums.routeKeys.READ,
          {
            filter: JSON.stringify(qry)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const whisperCreate = async (file) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post('https://nodered-metahealth-dev.agilite.io/api/ai/tools/whisper', file, {
          headers: {
            'Content-Type': 'multipart/form-data',
            model: 'whisper-1',
            Authorization: 'Bearer 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'
          }
        })

        if (response.data) {
          resolve(response.data?.text)
        } else {
          throw new Error('There was an issue contacting your assistant.')
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}
