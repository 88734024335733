import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { createDocument, generateSickNote } from '../../utils/api'
import { Form, message } from 'antd'
import { handleError } from '../../../../../lib/utils'
import { documentsTypes, pdfGenerationEnums } from '../../utils/enums'
import { MODAL_STATES } from '../../utils/constants'
import dayjs from 'dayjs'

export const useSickNote = ({ setModalOpen, bookingData, userRef, patient, handleAfterSuccess }) => {
  const { agiliteUser } = useSelector((state) => state.auth)
  const { signature, mpNumber } = agiliteUser.extraData
  const [loading, setLoading] = useState(false)
  const [familyResponsibility, setFamilyResponsibility] = useState(false)
  const [sickLeaveForm] = Form.useForm()

  const [formValues, setFormValues] = useState({
    name: '',
    startDate: null,
    endDate: null,
    familyResponsibility: false,
    comments: ''
  })

  const handleFieldChange = useCallback((changedFields, allFields) => {
    const newValues = {}
    changedFields.forEach((field) => {
      newValues[field.name[0]] = field.value
    })
    setFormValues((prev) => ({
      ...prev,
      ...newValues
    }))
  }, [])

  const handleCancel = useCallback(() => {
    sickLeaveForm.resetFields()
    setFormValues({
      name: '',
      startDate: null,
      endDate: null,
      familyResponsibility: false,
      comments: ''
    })
    setModalOpen(false)
  }, [sickLeaveForm, setModalOpen])

  const validateRequirements = useCallback(() => {
    if (!signature) {
      message.error('No Signature has been set. Please set your Signature in your Personal Details Medical Profile.')
      return false
    }

    if (!mpNumber) {
      message.error('No MP Number has been set. Please set your MP Number in your Personal Details Medical Profile.')
      return false
    }

    return true
  }, [signature, mpNumber])

  const handleFamilyResponsibilityChange = useCallback((value) => {
    setFamilyResponsibility(value)
  }, [])

  const handleGenerateSickLeaveTemplate = useCallback(async () => {
    setLoading(true)

    try {
      if (!validateRequirements()) {
        setLoading(false)
        return
      }

      // Resolves with base64 data
      const response = await generateSickNote(
        bookingData,
        {
          ...formValues,
          audit: {
            createdAt: new Date().toISOString(),
            createdBy: agiliteUser._id
          }
        },
        signature,
        pdfGenerationEnums.pdfGeneration.SICK_LEAVE_STANDARD,
        patient
      )

      const newRecord = await createDocument(
        response,
        {
          name: `Sick Note (${dayjs().format('DD-MM-YYYY')})`,
          userRef: bookingData ? bookingData.userRef : userRef,
          bookingRef: bookingData ? bookingData._id : ''
        },
        documentsTypes.SICK_NOTE
      )

      handleAfterSuccess(MODAL_STATES.SICK_NOTE, newRecord)
    } catch (e) {
      message.error(handleError(e, true))
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [bookingData, patient, signature, formValues, validateRequirements, agiliteUser.id])

  return {
    loading,
    familyResponsibility,
    sickLeaveForm,
    handleCancel,
    handleGenerateSickLeaveTemplate,
    handleFamilyResponsibilityChange,
    formValues,
    handleFieldChange
  }
}
