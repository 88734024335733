import { faBookMedical, faEarListen, faHistory, faPencil, faRobot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import DictaphoneControls from '../../../../lib/dictaphone-controls'
import { hexToRGBA } from '../../../../lib/utils'
import { Button, Popconfirm, Tooltip } from 'antd'
import Dictaphone from '../../../../lib/dictaphone'
import { ERROR_MESSAGES } from '../utils/constants'

const buttonBaseStyle = {
  width: '100%'
}

const gradientButtonStyle = (isDisabled) => ({
  ...buttonBaseStyle,
  background: isDisabled ? '' : `linear-gradient(45deg, ${hexToRGBA('#13B1E1', 0.2)}, ${hexToRGBA('#F14F8A', 0.2)})`
})

const blueButtonStyle = (isDisabled) => ({
  ...buttonBaseStyle,
  background: isDisabled ? '' : hexToRGBA('#13B1E1', 0.2)
})

const ClinicalNotesControls = ({
  setShowClinicalNotesTemplates,
  noBooking,
  handleUpdateNote,
  setShowHistorical,
  noteState,
  setNoteState,
  handleCreateNote,
  handleAutoGenerateNotes,
  setShowAiTranscription
}) => {
  const isDisabled = useMemo(() => {
    return (
      noteState.isAutoGenerating ||
      noteState.updatingError ||
      noteState.fetchingError ||
      noteState.isUpdating ||
      noteState.isCreating ||
      noteState.isSubmitting ||
      !noteState.noteId
    )
  }, [noteState])

  const [dictaphoneState, setDictaphoneState] = useState({
    isRecording: false,
    tmpTranscript: ''
  })

  const handleTranscriptionStart = () => {
    setDictaphoneState((prev) => ({ ...prev, isRecording: true }))
  }

  const handleAIListeningStart = () => {
    setShowAiTranscription(true)
  }

  const handleTemplatesOpen = () => {
    setShowClinicalNotesTemplates(true)
  }

  const handleNoteStateUpdate = (value) => {
    setNoteState((prev) => ({ ...prev, currentNote: value }))
  }

  const handleTranscriptUpdate = (value) => {
    setDictaphoneState((prev) => ({ ...prev, tmpTranscript: value }))
  }

  const handleRecordingUpdate = (value) => {
    setDictaphoneState((prev) => ({ ...prev, isRecording: value }))
  }

  if (noBooking) return null

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8, width: '100%', padding: 8 }}>
        <Button disabled={isDisabled} style={buttonBaseStyle} onClick={() => setShowHistorical(true)}>
          Show History <FontAwesomeIcon icon={faHistory} />
        </Button>

        {noteState.noteId && (
          <Tooltip title={!noteState.currentNote ? ERROR_MESSAGES.NO_NOTE_EMPTY : ''}>
            <Popconfirm
              overlayStyle={{ maxWidth: 450 }}
              onConfirm={handleCreateNote}
              okText='I am sure'
              title='Add New Note'
              description={ERROR_MESSAGES.ADD_NOTE_CONFIRM}
            >
              <Button style={blueButtonStyle(isDisabled)} disabled={!noteState.currentNote || isDisabled}>
                Add Note <FontAwesomeIcon icon={faPencil} />
              </Button>
            </Popconfirm>
          </Tooltip>
        )}

        <Button disabled={isDisabled} style={blueButtonStyle(isDisabled)} onClick={handleTemplatesOpen}>
          Templates <FontAwesomeIcon icon={faBookMedical} />
        </Button>

        {dictaphoneState.isRecording ? (
          <Dictaphone
            buttonStyle={blueButtonStyle(isDisabled)}
            noteId={noteState.noteId}
            handleUpdateNote={handleUpdateNote}
            value={noteState.currentNote}
            setValue={handleNoteStateUpdate}
            tmpTranscript={dictaphoneState.tmpTranscript}
            setTmpTranscript={handleTranscriptUpdate}
            setIsRecording={handleRecordingUpdate}
            isRecording={dictaphoneState.isRecording}
            disabled={isDisabled}
          />
        ) : (
          <DictaphoneControls
            buttonStyle={blueButtonStyle(isDisabled)}
            disabled={isDisabled}
            startListening={handleTranscriptionStart}
          />
        )}

        <Button style={gradientButtonStyle(isDisabled)} disabled={isDisabled} onClick={handleAutoGenerateNotes}>
          Generate Note (AI) <FontAwesomeIcon icon={faRobot} />
        </Button>

        <Button disabled={isDisabled} style={gradientButtonStyle(isDisabled)} onClick={handleAIListeningStart}>
          Transcribe (AI) <FontAwesomeIcon icon={faEarListen} />
        </Button>
      </div>
    </div>
  )
}

ClinicalNotesControls.propTypes = {
  setModalState: PropTypes.func.isRequired,
  noBooking: PropTypes.bool.isRequired,
  handleUpdateNote: PropTypes.func.isRequired,
  setShowHistorical: PropTypes.func.isRequired,
  noteState: PropTypes.shape({
    noteId: PropTypes.string,
    currentNote: PropTypes.string
  }).isRequired,
  setNoteState: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleCreateNote: PropTypes.func.isRequired,
  handleAutoGenerateNotes: PropTypes.func.isRequired,
  transcriptionState: PropTypes.shape({
    isRecording: PropTypes.bool.isRequired,
    tmpTranscript: PropTypes.string.isRequired
  }).isRequired,
  setTranscriptionState: PropTypes.func.isRequired
}

export default memo(ClinicalNotesControls)
