import { useCallback, useEffect, useMemo, useState } from 'react'
import { readClinicDataPoints } from '../../../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { message } from 'antd'
import { handleError } from '../../../../lib/utils'
import {
  createCinicalNotesTemplates,
  readClinicalNotesTemplates,
  updateCinicalNotesTemplates
} from '../../../examination-utils/examination-lib'
import { useSelector } from 'react-redux'
import { CLINICAL_NOTES_TEMPLATES_INITIAL_STATE } from '../utils/constants'

const useClinicalNotesTemplates = ({ setValue, setModalOpen, debouncedNoteCapture, setUpdatingNotes, noteId }) => {
  const authState = useSelector((state) => state.auth)
  const [state, setState] = useState(CLINICAL_NOTES_TEMPLATES_INITIAL_STATE)
  const { dataPoints, newTemplateContent, isNew, templates, dataPointsLoading, editTarget, loading } = state

  // Memoized form initial values
  const formInitialValues = useMemo(() => editTarget || {}, [editTarget])

  /**
   * Fetches data points from API
   */
  const handleFetchDataPoints = useCallback(async () => {
    setState((prev) => ({ ...prev, dataPointsLoading: true }))
    try {
      const data = await readClinicDataPoints()
      setState((prev) => ({ ...prev, dataPoints: data }))
    } catch (error) {
      message.error(handleError(error))
    } finally {
      setState((prev) => ({ ...prev, dataPointsLoading: false }))
    }
  }, [])

  /**
   * Fetches templates from API
   */
  const handleFetchTemplates = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }))
    try {
      const data = await readClinicalNotesTemplates({ createdBy: authState.agiliteUser._id })
      setState((prev) => ({ ...prev, templates: data }))
    } catch (error) {
      message.error(handleError(error))
    } finally {
      setState((prev) => ({ ...prev, loading: false }))
    }
  }, [authState.agiliteUser._id])

  useEffect(() => {
    handleFetchTemplates()
    handleFetchDataPoints()
  }, [handleFetchTemplates, handleFetchDataPoints])

  /**
   * Handles template creation/update
   * @param {Object} formData Form data
   */
  const handleCreateNotesTemplate = async (formData) => {
    const content = editTarget ? editTarget.content : newTemplateContent
    if (!content) {
      message.error('Template requires content. Please revise.')
      return
    }

    setState((prev) => ({ ...prev, loading: true }))
    try {
      const payload = {
        ...formData,
        content
      }

      if (editTarget) {
        await updateCinicalNotesTemplates(editTarget._id, payload)
      } else {
        await createCinicalNotesTemplates(payload)
      }

      await handleFetchTemplates()
      handleReset()
    } catch (error) {
      message.error(handleError(error))
    } finally {
      setState((prev) => ({ ...prev, loading: false }))
    }
  }

  const handleReset = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isNew: false,
      editTarget: null,
      newTemplateContent: ''
    }))
  }, [])

  const handleSelection = useCallback(
    (template) => {
      debouncedNoteCapture(noteId, template.content)
      setUpdatingNotes(true)
      setValue(template.content)
      setModalOpen(false)
    },
    [debouncedNoteCapture, noteId, setModalOpen, setValue, setUpdatingNotes]
  )

  // Memoized data point options
  const dataPointOptions = useMemo(
    () =>
      dataPoints.map((dataPoint) => ({
        value: dataPoint.key,
        label: dataPoint.label
      })),
    [dataPoints]
  )

  return {
    isNew,
    editTarget,
    loading,
    dataPointsLoading,
    templates,
    formInitialValues,
    setState,
    newTemplateContent,
    handleCreateNotesTemplate,
    handleFetchTemplates,
    dataPointOptions,
    handleSelection
  }
}

export default useClinicalNotesTemplates
