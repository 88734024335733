import { Button, Card, Col, Form, Input, Select, Space, theme } from 'antd'
import React from 'react'
import { hexToRGBA } from '../../../../lib/utils'
import CustomRow from '../../../../reusable-components/CustomRow'
import ReactQuill from 'react-quill'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEdit, faRefresh, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import useClinicalNotesTemplates from '../hooks/useClinicalNotesTemplates'

// Constants

const ClinicalNotesTemplates = ({ setValue, setModalOpen, debouncedNoteCapture, setUpdatingNotes, noteId }) => {
  const {
    isNew,
    editTarget,
    loading,
    dataPointsLoading,
    templates,
    formInitialValues,
    setState,
    newTemplateContent,
    handleCreateNotesTemplate,
    handleFetchTemplates,
    dataPointOptions,
    handleSelection
  } = useClinicalNotesTemplates({
    setValue,
    setModalOpen,
    debouncedNoteCapture,
    setUpdatingNotes,
    noteId
  })

  const { token } = theme.useToken()
  return (
    <>
      <FontAwesomeIcon
        onClick={() => setModalOpen(false)}
        color={token.colorError}
        style={{ fontSize: 20, position: 'absolute', right: 5, top: 5, cursor: 'pointer' }}
        icon={faXmarkCircle}
      />
      <Card
        headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
        style={{ marginTop: 12 }}
        size='small'
        title={<h2>Clinical Notes Templates: {isNew ? 'New Template' : editTarget ? 'Editing' : undefined}</h2>}
        extra={
          <Space>
            <Button
              type='primary'
              style={isNew || editTarget ? { background: token.colorError } : { background: token.colorPrimary }}
              onClick={() =>
                setState((prev) => ({ ...prev, isNew: !prev.isNew, editTarget: null, newTemplateContent: '' }))
              }
            >
              {isNew || editTarget ? 'Cancel' : 'New Template'}
            </Button>
            {!isNew && !editTarget && (
              <Button type='primary' style={{ background: token.colorSuccess }} onClick={handleFetchTemplates}>
                <FontAwesomeIcon icon={faRefresh} />
              </Button>
            )}
          </Space>
        }
      >
        {isNew || editTarget ? (
          <Form initialValues={formInitialValues} layout='vertical' onFinish={handleCreateNotesTemplate}>
            <Form.Item
              rules={[{ required: true, message: 'Template requires a Name. Please revise.' }]}
              label={<p>Name</p>}
              name='name'
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: 'Template requires a description. Please revise.' }]}
              label={<p>Description</p>}
              name='description'
            >
              <Input />
            </Form.Item>
            <ReactQuill
              value={editTarget ? editTarget.content : newTemplateContent}
              onChange={(html) => {
                if (editTarget) {
                  setState((prev) => ({ ...prev, editTarget: { ...prev.editTarget, content: html } }))
                } else {
                  setState((prev) => ({ ...prev, newTemplateContent: html }))
                }
              }}
            />
            <Form.Item
              style={{ marginTop: 12 }}
              label={
                <Space direction='vertical'>
                  <p>Data Points:</p>
                  <small>Select data points for reference.</small>
                </Space>
              }
              name='dataPoints'
            >
              <Select
                style={{ width: '100%' }}
                showSearch
                optionFilterProp='label'
                options={dataPointOptions}
                mode='multiple'
              />
            </Form.Item>

            <Form.Item noStyle>
              <Button
                type='primary'
                style={{ float: 'right', marginTop: 1, background: token.colorSuccess }}
                loading={loading}
                htmlType='submit'
              >
                {isNew ? 'Finalise Template' : 'Save Changes'}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <CustomRow>
            {loading || dataPointsLoading ? (
              <Col span={24}>
                <AgiliteSkeleton skActive spinnerTip='' />
              </Col>
            ) : templates.length > 0 ? (
              templates.map((template) => (
                <Col key={template._id} span={24}>
                  <Card
                    headStyle={{ background: hexToRGBA(token.colorSecondary, 0.2) }}
                    extra={
                      <Space>
                        <Button type='primary' onClick={() => setState((prev) => ({ ...prev, editTarget: template }))}>
                          Edit <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button
                          type='primary'
                          style={{ background: token.colorSuccess }}
                          onClick={() => handleSelection(template)}
                        >
                          Select <FontAwesomeIcon icon={faCheckCircle} />
                        </Button>
                      </Space>
                    }
                    size='small'
                    title={template.name}
                  >
                    {template.description}
                  </Card>
                </Col>
              ))
            ) : (
              <Col span={24}>
                <p>No Templates found.</p>
              </Col>
            )}
          </CustomRow>
        )}
      </Card>
    </>
  )
}

export default React.memo(ClinicalNotesTemplates)
