import React, { useState } from 'react'
import { Button, Table, Switch, Input, Tag, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faPlus, 
  faStar,
  faEdit,
  faArrowLeft 
} from '@fortawesome/free-solid-svg-icons'
import { diagnosticTestCategories } from '../../../../mockdata/diagnosticTestsData'
import TestSetup from './TestSetup'
import './AdminSetup.css'

const { Search } = Input

const TestManager = ({ onBack }) => {
  const [view, setView] = useState('list')
  const [selectedTest, setSelectedTest] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [tests, setTests] = useState(() => {
    // Initialize tests with featured flag
    return diagnosticTestCategories.reduce((acc, category) => {
      return [...acc, ...category.tests.map(test => ({
        ...test,
        category: category.name,
        featured: false
      }))]
    }, [])
  })

  const featuredTests = tests.filter(test => test.featured)

  const columns = [
    {
      title: 'Test Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: diagnosticTestCategories.map(cat => ({
        text: cat.name,
        value: cat.name
      })),
      onFilter: (value, record) => record.category === value
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: price => `R${(price / 100).toFixed(2)}`,
      sorter: (a, b) => a.price - b.price
    },
    {
      title: 'Available',
      dataIndex: 'available',
      key: 'available',
      render: (available, record) => (
        <Switch 
          checked={available}
          onChange={(checked) => handleAvailabilityToggle(record, checked)}
        />
      )
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: 'featured',
      render: (featured, record) => (
        <Button 
          type={featured ? 'primary' : 'text'}
          icon={<FontAwesomeIcon icon={faStar} />}
          onClick={() => handleFeaturedToggle(record)}
          className="cs-admin__feature-btn"
          disabled={!featured && featuredTests.length >= 8}
        />
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button 
          type="link" 
          icon={<FontAwesomeIcon icon={faEdit} />}
          onClick={() => handleEdit(record)}
        >
          Edit
        </Button>
      )
    }
  ]

  const handleAvailabilityToggle = (test, checked) => {
    setTests(prev => prev.map(t => 
      t.code === test.code ? { ...t, available: checked } : t
    ))
  }

  const handleFeaturedToggle = (test) => {
    if (!test.featured && featuredTests.length >= 8) {
      message.warning('Maximum of 8 featured tests allowed')
      return
    }

    setTests(prev => prev.map(t => 
      t.code === test.code ? { ...t, featured: !t.featured } : t
    ))
  }

  const handleEdit = (test) => {
    setSelectedTest(test)
    setView('edit')
  }

  const handleSave = (values) => {
    setTests(prev => prev.map(t => 
      t.code === selectedTest.code ? { ...t, ...values } : t
    ))
    setView('list')
    message.success('Test updated successfully')
  }

  const filteredTests = tests.filter(test => 
    test.name.toLowerCase().includes(searchText.toLowerCase()) ||
    test.category.toLowerCase().includes(searchText.toLowerCase())
  )

  if (view === 'edit' || view === 'add') {
    return (
      <TestSetup 
        onBack={() => setView('list')}
        onSave={handleSave}
        initialData={selectedTest}
      />
    )
  }

  return (
    <div className="cs-admin">
      <div className="cs-admin__header">
        <button 
          className="cs-admin__back"
          onClick={onBack}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span>Back</span>
        </button>
        <h1>Test Management</h1>
      </div>

      <div className="cs-admin__content">
        <div className="cs-admin__toolbar">
          <Search
            placeholder="Search tests..."
            onChange={e => setSearchText(e.target.value)}
            className="cs-admin__search"
          />
          <Button 
            type="primary"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              setSelectedTest(null)
              setView('add')
            }}
          >
            Add New Test
          </Button>
        </div>

        <div className="cs-admin__featured">
          <h2>Featured Tests (Carousel)</h2>
          <div className="cs-admin__featured-list">
            {featuredTests.map(test => (
              <Tag 
                key={test.code}
                closable
                onClose={() => handleFeaturedToggle(test)}
                className="cs-admin__featured-tag"
              >
                {test.name}
              </Tag>
            ))}
          </div>
          <p className="cs-admin__featured-help">
            * Click the star icon on any test to feature it in the carousel (max 8)
          </p>
        </div>

        <Table 
          columns={columns}
          dataSource={filteredTests}
          rowKey="code"
          className="cs-admin__table"
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} tests`
          }}
        />
      </div>
    </div>
  )
}

export default TestManager 