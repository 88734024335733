import { createSlice } from '@reduxjs/toolkit'
import PatAppointmentsState from './state'

const patAppointmentsReducer = createSlice({
  name: 'patAppointments',
  initialState: PatAppointmentsState,
  reducers: {
    setState: (state, action) => {
      state.data = action.payload
    },

    updateState: (state, action) => {
      state.data = action.payload
    }
  }
})

export default patAppointmentsReducer
