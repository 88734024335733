import { Card, Space, Typography } from 'antd'
import React from 'react'
import { documentLabels } from '../utils/enums'
import { MODAL_STATES } from '../utils/constants'
import { FileTextOutlined, ShareAltOutlined } from '@ant-design/icons'

const { Text } = Typography

const DocumentSelection = ({ handleSelection }) => {
  const cardStyle = {
    width: 200,
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s',
    ':hover': {
      transform: 'translateY(-4px)',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
    }
  }

  const iconStyle = {
    fontSize: '24px',
    marginBottom: '8px',
    color: '#1890ff'
  }

  return (
    <Card>
      <center>
        <Space direction='vertical' size='large' align='center'>
          <Typography.Title level={4}>What type of document would you like to generate?</Typography.Title>
          <Space size='large' align='center'>
            <Card hoverable style={cardStyle} onClick={() => handleSelection(MODAL_STATES.SICK_NOTE)}>
              <Space style={{ alignItems: 'center' }}>
                <FileTextOutlined style={iconStyle} />
                <Text strong>{documentLabels.SICK_NOTE}</Text>
              </Space>
            </Card>

            <Card hoverable style={cardStyle} onClick={() => handleSelection(MODAL_STATES.REFERRAL)}>
              <Space style={{ alignItems: 'center' }}>
                <ShareAltOutlined style={iconStyle} />
                <Text strong>{documentLabels.REFERRAL}</Text>
              </Space>
            </Card>

            {/* <Card hoverable style={cardStyle}>
              <Space style={{ alignItems: 'center' }}>
                <UploadOutlined style={iconStyle} />
                <Text strong>Upload</Text>
              </Space>
            </Card> */}
          </Space>
        </Space>
      </center>
    </Card>
  )
}

export default DocumentSelection
