import React, { useState } from 'react'
import { Card, Col, Form, Input, Row, Select, Space, Spin, message } from 'antd'
import { hexToRGBA, stringToColor } from '../../../../lib/utils'
import CustomRow from '../../../../reusable-components/CustomRow'
import CustomButton from '../../../../reusable-components/CustomButton'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import TextArea from 'antd/es/input/TextArea'
import ReactQuill from 'react-quill'
import useReferral from './hooks/useReferral'
import { SAOfficialLanguages, specialistTypes } from './utils/constants'

const ReferralLetterCapture = ({ bookingData, userRef, setModalOpen, patient, handleAfterSuccess }) => {
  const {
    autogenConfigRequired,
    autogenConfig,
    content,
    includedDataKeys,
    templateSelectionRequired,
    dataPoints,
    dataTemplates,
    awaitingAi,
    loadingDataAndTemplates,
    referralLoading,
    setAutogenConfigRequired,
    handleAutogenConfig,
    handleTemplateSelection,
    handleAiAutoGenerateReferral,
    handleSubmitReferral,
    setIncludedDataKeys,
    setContent
  } = useReferral({ bookingData, userRef, setModalOpen, patient, handleAfterSuccess })

  const [selection, setSelection] = useState(null)

  const cardStyle = {
    width: '100%',
    minHeight: '80vh',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '24px'
  }

  const optionCardStyle = {
    textAlign: 'center',
    height: '220px',
    padding: '32px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    border: '1px solid #e8e8e8'
  }

  const dataPointTagStyle = (key) => ({
    color: '#000000',
    padding: '6px 12px',
    background: hexToRGBA(stringToColor(key), 0.08),
    borderRadius: '6px',
    border: `1px ${stringToColor(key)} solid`,
    fontSize: '13px'
  })

  return (
    <Card style={cardStyle}>
      {awaitingAi ? (
        <AgiliteSkeleton skActive spinnerTip='Generating AI Referral...' />
      ) : (
        <>
          <CustomRow>
            {!selection ? (
              <Col span={24}>
                <h2 style={{ textAlign: 'center', marginBottom: 32, fontSize: '24px' }}>
                  How would you like to create the referral letter?
                </h2>
                <Row gutter={[24, 24]} justify='center'>
                  <Col xs={24} sm={12} md={10} lg={8}>
                    <Card
                      hoverable
                      style={optionCardStyle}
                      onClick={() => {
                        setSelection('ai')
                        setAutogenConfigRequired(true)
                      }}
                    >
                      <h3 style={{ fontSize: '20px', marginBottom: '16px' }}>AI-Assisted Generation</h3>
                      <p style={{ color: '#666', marginBottom: 0 }}>
                        Generate a smart referral letter using AI, based on patient data and your requirements
                      </p>
                    </Card>
                  </Col>
                  <Col xs={24} sm={12} md={10} lg={8}>
                    <Card hoverable style={optionCardStyle} onClick={() => setSelection('manual')}>
                      <h3 style={{ fontSize: '20px', marginBottom: '16px' }}>Manual Creation</h3>
                      <p style={{ color: '#666', marginBottom: 0 }}>
                        Create your referral letter from scratch with complete control over the content
                      </p>
                    </Card>
                  </Col>
                </Row>
              </Col>
            ) : (
              <>
                {autogenConfigRequired ? (
                  <div style={{ maxWidth: '800px', margin: '0 auto' }}>
                    <h2 style={{ marginBottom: 16, fontSize: '22px' }}>Configure AI Referral Generation</h2>
                    <p style={{ marginBottom: 32, color: '#666' }}>
                      Configure the following details to generate a personalized referral letter.
                    </p>
                    <Col span={24}>
                      <Space size='middle' style={{ marginBottom: 24, width: '100%' }}>
                        <Input
                          value={autogenConfig.specialistName}
                          placeholder='Specialist name'
                          style={{ minWidth: '200px' }}
                          onChange={(e) => handleAutogenConfig(e.target.value, 'specialistName')}
                        />
                        <Select
                          value={autogenConfig.specialistType}
                          placeholder='Select specialist type'
                          showSearch
                          style={{ minWidth: '200px' }}
                          optionFilterProp='label'
                          options={specialistTypes.map((spec) => ({ value: spec, label: spec }))}
                          onChange={(selection) => handleAutogenConfig(selection, 'specialistType')}
                        />
                        <Select
                          value={autogenConfig.language}
                          placeholder='Select language'
                          style={{ minWidth: '160px' }}
                          options={SAOfficialLanguages.map((language) => ({ value: language, label: language }))}
                          onChange={(selection) => handleAutogenConfig(selection, 'language')}
                        />
                      </Space>
                    </Col>
                    <Col span={24}>
                      <TextArea
                        placeholder='Additional instructions or specific requirements...'
                        value={autogenConfig.additionalPrompts}
                        onChange={(e) => handleAutogenConfig(e.target.value, 'additionalPrompts')}
                        rows={8}
                        style={{ marginBottom: 24 }}
                      />
                    </Col>
                    <Col span={24}>
                      {loadingDataAndTemplates ? (
                        <AgiliteSkeleton skActive />
                      ) : (
                        <Space direction='vertical' style={{ width: '100%' }}>
                          <h3 style={{ fontSize: '18px', marginBottom: 16 }}>
                            {templateSelectionRequired ? 'Select data points to include:' : 'Select a data template:'}
                          </h3>

                          {templateSelectionRequired ? (
                            <Row gutter={[0, 16]}>
                              {dataTemplates.map((template) => (
                                <Col span={24} key={template.name}>
                                  <Card
                                    size='small'
                                    style={{ borderRadius: '8px' }}
                                    title={<span style={{ fontSize: '16px' }}>{template.name}</span>}
                                    extra={
                                      <CustomButton
                                        text='Select'
                                        type='primary'
                                        size='small'
                                        onClick={() => handleTemplateSelection(template.keys)}
                                      />
                                    }
                                  >
                                    <Space wrap size={[8, 16]}>
                                      {template.keys.map((key) => {
                                        const dataReference = dataPoints.find((i) => i.key === key)
                                        return (
                                          <div key={key} style={dataPointTagStyle(key)}>
                                            {dataReference.label}
                                          </div>
                                        )
                                      })}
                                    </Space>
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                          ) : (
                            <Select
                              value={includedDataKeys}
                              placeholder='Select data points'
                              mode='multiple'
                              style={{ width: '100%' }}
                              showSearch
                              optionFilterProp='label'
                              onChange={setIncludedDataKeys}
                              options={dataPoints.map((dataPoint) => ({
                                value: dataPoint.key,
                                label: dataPoint.label
                              }))}
                            />
                          )}
                        </Space>
                      )}
                    </Col>
                    <Col span={24} style={{ marginTop: 32 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                        <CustomButton
                          type='default'
                          size='middle'
                          text='← Back'
                          onClick={() => {
                            setSelection(null)
                            setAutogenConfigRequired(false)
                          }}
                        />
                        <CustomButton
                          type='primary'
                          size='middle'
                          disabled={awaitingAi}
                          text='Generate Referral →'
                          onClick={() => {
                            if (!autogenConfig?.specialistName) {
                              return message.error("Please provide the specialist's name")
                            }
                            if (!autogenConfig?.language) {
                              return message.error('Please select a language')
                            }
                            if (!autogenConfig?.specialistType) {
                              return message.error('Please select the specialist type')
                            }
                            handleAiAutoGenerateReferral()
                          }}
                        />
                      </div>
                    </Col>
                  </div>
                ) : (
                  <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
                    <h2 style={{ marginBottom: 16, fontSize: '22px' }}>Edit Referral Letter</h2>
                    <p style={{ marginBottom: 32, color: '#666' }}>
                      Review and customize the referral letter using the rich text editor below.
                    </p>
                    <Form>
                      <Form.Item
                        style={{ marginBottom: 32 }}
                        name={['referral', 'comment']}
                        rules={[{ required: true, message: 'Please provide referral content' }]}
                      >
                        <Spin spinning={awaitingAi} tip='Generating AI Referral...'>
                          <ReactQuill value={content} onChange={setContent} style={{ minHeight: '300px' }} />
                        </Spin>
                      </Form.Item>
                    </Form>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                      <CustomButton
                        type='default'
                        size='middle'
                        text='← Back'
                        onClick={() => {
                          setSelection(null)
                          setAutogenConfigRequired(false)
                        }}
                      />
                      <CustomButton
                        loading={referralLoading}
                        type='primary'
                        size='middle'
                        text='Submit Referral →'
                        onClick={handleSubmitReferral}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </CustomRow>
        </>
      )}
    </Card>
  )
}

export default ReferralLetterCapture
