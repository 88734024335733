import React from 'react'
import { Col } from 'antd'
import '../styling/MobileVideoConsultation.css'
import PatLiveVideoWrapper from '../../Agora/PatLiveVideoWrapper'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../../../core/utils/reducer'
import CoreEnums from '../../../../../core/utils/enums'

const MobVideoConsultation = ({ appointment, uid, token, channel, joined, setJoined }) => {
  const dispatch = useDispatch()
  const closeTab = () => {
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: CoreEnums.tabKeys.PAT_VIRTUAL_CONSULT_SESSION,
        removeBreadcrumb: true
      })
    )
  }
  return (
    <div className='video-consultation'>
      {joined ? (
        <Col span={24} style={{ height: '100%' }}>
          <PatLiveVideoWrapper
            appointment={appointment}
            channelName={channel}
            token={token}
            uid={uid}
            disconnectCallback={() => {
              setJoined(false)
              closeTab()
            }}
          />
        </Col>
      ) : undefined}
    </div>
  )
}

export default MobVideoConsultation
