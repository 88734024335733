import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Typography, Card, Space, Alert } from 'antd'
import { useSwipeable } from 'react-swipeable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import StatsCard from './components/StatsCard'
import TrendGraph from './components/TrendGraph'
import '../../../styling/ResultDetail.css'
import { useSelector } from 'react-redux'
import PrevNextFloatingButtons from '../../../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import AgiliteSkeleton from '../../../../../../reusable-components/AgiliteSkeleton'

const { Title, Text } = Typography

const ResultDetail = ({ result, onBack, onNext, onPrevious, hasNext, hasPrevious }) => {
  const careSyncDataPoints = useSelector((state) => state.careSyncDataPoints.data)
  const [careSyncResult, setCareSyncResult] = useState(result)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!result.dataPoint) {
      handleFetchInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFetchInfo = async () => {
    console.log('Here')
    console.log('care sync data points', careSyncDataPoints)
    setLoading(true)
    try {
      let tmpDataPoint = careSyncDataPoints?.find((dp) => dp.key === result.key)
      console.log('tmpDataPoint', tmpDataPoint)
      if (tmpDataPoint) {
        setCareSyncResult({ ...careSyncResult, dataPoint: tmpDataPoint })
      }
    } catch (error) {
      console.log('Error fetching info', error)
    } finally {
      setLoading(false)
    }
  }

  // Find the corresponding dataPoint template

  const dataPoint = careSyncDataPoints?.find((dp) => dp.key === result.key)

  const handlers = useSwipeable({
    onSwipedLeft: () => hasNext && onNext(),
    onSwipedRight: () => hasPrevious && onPrevious(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false
  })

  // Parse reference range data from dataPoint
  const referenceRanges = dataPoint?.data ? JSON.parse(dataPoint.data).referenceRangeData : []

  // Create metrics object for StatsCard
  const metrics = {
    current: {
      value: result.value,
      unit: dataPoint?.suffix
    },
    referenceRanges: referenceRanges
  }

  console.log('Result Detail', result)
  return (
    <>
      <div className='cs-result-detail'>
        <div className='cs-result-detail__back'>
          {/* <Button
          type='text'
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={onBack}
          className='cs-result-detail__back-btn'
        /> */}
          <div className='cs-result-detail__header-info'>
            <Text className='cs-result-detail__title'>{dataPoint?.label}</Text>
            <Text className='cs-result-detail__date'>{new Date(result.dateCreated).toLocaleDateString()}</Text>
          </div>
        </div>
        <div className='cs-result-detail__content' {...handlers}>
          <div className='cs-result-detail__section'>
            <div className='cs-result-detail__card-container'>
              <StatsCard metrics={metrics} result={result} dataPoint={dataPoint} />
            </div>

            <div className='cs-result-detail__card-container'>
              <Card size='small'>
                <div className='cs-result-detail__info-section'>
                  <Title level={5}>Reference Ranges</Title>
                  {loading ? (
                    <AgiliteSkeleton skActive spinnerTip='Loading reference ranges...' />
                  ) : (
                    <Row gutter={[16, 16]}>
                      {careSyncResult.dataPoint?.data &&
                      JSON.parse(careSyncResult.dataPoint?.data)?.referenceRangeData ? (
                        <Col span={24}>
                          <Space direction='vertical'>
                            {JSON.parse(careSyncResult.dataPoint.data).referenceRangeData.map((range, index) => (
                              <Alert
                                description={
                                  <Text key={index} display='block'>
                                    {range.label}
                                  </Text>
                                }
                              />
                            ))}
                          </Space>
                        </Col>
                      ) : (
                        'NO REFERENCE RANGES AVAILABLE'
                      )}
                    </Row>
                  )}
                </div>
              </Card>
            </div>

            {/* Only show TrendGraph if we implement history fetching */}
            {/* <div className='cs-result-detail__card-container'>
            <TrendGraph data={history} metrics={metrics} />
          </div> */}
          </div>
        </div>
      </div>
      <PrevNextFloatingButtons
        onPrev={() => {
          onBack()
        }}
        prevOnly
      />
    </>
  )
}

export default ResultDetail
