import React, { useState } from 'react'
import { Card, theme, message, Space } from 'antd'
import { Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, ComposedChart, Bar } from 'recharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBurger, faDrumstickBite, faCandyCane, faFire } from '@fortawesome/free-solid-svg-icons'
import '../style/MobileMacroGraphs.css'
import CustomTabs from '../../../../../Appointments/Mobile/components/CustomTabs'
import { useSelector } from 'react-redux'
import PrevNextFloatingButtons from '../../../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import dayjs from 'dayjs'
import DatePickerComponent from '../../../../../Reusable Components/DatePicker'

const xAxisConfig = {
  dataKey: 'date',
  tickFormatter: (tick) => {
    const date = dayjs(tick)
    return date.format('DD-MM')
  },
  angle: -45,
  textAnchor: 'end',
  height: 35,
  interval: 4,
  minTickGap: 5,
  tick: {
    fontSize: 11,
    dy: 5,
    dx: -5
  }
}

const MacroCard = ({ title, data, dataKey, color, icon }) => (
  <Card
    className='macro-card'
    style={{ boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)' }}
    data-macro={title.toLowerCase()}
    bordered={false}
  >
    <div className='macro-header'>
      <h3>
        <span>{title}</span>
        <FontAwesomeIcon icon={icon} className='macro-icon' style={{ color }} />
      </h3>
    </div>
    <ResponsiveContainer width='100%' height={240}>
      <ComposedChart data={data} margin={{ top: 5, right: 10, bottom: 15, left: 10 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis {...xAxisConfig} />
        <YAxis />
        <Bar dataKey={dataKey} fill={color} opacity={0.3} radius={[4, 4, 0, 0]} />
        <Line type='monotone' dataKey={dataKey} stroke={color} strokeWidth={2} dot={false} activeDot={{ r: 4 }} />
      </ComposedChart>
    </ResponsiveContainer>
  </Card>
)

const MobileMacroGraphs = ({ dateRange, setDateRange, onBack }) => {
  const trackerData = useSelector((state) => state.patMacroTracker.trackerData)

  // Local state for managing the "from" and "to" dates
  const [fromDate, setFromDate] = useState(dayjs(dateRange[0]))
  const [toDate, setToDate] = useState(dayjs(dateRange[1]))
  const [showFromPicker, setShowFromPicker] = useState(false)
  const [showToPicker, setShowToPicker] = useState(false)

  const handleFromDateSelect = (date) => {
    if (date.isAfter(toDate)) {
      message.error('From date cannot be after To date.')
      return
    }
    if (toDate.diff(date, 'days') > 31) {
      message.error('The date range cannot exceed 31 days.')
      return
    }
    setFromDate(date)
    setDateRange([date.toDate(), toDate.toDate()])
  }

  const handleToDateSelect = (date) => {
    if (date.isBefore(fromDate)) {
      message.error('To date cannot be before From date.')
      return
    }
    if (date.diff(fromDate, 'days') > 31) {
      message.error('The date range cannot exceed 31 days.')
      return
    }
    setToDate(date)
    setDateRange([fromDate.toDate(), date.toDate()])
  }

  const macroConfigs = [
    { title: 'Protein', dataKey: 'macros.prot', color: '#ff8000', icon: faDrumstickBite },
    { title: 'Carbs', dataKey: 'macros.carb', color: '#3498db', icon: faBurger },
    { title: 'Fats', dataKey: 'macros.fat', color: '#2ecc71', icon: faFire },
    { title: 'Sugar', dataKey: 'macros.sug', color: '#ff1493', icon: faCandyCane }
  ]

  // Function to generate all dates in the range
  const generateDateRange = (start, end) => {
    const dates = []
    let currentDate = start

    while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
      dates.push(currentDate.format('YYYY-MM-DD'))
      currentDate = currentDate.add(1, 'day')
    }

    return dates
  }

  // Function to aggregate data by day and fill missing dates
  const aggregateDataByDay = (data, dateRange) => {
    const aggregatedData = {}

    data.forEach((entry) => {
      const date = dayjs(entry.createdAt).format('YYYY-MM-DD')
      if (!aggregatedData[date]) {
        aggregatedData[date] = { ...entry.macros }
      } else {
        Object.keys(entry.macros).forEach((key) => {
          aggregatedData[date][key] += entry.macros[key]
        })
      }
    })

    const allDates = generateDateRange(dayjs(dateRange[0]), dayjs(dateRange[1]))

    return allDates.map((date) => ({
      date,
      macros: aggregatedData[date] || { prot: 0, carb: 0, fat: 0, sug: 0, grams: 0, cal: 0 }
    }))
  }

  // Use aggregated data
  const data = aggregateDataByDay(trackerData, dateRange)

  const xAxisConfig = {
    dataKey: 'date',
    tickFormatter: (tick) => {
      const date = dayjs(tick)
      return date.format('DD-MM')
    },
    angle: -45,
    textAnchor: 'end',
    height: 35,
    interval: data.length - 1, // Only show first and last tick
    minTickGap: 5,
    tick: {
      fontSize: 11,
      dy: 5,
      dx: -5
    }
  }

  const tabs = [
    {
      label: 'Combined',
      children: (
        <div>
          <Space className='color-key' style={{ marginTop: 16, width: '100%', justifyContent: 'center' }}>
            {macroConfigs.map(({ title, color }) => (
              <span key={title} style={{ color, fontSize: '10px' }}>
                {title}
              </span>
            ))}
          </Space>
          <Card className='combined-graph-card' bordered={false} style={{ marginTop: 16 }}>
            <ResponsiveContainer width='100%' height={340}>
              <ComposedChart data={data} margin={{ top: 5, right: 10, bottom: 15, left: 10 }}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis {...xAxisConfig} />
                <YAxis
                  label={{
                    value: 'Grams',
                    angle: -90,
                    position: 'insideLeft'
                  }}
                />
                {macroConfigs.map(({ title, dataKey, color }) => (
                  <Bar
                    key={dataKey}
                    dataKey={dataKey}
                    name={title}
                    stackId='macros'
                    fill={color}
                    radius={[4, 4, 0, 0]}
                  />
                ))}
              </ComposedChart>
            </ResponsiveContainer>
          </Card>
        </div>
      )
    },
    {
      label: 'Individual',
      children: (
        <div className='individual-graphs' style={{ marginTop: 16 }}>
          {macroConfigs.map((config) => (
            <MacroCard key={config.dataKey} data={data} {...config} />
          ))}
        </div>
      )
    }
  ]
  const { token } = theme.useToken()
  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>
        <label style={{ display: 'block', marginBottom: '0.5rem', fontWeight: 'bold' }}>Date Range</label>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <input
            type='text'
            value={fromDate.format('DD-MM-YYYY')}
            readOnly
            onClick={() => setShowFromPicker(true)}
            style={{
              width: '45%',
              padding: '8px',
              borderRadius: '4px',
              border: `1px solid ${token.colorPrimary}`
            }}
          />
          <span style={{ color: '#1e90ff', fontSize: '1.2rem' }}>→</span>
          <input
            type='text'
            value={toDate.format('DD-MM-YYYY')}
            readOnly
            onClick={() => setShowToPicker(true)}
            style={{
              width: '45%',
              padding: '8px',
              borderRadius: '4px',
              border: `1px solid ${token.colorPrimary}`
            }}
          />
        </div>
      </div>

      <DatePickerComponent
        showDatePicker={showFromPicker}
        setShowDatePicker={setShowFromPicker}
        initialDate={fromDate}
        handleDateSelect={handleFromDateSelect}
      />

      <DatePickerComponent
        showDatePicker={showToPicker}
        setShowDatePicker={setShowToPicker}
        initialDate={toDate}
        handleDateSelect={handleToDateSelect}
      />

      <div style={{ height: '100%', minHeight: 0, paddingBottom: 100 }}>
        <CustomTabs tabs={tabs} />
      </div>

      <PrevNextFloatingButtons onPrev={onBack} prevOnly={true} prevText='Back' />
    </div>
  )
}

export default MobileMacroGraphs
