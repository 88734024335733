import React from 'react'
import { Typography } from 'antd'
import mHealthBanner from '../../../../../../../assets/images/mhealth-720x350px.jpg'
import careSyncLogo from '../../../../../../../assets/images/caresync navy.png'
import '../../../styling/PromotionalBanner.css'

const { Text } = Typography

const PromotionalBanner = () => {
  return (
    <div className='cs-overview__banner'>
      <div className='cs-overview__banner-image'>
        <img src={mHealthBanner} alt='mHealth Banner' />
      </div>
      <div className='cs-overview__banner-logo'>
        <img src={careSyncLogo} alt='CareSync Logo' />
      </div>
    </div>
  )
}

export default PromotionalBanner 