import React, { useState } from 'react'
import { List, Space, Typography, Tag, Button, Card, theme, Collapse, Col } from 'antd'
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  DownloadOutlined,
  ShareAltOutlined,
  RightOutlined,
  LeftOutlined
} from '@ant-design/icons'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { hexToRGBA } from '../../../../lib/utils'
import CustomRow from '../../../../reusable-components/CustomRow'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import MobResultDetail from './MobResultDetail'

const { Text, Title } = Typography
const { Panel } = Collapse

const MobPatCareSyncResults = ({ tests, testResults, dataPoints, setFacet, setPreviewResult, previewResult }) => {
  const { token } = theme.useToken()
  const [selectedTest, setSelectedTest] = useState(previewResult?.name)

  const processTestResults = (results) => {
    // Group results by test name
    const groupedResults = results.reduce((acc, result) => {
      const dataPoint = dataPoints.find((dp) => dp.key === result.key)
      const test = tests.find((test) => test.key === result.key)
      const processedResult = {
        name: result.key,
        testName: test?.name || result.key,
        date: new Date(result.dateCreated),
        displayDate: new Date(result.dateCreated).toLocaleDateString(),
        value: parseFloat(result.value),
        unit: dataPoint?.suffix || '',
        description: test?.description || '',
        referenceRanges: dataPoint?.data ? JSON.parse(dataPoint.data).referenceRangeData : []
      }

      if (!acc[result.key]) {
        acc[result.key] = []
      }
      acc[result.key].push(processedResult)
      return acc
    }, {})

    // Sort each group by date
    Object.keys(groupedResults).forEach((key) => {
      groupedResults[key].sort((a, b) => b.date - a.date)
    })

    return groupedResults
  }

  const groupedTestResults = processTestResults(testResults)
  return (
    <CustomRow style={{ marginBottom: selectedTest ? 0 : 75 }}>
      {selectedTest ? (
        <Col span={24}>
          <MobResultDetail
            setFacet={setFacet}
            groupedTestResults={groupedTestResults}
            testKey={selectedTest}
            setSelectedTest={setSelectedTest}
            selectedTest={selectedTest}
            testName={groupedTestResults[selectedTest]?.[0]?.testName}
            previewResult={previewResult}
            setPreviewResult={setPreviewResult}
          />
        </Col>
      ) : (
        <>
          <Col span={24}>
            {Object.entries(groupedTestResults).map(([key, tests]) => {
              const item = { name: key, ...tests[0] }
              return (
                <Card
                  key={item.name}
                  onClick={() => setSelectedTest(item.name)}
                  style={{
                    cursor: 'pointer',
                    marginBottom: 8
                  }}
                  size='small'
                >
                  <div
                    style={{
                      width: '100%',
                      flex: 1,
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      display: 'flex'
                    }}
                  >
                    <Text style={{ background: hexToRGBA(token.colorPrimary, 0.1), padding: 6, width: '100%' }} strong>
                      {item.name}
                      <Tag style={{ float: 'right' }} color='blue'>
                        Latest
                      </Tag>
                    </Text>
                    <Text>{item.Description}</Text>
                    <Space direction='vertical' style={{ padding: 6 }}>
                      <Text type='secondary'>Date: {item.displayDate}</Text>
                      <Text>
                        Reading: {item.value} {item.unit}
                      </Text>
                      <Button style={{ float: 'right', marginTop: 12 }} type='primary' size='small'>
                        View <RightOutlined />
                      </Button>
                    </Space>
                  </div>
                </Card>
              )
            })}
          </Col>
          <PrevNextFloatingButtons
            onPrev={() => {
              setFacet('')
            }}
            prevOnly
            prevText='Back'
          />
        </>
      )}
    </CustomRow>
  )
}

export default MobPatCareSyncResults
