import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { readClinicDataPoints } from '../../../../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { readDataPointTemplates } from '../../../../../Data-Point-Templates/utils'
import { handleError } from '../../../../../lib/utils'
import { message } from 'antd'
import { assistantInitiate } from '../../../../../AI Tools/utils'
import { AssistantEnums } from '../../../../../AI Tools/assitants-enums'
import { generateAIResponse } from '../../../../../AI Tools/assistants-lib'
import { readMedicalHistory } from '../../../../../Admin/patients/utils/utils'
import { groupData, readClinicalEntries } from '../../../../examination-utils/examination-lib'
import { MODAL_STATES } from '../../utils/constants'
import { createDocument, downloadReferralLetter } from '../../utils/api'
import { documentsTypes } from '../../utils/enums'
import dayjs from 'dayjs'

const useReferral = ({ userRef, bookingData, patient, handleAfterSuccess }) => {
  const authState = useSelector((state) => state.auth)
  const [autogenConfigRequired, setAutogenConfigRequired] = useState(false)
  const [autogenConfig, setAutogenConfig] = useState({
    specialistType: null,
    specialistName: null,
    language: null,
    additionalPrompts: ''
  })
  const [content, setContent] = useState('')
  const [includedDataKeys, setIncludedDataKeys] = useState([])
  const [templateSelectionRequired, setTemplateSelectionRequired] = useState(false)

  // Data
  const [dataPoints, setDataPoints] = useState([])
  const [dataTemplates, setDataTemplates] = useState([])

  // Error Handling
  const [dataTemplateFetchingError, setDataTemplateFetchingError] = useState('')

  // Loading
  const [awaitingAi, setAwaitingAi] = useState(false)
  const [loadingDataAndTemplates, setLoadingDataAndTemplates] = useState(false)
  const [referralLoading, setReferralLoading] = useState(false)

  // Facet control
  const [isHistroical, setIsHistorical] = useState(false)

  const handleSubmitReferral = async () => {
    const signature = authState.agiliteUser.extraData.signature
    const mpNumber = authState.agiliteUser.extraData.mpNumber

    setReferralLoading(true)
    if (!signature) {
      setReferralLoading(false)
      return message.error(
        'No Signature has been set. Please set your Signature in your Personal Details Medical Profile.'
      )
    }

    if (!mpNumber) {
      setReferralLoading(false)
      return message.error(
        'No MP Number has been set. Please set your MP Number in your Personal Details Medical Profile.'
      )
    }

    try {
      // Base64 response
      const response = await downloadReferralLetter(
        bookingData,
        bookingData ? bookingData.patientRecord : patient,
        content,
        signature,
        documentsTypes.REFERRAL
      )

      const newRecord = await createDocument(
        response,
        {
          name: `Referral Letter (${dayjs().format('DD-MM-YYYY')})`,
          userRef: bookingData ? bookingData.userRef : userRef,
          bookingRef: bookingData ? bookingData._id : ''
        },
        documentsTypes.REFERRAL
      )

      handleAfterSuccess(MODAL_STATES.REFERRAL_LETTER, newRecord)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setReferralLoading(false)
  }

  const handleAiAutoGenerateReferral = async () => {
    let tmpContent = ''
    let tmpClinicalData = null
    let aiResponse = null
    const payload = {}

    setAwaitingAi(true)
    try {
      tmpClinicalData = await readClinicalEntries({
        key: { $in: includedDataKeys },
        userRef: bookingData.userRef ? bookingData.userRef : userRef
      })
      payload.config = autogenConfig
      payload.patientDetails = (() => {
        if (bookingData?.patientRecord) {
          const { firstName, lastName, dateOfBirth, gender } = bookingData.patientRecord
          return {
            fullName: `${firstName} ${lastName}`,
            dateOfBirth,
            gender
          }
        }
        return null
      })()

      payload.clinicalData = (() => {
        let groupedData = groupData(tmpClinicalData)
        groupedData.forEach((dataKey) => {
          dataKey.records = dataKey.records.map((record) => ({
            value: record.value,
            date: record.dateCreated
          }))
        })
        return groupedData
      })()

      payload.medicalHistory = await readMedicalHistory({ userRef: userRef ? userRef : bookingData.userRef })

      // TODO: convert keys
      const tmpData = await assistantInitiate(JSON.stringify(payload), AssistantEnums.assistant_ids.REFERRAL_LETTER)
      aiResponse = await generateAIResponse(tmpData)
      tmpContent = aiResponse?.data?.data[0]?.content[0]?.text?.value

      setContent(tmpContent)
      setAutogenConfigRequired(false)
      handleReset()
    } catch (e) {
      message.error(handleError(e))
    }
    setAwaitingAi(false)
  }

  const handleAutogenConfig = (value, key) => {
    let tmpAutogenConfig = JSON.parse(JSON.stringify(autogenConfig))
    tmpAutogenConfig[key] = value
    setAutogenConfig(tmpAutogenConfig)
  }

  useEffect(() => {
    if (autogenConfigRequired) {
      fetchDatapointData()
    }
  }, [autogenConfigRequired])

  const fetchDatapointData = async () => {
    let tmpDataPoints = []
    let tmpDataTemplates = []

    setLoadingDataAndTemplates(true)
    try {
      tmpDataPoints = await readClinicDataPoints()
      tmpDataTemplates = await readDataPointTemplates()

      setDataTemplateFetchingError('')
      setDataPoints(tmpDataPoints)
      setDataTemplates(tmpDataTemplates)
    } catch (e) {
      setDataTemplateFetchingError(handleError(e))
    }
    setLoadingDataAndTemplates(false)
  }

  // eslint-disable-next-line

  const handleTemplateSelection = (keys) => {
    const tmpIncludedDataKeys = []
    keys.forEach((key) => {
      const dataReference = dataPoints.find((i) => i.key === key)
      tmpIncludedDataKeys.push(dataReference.key)
    })
    setIncludedDataKeys(tmpIncludedDataKeys)
    setTemplateSelectionRequired(false)
  }

  const handleReset = () => {
    setDataPoints([])
    setDataTemplates([])
    setIncludedDataKeys([])
    setAutogenConfigRequired(false)
  }

  return {
    authState,
    autogenConfigRequired,
    autogenConfig,
    content,
    includedDataKeys,
    templateSelectionRequired,
    dataPoints,
    dataTemplates,
    dataTemplateFetchingError,
    awaitingAi,
    loadingDataAndTemplates,
    referralLoading,
    handleSubmitReferral,
    handleAiAutoGenerateReferral,
    handleAutogenConfig,
    handleTemplateSelection,
    handleReset,
    setAutogenConfigRequired,
    setAutogenConfig,
    setContent,
    setTemplateSelectionRequired,
    setDataTemplateFetchingError,
    setLoadingDataAndTemplates,
    fetchDatapointData,
    setIncludedDataKeys,
    isHistroical,
    setIsHistorical
  }
}

export default useReferral
