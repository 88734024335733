import React, { memo, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Button, message, Col } from 'antd'
import { Popup } from 'antd-mobile'
import { Picker } from 'antd-mobile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt,
  faChevronRight,
  faMagnifyingGlass,
  faUser,
  faUserDoctor,
  faKitMedical
} from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import '../styling/MobileBookingDetails.css'
import { useSelector } from 'react-redux'
import { handleError } from '../../../../lib/utils'
import { readClinics } from '../../../../Admin/clinics/utils/utils'
import { readConfig } from '../../../../Admin/config/utils/utils'
import { readBookings } from '../../../../Bookings/utils/utils'
import CoreEnums from '../../../../../core/utils/enums'
import { readAvailability } from '../../../../Availability/components/utils/utils'
import { readMedicalProfessionalUsers } from '../../../../Admin/medical-professionals/utils/utils'
import { isAlreadyBooked, willOverlap } from '../../../../Bookings/utils/lib'
import { readSystemUsers } from '../../../../Admin/system-users/utils/utils'
import MobMedicalHistoryLoadingOverlay from '../../../Medical History/Mobile/components/LoadingOverlay'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import Lottie from 'react-lottie'
import searchingDoctorsAnimation from '../../../../../assets/Animations/SearchingDoctors.json'
import CustomRow from '../../../../reusable-components/CustomRow'

const testDate = null // Set to null by default, or a specific date for testing
// const testDate = new Date('2025-12-15') // Uncomment and modify for testing

const MobBookingDetailsCapture = memo(
  ({
    patient,
    bookingDetails,
    setBookingDetails,
    isVirtualVisit,
    isReceptionBooking,
    isHomeVisit,
    setPatient,
    onBack,
    onNext,
    setDataResults
  }) => {
    const [selectedDate, setSelectedDate] = useState(testDate || new Date())
    const [showDatePicker, setShowDatePicker] = useState(false)
    const authState = useSelector((state) => state.auth)
    const state = useSelector((state) => state)
    const [clinicResults, setClinicResults] = useState(null)
    const [daysInAdvance, setDaysInAdvance] = useState(null)
    const [dependants, setDependants] = useState([])
    const [dependantsLoading, setDependantsLoading] = useState(true)
    const [showServicePicker, setShowServicePicker] = useState(false)
    const [searchingForDoctors, setSearchingForDoctors] = useState(false)

    // TODO: Unused state variables
    const [dataResultsLoading, setDataResultsLoading] = useState(false)
    const [clinics, setClinics] = useState([])
    const [active, setActive] = useState({
      timeslot: null,
      medicalProfRef: null
    })
    const [pickerValue, setPickerValue] = useState(() => {
      const dateToUse = testDate || new Date()
      return [dateToUse.getDate(), dateToUse.getMonth() + 1, dateToUse.getFullYear()]
    })
    const [showDependantPicker, setShowDependantPicker] = useState(false)

    useEffect(() => {
      setDataResults([])
      // eslint-disable-next-line
    }, [patient, bookingDetails.service, bookingDetails.province, bookingDetails.bookingDate])

    useEffect(() => {
      fetchClinics()
      // eslint-disable-next-line
    }, [patient])

    useEffect(() => {
      setupDependants()
      // eslint-disable-next-line
    }, [])

    const fetchClinics = async () => {
      let clinics = []
      try {
        clinics = await readClinics({ isActive: true, entityRef: state.core.entity._id })
        setClinics(clinics)
      } catch (e) {
        message.error(handleError(e, true))
      }
    }

    const handleSubmit = async () => {
      setActive({
        timeslot: null,
        medicalProfRef: null
      })
      let tmpArray = []
      let clinics = []
      let qry = null
      // let tmpResults = []

      setSearchingForDoctors(true)

      setDaysInAdvance(tmpArray)

      const tmpClinicArray = []
      let result = null

      try {
        const bookingsConfig = await readConfig()
        for (let x = 0; x <= bookingsConfig[0].bookings.daysInAdvance; x++) {
          tmpArray.push({
            day: formatDateDay(new Date(dayjs(bookingDetails.bookingDate).add(x, 'day'))),
            date: new Date(dayjs(bookingDetails.bookingDate).add(x, 'day')),
            entries: []
          })
        }

        qry = { isActive: true }

        // if (!isVirtualVisit) {
        //   qry.province = bookingDetails.province
        // }

        // Reception
        if (isReceptionBooking) {
          qry._id = authState.agiliteUser.extraData.clinics[0]
        }

        clinics = await readClinics(qry, isVirtualVisit)

        for (const clinic of clinics) {
          tmpClinicArray.push({
            clinicAddress: clinic.address,
            clinicRef: clinic._id,
            clinicPaymentMethods: clinic.paymentMethods,
            clinicName: clinic.name,
            clinicActive: clinic.isActive,
            availabilityEntries: []
          })
        }

        setClinicResults(tmpClinicArray)
      } catch (e) {
        message.error(handleError(e, true))
      }
    }

    useEffect(() => {
      if (clinicResults) {
        getData()
      }
      // eslint-disable-next-line
    }, [clinicResults])

    const formatDateDay = (date) => {
      switch (new Date(date).getDay()) {
        case 0:
          return 7
        default:
          return new Date(date).getDay()
      }
    }

    const getData = async () => {
      const tmpDataResults = JSON.parse(JSON.stringify(clinicResults))
      let professionalUsers = []

      try {
        setDataResultsLoading(true)
        const [currentBookings, availabilityData, professionals] = await Promise.all([
          readBookings({
            $and: [
              { status: { $ne: CoreEnums.bookingStatuses.cancelled } },
              { status: { $ne: CoreEnums.bookingStatuses.completed } }
            ]
          }),
          readAvailability(),
          await readMedicalProfessionalUsers(
            {
              // 'extraData.isActive': true,
              // 'extraData.services': { $in: servicesState }
            },
            'extraData.services extraData.profession extraData.isActive firstName lastName email phoneNumber profileImage'
          )
        ])

        // Combine users and professionals
        professionals.map((user) => {
          return professionalUsers.push({
            _id: user._id,
            services: user.extraData.services,
            profession: user.extraData.profession,
            isActive: user.extraData.isActive,
            firstName: user.firstName,
            lastName: user.lastName,
            profileImage: user.profileImage,
            email: user.email,
            phoneNumber: user.phoneNumber,
            availability: []
          })
        })

        // Cleanup
        for (const entry of tmpDataResults) {
          entry.entries = []
        }

        tmpDataResults.forEach((clinic, clinicIndex) => {
          const tmpDayResults = JSON.parse(JSON.stringify(daysInAdvance))

          tmpDayResults.forEach((dataEntry, dayIndex) => {
            professionalUsers.forEach((professionalUser) => {
              const tmpProfessionalUser = JSON.parse(JSON.stringify(professionalUser))
              const upcomingBookings = []
              const availabilityEntry = availabilityData
                .concat()
                .find(
                  (entry) => entry.profession === tmpProfessionalUser.profession && entry.clinicRef === clinic.clinicRef
                )

              if (availabilityEntry) {
                tmpProfessionalUser.availability =
                  availabilityEntry.availability
                    .find((entry) => entry.date === dayjs(dataEntry.date).format('YYYY-MM-DD'))
                    ?.timeSlots.filter((slot) => slot.linkedProfessional === tmpProfessionalUser._id && !slot.blocked)
                    .map((slot) => slot.time) || []
              }

              // Find this medical professionals existing bookings and assign them
              currentBookings.forEach((currentBooking) => {
                if (
                  currentBooking.medicalProfRef === tmpProfessionalUser._id &&
                  new Date(currentBooking.bookingDate).toDateString() === new Date(dataEntry.date).toDateString() &&
                  currentBooking.status !== CoreEnums.bookingStatuses.cancelled
                ) {
                  upcomingBookings.push([currentBooking.startTime, currentBooking.endTime])
                }
              })

              if (tmpProfessionalUser.availability.length > 0) {
                const timeSlotIntervals = []
                tmpProfessionalUser.availability.forEach((slot) => {
                  if (!timeSlotIntervals.includes(slot)) {
                    if (
                      !isAlreadyBooked(slot, upcomingBookings) &&
                      !willOverlap(slot, bookingDetails, upcomingBookings)
                    ) {
                      // only push if slot (1) isnt booked, (2) won't overlap with any existing timeslots and (3) is later than NOW,
                      if (dayjs(dataEntry.date).format('DD MMM YYYY') === dayjs(new Date()).format('DD MMM YYYY')) {
                        // Check if slot is later than NOW
                        let currentHour = new Date().getHours().toString()
                        let currentMinute = new Date().getMinutes().toString()

                        if (currentMinute.length < 2) {
                          currentMinute = '0' + currentMinute
                        }

                        if (currentHour.length === 1) {
                          // Correctly format current hour if earlier than 10am
                          currentHour = '0' + currentHour
                        }

                        const currentTime = currentHour + ':' + currentMinute

                        if (slot > currentTime) {
                          timeSlotIntervals.push(slot)
                        }
                      } else {
                        timeSlotIntervals.push(slot)
                      }
                    }
                  }
                })
                // timeSlotIntervals.splice(timeSlotIntervals.length - 1, 1)
                tmpProfessionalUser.availability = timeSlotIntervals

                if (tmpProfessionalUser.availability.length > 0) {
                  tmpDayResults[dayIndex].entries.push(tmpProfessionalUser)
                }
              }
            })

            if (tmpDayResults[dayIndex].entries.length > 0) {
              tmpDataResults[clinicIndex].availabilityEntries.push(tmpDayResults[dayIndex])
            }
          })
        })

        setDataResults(tmpDataResults)
        setDataResultsLoading(false)
        onNext()
      } catch (e) {
        handleError(e, true)
        setDataResultsLoading(false)
      }

      setSearchingForDoctors(false)
    }

    const setupServices = () => {
      const tmpServices = []
      if (isVirtualVisit) {
        state.virtualServices.data.map((service) => {
          tmpServices.push({ label: service.name, value: service._id })

          return null
        })
      } else {
        state.services.data.map((service) => {
          if (isHomeVisit) {
            if (service.type === 'Home Visit') {
              tmpServices.push({ label: service.name, value: service._id })
            }
          } else {
            tmpServices.push({ label: service.name, value: service._id })
          }

          return null
        })
      }

      return tmpServices
    }

    const setupDependants = async () => {
      let responseData = []
      let tmpDependants = []

      try {
        setDependantsLoading(true)
        tmpDependants.push({
          label: 'Myself',
          value: JSON.stringify(authState.agiliteUser)
        })

        responseData = await readSystemUsers({
          mainMemberId: authState.agiliteUser._id
        })

        responseData.map((dependant) => {
          tmpDependants.push({
            label: dependant.firstName + ' ' + dependant.lastName,
            value: JSON.stringify(dependant)
          })
          return null
        })

        setDependants(tmpDependants)
      } catch (e) {
        message.error(handleError(e, true))
      }

      setDependantsLoading(false)
    }

    const handleDateSelect = (val) => {
      const [day, month, year] = val
      const newDate = new Date(year, month - 1, day)
      setSelectedDate(newDate)
      if (setBookingDetails && bookingDetails) {
        setBookingDetails({
          ...bookingDetails,
          date: dayjs(newDate),
          bookingDate: dayjs(newDate)
        })
      }
      setShowDatePicker(false)
    }

    const generateDateColumns = () => {
      const today = testDate || new Date()
      const currentDay = today.getDate()
      const currentMonth = today.getMonth() + 1
      const currentYear = today.getFullYear()

      // Get selected month and year from picker value
      const [_, selectedMonth, selectedYear] = pickerValue

      // Generate days
      const days = Array.from({ length: 31 }, (_, i) => {
        const day = i + 1
        return {
          label: day.toString(),
          value: day
        }
      }).filter((day) => {
        // If it's current year and current month, filter days before today
        if (selectedYear === currentYear && selectedMonth === currentMonth) {
          // Filter out today and tomorrow
          return day.value > currentDay + 1
        }
        // If it's current year but past month, filter all days
        if (selectedYear === currentYear && selectedMonth < currentMonth) {
          return false
        }
        return true
      })

      // Generate months
      const months = Array.from({ length: 12 }, (_, i) => {
        const month = i + 1
        return {
          label: dayjs().month(i).format('MMMM'),
          value: month
        }
      }).filter((month) => {
        if (selectedYear === currentYear) {
          return month.value >= currentMonth
        }
        return true
      })

      // Generate years - only current year and next year if we're in the last month
      const shouldShowNextYear = currentMonth === 12
      const years = Array.from({ length: shouldShowNextYear ? 2 : 1 }, (_, i) => ({
        label: (currentYear + i).toString(),
        value: currentYear + i
      }))

      return [days, months, years]
    }

    const generateServiceColumns = () => {
      return [
        setupServices().map((service) => ({
          label: service.label,
          value: service.value
        }))
      ]
    }

    const handleServiceSelect = (val) => {
      const [serviceId] = val // Extract the first value from the array
      setBookingDetails({ ...bookingDetails, service: serviceId })
      setShowServicePicker(false)
    }

    const pickerPopupStyle = {
      // '--adm-color-background': '#1e2633',
      // '--adm-color-text': '#ffffff',
      '--adm-color-primary': '#00b6d8',
      '--adm-color-border': '#333333',
      '--adm-font-size-main': '16px'
      // '--adm-picker-color': '#ffffff',
      // '--adm-color-text-secondary': '#ffffff',
    }

    const handleQuickDateSelect = (daysToAdd = 0) => {
      const date = testDate || new Date()
      const newDate = new Date(date)
      newDate.setDate(date.getDate() + daysToAdd)

      setSelectedDate(newDate)
      setBookingDetails({
        ...bookingDetails,
        date: dayjs(newDate),
        bookingDate: dayjs(newDate)
      })
      setShowDatePicker(false)
    }

    const generateDependantColumns = () => {
      return [
        dependants.map((dep) => ({
          label: dep.label,
          value: dep.value
        }))
      ]
    }

    const handleDependantSelect = (value) => {
      const selectedPatient = JSON.parse(value)
      setPatient(selectedPatient)
      setShowDependantPicker(false)
    }

    // Add Lottie options
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: searchingDoctorsAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <>
        <style>
          {`
            @keyframes shimmer {
              0% { opacity: 0.6; }
              50% { opacity: 1; }
              100% { opacity: 0.6; }
            }
            .shimmer-text {
              animation: shimmer 3s infinite;
            }
          `}
        </style>

        {searchingForDoctors && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1001,
              background: 'white'
            }}
          >
            <Lottie options={defaultOptions} height={300} width={300} style={{ marginBottom: 20 }} />
            <div
              style={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 500
              }}
            >
              Searching for available doctors...
            </div>
          </div>
        )}

        {dependantsLoading && <MobMedicalHistoryLoadingOverlay loading={true} text='Loading...' />}

        <CustomRow style={{ alignItems: 'center' }}>
          <Col span={24}>
            <div
              style={{
                textAlign: 'center',
                fontSize: 12,
                marginTop: 12,
                fontWeight: 500,
                color: '#000000'
              }}
            >
              Configure your booking and find an available doctor online
              <b
                className='shimmer-text'
                style={{
                  color: '#000000',
                  opacity: 0.8
                }}
              >
                {' '}
                in seconds.
              </b>
            </div>
          </Col>
          <Col span={24}>
            <CustomRow style={{ color: '#000000' }} gutter={[12, 8]}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <p style={{ color: '#000000', marginBottom: '8px' }}>Date</p>
                <div
                  onClick={() => setShowDatePicker(true)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    padding: '12px',
                    border: '1px solid #6B7280',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <FontAwesomeIcon icon={faCalendarAlt} style={{ color: '#00b6d8', width: '20px', height: '20px' }} />
                    <span>{dayjs(bookingDetails.bookingDate).format('MMM D, YYYY')}</span>
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} style={{ color: '#6B7280', width: '14px', height: '14px' }} />
                </div>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                <p style={{ color: '#000000', marginBottom: '8px' }}>Service</p>
                <div
                  onClick={() => setShowServicePicker(true)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    padding: '12px',
                    border: '1px solid #6B7280',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <FontAwesomeIcon icon={faKitMedical} style={{ color: '#00b6d8', width: '20px', height: '20px' }} />
                    <span>
                      {setupServices().find((s) => s.value === bookingDetails.service)?.label || 'Select Service'}
                    </span>
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} style={{ color: '#6B7280', width: '14px', height: '14px' }} />
                </div>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                <p style={{ color: '#000000', marginBottom: '8px' }}>Appointment for</p>
                <div
                  onClick={() => setShowDependantPicker(true)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    padding: '12px',
                    border: '1px solid #6B7280',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <FontAwesomeIcon icon={faUser} style={{ color: '#00b6d8', width: '20px', height: '20px' }} />
                    <span>
                      {patient
                        ? dependants?.find((dep) => JSON.parse(dep.value)._id === patient._id)?.label ||
                          'Select Patient'
                        : 'Select Patient'}
                    </span>
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} style={{ color: '#6B7280', width: '14px', height: '14px' }} />
                </div>
              </Col>
            </CustomRow>
          </Col>
        </CustomRow>

        {!dependantsLoading && !searchingForDoctors && (
          <PrevNextFloatingButtons
            nextText='Search'
            onPrev={onBack}
            onNext={handleSubmit}
            onNextDisabled={!patient || !bookingDetails.bookingDate || !bookingDetails.service}
          />
        )}

        <Picker
          visible={showDatePicker}
          onClose={() => setShowDatePicker(false)}
          value={pickerValue}
          onConfirm={handleDateSelect}
          columns={generateDateColumns()}
          style={{ background: 'white' }}
          title={
            <div
              style={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'center',
                padding: '8px 0'
              }}
            >
              <Button
                onClick={() => handleQuickDateSelect(0)}
                style={{
                  backgroundColor: '#00b6d8',
                  color: '#ffffff',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '4px 12px'
                }}
              >
                Today
              </Button>
              <Button
                onClick={() => handleQuickDateSelect(1)}
                style={{
                  backgroundColor: '#00b6d8',
                  color: '#ffffff',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '4px 12px'
                }}
              >
                Tomorrow
              </Button>
            </div>
          }
          confirmText='Confirm'
          cancelText='Cancel'
          popupStyle={pickerPopupStyle}
          onSelect={(val) => setPickerValue(val)}
        />

        <CustomOptionPicker
          visible={showDependantPicker}
          onClose={() => setShowDependantPicker(false)}
          options={generateDependantColumns()[0]}
          onSelect={(value) => handleDependantSelect([value])}
          title='Select Patient'
          icon={faUser}
        />

        <CustomOptionPicker
          visible={showServicePicker}
          onClose={() => setShowServicePicker(false)}
          options={generateServiceColumns()[0]}
          onSelect={(value) => handleServiceSelect([value])}
          title='Select Service'
          icon={faKitMedical}
        />
      </>
    )
  }
)

const CustomOptionPicker = ({ visible, onClose, options, onSelect, title, icon }) => {
  return (
    <Popup
      visible={visible}
      onClose={onClose}
      position='bottom'
      bodyStyle={{
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        maxHeight: '70vh'
      }}
    >
      <div style={{ padding: '16px' }}>
        <div
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: '16px',
            fontSize: '16px'
          }}
        >
          {title}
        </div>
        <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => {
                onSelect(option.value)
                onClose()
              }}
              style={{
                padding: '12px',
                borderBottom: '1px solid #eee',
                width: '100%',
                textAlign: 'left',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '12px'
              }}
            >
              <FontAwesomeIcon icon={icon} style={{ color: '#00b6d8', width: '20px', height: '20px' }} />
              {option.label}
            </div>
          ))}
        </div>
        <div style={{ marginTop: '16px' }}>
          <Button block onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Popup>
  )
}

export default MobBookingDetailsCapture
