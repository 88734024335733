import { Card, Col, Form, Input, Modal, Row, theme, message } from 'antd'
import { useSelector } from 'react-redux'
import { useTemplates } from './hooks/useTemplates'
import { useDataPoints } from './hooks/useDataPoints'
import { useTabManagement } from './hooks/useTabManagement'
import { useFormHandling } from './hooks/useFormHandling'
import { useState } from 'react'
import { FORM_GRID_BREAKPOINTS, LIST_GRID_BREAKPOINTS, MODAL_WIDTH } from './utils/constants'
import { getUnfilledCount, formatValidationError } from './utils/helpers'
import ModalHeader from './components/ModalHeader'
import TabsHeader from './components/TabsHeader'
import FormSections from './components/FormSections'
import DataPointsList from './components/DataPointsList'
import TemplateList from './components/TemplateList'
import SearchBar from './components/SearchBar'

const ExamDataCapture = ({
  websocketConnection,
  userRef,
  bookingData,
  modalOpen = false,
  setModalOpen,
  handleRefresh,
  hasLabReport = false,
  onAfterSuccess,
  onAfterCancel
}) => {
  const { token } = theme.useToken()
  const { agiliteUser } = useSelector((state) => state.auth)
  const [dataForm] = Form.useForm()

  const { data: dataTemplates } = useSelector((state) => state.examTemplates)

  const {
    selectedDataPoints,
    availableDataPoints,
    selectedKeys,
    toggleDataPoint,
    setSelectedDataPoints,
    setSelectedKeys
  } = useDataPoints(hasLabReport)

  const {
    activeTemplate,
    isAddingTemplate,
    newTemplateName,
    setActiveTemplate,
    setIsAddingTemplate,
    setNewTemplateName,
    handleSaveNewTemplate
  } = useTemplates()

  const { activeTab, searchTerm, setActiveTab, setSearchTerm } = useTabManagement()

  const resetForm = () => {
    setSelectedDataPoints([])
    setActiveTemplate(null)
    setIsAddingTemplate(false)
    setNewTemplateName('')
    setSelectedKeys([])
    dataForm.resetFields()
    setActiveTab('templates')
    setModalOpen(false)
    onAfterCancel?.()
  }

  const { handleDataCapture } = useFormHandling({
    availableDataPoints,
    agiliteUser,
    bookingData,
    userRef,
    websocketConnection,
    handleRefresh,
    onAfterSuccess,
    resetForm
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async () => {
    setIsSubmitting(true)

    try {
      if (isAddingTemplate) {
        const newTemplate = await handleSaveNewTemplate(selectedKeys)
        if (newTemplate) {
          setIsAddingTemplate(false)
          setActiveTemplate(newTemplate)
        }
      } else {
        await dataForm.validateFields()
        await handleDataCapture(dataForm.getFieldsValue())
      }
    } catch (error) {
      if (error.errorFields) {
        message.error(formatValidationError(error, availableDataPoints))
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal destroyOnClose closable={false} maskClosable={false} width={MODAL_WIDTH} open={modalOpen} footer={false}>
      <ModalHeader
        isAddingTemplate={isAddingTemplate}
        activeTemplate={activeTemplate}
        resetForm={resetForm}
        token={token}
      />

      <TabsHeader
        activeTab={activeTab}
        onTabChange={(newTab) => {
          setSearchTerm('')
          setActiveTab(newTab)
        }}
        isAddingTemplate={isAddingTemplate}
        isSubmitting={isSubmitting}
        onCancel={resetForm}
        onSubmit={handleSubmit}
        token={token}
        getUnfilledCount={(category) => getUnfilledCount(category, selectedDataPoints, dataForm)}
        newTemplateName={newTemplateName}
        selectedDataPoints={selectedDataPoints}
        form={dataForm}
      />

      <Row gutter={[12, 12]}>
        <Col {...LIST_GRID_BREAKPOINTS}>
          {isAddingTemplate && (
            <Input
              placeholder='Enter template name'
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
              style={{ marginBottom: 10 }}
            />
          )}
          <Card
            size='small'
            styles={{ body: { minHeight: 300, maxHeight: '65vh', overflow: 'auto' } }}
            style={{ position: 'relative', overflow: 'hidden' }}
          >
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} token={token} />

            {activeTab === 'templates' && !isAddingTemplate ? (
              <TemplateList
                dataTemplates={dataTemplates}
                searchTerm={searchTerm}
                activeTemplate={activeTemplate}
                token={token}
                onAddTemplate={() => {
                  setIsAddingTemplate(true)
                  setActiveTemplate(null)
                  setSelectedDataPoints([])
                  setSelectedKeys([])
                  setActiveTab('vitals')
                }}
                onSelectTemplate={(template) => {
                  setActiveTemplate(template)
                  // Convert template keys to selectedDataPoints format
                  const templateDataPoints = template.keys
                    .map((key) => {
                      const dataPoint = availableDataPoints.find((dp) => dp.key === key)
                      return dataPoint ? JSON.stringify(dataPoint) : null
                    })
                    .filter(Boolean)

                  setSelectedDataPoints(templateDataPoints)
                  setSelectedKeys(template.keys)
                }}
                onSelectCustomTemplate={() => {
                  setActiveTemplate(null)
                  setSelectedDataPoints([])
                  setSelectedKeys([])
                }}
              />
            ) : (
              <div style={{ marginTop: 36 }}>
                <DataPointsList
                  filteredPoints={availableDataPoints
                    .filter((point) =>
                      hasLabReport
                        ? point.hasLabReport &&
                          (point.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            point.key.toLowerCase().includes(searchTerm.toLowerCase())) &&
                          point.category.toLowerCase() === activeTab.toLowerCase()
                        : (point.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            point.key.toLowerCase().includes(searchTerm.toLowerCase())) &&
                          point.category.toLowerCase() === activeTab.toLowerCase()
                    )
                    .sort((a, b) => {
                      // First sort by selection status
                      const aSelected = selectedDataPoints.includes(JSON.stringify(a))
                      const bSelected = selectedDataPoints.includes(JSON.stringify(b))
                      if (aSelected !== bSelected) {
                        return bSelected ? 1 : -1 // Selected items first
                      }
                      // Then sort alphabetically by label
                      return a.label.localeCompare(b.label)
                    })}
                  selectedDataPoints={selectedDataPoints}
                  onToggleDataPoint={toggleDataPoint}
                  token={token}
                />
              </div>
            )}
          </Card>
        </Col>
        <Col {...FORM_GRID_BREAKPOINTS}>
          <FormSections
            selectedDataPoints={selectedDataPoints}
            isAddingTemplate={isAddingTemplate}
            activeTab={activeTab}
            onSubmit={handleDataCapture}
            form={dataForm}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default ExamDataCapture
