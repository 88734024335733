import React from 'react'
import DataPointItem from './DataPointItem'

const DataPointsList = ({ filteredPoints, selectedDataPoints, onToggleDataPoint, token }) => {
  return filteredPoints.map((dataPoint, index) => (
    <DataPointItem
      key={dataPoint.key + index}
      dataPoint={dataPoint}
      isSelected={selectedDataPoints.includes(JSON.stringify(dataPoint))}
      onToggle={onToggleDataPoint}
      token={token}
      isLast={index === filteredPoints.length - 1}
    />
  ))
}

export default DataPointsList
