// src/components/BatteryStatus.js
import React, { useContext, useEffect, useState } from 'react'
import BluetoothContext from '../BluetoothContext'

const Detection = {
  BATTERY: 'Battery'
}

const BatteryStatus = () => {
  const { engine, bleCommandCharacteristic } = useContext(BluetoothContext)
  const [batteryLevel, setBatteryLevel] = useState(null)

  useEffect(() => {
    if (!engine || !bleCommandCharacteristic) {
      console.warn('Engine or bleCommandCharacteristic not available for BatteryStatus')
      return
    }

    if (typeof engine.registBatteryListener === 'function') {
      engine.registBatteryListener({
        onProcess: (type, data) => {
          if (bleCommandCharacteristic) {
            bleCommandCharacteristic.writeValue(data).catch((err) => {
              console.error('Error writing to bleCommandCharacteristic:', err)
            })
          }
        },
        onPowerResult: (data) => {
          setBatteryLevel(data)
        },
        onException: (err) => {
          console.error('Battery Status Error:', err)
        }
      })
    } else {
      console.error('engine.registBatteryListener is not a function')
    }

    getBatteryStatus()

    return () => {
      if (typeof engine.unregistBatteryListener === 'function') {
        engine.unregistBatteryListener()
      } else {
        console.warn('engine.unregistBatteryListener is not a function')
      }
    }
    // eslint-disable-next-line
  }, [engine, bleCommandCharacteristic])

  // Define getBatteryStatus function
  const getBatteryStatus = () => {
    if (engine && bleCommandCharacteristic) {
      engine.startDetect(Detection.BATTERY)
    } else {
      console.warn('Engine or bleCommandCharacteristic is not available')
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <h3>Battery Status</h3>
      <p>Battery Level: {batteryLevel !== null ? `${batteryLevel}%` : '--'}</p>
    </div>
  )
}

export default BatteryStatus
