import { useCallback, useEffect, useMemo, useState } from 'react'
import { handleError } from '../../../../lib/utils'
import { readDocuments } from '../utils/api'
import { message } from 'antd'
import { MODAL_STATES } from '../utils/constants'

export const useDocuments = ({ userRef }) => {
  // Document state
  const [documents, setDocuments] = useState([])
  const [loadingDocuments, setLoadingDocuments] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)

  // Filter state
  const [searchQuery, setSearchQuery] = useState('')
  const [dateRange, setDateRange] = useState([])
  const [selectionRequired, setSelectionRequired] = useState(false)

  // Initialize modal state with frozen object for immutability
  const initialModalState = useMemo(
    () => Object.freeze(Object.keys(MODAL_STATES).reduce((acc, key) => ({ ...acc, [MODAL_STATES[key]]: false }), {})),
    []
  )
  const [modalState, setModalState] = useState(initialModalState)

  const handleReadDocuments = useCallback(async () => {
    setLoadingDocuments(true)
    try {
      const data = await readDocuments({ userRef })
      setDocuments(data)
    } catch (error) {
      message.error(handleError(error))
    } finally {
      setLoadingDocuments(false)
    }
    // eslint-disable-next-line
  }, [])

  // Fetch documents on mount
  useEffect(() => {
    handleReadDocuments()
  }, [handleReadDocuments])

  // Modal state management
  const toggleModalState = useCallback((modalKey) => {
    setModalState((prevState) =>
      Object.freeze({
        ...prevState,
        [modalKey]: !prevState[modalKey]
      })
    )
  }, [])

  const handleModalReset = useCallback(() => {
    setModalState(initialModalState)
  }, [initialModalState])

  // Filter documents based on search query and date range
  const filteredDocuments = useMemo(() => {
    if (!documents.length) return []

    return documents.filter((doc) => {
      if (!doc) return false

      const searchTerm = searchQuery.toLowerCase()
      const matchesName = !searchQuery || (doc.name && doc.name.toLowerCase().includes(searchTerm))

      const switchOnDocType = (type) => {
        switch (type) {
          case 'referral':
            return 'Referral'
          case 'sickNote':
            return 'Sick Note'
          case 'labReport':
            return 'Lab Report'
        }
      }
      const matchesType =
        !searchQuery || (switchOnDocType(doc.type) && switchOnDocType(doc.type).toLowerCase().includes(searchTerm))

      const matchesDateRange =
        !dateRange.length ||
        dateRange.length !== 2 ||
        (doc.audit?.createdAt && doc.audit.createdAt >= dateRange[0] && doc.audit.createdAt <= dateRange[1])

      return (matchesName || matchesType) && matchesDateRange
    })
  }, [documents, searchQuery, dateRange])

  return {
    setDocuments,
    documents: filteredDocuments,
    handleReadDocuments,
    loadingDocuments,
    selectedDocument,
    setSelectedDocument,
    handleModalReset,
    toggleModalState,
    modalState,
    searchQuery,
    setSearchQuery,
    dateRange,
    setDateRange,
    selectionRequired,
    setSelectionRequired
  }
}
