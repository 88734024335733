import React from 'react'
import MobileChronicMedications from './MobChronicMedicationsCarousel'
import '../Styling/MobileHealthStats.css'
import usePatientHealthStats from '../../hooks/usePatHealthStats'
import MobPatHealthMetrics from './MobPatHealthMetrics'
import usePatChronicMeds from '../../hooks/usePatChronicMeds'
import MobMedicalHistoryLoadingOverlay from '../../../Medical History/Mobile/components/LoadingOverlay'

const MobHealthStatsWrapper = () => {
  const { loadingHealthMetrics } = usePatientHealthStats()
  const { loadingChronicMedications } = usePatChronicMeds()
  //loadingChronicMedications || loadingHealthMetrics
  return (
    <div className='mobile-health-stats'>
      {loadingChronicMedications || loadingHealthMetrics ? (
        <MobMedicalHistoryLoadingOverlay
          loading={true}
          text={loadingChronicMedications ? 'Loading chronic meds... ' : 'Loading health metrics...'}
        />
      ) : (
        <>
          <div style={{ textAlign: 'center', fontSize: 12, color: '#8C8C8C', marginTop: 12 }}>
            Medical statistics from connected devices <br />
            will be displayed here.{' '}
          </div>
          {/* <div style={{ textAlign: 'center', fontSize: 12, color: '#8C8C8C', marginTop: 12 }}>
            Connect your health devices to
            <br /> capture your data.
            <sup style={{ color: 'goldenrod', fontSize: 4 }}>COMING SOON</sup>
          </div> */}
          <MobPatHealthMetrics />
          <div style={{ textAlign: 'center', fontSize: 12, color: '#8C8C8C', marginTop: 12 }}>
            Any Chronic Medications captured <br /> will be displayed here.
          </div>
          <MobileChronicMedications />
        </>
      )}
      {/* {loadingHealthMetrics ? <AgiliteSkeleton sk
      {Active /> : <MobPatHealthMetrics />}
      {loadingChronicMedications ? <AgiliteSkeleton skActive /> : <MobileChronicMedications />} */}
    </div>
  )
}

export default MobHealthStatsWrapper
