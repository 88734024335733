import { useEffect, useState } from 'react'
import ProfileDisplayMode from './display-mode'
import ProfileEditMode from './edit-mode'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import { useDispatch, useSelector } from 'react-redux'
import { firebaseAuth } from '../../..'
import ProfileEnums from '../utils/enums'
import '../utils/style.css'
import CustomRow from '../../reusable-components/CustomRow'
import { Col } from 'antd'
import CoreEnums from '../../../core/utils/enums'
import coreReducer from '../../../core/utils/reducer'
import EmployeePatientView from './EmployeePatientView'

const Profile = ({ isReception, record, setFormData, setFacet, refreshView }) => {
  const authState = useSelector((state) => state.auth)
  const [isEditMode, setIsEditMode] = useState(isReception)
  const [userData, setUserData] = useState(record ? record : authState.agiliteUser)
  const [image, setImage] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    handleGetImage()
    // eslint-disable-next-line
  }, [userData])

  useEffect(() => {
    setUserData(record ? record : authState.agiliteUser)
    // eslint-disable-next-line
  }, [authState.agiliteUser])

  const handleGetImage = () => {
    let image = null
    if (userData?.profileImage) {
      image = userData.profileImage
    } else if (firebaseAuth?.currentUser?.photoURL) {
      image = firebaseAuth.currentUser.photoURL
    } else {
      image = ProfileEnums.images.DEFAULT_IMAGE
    }
    setImage(image)
  }

  const closeTab = () => {
    refreshView()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: `${CoreEnums.tabKeys.PATIENTS}_${CoreEnums.tabKeys.PERSONAL_DETAILS}_${record._id}`,
        removeBreadcrumb: true
      })
    )
  }

  return (
    <ContainerCard title='Profile'>
      <CustomRow style={{ justifyContent: 'center' }}>
        <Col xs={24} sm={24} md={20} lg={20} xl={16} xxl={16}>
          {isReception ? (
            <EmployeePatientView
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              isReception={isReception}
              userData={userData}
              setUserData={setUserData}
              setFormData={setFormData}
              refreshView={refreshView}
              image={image}
            />
          ) : (
            <>
              {isEditMode ? (
                <ProfileEditMode
                  isEditMode={isEditMode}
                  setIsEditMode={setIsEditMode}
                  isReception={isReception}
                  userData={userData}
                  setUserData={setUserData}
                  setFormData={setFormData}
                  refreshView={refreshView}
                  image={image}
                />
              ) : (
                <ProfileDisplayMode
                  setIsEditMode={setIsEditMode}
                  userData={userData}
                  image={image}
                  closeTab={closeTab}
                  isReception={isReception}
                />
              )}
            </>
          )}
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default Profile
