import React, { useEffect, useState } from 'react'
import UpcomingAppointments from './UpcomingAppointments'
import PastAppointments from './PastAppointments'
import PromoCarousel from './PromoCarousel'
import CustomTabs from './CustomTabs'
import { getPatAppointments } from '../../api/api'
import patAppointmentsReducer from '../../utils/reducer'
import { handleError } from '../../../../lib/utils'
import { Button, Col, message, Row, Skeleton, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import '../styling/PatMobAppointmentsWrapper.css'
import '../styling/UpcomingAppointments.css'
import JourneyTracker from './JourneyTracker/JourneyTracker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const MobPatAppointmentsWrapper = () => {
  const dispatch = useDispatch()
  const [loadingAppointments, setLoadingAppointments] = useState(false)
  const patAppointmentsState = useSelector((state) => state.patAppointments.data)
  const [upcomingAppointments, setUpcomingAppointments] = useState([])
  const [pastAppointments, setPastAppointments] = useState([])

  // Agora
  const [joined, setJoined] = useState(false)
  const [agoraToken, setAgoraToken] = useState(null)
  const [agoraChannel, setAgoraChannel] = useState(null)
  const [agoraUid, setAgoraUid] = useState(null)

  useEffect(() => {
    handleGetPatAppointments()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const now = new Date()
    setUpcomingAppointments(
      patAppointmentsState.filter((appointment) => {
        const appointmentDate = new Date(appointment.bookingDate)
        // Convert both dates to midnight for accurate date comparison
        return new Date(appointmentDate.toDateString()) >= new Date(now.toDateString())
      })
    )
    setPastAppointments(
      patAppointmentsState.filter((appointment) => {
        const appointmentDate = new Date(appointment.bookingDate)
        // Convert both dates to midnight for accurate date comparison
        return new Date(appointmentDate.toDateString()) < new Date(now.toDateString())
      })
    )
  }, [patAppointmentsState])

  const handleGetPatAppointments = async () => {
    setLoadingAppointments(true)

    try {
      const tmpBookings = await getPatAppointments()

      dispatch(patAppointmentsReducer.actions.setState(tmpBookings))
    } catch (e) {
      message.error(handleError(e, true))
    } finally {
      setLoadingAppointments(false)
    }
  }

  const tabs = [
    {
      label: 'Upcoming',
      children: (
        <>
          {loadingAppointments ? (
            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
              <Col span={24}>
                <Space style={{ width: '100%' }} direction='vertical'>
                  <div
                    style={{
                      width: '100%',
                      padding: '16px',
                      background: `linear-gradient(
                        145deg,
                        rgba(16, 178, 225, 0.15) 0%,
                        rgba(16, 178, 225, 0.05) 50%,
                        rgba(16, 178, 225, 0.02) 100%
                      )`,
                      borderRadius: '8px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                    }}
                  >
                    <Skeleton active paragraph={{ rows: 3 }} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      padding: '16px',
                      background: `linear-gradient(
                        145deg,
                        rgba(16, 178, 225, 0.15) 0%,
                        rgba(16, 178, 225, 0.05) 50%,
                        rgba(16, 178, 225, 0.02) 100%
                      )`,
                      borderRadius: '8px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                    }}
                  >
                    <Skeleton active paragraph={{ rows: 3 }} />
                  </div>
                </Space>
              </Col>
            </Row>
          ) : (
            <div style={{ paddingTop: 16 }}>
              <UpcomingAppointments data={upcomingAppointments} />
            </div>
          )}
        </>
      )
    },
    {
      label: 'Past',
      children: (
        <>
          {loadingAppointments ? (
            <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
              <Col span={24}>
                <Space style={{ width: '100%' }} direction='vertical'>
                  <div
                    style={{
                      width: '100%',
                      padding: '16px',
                      background: `linear-gradient(
                    145deg,
                    rgba(16, 178, 225, 0.15) 0%,
                    rgba(16, 178, 225, 0.05) 50%,
                    rgba(16, 178, 225, 0.02) 100%
                  )`,
                      borderRadius: '8px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                    }}
                  >
                    <Skeleton active paragraph={{ rows: 3 }} />
                  </div>{' '}
                  <div
                    style={{
                      width: '100%',
                      padding: '16px',
                      background: `linear-gradient(
                      145deg,
                      rgba(16, 178, 225, 0.15) 0%,
                      rgba(16, 178, 225, 0.05) 50%,
                      rgba(16, 178, 225, 0.02) 100%
                    )`,
                      borderRadius: '8px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                    }}
                  >
                    <Skeleton active paragraph={{ rows: 3 }} />
                  </div>
                </Space>
              </Col>
            </Row>
          ) : (
            <div style={{ paddingTop: 16 }}>
              <PastAppointments data={pastAppointments} />
            </div>
          )}
        </>
      )
    }
  ]

  const [showJourneyModal, setShowJourneyModal] = useState(false)
  // const handleTrackClick = () => {
  //   setShowJourneyModal(true)
  // }

  return (
    <div className='appointments-wrapper'>
      <div className='promo-section' style={{ minHeight: 0 }}>
        <PromoCarousel />
      </div>
      <div style={{ height: '100%', minHeight: 0 }}>
        {/* <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button
              onClick={handleTrackClick}
              type='primary'
              style={{ width: '100%', marginTop: 12, marginBottom: 12 }}
            >
              Track Latest Booking <FontAwesomeIcon icon={faSearch} />
            </Button>
          </Col>
        </Row> */}
        <CustomTabs tabs={tabs} />
      </div>

      <JourneyTracker isOpen={showJourneyModal} onClose={() => setShowJourneyModal(false)} />
      {/* <JourneyFloatingButton onClick={handleTrackClick} /> */}
    </div>
  )
}

export default MobPatAppointmentsWrapper
