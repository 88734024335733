import React, { useState, useEffect } from 'react'
import JourneyModal from './components/JourneyModal/JourneyModal'
import JourneyStep from './components/JourneyStep/JourneyStep'
import BillingSummary from './components/BillingSummary/BillingSummary'
import RunningTotal from './components/RunningTotal/RunningTotal'
import ApprovalRequest from './components/ApprovalRequest/ApprovalRequest'
import { initialSteps, statusMessages } from './data/journeyData'
import styles from './JourneyTracker.module.css'

const JourneyTracker = ({ isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [statusIndex, setStatusIndex] = useState(0)
  const [showApproval, setShowApproval] = useState(false)
  const [currentApproval, setCurrentApproval] = useState(null)

  // Auto progress through steps when modal opens
  useEffect(() => {
    if (isOpen) {
      const progressSteps = () => {
        setCurrentStep(prev => {
          if (prev < initialSteps.length - 1) {
            // Check if next step requires approval
            const nextStep = initialSteps[prev + 1]
            if (nextStep.requiresApproval) {
              setShowApproval(true)
              setCurrentApproval(nextStep.approval)
              return prev
            }
            return prev + 1
          }
          return prev
        })
      }

      const stepInterval = setInterval(progressSteps, 2000) // Progress every 2 seconds

      // Start cycling status messages when reaching step 3
      if (currentStep === 3) {
        const messageInterval = setInterval(() => {
          setStatusIndex(prev => (prev + 1) % statusMessages.length)
        }, 3000)
        return () => {
          clearInterval(stepInterval)
          clearInterval(messageInterval)
        }
      }

      return () => clearInterval(stepInterval)
    }
  }, [isOpen, currentStep])

  const handleApprovalAction = (id, isApproved) => {
    setShowApproval(false)
    setCurrentApproval(null)
    if (isApproved) {
      setCurrentStep(prev => prev + 1)
    }
  }

  if (!isOpen) return null

  return (
    <>
      <JourneyModal onClose={onClose}>
        <div className={styles.content}>
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>Journey Progress</h3>
            <div className={styles.timeline}>
              {initialSteps.map((step, index) => (
                <JourneyStep
                  key={step.id}
                  step={step}
                  status={
                    index === currentStep ? 'current' :
                    index < currentStep ? 'completed' :
                    'upcoming'
                  }
                  index={index}
                  totalSteps={initialSteps.length}
                />
              ))}
            </div>
          </div>

          {currentStep === 3 && !showApproval && (
            <div className={styles.eta}>
              <p className={styles.statusMessage}>
                {statusMessages[statusIndex]}
              </p>
            </div>
          )}

          <div className={styles.section}>
            <BillingSummary 
              steps={initialSteps} 
              currentStep={currentStep} 
            />
          </div>
        </div>

        <RunningTotal 
          steps={initialSteps} 
          currentStep={Math.min(currentStep, initialSteps.length - 1)}
        />
      </JourneyModal>

      {showApproval && currentApproval && (
        <ApprovalRequest
          key="approval-modal"
          approval={currentApproval}
          onApprove={(id) => handleApprovalAction(id, true)}
          onDecline={(id) => handleApprovalAction(id, false)}
        />
      )}
    </>
  )
}

export default JourneyTracker 