import Agilite from 'agilite'
import dayjs from 'dayjs'
import store from '../../../../store'
import pdfGenerationEnums from './enums'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(new Blob([blob]))

    reader.onloadend = async () => {
      resolve(reader.result)
    }

    reader.onerror = (error) => reject(error)
  })
}

export const downloadScriptWithBooking = (medications, signature, mpNumber, bookingData, template, returnPDF) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      const clinicDetails = store.getState().clinics.data.find((clinic) => clinic._id === bookingData.clinicRef)
      let data = null
      let pdfResponse = null
      let templateData = {}

      const generateMedLabel = (med, index) => {
        let str = `${index + 1}). `
        if (med.dosage) {
          str += med.dosage
          str += ' '
        }
        str += med.name
        if (med.freq) {
          str += ' '
          str += med.freq
        }
        if (med.adminRoute) {
          str += ' '
          str += med.adminRoute
        }
        if (med.duration) {
          str += ' '
          str += med.duration
        }
        if (med.repeatMonths) {
          str += ` - repeat x ${med.repeatMonths} months`
        }
        return str
      }

      try {
        data = []

        medications.forEach((med, index) => {
          const formattedName = generateMedLabel(med, index)
          data.push({
            value: formattedName
          })
        })

        templateData = {
          patientName: bookingData.patientName,
          dateOfBirth: bookingData.patientRecord.dateOfBirth,
          phoneNumber: hasValue(bookingData.patientRecord.phoneNumber, 'n/a'),
          email: hasValue(bookingData.patientRecord.email, 'n/a'),
          address: bookingData.patientRecord.residentialAddress,
          medProfName: store.getState().auth.agiliteUser.firstName + ' ' + store.getState().auth.agiliteUser.lastName,
          qualification: store.getState().auth.agiliteUser.extraData.qualification,
          mpNumber: store.getState().auth.agiliteUser.extraData.mpNumber,
          date: dayjs(new Date()).format('YYYY-MM-DD'),
          medAidName: hasValue(bookingData.patientRecord.medicalAid.name, 'n/a'),
          medAidPlan: hasValue(bookingData.patientRecord.medicalAid?.plan, 'n/a'),
          medAidNumber: hasValue(bookingData.patientRecord.medicalAid?.number, 'n/a'),
          dependantNumber: hasValue(bookingData.patientRecord.medicalAid?.dependantNumber, 'n/a'),
          practiceName: clinicDetails?.practiceName,
          practiceNo: clinicDetails?.practiceNumber,
          clinicPhone: clinicDetails?.phoneNumber,
          regNo: clinicDetails?.practiceRegNumber,
          clinicAddress: clinicDetails?.address,
          entityLogo: store.getState().core.entity.entityLogo?.split(',')[1],
          entityIcon: store.getState().core.entity.entityIcon?.split(',')[1],
          signatureData: signature.split(',')[1],
          scriptItems: data
        }

        pdfResponse = await agilite.Connectors.execute(
          pdfGenerationEnums.pdfGeneration.CRAFT_MY_PDF,
          pdfGenerationEnums.pdfGeneration.CREATE_SCRIPT,
          {
            data: JSON.stringify(templateData)
          }
        )

        if (pdfResponse.data && pdfResponse.data.status === 'success') {
          if (returnPDF) {
            resolve(pdfResponse.data.file)
          } else {
            window.open(pdfResponse.data.file, '_blank', 'noreferrer')
            resolve()
          }
        } else {
          reject('Error generating PDF')
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}

/**
 * Generates and downloads a medical prescription script as a PDF
 * @param {Array} medications - Array of medication objects containing prescription details
 * @param {string} signature - Base64 encoded signature data
 * @param {Object} clinicDetails - Object containing clinic information
 * @param {string} clinicDetails.practiceName - Name of the medical practice
 * @param {string} clinicDetails.practiceNumber - Practice registration number
 * @param {string} clinicDetails.phoneNumber - Clinic contact number
 * @param {string} clinicDetails.practiceRegNumber - Practice registration number
 * @param {string} clinicDetails.address - Clinic physical address
 * @param {Object} patientDetails - Object containing patient information
 * @param {string} patientDetails.firstName - Patient's first name
 * @param {string} patientDetails.lastName - Patient's last name
 * @param {string} patientDetails.dateOfBirth - Patient's date of birth
 * @param {string} patientDetails.phoneNumber - Patient's contact number
 * @param {string} patientDetails.email - Patient's email address
 * @param {string} patientDetails.residentialAddress - Patient's residential address
 * @param {Object} patientDetails.medicalAid - Patient's medical aid details
 * @returns {Promise<string>} Returns a promise that resolves with the PDF file URL and automatically opens it in a new tab
 * @throws {Error} Throws an error if PDF generation fails
 */
export const downloadScript = (medications, signature, clinicDetails, patientDetails) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let data = null
      let pdfResponse = null
      let templateData = {}

      const generateMedLabel = (med, index) => {
        let str = `${med.name} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
          med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
        } ${med.presentationCode ? med.presentationCode : ''} - ${med.standardPacksize ? med.standardPacksize : ''}`
        return str
      }

      try {
        data = []

        medications.forEach((med, index) => {
          const formattedName = generateMedLabel(med, index)
          data.push({
            label: formattedName,
            dosage: med.scriptingDosage ? med.scriptingDosage : 'n/a',
            qty: med.standardPacksize ? med.standardPacksize : 'n/a',
            rpt: med.rpt ? med.rpt : 'n/a',
            icd10: med.icd?.code ? med.icd.code : 'n/a'
          })
        })

        templateData = {
          patientName: patientDetails.firstName + ' ' + patientDetails.lastName,
          dateOfBirth: patientDetails.dateOfBirth,
          phoneNumber: hasValue(patientDetails.phoneNumber, 'n/a'),
          email: hasValue(patientDetails.email, 'n/a'),
          address: patientDetails.residentialAddress,
          medProfName: store.getState().auth.agiliteUser.firstName + ' ' + store.getState().auth.agiliteUser.lastName,
          qualification: store.getState().auth.agiliteUser.extraData.qualification,
          mpNumber: store.getState().auth.agiliteUser.extraData.mpNumber,
          date: dayjs(new Date()).format('YYYY-MM-DD'),
          medAidName: hasValue(patientDetails.medicalAid.name, 'n/a'),
          medAidPlan: hasValue(patientDetails.medicalAid?.plan, 'n/a'),
          medAidNumber: hasValue(patientDetails.medicalAid?.number, 'n/a'),
          dependantNumber: hasValue(patientDetails.medicalAid?.dependantNumber, 'n/a'),
          practiceName: clinicDetails?.practiceName,
          practiceNo: clinicDetails?.practiceNumber,
          clinicPhone: clinicDetails?.phoneNumber,
          regNo: clinicDetails?.practiceRegNumber,
          clinicAddress: clinicDetails?.address,
          entityLogo: store.getState().core.entity.entityLogo.split(',')[1],
          entityIcon: store.getState().core.entity.entityIcon.split(',')[1],
          signatureData: signature.split(',')[1],
          scriptItems: data
        }

        pdfResponse = await agilite.Connectors.execute(
          pdfGenerationEnums.pdfGeneration.CRAFT_MY_PDF,
          pdfGenerationEnums.pdfGeneration.CREATE_SCRIPT,
          {
            data: JSON.stringify(templateData)
          }
        )

        if (pdfResponse.data && pdfResponse.data.status === 'success') {
          // window.open(pdfResponse.data.file, '_blank', 'noreferrer')
          resolve(pdfResponse.data.file)
        } else {
          reject('Error generating PDF')
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const downloadSickLeave = (data, sickLeave, signature, template, patient) => {
  const handleGeneratePronoun1 = () => {
    if (patient) {
      switch (patient.gender) {
        case 'female':
          return 'she'
        default:
          return 'he'
      }
    } else {
      switch (data.patientRecord.gender) {
        case 'female':
          return 'she'
        default:
          return 'he'
      }
    }
  }
  const handleGeneratePronoun2 = () => {
    if (patient) {
      switch (patient.gender) {
        case 'female':
          return 'her'
        default:
          return 'his'
      }
    } else {
      switch (data.patientRecord.gender) {
        case 'female':
          return 'her'
        default:
          return 'his'
      }
    }
  }

  return new Promise((resolve, reject) => {
    ;(async () => {
      let templateResponse = null
      let pdfResponse = null
      let fileResponse = null
      let templateData = null

      try {
        if (data) {
          const clinicDetails = store.getState().clinics.data.find((clinic) => clinic._id === data.clinicRef)
          templateData = {
            bookingDate: dayjs(data.bookingDate).format('DD MMMM YYYY'),
            startDate: dayjs(sickLeave.startDate).format('DD MMM YYYY'),
            endDate: dayjs(sickLeave.endDate).format('DD MMM YYYY'),
            pronoun1: handleGeneratePronoun1(),
            pronoun2: handleGeneratePronoun2(),
            signature,
            submitted: dayjs(new Date()).format('DD MMM YYYY'),
            patientName: data.patientRecord.firstName + ' ' + data.patientRecord.lastName,
            professionalName:
              store.getState().auth.agiliteUser.firstName + ' ' + store.getState().auth.agiliteUser.lastName,
            practiceName: clinicDetails?.practiceName,
            practiceRegNo: clinicDetails?.practiceRegNumber,
            practiceNo: clinicDetails?.practiceNumber,
            clinicAddress: clinicDetails?.address,
            clinicPhone: clinicDetails?.phoneNumber,
            logo: store.getState().core.entity.entityLogo,
            familyResponsibility: sickLeave?.comments ? sickLeave?.comments : '',
            firstLine: sickLeave?.familyResponsibility ? 40 : 20,
            secondLine: sickLeave?.familyResponsibility ? 40 : 20,
            qualification: store.getState().auth.agiliteUser.extraData.qualification
          }
        } else {
          const clinicDetails = store
            .getState()
            .clinics.data.find((clinic) => store.getState().auth.agiliteUser.extraData.clinics.includes(clinic._id))

          templateData = {
            bookingDate: dayjs().format('DD MMMM YYYY'),
            startDate: dayjs().format('DD MMM YYYY'),
            endDate: dayjs().format('DD MMM YYYY'),
            pronoun1: handleGeneratePronoun1(),
            pronoun2: handleGeneratePronoun2(),
            signature,
            submitted: dayjs(new Date()).format('DD MMM YYYY'),
            patientName: patient.firstName + ' ' + patient.lastName,
            professionalName:
              store.getState().auth.agiliteUser.firstName + ' ' + store.getState().auth.agiliteUser.lastName,
            practiceName: clinicDetails?.practiceName,
            practiceRegNo: clinicDetails?.practiceRegNumber,
            practiceNo: clinicDetails?.practiceNumber,
            clinicAddress: clinicDetails?.address,
            clinicPhone: clinicDetails?.phoneNumber,
            logo: store.getState().core.entity.entityLogo,
            familyResponsibility: sickLeave?.comments ? sickLeave?.comments : '',
            firstLine: sickLeave?.familyResponsibility ? 40 : 20,
            secondLine: sickLeave?.familyResponsibility ? 40 : 20,
            qualification: store.getState().auth.agiliteUser.extraData.qualification
          }
        }
        templateResponse = await agilite.Templates.execute(template, templateData)
        pdfResponse = await agilite.Utils.generatePDF(templateResponse.data)
        fileResponse = await agilite.Files.getFile(pdfResponse.data._id)

        const file = new Blob([fileResponse.data], { type: 'application/pdf' })
        const base64data = await blobToBase64(file)
        localStorage.setItem('pdfFile', base64data)

        const fileURL = URL.createObjectURL(file)
        const pdfWindow = window.open()
        pdfWindow.location.href = fileURL

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}
export const viewStoredPDF = () => {
  const base64PDF = localStorage.getItem('pdfFile')
  if (!base64PDF) {
    console.error('No PDF file found in localStorage')
    return null
  }

  // Create blob from base64
  const byteCharacters = atob(base64PDF.split(',')[1])
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: 'application/pdf' })

  // Return the URL instead of opening in new tab
  return URL.createObjectURL(file)
}
export const downloadReferralLetter = (booking, patientRecord, content, signature, template) => {
  const authUser = store.getState().auth.agiliteUser
  let clinicDetails = null
  if (booking) {
    clinicDetails = store.getState().clinics.data.find((clinic) => clinic._id === booking.clinicRef)
  } else {
    clinicDetails = store.getState().clinics.data.find((clinic) => authUser.extraData.clinics.includes(clinic._id))
  }

  return new Promise((resolve, reject) => {
    ;(async () => {
      let pdfResponse = null
      let templateData = {
        patientName: `${patientRecord.firstName}  ${patientRecord.lastName}`,
        idNo: patientRecord.idNo,
        phoneNumber: patientRecord.phoneNumber,
        medAidName: patientRecord.medicalAid.name,
        medAidPlan: patientRecord.medicalAid.plan,
        medAidNumber: patientRecord.medicalAid.number,
        dependantNumber: patientRecord.medicalAid.dependantNumber,
        date: dayjs(new Date()).format('DD MMM YYYY'),
        practiceName: clinicDetails?.practiceName,
        practiceNo: clinicDetails?.practiceNumber,
        regNo: clinicDetails?.practiceRegNumber,
        clinicAddress: clinicDetails?.address,
        clinicPhone: clinicDetails?.phoneNumber,
        referralData: content.replace(/<p>\s*<br\s*\/?>\s*<\/p>/gi, ''), // Handle both <br> and <br />,
        medProfName: authUser.firstName + ' ' + authUser.lastName,
        qualification: authUser.extraData.qualification,
        entityLogo: store.getState().core.entity.entityLogo.split(',')[1],
        entityIcon: store.getState().core.entity.entityIcon.split(',')[1],
        signatureData: signature.split(',')[1]
      }

      try {
        pdfResponse = await agilite.Connectors.execute(
          pdfGenerationEnums.pdfGeneration.CRAFT_MY_PDF,
          pdfGenerationEnums.pdfGeneration.CREATE_REFERRAL,
          {
            data: JSON.stringify(templateData)
          }
        )

        if (pdfResponse.data && pdfResponse.data.status === 'success') {
          window.open(pdfResponse.data.file, '_blank', 'noreferrer')
        } else {
          reject('Error generating PDF')
        }

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const sendScript = (agiliteUser, medications) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let file = null

      try {
        file = await downloadScript(medications)
        file = await blobToBase64(file)

        await agilite.Connectors.execute('sendgrid_new', 'send_dynamic', {
          apiKey: process.env.REACT_APP_SENDGRID_API_KEY,
          data: JSON.stringify({
            from: { email: 'noreply@meta-health.co.za' },
            personalizations: [
              {
                to: [{ email: agiliteUser.email }]
              }
            ],
            template_id: 'd-a8d1c44dcde445beb7965d8a26cacdcc',
            attachments: [
              {
                content: file.split('base64,')[1],
                filename: 'script.pdf',
                type: 'application/pdf',
                disposition: 'attachment'
              }
            ]
          })
        })

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const hasValue = (value, placeholder) => {
  if (value) {
    return value
  } else {
    return placeholder
  }
}
