import dayjs from 'dayjs'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { stringToColor } from '../../lib/utils'

/**
 * StandardDataPointGraph component for rendering line charts of medical data points
 * @param {Object} props Component props
 * @param {Object} props.data Data object containing records array
 * @param {Object} props.dataTemplate Template defining data structure and display
 * @returns {JSX.Element} Line chart visualization
 */
export const StandardDataPointGraph = ({ data, dataTemplate }) => {
  const DATE_FORMAT = 'DD-MM-YYYY'
  const [graphData, setGraphData] = useState([])

  // Sort records chronologically
  const sortGraphData = useMemo(
    () => (records) => records.sort((a, b) => dayjs(a.dateCreated).valueOf() - dayjs(b.dateCreated).valueOf()),

    []
  )

  // Transform raw data into chart-compatible format
  const handleSetGraphData = useCallback(() => {
    if (!data?.records?.length) return

    const sortedRecords = sortGraphData(data.records)
    const newGraphData = sortedRecords.map((record) => {
      const baseObject = {
        name: dayjs(record.dateCreated).format(DATE_FORMAT),
        timestamp: dayjs(record.dateCreated).valueOf() // For proper sorting
      }

      if (Array.isArray(record.value)) {
        record.value.forEach(({ key, value }) => {
          baseObject[key] = Number(value) // Ensure numeric values
        })
      } else {
        baseObject[record.key] = Number(record.value)
      }

      return baseObject
    })

    setGraphData(newGraphData)
  }, [data, sortGraphData])

  useEffect(() => {
    handleSetGraphData()
  }, [handleSetGraphData])

  // Custom label component for data points
  const CustomLabel = useMemo(
    () =>
      ({ x, y, stroke, value }) =>
        value && (
          <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor='middle'>
            {value}
          </text>
        ),
    []
  )

  // Generate Line components based on data structure
  const chartLines = useMemo(() => {
    if (!dataTemplate) return null

    if (dataTemplate.subFields?.length > 0) {
      return dataTemplate.subFields.map((subField) => (
        <Line
          key={subField.key}
          type='monotone'
          dataKey={subField.key}
          stroke={stringToColor(subField.key)}
          activeDot={{ r: 8 }}
          label={<CustomLabel />}
          connectNulls
        />
      ))
    }

    return (
      <Line
        key={data.key}
        type='monotone'
        dataKey={data.key}
        stroke={stringToColor(data.key)}
        activeDot={{ r: 8 }}
        label={<CustomLabel />}
        connectNulls
      />
    )
    // eslint-disable-next-line
  }, [dataTemplate, data, CustomLabel])

  // Custom legend formatter
  const renderLegend = useCallback(
    (value) => {
      if (!dataTemplate) return null

      if (dataTemplate.subFields?.length > 0) {
        const subField = dataTemplate.subFields.find((field) => field.key === value)
        return subField ? <span style={{ color: stringToColor(subField.key) }}>{subField.label}</span> : null
      }

      return <span style={{ color: stringToColor(dataTemplate.key) }}>{dataTemplate.label}</span>
    },
    [dataTemplate]
  )

  if (!data?.records?.length || !dataTemplate) {
    return null
  }

  const startDate = dayjs(data.records[0].dateCreated).format(DATE_FORMAT)
  const endDate = dayjs(data.records[data.records.length - 1].dateCreated).format(DATE_FORMAT)

  return (
    <ResponsiveContainer width='100%' height={250} style={{ background: 'white' }}>
      <LineChart
        data={graphData}
        margin={{
          top: 25,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' ticks={startDate !== endDate ? [startDate, endDate] : [startDate]} />

        <YAxis />
        <Tooltip />
        <Legend formatter={renderLegend} />
        {chartLines}
      </LineChart>
    </ResponsiveContainer>
  )
}
