import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Card, Col, Row, Space, theme, Table, Input, Radio, Empty, Button } from 'antd'
import { hexToRGBA } from '../../../../lib/utils'
import { StandardDataPointGraph } from '../../../examination-utils/standard-graph'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

const ClinicalTesting = React.memo(({ patientDataEntries, setLabReportModalOpen }) => {
  const [selectedTest, setSelectedTest] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [testType, setTestType] = useState('all')
  const { data: dataPoints } = useSelector((state) => state.dataPoints)
  const { token } = theme.useToken()

  // Memoize data point templates lookup for better performance
  const dataPointTemplatesMap = useMemo(() => {
    return dataPoints.reduce((acc, point) => {
      acc[point.key] = point
      return acc
    }, {})
  }, [dataPoints])

  const getTestData = useCallback(
    (key) => {
      if (!patientDataEntries?.length) return []
      const dataPointTemplate = dataPointTemplatesMap[key]
      if (!dataPointTemplate) return []

      const subFieldsMap = dataPointTemplate.subFields?.reduce((acc, field) => {
        acc[field.key] = field
        return acc
      }, {})

      return patientDataEntries
        .filter((entry) => entry?.key === key)
        .reduce((acc, entry) => {
          if (Array.isArray(entry.value)) {
            const processedValues = entry.value.map((v) => {
              const fieldTemplate = subFieldsMap?.[v.key]
              return {
                ...v,
                isNumeric: !isNaN(parseFloat(v.value)),
                name: fieldTemplate?.label || v.name || 'Reading'
              }
            })

            acc.push({
              ...entry,
              value: processedValues,
              displayValue: processedValues.map((v) => v.value).join('/'),
              name: dayjs(entry.dateCreated).format('DD-MM-YYYY'),
              subfields: processedValues.map((v) => v.name)
            })
          } else if (!isNaN(parseFloat(entry.value))) {
            acc.push({
              ...entry,
              value: entry.value,
              displayValue: entry.value,
              name: dayjs(entry.dateCreated).format('DD-MM-YYYY')
            })
          }
          return acc
        }, [])
        .sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
    },
    [patientDataEntries, dataPointTemplatesMap]
  )

  const availableTests = useMemo(() => {
    const categoryFilters = {
      rapid: (point) => point.category === 'rapid',
      poc: (point) => point.category === 'poc',
      labs: (point) => point.category === 'labs',
      all: (point) => ['poc', 'rapid', 'labs'].includes(point.category)
    }

    const filterFn = categoryFilters[testType]

    return dataPoints.filter(filterFn).reduce((acc, point) => {
      const data = getTestData(point.key)
      if (data.length > 0) {
        acc.push({
          title: point.label,
          key: point.key,
          data,
          suffix: point.suffix
        })
      }
      return acc
    }, [])
  }, [dataPoints, getTestData, testType])

  const filteredTests = useMemo(() => {
    if (!searchTerm) return availableTests
    const lowerSearchTerm = searchTerm.toLowerCase()
    return availableTests.filter((test) => test.title.toLowerCase().includes(lowerSearchTerm))
  }, [availableTests, searchTerm])

  useEffect(() => {
    if (filteredTests.length > 0) {
      const testWithData = filteredTests.find((test) => test.data && test.data.length > 0)
      setSelectedTest(testWithData || null)
    } else {
      setSelectedTest(null)
    }
  }, [filteredTests, testType])

  const formatTestValue = useCallback((test) => {
    if (!test?.data?.length) return 'No data'
    const latestData = test.data[test.data.length - 1]
    if (Array.isArray(latestData.value)) return 'Click to view'
    return `${latestData.displayValue}${test.suffix ? ` ${test.suffix}` : ''}`
  }, [])

  const getTableColumns = useCallback(() => {
    if (!selectedTest?.data?.length) return []

    const baseColumns = [
      {
        title: 'Date',
        dataIndex: 'dateCreated',
        key: 'dateCreated',
        render: (date) => dayjs(date).format('DD MMM YYYY HH:mm')
      }
    ]

    if (Array.isArray(selectedTest.data[0]?.value)) {
      return [
        ...baseColumns,
        ...selectedTest.data[0].value.map((v, index) => ({
          title: v.name,
          dataIndex: ['value', index, 'value'],
          key: `value_${index}`
        }))
      ]
    }

    return [
      ...baseColumns,
      {
        title: 'Reading',
        key: 'reading',
        render: (record) => `${record.displayValue}${selectedTest.suffix ? ` ${selectedTest.suffix}` : ''}`
      }
    ]
  }, [selectedTest])

  const isNumericValue = useCallback((value) => {
    const numValue = parseFloat(value)
    return (
      !isNaN(numValue) && !String(value).includes('+') && !String(value).includes('-') && typeof numValue === 'number'
    )
  }, [])

  const checkAllNumeric = useCallback(
    (record) => {
      if (Array.isArray(record.value)) {
        return record.value.every((v) => v.isNumeric && isNumericValue(v.value))
      }
      return isNumericValue(record.value)
    },
    [isNumericValue]
  )

  const renderGraph = useMemo(() => {
    if (!selectedTest?.data.length) return null

    const isAllNumeric = selectedTest.data.every(checkAllNumeric)
    if (!isAllNumeric) return null

    return (
      <StandardDataPointGraph
        data={{
          records: selectedTest.data.map((record) => ({
            ...record,
            value: Array.isArray(record.value) ? record.value.map((v) => parseFloat(v.value)) : parseFloat(record.value)
          })),
          key: selectedTest.key
        }}
        dataTemplate={dataPointTemplatesMap[selectedTest.key]}
      />
    )
  }, [selectedTest, checkAllNumeric, dataPointTemplatesMap])

  return (
    <Card title='Blood Tests' size='small' styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}>
      <Row>
        <Col span={24} style={{ marginBottom: 16 }}>
          <Space style={{ width: '100%' }}>
            <Input.Search
              placeholder='Search tests...'
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: '300px' }}
            />
            <Radio.Group value={testType} onChange={(e) => setTestType(e.target.value)} buttonStyle='solid'>
              <Radio.Button value='all'>All Tests</Radio.Button>
              <Radio.Button value='rapid'>Rapid</Radio.Button>
              <Radio.Button value='poc'>POC</Radio.Button>
              <Radio.Button value='labs'>Labs</Radio.Button>
            </Radio.Group>
            <Button
              type='primary'
              onClick={() => {
                setLabReportModalOpen(true)
              }}
            >
              Lab Report
            </Button>
          </Space>
        </Col>
        <Col span={24}>
          {filteredTests.length > 0 ? (
            <Space
              wrap
              style={{
                background: '#f5f5f5',
                gap: 8,
                border: '5px #f5f5f5 solid',
                borderBottom: 'none',
                width: '100%'
              }}
            >
              {filteredTests.map((test) => {
                const isSelected = selectedTest?.key === test.key
                return (
                  <div
                    key={test.key}
                    onClick={() => setSelectedTest(test)}
                    style={{
                      padding: 12,
                      borderBottom: '5px solid',
                      cursor: 'pointer',
                      borderColor: isSelected ? token.colorPrimary : '#f5f5f5',
                      background: isSelected ? '#ffffff' : '#f5f5f5',
                      transition: 'all 0.3s ease',
                      minWidth: 150
                    }}
                  >
                    <Space style={{ gap: 4, width: '100%' }} direction='vertical'>
                      <span>{test.title}</span>
                      <div>
                        <b>{formatTestValue(test)}</b>
                      </div>
                    </Space>
                  </div>
                )
              })}
            </Space>
          ) : (
            <Empty description='Nothing has been recorded' />
          )}
        </Col>

        {selectedTest && (
          <>
            <Col span={24}>{renderGraph}</Col>

            <Col span={24}>
              <Table
                size='small'
                columns={getTableColumns()}
                dataSource={selectedTest.data}
                rowKey={(record, index) => index}
                pagination={false}
              />
            </Col>
          </>
        )}
      </Row>
    </Card>
  )
})

ClinicalTesting.displayName = 'ClinicalTesting'

export default ClinicalTesting
