export const ERROR_MESSAGES = {
  TRANSCRIPTION_FAILED: 'Failed to transcribe audio',
  NO_NOTE_EMPTY: "You can't add another note when your current note is empty.",
  ADD_NOTE_CONFIRM:
    'Are you sure? You will not be able to go back and edit your previous note after adding an additional note. Old notes can be accessed from the historical notes tab.'
}

export const DEBOUNCE_DELAY = 3000

export const MAX_RECORDING_TIME = 15 * 60 * 1000 // 15 minutes in milliseconds

export const DIANGOSIS_DEBOUNCE_DELAY = 300 // Reduced from 500ms for better responsiveness
export const DIANGOSIS_ERROR_MESSAGE = 'There was a problem saving this diagnosis. Please try again.'
export const DIANGOSIS_INITIAL_STATE = {
  selectedDiagnoses: [],
  isSaveError: false,
  isLoading: false,
  searchQuery: '',
  icd10Data: []
}

export const CLINICAL_NOTES_TEMPLATES_INITIAL_STATE = {
  dataPoints: [],
  newTemplateContent: '',
  isNew: false,
  templates: [],
  dataPointsLoading: false,
  editTarget: null,
  loading: false
}
