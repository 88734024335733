import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { readMacroTrackerMetaData, readMacroTrackers, readSavedMacroTrackerMeals } from '../utils/macro-tracker-utils'
import dayjs from 'dayjs'

const useMobileTracker = ({ userRef }) => {
  const authState = useSelector((state) => state.auth)
  const [limits, setLimits] = useState(null)
  const [savedMeals, setSavedMeals] = useState([])
  const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false)
  const [showGraphs, setShowGraphs] = useState(false)
  const [showHealthyAlternatives, setShowHealthyAlternatives] = useState(false)
  const [capturedImage, setCapturedImage] = useState(null)

  const [loading, setLoading] = useState(false)
  const [trackerData, setTrackerData] = useState([])
  const [dateRange, setDateRange] = useState([dayjs().subtract(7, 'days'), dayjs()])

  const [isCapturing, setIsCapturing] = useState(false)

  useEffect(() => {
    handleGetMetaData()
    handleGetSavedMeals()
    handleGetTrackerData()
    // eslint-disable-next-line
  }, [dateRange])

  useEffect(() => {
    // Prevent body scroll when image upload modal is open
    if (imageUploadModalOpen || showGraphs || showHealthyAlternatives) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [imageUploadModalOpen, showGraphs, showHealthyAlternatives])

  useEffect(() => {
    if (!imageUploadModalOpen) {
      setIsCapturing(false)
    }
  }, [imageUploadModalOpen])

  const handleGetMetaData = async () => {
    try {
      const data = await readMacroTrackerMetaData({
        userRef: userRef || authState.agiliteUser._id
      })
      if (data.length > 0) {
        setLimits(data[0])
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleGetSavedMeals = async () => {
    try {
      const data = await readSavedMacroTrackerMeals({
        userRef: userRef || authState.agiliteUser._id
      })
      setSavedMeals(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleGetTrackerData = async () => {
    setLoading(true)
    try {
      const filter = {
        userRef: userRef || authState.agiliteUser._id,
        createdAt: {
          $gte: dateRange[0].toDate(),
          $lte: dateRange[1].toDate()
        }
      }
      const data = await readMacroTrackers(filter)
      setTrackerData(data)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const handleCameraCapture = async (base64Data, file) => {
    setIsCapturing(true)
    setCapturedImage({ base64: base64Data, file })
    setImageUploadModalOpen(true)
  }
  return {
    imageUploadModalOpen,
    setImageUploadModalOpen,
    savedMeals,
    setSavedMeals,
    limits,
    handleGetMetaData,
    capturedImage,
    setCapturedImage,
    showGraphs,
    trackerData,
    loading,
    dateRange,
    setDateRange,
    setShowGraphs,
    setShowHealthyAlternatives,
    showHealthyAlternatives,
    handleCameraCapture,
    isCapturing
  }
}

export default useMobileTracker
