import { useEffect, useState, useCallback, useMemo } from 'react'
import CoreEnums from '../../../../core/utils/enums'
import { uniqBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import coreReducer from '../../../../core/utils/reducer'

const useClinicsList = ({ data = [], createRecord, handleSearch }) => {
  const coreState = useSelector((state) => state.core)
  const dispatch = useDispatch()

  const [filters, setFilters] = useState({
    province: [],
    clinicType: [], // Added missing initial state
    name: [],
    address: []
  })

  const provinceFilters = useMemo(() => {
    return Object.values(CoreEnums.provinces).map((province) => ({
      text: province,
      value: province
    }))
  }, [])

  const generateFilters = useCallback(() => {
    const clinicTypeFilters = data
      .filter((item) => item.clinicType) // Filter out items without clinicType
      .map((item) => ({
        text: item.clinicType,
        value: item.clinicType
      }))

    setFilters((prevFilters) => ({
      ...prevFilters,
      province: provinceFilters,
      clinicType: uniqBy(clinicTypeFilters, 'text')
    }))
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    generateFilters()
  }, [generateFilters])

  const handleCreateClick = () => {
    if (coreState.tourOpen) {
      dispatch(coreReducer.actions.nextTourStep())
    }
    createRecord()
  }

  const handleSearchChange = (value) => {
    handleSearch(value)
  }

  return {
    filters,
    generateFilters,
    coreState,
    handleCreateClick,
    handleSearchChange
  }
}

export default useClinicsList
