import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Space } from 'antd'
import React from 'react'

const DataPointItem = ({ dataPoint, isSelected, onToggle, token, isLast }) => {
  return (
    <React.Fragment>
      <Space style={{ width: '100%', padding: 10, justifyContent: 'space-between' }}>
        <p style={{ width: '100%', margin: 0 }}>{dataPoint.label}</p>
        <div
          style={{
            background: isSelected ? token.colorError : token.colorSuccess,
            width: 20,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            color: '#ffffff',
            cursor: 'pointer'
          }}
          onClick={() => onToggle(dataPoint)}
        >
          <FontAwesomeIcon style={{ fontSize: 10 }} icon={isSelected ? faMinus : faPlus} />
        </div>
      </Space>
      {!isLast && <Divider style={{ margin: 0 }} />}
    </React.Fragment>
  )
}

export default DataPointItem
