import React from 'react'
import { Drawer, Checkbox, Radio, Space, Typography } from 'antd'
import { diagnosticTestCategories, specimenTypes } from '../../../mockdata/diagnosticTestsData'
import MobTransparentButton from '../../../../Reusable Components/Buttons/MobTransparentButton'
import '../../styling/FilterDrawer.css'

const { Text } = Typography

const FilterDrawer = ({ open, onClose, onChange, activeFilters }) => {
  const [selectedCategories, setSelectedCategories] = React.useState(activeFilters)
  const [specimenType, setSpecimenType] = React.useState('all')
  const [testType, setTestType] = React.useState('all')

  const handleApply = () => {
    onChange({
      categories: selectedCategories,
      specimenType,
      testType
    })
  }

  const handleReset = () => {
    setSelectedCategories([])
    setSpecimenType('all')
    setTestType('all')
    onChange({
      categories: [],
      specimenType: 'all',
      testType: 'all'
    })
  }

  return (
    <Drawer
      title="Filter Tests"
      placement="right"
      onClose={onClose}
      open={open}
      className="cs-filter-drawer"
      footer={
        <div className="cs-filter-drawer__footer">
          <MobTransparentButton
            text="Reset"
            type="danger"
            functionCall={handleReset}
            style={{
              width: '100px',
              height: '40px',
              marginRight: '12px'
            }}
          />
          <MobTransparentButton
            text="Apply Filters"
            functionCall={handleApply}
            style={{
              width: '120px',
              height: '40px'
            }}
          />
        </div>
      }
    >
      <div className="cs-filter-drawer__section">
        <h3>Test Type</h3>
        <Radio.Group 
          value={testType} 
          onChange={e => setTestType(e.target.value)}
          className="cs-filter-drawer__radio-group"
        >
          <Space direction="vertical" className="cs-filter-drawer__radio-space">
            <Radio value="all">All Tests</Radio>
            <Radio value="caresync">CareSync Tests Only</Radio>
            <Radio value="lab">Standard Lab Tests Only</Radio>
          </Space>
        </Radio.Group>
      </div>

      <div className="cs-filter-drawer__section">
        <h3>Categories</h3>
        <Checkbox.Group 
          value={selectedCategories}
          onChange={setSelectedCategories}
          className="cs-filter-drawer__checkbox-group"
        >
          <Space direction="vertical" className="cs-filter-drawer__checkbox-space">
            {diagnosticTestCategories.map(category => (
              <Checkbox key={category.id} value={category.id}>
                {category.name}
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      </div>

      <div className="cs-filter-drawer__section">
        <h3>Specimen Type</h3>
        <Radio.Group 
          value={specimenType} 
          onChange={e => setSpecimenType(e.target.value)}
          className="cs-filter-drawer__radio-group"
        >
          <Space direction="vertical" className="cs-filter-drawer__radio-space">
            <Radio value="all">All Types</Radio>
            <Radio value="W">Whole Blood</Radio>
            <Radio value="S">Serum</Radio>
            <Radio value="P">Plasma</Radio>
            <Radio value="U">Urine</Radio>
            <Radio value="H">Hair</Radio>
            <Radio value="F">Stool</Radio>
            <Radio value="SW">Swab</Radio>
          </Space>
        </Radio.Group>
      </div>
    </Drawer>
  )
}

export default FilterDrawer 