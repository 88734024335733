import React from 'react'
import { motion } from 'framer-motion'
import { Button } from 'antd'
import { CheckOutlined, DownloadOutlined, ArrowRightOutlined } from '@ant-design/icons'
import '../styles/PaymentSuccess.css'

const PaymentSuccess = ({ transactionDetails, onContinue }) => {
  // Format amount with proper checks
  const formatAmount = (amount) => {
    if (typeof amount === 'number') {
      return amount.toFixed(2)
    }
    return '0.00'
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className='payment-success-container'
    >
      <div className='success-card'>
        <div className='success-icon-wrapper'>
          <div className='success-circle'></div>
          <CheckOutlined className='success-icon' />
        </div>
        <h2>Payment Successful!</h2>
        
        <div className='transaction-details'>
          <div className='detail-item'>
            <span className='detail-label'>Transaction ID</span>
            <span>{transactionDetails?.id || 'N/A'}</span>
          </div>
          <div className='detail-item'>
            <span className='detail-label'>Amount Paid</span>
            <span>R {formatAmount(transactionDetails?.amount)}</span>
          </div>
          <div className='detail-item'>
            <span className='detail-label'>Date</span>
            <span>{new Date().toLocaleDateString()}</span>
          </div>
        </div>

        <p className='confirmation-notice'>
          A confirmation email has been sent to your registered email address
        </p>

        <div className='action-buttons'>
          <Button 
            className='primary-button'
            onClick={onContinue}
            icon={<ArrowRightOutlined />}
          >
            Continue
          </Button>
          <Button 
            className='secondary-button'
            icon={<DownloadOutlined />}
          >
            Download Receipt
          </Button>
        </div>
      </div>
    </motion.div>
  )
}

export default PaymentSuccess
