// src/components/Detection/BPDetection.js
import React, { useContext, useEffect, useState, useRef } from 'react'
import BluetoothContext from '../../BluetoothContext'
import CustomButton from '../../../../reusable-components/CustomButton'
import { Col, Space, theme } from 'antd'
import CustomRow from '../../../../reusable-components/CustomRow'
import BPReadings from '../Readings/BPReadings'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'

const Detection = {
  BP: 'Blood Pressure'
}

const BPDetection = ({ userRef, bookingRef, setBusy, readings, setReadings }) => {
  const { engine, bleCommandCharacteristic } = useContext(BluetoothContext)
  // const [systolic, setSystolic] = useState(null)
  // const [diastolic, setDiastolic] = useState(null)
  // const [heartRate, setHeartRate] = useState(null)
  const [error, setError] = useState(null)
  const [isDetecting, setIsDetecting] = useState(false)

  // useRef for writeQueue to maintain state across renders
  const writeQueue = useRef(Promise.resolve())

  useEffect(() => {
    setBusy(isDetecting)

    // eslint-disable-next-line
  }, [isDetecting])

  useEffect(() => {
    if (!engine || !bleCommandCharacteristic) {
      console.warn('Engine or bleCommandCharacteristic not available for BPDetection')
      return
    }

    if (typeof engine.registBPListener === 'function') {
      engine.registBPListener({
        onProcess: (type, data) => {
          if (bleCommandCharacteristic) {
            // Chain write operations to ensure sequential execution
            writeQueue.current = writeQueue.current
              .then(() => bleCommandCharacteristic.writeValue(data))
              .catch((err) => {
                console.error('Error writing to bleCommandCharacteristic:', err)
              })
          }

          // Optional: Handle interim data if your library provides it
          // For example, updating interim readings can be implemented here
        },
        onResult: (result) => {
          if (result && result.ps !== undefined && result.pd !== undefined && result.hr !== undefined) {
            const tmpReadings = [...readings]
            const tmpReading = {
              id: tmpReadings.length,
              systolic: Math.floor(result.ps),
              diastolic: Math.floor(result.pd),
              pulse: Math.floor(result.hr),
              timestamp: new Date()
            }
            tmpReadings.push(tmpReading)
            setReadings(tmpReadings)
            // setSystolic(Math.floor(result.ps))
            // setDiastolic(Math.floor(result.pd))
            // setHeartRate(Math.floor(result.hr))
            setError(null) // Clear any previous errors
            stopDetection()
          } else {
            console.warn('Invalid BP result received:', result)
            setError('Invalid result received from device.')
            stopDetection()
          }
        },
        onException: (err) => {
          console.error('BP Detection Error:', err)
          setError(`BP Detection Error: ${err.message || 'Unknown error'}`)
          stopDetection()
        }
      })
    } else {
      console.error('engine.registBPListener is not a function')
    }

    return () => {
      if (engine && typeof engine.unregistBPListener === 'function') {
        engine.unregistBPListener()
      } else {
        console.warn('Engine or unregistBPListener method not available')
      }
    }
    // eslint-disable-next-line
  }, [engine, bleCommandCharacteristic, isDetecting])

  // Define startDetection function
  const startDetection = () => {
    if (isDetecting) {
      return
    }
    if (engine && bleCommandCharacteristic) {
      setError(null)
      // setSystolic(null)
      // setDiastolic(null)
      // setHeartRate(null)
      setIsDetecting(true)
      engine.startDetect(Detection.BP)
    } else {
      console.warn('Engine or bleCommandCharacteristic is not available')
      setError('Device is not ready. Please wait and try again.')
    }
  }

  // Define stopDetection function
  const stopDetection = () => {
    if (engine && bleCommandCharacteristic) {
      if (typeof engine.stopDetect === 'function') {
        engine.stopDetect(Detection.BP)
      } else {
        console.warn('engine.stopDetect is not a function')
      }
      setIsDetecting(false)
    } else {
      console.warn('Engine or bleCommandCharacteristic is not available')
    }
  }

  const { token } = theme.useToken()

  return (
    <>
      {!engine || !bleCommandCharacteristic ? (
        <p>Device is not ready. Please wait...</p>
      ) : (
        <CustomRow>
          {isDetecting ? (
            <Col span={24}>
              <AgiliteSkeleton skActive spinnerTip='Detecting...' />
            </Col>
          ) : undefined}
          <Col span={24}>{error && <p style={{ color: 'red' }}>{error}</p>}</Col>
          {isDetecting ? undefined : (
            <Col span={24}>
              Device is ready! <br />
              Click start to detect a new reading.
            </Col>
          )}
          <Col span={24}>
            <Space>
              <CustomButton type='primary' onClick={startDetection} text='Start' disabled={isDetecting} size='small' />
              <CustomButton
                text='Stop'
                type='primary'
                style={{ background: !isDetecting ? '' : token.colorError }}
                onClick={stopDetection}
                disabled={!isDetecting}
                size='small'
              />
            </Space>
          </Col>
          <Col span={24}>
            <BPReadings
              userRef={userRef}
              bookingRef={bookingRef}
              readings={readings}
              setReadings={setReadings}
              isDetecting={isDetecting}
            />
          </Col>
        </CustomRow>
      )}{' '}
    </>
  )
}

export default BPDetection
