import React, { memo } from 'react'
import { Col, Button, Table, Empty, Input, Space, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons'
import Templates from '../utils/templates'
import { sortBy } from 'lodash'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'
import useClinicsList from '../hooks/useClinicsList'
import PropTypes from 'prop-types'

const _ListView = ({ data, refreshView, createRecord, editRecord, loading, handleSearch }) => {
  const { filters, handleCreateClick, handleSearchChange } = useClinicsList({ data, createRecord, handleSearch })

  const tableColumns = Templates.columnTemplate(editRecord, filters)

  const { token } = theme.useToken()
  return (
    <CustomRow className='basic-card'>
      <Col span={24}>
        <Space wrap>
          <Input
            placeholder='Search for Clinic Name or Address'
            suffix={<FontAwesomeIcon icon={faSearch} />}
            allowClear
            onChange={(e) => handleSearchChange(e.target.value)}
            style={{ width: 500, maxWidth: '100%' }}
          />
          <CustomButton
            size='small'
            text='Refresh'
            icon={faRefresh}
            onClick={refreshView}
            type='primary'
            disabled={loading}
          />
          <Button
            type='primary'
            style={{ background: loading ? '' : token.colorSuccess }}
            onClick={handleCreateClick}
            disabled={loading}
            id='clinic_add_btn'
          >
            Add Clinic
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <Table
          bordered
          size='small'
          id='clinic_list_view'
          loading={loading}
          rowKey='_id'
          dataSource={sortBy(data, 'name')}
          pagination={false}
          scroll={{ x: 950 }}
          columns={tableColumns}
          onRow={(record) => ({
            onClick: () => editRecord(record)
          })}
          locale={{
            emptyText: <Empty description='There are currently no registered clinics to show.' />
          }}
        />
      </Col>
    </CustomRow>
  )
}

_ListView.propTypes = {
  data: PropTypes.array.isRequired,
  refreshView: PropTypes.func.isRequired,
  createRecord: PropTypes.func.isRequired,
  editRecord: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired
}

const ListView = memo(_ListView)

export default ListView
