import React from 'react'
import { Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import '../styling/WarningSection.css'

const { Text } = Typography

const WarningSection = () => {
  return (
    <div className="test-detail-warning">
      <FontAwesomeIcon icon={faTriangleExclamation} />
      <Text>
        This test is for screening purposes only. Please consult with a healthcare provider to interpret your results.
      </Text>
    </div>
  )
}

export default WarningSection 