import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Card, Typography, Steps, Button } from 'antd'
import TestSelection from './steps/TestSelection'
import LocationPicker from './steps/LocationPicker'
import TimeSlotPicker from './steps/TimeSlotPicker'
import ConfirmationView from './steps/ConfirmationView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import '../../styling/QuickBook.css'

const { Title, Text } = Typography

const QuickBook = ({ onComplete, onBack, preselectedTest }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [bookingData, setBookingData] = useState({
    selectedTest: null,
    location: null,
    timeSlot: null
  })

  // Auto-select test and location if preselectedTest is provided
  useEffect(() => {
    if (preselectedTest) {
      setBookingData((prev) => ({
        ...prev,
        selectedTest: preselectedTest,
        location: {
          id: 'lab1',
          name: 'Downtown Lab',
          address: '123 Main St, Downtown',
          distance: '0.8 miles away'
        },
        timeSlot: {
          date: '2024-03-20',
          time: '9:00 AM'
        }
      }))
      setCurrentStep(3) // Jump to confirmation
    }
  }, [preselectedTest])

  const handleComplete = () => {
    setBookingData(null)
    setCurrentStep(0)
    onComplete && onComplete()
  }

  const handleBack = () => {
    if (currentStep === 0) {
      onBack && onBack()
    } else {
      setCurrentStep((prev) => prev - 1)
    }
  }

  const steps = [
    {
      title: 'Select Test',
      content: (
        <TestSelection
          onSelect={(test) => {
            setBookingData((prev) => ({ ...prev, selectedTest: test }))
            setCurrentStep(1)
          }}
        />
      )
    },
    {
      title: 'Choose Location',
      content: (
        <LocationPicker
          onSelect={(location) => {
            setBookingData((prev) => ({ ...prev, location }))
            setCurrentStep(2)
          }}
        />
      )
    },
    {
      title: 'Select Time',
      content: (
        <TimeSlotPicker
          onSelect={(timeSlot) => {
            setBookingData((prev) => ({ ...prev, timeSlot }))
            setCurrentStep(3)
          }}
        />
      )
    },
    {
      title: 'Confirm',
      content: <ConfirmationView 
        booking={bookingData} 
        onComplete={() => {
          setTimeout(() => {
            handleComplete()
          }, 8000)
        }}
      />
    }
  ]

  return (
    <motion.div
      className='cs-quick-book'
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <div className='cs-quick-book__header'>
        <Title level={2}>Schedule Blood Test</Title>
        <Text type="secondary">Complete booking in under 2 minutes</Text>
      </div>

      <Steps 
        current={currentStep} 
        items={steps.map((step) => ({ 
          title: step.title,
          className: 'cs-quick-book__steps-title'
        }))} 
        className='cs-quick-book__steps' 
      />

      <AnimatePresence mode='wait'>
        <motion.div
          key={currentStep}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.2 }}
          className='cs-quick-book__content-wrapper'
        >
          {steps[currentStep].content}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  )
}

export default QuickBook
