import React from 'react'
import { Typography } from 'antd'
import '../styling/TestDescription.css'

const { Title, Text } = Typography

const TestDescription = ({ description }) => {
  return (
    <section className="test-detail-section test-description-card">
      <Title level={4}>About This Test</Title>
      <Text className="test-detail-description">{description}</Text>
    </section>
  )
}

export default TestDescription 