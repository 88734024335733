import React from 'react'
import { motion } from 'framer-motion'
import { Button } from 'antd'
import { PrinterOutlined, DownloadOutlined, MailOutlined } from '@ant-design/icons'
import '../styles/PaymentReceipt.css'

const PaymentReceipt = ({ transactionDetails = {
  id: 'TXN-2024-0001-4829',
  date: new Date(),
  paymentMethod: {
    type: 'Visa',
    last4: '4242',
    expiryMonth: '12',
    expiryYear: '25'
  },
  status: 'Successful',
  items: [
    { name: 'Consultation Fee', price: 850.00 },
    { name: 'Medication', price: 450.00 }
  ],
  subtotal: 1300.00,
  vat: 195.00,
  total: 1495.00
} }) => {
  const formatDate = (date) => {
    return new Date(date).toLocaleString('en-ZA', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  const formatPaymentMethod = (method) => {
    if (!method) return 'N/A'
    return `${method.type} Card ending in ${method.last4}`
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className='payment-receipt-container'
    >
      <div className='receipt-content'>
        <div className='receipt-card'>
          <h2>Payment Receipt</h2>

          <div className='receipt-section'>
            <div className='section-title'>Transaction Details</div>
            <div className='detail-row'>
              <span className='detail-label'>Transaction ID</span>
              <span className='detail-value'>{transactionDetails?.id}</span>
            </div>
            <div className='detail-row'>
              <span className='detail-label'>Payment Method</span>
              <span className='detail-value'>{formatPaymentMethod(transactionDetails?.paymentMethod)}</span>
            </div>
            <div className='detail-row'>
              <span className='detail-label'>Status</span>
              <span className='detail-value success-status'>{transactionDetails?.status}</span>
            </div>
            <div className='detail-row'>
              <span className='detail-label'>Date & Time</span>
              <span className='detail-value'>{formatDate(transactionDetails?.date)}</span>
            </div>
          </div>

          <div className='receipt-section'>
            <div className='section-title'>Order Summary</div>
            {transactionDetails?.items?.map((item, index) => (
              <div key={index} className='detail-row'>
                <span className='detail-label'>{item.name}</span>
                <span className='detail-value'>R {item.price.toFixed(2)}</span>
              </div>
            ))}
            <div className='detail-row'>
              <span className='detail-label'>Subtotal</span>
              <span className='detail-value'>R {transactionDetails?.subtotal?.toFixed(2)}</span>
            </div>
            <div className='detail-row'>
              <span className='detail-label'>VAT (15%)</span>
              <span className='detail-value'>R {transactionDetails?.vat?.toFixed(2)}</span>
            </div>
            <div className='detail-row total-row'>
              <span className='detail-label'>Total</span>
              <span className='detail-value'>R {transactionDetails?.total?.toFixed(2)}</span>
            </div>
          </div>

          <div className='merchant-info'>
            Meta-Health (Pty) Ltd<br />
            VAT: 4230184362<br />
            support@meta-health.co.za<br />
            Receipt generated on {formatDate(new Date())}
          </div>
        </div>

        <div className='receipt-actions'>
          <Button 
            className='action-button'
            icon={<PrinterOutlined />}
            onClick={() => window.print()}
          >
            Print
          </Button>
          <Button 
            className='action-button'
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
          <Button 
            className='action-button'
            icon={<MailOutlined />}
          >
            Email
          </Button>
        </div>
      </div>
    </motion.div>
  )
}

export default PaymentReceipt
