import React from 'react'
import usePatientHealthStats from '../../hooks/usePatHealthStats'
import { LineChart, ResponsiveContainer, XAxis, YAxis, Line } from 'recharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDroplet, faHeart, faWeightScale, faRuler } from '@fortawesome/free-solid-svg-icons'

const MobPatHealthMetrics = () => {
  const { handleScroll, activeIndex, patHealthMetrics, transformHealthData } = usePatientHealthStats()

  return (
    <div>
      <div className='stats-carousel' onScroll={handleScroll}>
        {[
          {
            title: 'Blood Sugar',
            key: 'bloodSugar',
            unit: 'mmol/L',
            icon: <FontAwesomeIcon icon={faDroplet} color='#e74c3c' />
          },
          {
            title: 'Cholesterol',
            key: 'cholesterol',
            unit: 'mmol/L',
            icon: <FontAwesomeIcon icon={faHeart} color='#e91e63' />
          },
          {
            title: 'Weight',
            key: 'weight',
            unit: 'kg',
            icon: <FontAwesomeIcon icon={faWeightScale} color='#3498db' />
          },
          {
            title: 'Waist',
            key: 'waist',
            unit: 'cm',
            icon: <FontAwesomeIcon icon={faRuler} color='#f1c40f' />
          }
        ].map((metric, index) => (
          <div key={index} className='stats-card'>
            <div className='heart-rate-display'>
              <div className='metric-info'>
                <div className='metric-title'>{metric.title}</div>
                <div className='value-container'>
                  <span className='bpm-value'>
                    {patHealthMetrics?.find((item) => item.key === metric.key)?.value || '--'}
                  </span>
                  <span className='bpm-label'>{metric.unit}</span>
                </div>
              </div>
              <div className='heart-icon'>{metric.icon}</div>
            </div>
            <div className='date-display'>Tuesday, 26 November, 2025</div>
            <div className='graph-container'>
              <ResponsiveContainer width='90%' height={140}>
                <LineChart
                  data={transformHealthData(patHealthMetrics, metric.key)}
                  margin={{ top: 10, right: 10, left: -20, bottom: 0 }}
                >
                  <XAxis dataKey='time' axisLine={false} tickLine={false} tick={{ fontSize: 10 }} interval={0} />
                  <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 10 }} />
                  <Line type='natural' dataKey='value' stroke='#10B2E1' strokeWidth={3} dot={false} activeDot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        ))}
      </div>

      <div className='health-stats-indicators'>
        {[
          {
            title: 'Blood Sugar',
            key: 'BS-RAPID',
            unit: 'mmol/L',
            icon: <FontAwesomeIcon icon={faDroplet} color='white' />
          },
          {
            title: 'Cholesterol',
            key: 'CHOL-RAPID',
            unit: 'mmol/L',
            icon: <FontAwesomeIcon icon={faHeart} color='white' />
          },
          {
            title: 'Weight',
            key: 'WEIGHT-GENERAL',
            unit: 'kg',
            icon: <FontAwesomeIcon icon={faWeightScale} color='white' />
          },
          {
            title: 'Waist',
            key: 'WAIST-GENERAL',
            unit: 'cm',
            icon: <FontAwesomeIcon icon={faRuler} color='white' />
          }
        ].map((metric, index) => (
          <div key={index} className={`health-stats-indicator ${activeIndex === index ? 'active' : ''}`} />
        ))}
      </div>
    </div>
  )
}

export default MobPatHealthMetrics
