import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { faChainBroken, faRefresh, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Select, Space, Spin, Tooltip, message, theme, Tag } from 'antd'

import debounce from 'lodash/debounce'

import { handleError, hexToRGBA } from '../../../../lib/utils'
import { findBookingByIDUpdate } from '../../../examination-utils/examination-lib'
import { readBookings } from '../../../../Bookings/utils/utils'
import { SymptomsArray } from './SymptomsList'

// Constants
const DEBOUNCE_DELAY = 500 // Reduced from 1000ms for better responsiveness
const ERROR_MESSAGE = 'There was a problem saving this symptom. Please try again.'
const SUCCESS_MESSAGE = 'Symptoms Captured Successfully'

/**
 * SymptomsCapture Component
 * Allows users to select and manage symptoms for a booking
 * @param {Object} props
 * @param {Object} props.bookingData - Booking information containing ID and symptoms
 */
const SymptomsCapture = ({ bookingData }) => {
  // State management
  const [selectedSymptoms, setSelectedSymptoms] = useState([])
  const [isSaveError, setIsSaveError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const { token } = theme.useToken()

  // Fetch existing symptoms on component mount
  useEffect(() => {
    let isMounted = true

    const fetchExistingSymptoms = async () => {
      if (!bookingData?._id) return

      setIsLoading(true)
      try {
        const fetchedSymptoms = await readBookings({ _id: bookingData._id }, null, 'symptoms')
        if (isMounted) {
          setSelectedSymptoms(fetchedSymptoms[0]?.symptoms || [])
        }
      } catch (error) {
        if (isMounted) {
          message.error(handleError(error))
        }
      } finally {
        if (isMounted) {
          setIsLoading(false)
        }
      }
    }

    fetchExistingSymptoms()

    return () => {
      isMounted = false
    }
  }, [bookingData?._id])

  // Optimized debounced function to handle symptom changes
  const debouncedSymptomsChange = useCallback(
    debounce(async (updatedSymptoms) => {
      if (!bookingData?._id) return

      setIsLoading(true)
      setIsSaveError(false)

      try {
        await findBookingByIDUpdate(bookingData._id, { symptoms: updatedSymptoms })
      } catch (error) {
        setIsSaveError(true)
        message.error(ERROR_MESSAGE)
      } finally {
        setIsLoading(false)
      }
    }, DEBOUNCE_DELAY),
    [bookingData?._id]
  )

  // Memoized filtered symptoms with optimized search
  const filteredSymptoms = useMemo(() => {
    const searchLower = searchQuery.toLowerCase()
    return SymptomsArray.filter(
      (symptom) => !selectedSymptoms.includes(symptom) && (!searchLower || symptom.toLowerCase().includes(searchLower))
    )
  }, [searchQuery, selectedSymptoms])

  // Optimized symptom selection handler
  const handleSymptomSelect = useCallback(
    (symptom) => {
      if (!symptom || selectedSymptoms.includes(symptom)) return

      const updatedSymptoms = [...selectedSymptoms, symptom]
      setSelectedSymptoms(updatedSymptoms)
      debouncedSymptomsChange(updatedSymptoms)
      setSearchQuery('')
    },
    [selectedSymptoms, debouncedSymptomsChange]
  )

  // Optimized symptom removal handler
  const handleSymptomRemove = useCallback(
    (symptomToRemove) => {
      const updatedSymptoms = selectedSymptoms.filter((symptom) => symptom !== symptomToRemove)
      setSelectedSymptoms(updatedSymptoms)
      debouncedSymptomsChange(updatedSymptoms)
    },
    [selectedSymptoms, debouncedSymptomsChange]
  )

  // Memoized symptom tags renderer
  const renderSymptomTags = useMemo(
    () => (
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 12, width: '100%' }}>
        {selectedSymptoms.map((symptom) => {
          const [, symptomName] = symptom.split(': ')
          const displayName = symptomName ? symptomName.charAt(0).toUpperCase() + symptomName.slice(1) : symptom

          return (
            <Tag
              key={symptom}
              closable
              onClose={() => handleSymptomRemove(symptom)}
              style={{
                backgroundColor: token.colorPrimaryBg,
                marginBottom: '5px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {displayName}
            </Tag>
          )
        })}
      </div>
    ),
    [selectedSymptoms, handleSymptomRemove, token.colorPrimaryBg]
  )

  return (
    <Card
      styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
      size='small'
      title='Symptoms'
      extra={
        <Space>
          {isLoading && <Spin />}
          {isSaveError && (
            <>
              <Tooltip title='Error Saving Symptoms'>
                <FontAwesomeIcon icon={faChainBroken} color={token.colorError} />
              </Tooltip>
              <Button onClick={() => debouncedSymptomsChange(selectedSymptoms)}>
                <Space>
                  Retry <FontAwesomeIcon icon={faRefresh} color={token.colorSuccess} />
                </Space>
              </Button>
            </>
          )}
          {!isSaveError && !isLoading && (
            <Tooltip title={SUCCESS_MESSAGE}>
              <FontAwesomeIcon icon={faCheckCircle} color={token.colorSuccess} />
            </Tooltip>
          )}
        </Space>
      }
    >
      <div
        style={{
          width: '100%',
          background: isSaveError ? hexToRGBA(token.colorError, 0.1) : 'transparent',
          padding: isSaveError ? 8 : 0
        }}
      >
        {renderSymptomTags}

        <Select
          style={{ width: '100%' }}
          value={null}
          onSearch={setSearchQuery}
          onSelect={handleSymptomSelect}
          onClear={() => setSearchQuery('')}
          onDropdownVisibleChange={(visible) => !visible && setSearchQuery('')}
          dropdownStyle={{ minWidth: 650 }}
          options={filteredSymptoms.map((symptom) => ({
            value: symptom,
            label: symptom
          }))}
          filterOption={false}
          placeholder='- Select Symptoms -'
          optionFilterProp='label'
          showSearch
        />

        {isSaveError && (
          <div style={{ width: '100%', marginTop: 12 }}>
            <p>There was an issue saving the symptoms for this booking. Please try again.</p>
          </div>
        )}
      </div>
    </Card>
  )
}

export default React.memo(SymptomsCapture)
