import React from 'react'
import AgoraRTC, { AgoraRTCProvider, useRTCClient } from 'agora-rtc-react'
import MedProfLiveVideo from './MedProfLiveVideo'

const MedProfLiveVideoWrapper = ({ channelName, token, uid, disconnectCallback }) => {
  const agoraClient = useRTCClient(AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' }))

  return (
    <AgoraRTCProvider client={agoraClient}>
      <MedProfLiveVideo channelName={channelName} token={token} uid={uid} disconnectCallback={disconnectCallback} />
    </AgoraRTCProvider>
  )
}

export default MedProfLiveVideoWrapper
