import React from 'react'
import { useWindowSize } from '../hooks/useWindowSize'
import MobileMacroTracker from './components/MobileMacroTracker'
import DesktopMacroTracker from './components/DesktopMacroTracker'

const AIMacroTrackerWrapper = ({ userRef, isDoctor }) => {
  const { width } = useWindowSize()
  const isMobile = width <= 768

  return isMobile ? (
    <MobileMacroTracker userRef={userRef} isDoctor={isDoctor} />
  ) : (
    <DesktopMacroTracker userRef={userRef} isDoctor={isDoctor} />
  )
}

export default AIMacroTrackerWrapper
