import React, { useState } from 'react'
import { Button, Radio, message, Card, Row, Col, Typography, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVirus, faCalendar } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import '../style/MobCovidInfo.css'
import MobSectionHeader from './MobSectionHeader'
import DatePicker from '../../../Reusable Components/DatePicker'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import { useDispatch, useSelector } from 'react-redux'
import { updateCovidInfo } from '../../api/api'
import patMedHistReducer from '../../utils/reducer'

const { Title, Text } = Typography

const MobCovidInfo = ({ clientForm, setFacet, setSaving }) => {
  const patMedHist = useSelector((state) => state.patMedHist.data)
  const covidState = patMedHist.covidInfo || {
    hadCovid: false,
    fullyVaccinated: false,
    lastVaccinatedDate: null,
    updatedAt: new Date().toISOString()
  }
  const [covidInfo, setCovidInfo] = useState({
    hadCovid: covidState.hadCovid || false,
    fullyVaccinated: covidState.fullyVaccinated || false,
    lastVaccinatedDate: covidState.lastVaccinatedDate,
    updatedAt: covidState.updatedAt || new Date().toISOString()
  })

  const [showDatePickers, setShowDatePickers] = useState({
    vaccination: false
  })

  const handleDateSelect = (field) => (date) => {
    const isoDate = date.toISOString()
    setCovidInfo((prev) => ({
      ...prev,
      [field]: isoDate
    }))
    setShowDatePickers((prev) => ({ ...prev, [field]: false }))
  }

  const updateField = (field, value) => {
    setCovidInfo((prev) => ({
      ...prev,
      [field]: value
    }))
  }
  const dispatch = useDispatch()
  const handleSubmit = async () => {
    setSaving(true)
    try {
      const newRecord = await updateCovidInfo(patMedHist._id, covidInfo)
      dispatch(patMedHistReducer.actions.updateState(newRecord))
    } catch (error) {
      console.error('Error saving COVID-19 information:', error)
      message.error('Failed to save COVID-19 information')
    }
    setSaving(false)
  }

  const styles = {
    card: {
      borderRadius: '12px',
      border: 'none',
      marginBottom: '12px'
    },
    title: {
      fontSize: '20px',
      marginBottom: '16px'
    },
    text: {
      color: '#8F9BB3',
      fontSize: '16px',
      display: 'block',
      marginBottom: '8px'
    },
    dateInput: {
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      placeholder: {
        color: '#8F9BB3'
      }
    },

    actionButton: {
      height: '48px',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: 500
    }
  }

  return (
    <>
      <MobSectionHeader
        icon={faVirus}
        title='COVID-19 INFORMATION'
        subtitle='Update your COVID-19 status and vaccination details'
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card style={{ ...styles.card }}>
            <Title level={5} style={{ marginBottom: '16px' }}>
              Have you had COVID-19?
            </Title>
            <Radio.Group
              value={covidInfo.hadCovid}
              onChange={(e) => updateField('hadCovid', e.target.value)}
              style={{ ...styles.radioGroup }}
            >
              <Radio value={true} style={{ ...styles.radio }}>
                Yes
              </Radio>
              <Radio value={false} style={{ ...styles.radio }}>
                No
              </Radio>
            </Radio.Group>
          </Card>
        </Col>
        <Col span={24}>
          <Card style={styles.card} bordered={false}>
            <Title level={5} style={styles.title}>
              Vaccination Status
            </Title>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Text style={styles.text}>Are you fully vaccinated?</Text>
                <Radio.Group
                  value={covidInfo.fullyVaccinated}
                  onChange={(e) => updateField('fullyVaccinated', e.target.value)}
                  style={{ ...styles.radioGroup }}
                >
                  <Radio value={true} style={{ ...styles.radio }}>
                    Yes
                  </Radio>
                  <Radio value={false} style={{ ...styles.radio }}>
                    No
                  </Radio>
                </Radio.Group>
              </Col>

              {covidInfo.fullyVaccinated && (
                <Col span={24} className='date-section'>
                  <Text style={styles.text}>Date of last vaccination</Text>
                  <Input
                    value={
                      covidInfo.lastVaccinatedDate ? dayjs(covidInfo.lastVaccinatedDate).format('DD MMM YYYY') : ''
                    }
                    placeholder='Select date'
                    suffix={<FontAwesomeIcon icon={faCalendar} style={{ color: '#00A6E3' }} />}
                    readOnly
                    onClick={() => setShowDatePickers((prev) => ({ ...prev, vaccination: true }))}
                    style={styles.dateInput}
                  />
                  <DatePicker
                    showDatePicker={showDatePickers.vaccination}
                    setShowDatePicker={(value) => setShowDatePickers((prev) => ({ ...prev, vaccination: value }))}
                    pickerValue={covidInfo.lastVaccinatedDate ? dayjs(covidInfo.lastVaccinatedDate) : null}
                    setPickerValue={(date) => updateField('lastVaccinatedDate', date.toISOString())}
                    handleDateSelect={handleDateSelect('lastVaccinatedDate')}
                  />
                </Col>
              )}
            </Row>
          </Card>
        </Col>
      </Row>

      <PrevNextFloatingButtons
        onPrev={() => {
          clientForm.resetFields()
          setFacet(null)
        }}
        onNext={handleSubmit}
        prevText='Cancel'
        nextText='Save'
      />
    </>
  )
}

export default MobCovidInfo
