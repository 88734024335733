import Agilite from 'agilite'
import store from '../../../../store'
import ConnectorsEnums from '../../../Connectors/enums'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const getPatAppointments = async () => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      const agiliteUser = store.getState().auth.agiliteUser

      const bookingQuery = {
        userRef: agiliteUser._id
      }

      try {
        // Fetch Bookings First
        let { data: bookingResponse } = await agilite.Connectors.execute(
          ConnectorsEnums.profileKeys.BOOKINGS,
          ConnectorsEnums.routeKeys.READ,
          {
            filter: JSON.stringify(bookingQuery)
          }
        )

        // Extract all the medical professionals _IDs then fetch there profiles.
        let medProfIds = bookingResponse.map((booking) => booking.medicalProfRef)

        // Fetch Medical Professionals Profiles
        let { data: medProfResponse } = await agilite.Connectors.execute(
          ConnectorsEnums.profileKeys.AUTH_USERS,
          ConnectorsEnums.routeKeys.FIND,
          {
            filter: JSON.stringify({ _id: { $in: medProfIds } }),
            projection: 'firstName lastName extraData.profession profileImage'
          }
        )

        // Assign needed props to booking records
        bookingResponse.forEach((booking) => {
          const medProf = medProfResponse.find((prof) => prof._id === booking.medicalProfRef)
          booking.medicalProfProfile = medProf
        })

        resolve(bookingResponse)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
