import React from 'react'

// UI Component imports from Ant Design and other libraries
import CustomRow from '../../../reusable-components/CustomRow'
import { Button, Card, Col, Empty, Modal, Row, Space, Spin, theme } from 'antd'

import CustomButton from '../../../reusable-components/CustomButton'
import ReactQuill from 'react-quill'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChainBroken, faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons'

// Custom component imports for various medical functionalities
import DiagnosisCapture from './components/DiagnosisCapture'

import ClinicalNotesTemplates from './components/ClinicalNotesTemplates'

import CustomLoadingIcon from '../../../reusable-components/CustomLoadingIcon'

// Utility and helper function imports
import { hexToRGBA } from '../../../lib/utils'

// Style imports
import './notes.css'
import '../../../Examination/examination-utils/style.css'
import ClinicalNotesControls from './components/ClinicalNotesControls'
import HistoricalNotes from './components/HistoricalNotes'
import { useNoteState } from './hooks/useNoteState'
import TranscribeAi from './components/TranscribeAi'

// Memoized components to prevent unnecessary re-renders
const MemoizedDiagnosisCapture = React.memo(DiagnosisCapture)
const MemoizedReactQuill = React.memo(ReactQuill)

const ClinicalNotes = ({ bookingData, userRef, noBooking, setModalOpen }) => {
  // Redux state and theme access
  const { token } = theme.useToken()

  const {
    noteState,
    setNoteState,
    showClinicalNotesTemplates,
    setShowClinicalNotesTemplates,
    showHistorical,
    setShowHistorical,
    checkForExistingNote,
    handleCreateNote,
    handleUpdateNote,
    showAiTranscription,
    setShowAiTranscription,
    handleAutoGenerateNotes,
    transcriptionState,
    debouncedNoteCapture
  } = useNoteState({ bookingData, userRef })

  const renderCurrentNote = () => (
    <Row>
      <Col span={24}>
        <div style={{ display: 'grid', gridTemplateColumns: '225px 1fr' }}>
          <ClinicalNotesControls
            handleAutoGenerateNotes={handleAutoGenerateNotes}
            handleCreateNote={handleCreateNote}
            setShowAiTranscription={setShowAiTranscription}
            setShowClinicalNotesTemplates={setShowClinicalNotesTemplates}
            noBooking={noBooking}
            handleUpdateNote={handleUpdateNote}
            setShowHistorical={setShowHistorical}
            noteState={noteState}
            setNoteState={setNoteState}
          />
          <div>
            <CustomLoadingIcon
              loading={noteState.isAutoGenerating || noteState.isSubmitting}
              loadingText={
                noteState.isCreating
                  ? 'Creating Note...'
                  : noteState.isAutoGenerating
                  ? 'AI is Generating Clinical Notes...'
                  : 'Loading Notes...'
              }
              content={
                !noteState.noteId ? (
                  <Row gutter={[0, 12]}>
                    <Col span={24}>
                      <Empty description='You have no notes captured for this booking' />
                    </Col>
                    <Col span={24}>
                      <center>
                        <Button type='primary' style={{ background: token.colorSuccess }} onClick={handleCreateNote}>
                          Add Note
                        </Button>
                      </center>
                    </Col>
                  </Row>
                ) : (
                  <MemoizedReactQuill
                    id={`${bookingData?._id}-notes-capture-area`}
                    style={{ display: showHistorical ? 'none' : '' }}
                    onChange={(e) => {
                      if (!noteState.isCreating) {
                        setNoteState((prev) => ({ ...prev, isUpdating: true, currentNote: e }))
                        debouncedNoteCapture(noteState.noteId, e)
                      }
                    }}
                    value={noteState.currentNote + transcriptionState.tmpTranscript}
                  />
                )
              }
            />
          </div>
        </div>
      </Col>
    </Row>
  )
  return (
    <>
      <CustomRow style={{ marginTop: 12 }}>
        {noBooking && (
          <Col span={24}>
            <FontAwesomeIcon
              size='2x'
              color={token.colorError}
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => setModalOpen(false)}
              icon={faCircleXmark}
            />
          </Col>
        )}
        {!noBooking && (
          <Col span={24}>
            <MemoizedDiagnosisCapture bookingData={bookingData} />
          </Col>
        )}
        <Col span={24} style={{ minHeight: '100%', position: 'relative' }}>
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr auto', gap: 12 }}>
            <Row>
              <Col span={24}>
                <Card
                  title='Clinical Notes'
                  styles={{
                    body: !noteState.noteId ? { padding: 0 } : { padding: 0 },
                    header: { background: hexToRGBA(token.colorPrimary, 0.2) }
                  }}
                  size='small'
                  extra={
                    <>
                      {showHistorical ? (
                        <Button onClick={() => setShowHistorical(false)}>Show Current </Button>
                      ) : (
                        <div>
                          {noteState.isUpdating || noteState.isSubmitting ? (
                            <Space style={{ alignItems: 'center' }}>
                              <small>{noteState.isSubmitting ? 'LOADING NOTE' : 'SAVING'}</small>
                              <Spin spinning />
                            </Space>
                          ) : (
                            <>
                              {!showHistorical && (
                                <div style={{ alignItems: 'center', display: 'flex', gap: 4 }}>
                                  <small>
                                    {noteState.fetchingError || noteState.updatingError ? 'ERROR' : 'SAVED'}
                                  </small>
                                  <FontAwesomeIcon
                                    fontSize={20}
                                    icon={
                                      noteState.fetchingError || noteState.updatingError ? faChainBroken : faCheckCircle
                                    }
                                    color={
                                      noteState.fetchingError || noteState.updatingError
                                        ? token.colorError
                                        : token.colorSuccess
                                    }
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}{' '}
                    </>
                  }
                >
                  {showHistorical || noBooking ? (
                    <HistoricalNotes bookingData={bookingData} userRef={userRef} noteState={noteState} />
                  ) : (
                    renderCurrentNote()
                  )}
                </Card>
                {(noteState.fetchingError || noteState.updatingError) && (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      background: hexToRGBA(token.colorError, 0.2),
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 30
                    }}
                  >
                    <center>
                      <Space direction='vertical'>
                        <h2>Oops something went wrong.</h2>
                        <p>{noteState.fetchingError || noteState.updatingError}</p>
                        <CustomButton
                          size='small'
                          text='Retry'
                          type='success'
                          onClick={() => {
                            if (noteState.fetchingError) {
                              setNoteState((prev) => ({ ...prev, fetchingError: '', isSubmitting: true }))
                              checkForExistingNote()
                            } else {
                              setNoteState((prev) => ({ ...prev, updatingError: '', isUpdating: true }))
                              handleUpdateNote(noteState.noteId, noteState.currentNote)
                            }
                          }}
                        />
                      </Space>
                    </center>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
        <TranscribeAi
          setNoteState={setNoteState}
          setModalOpen={setShowAiTranscription}
          modalOpen={showAiTranscription}
        />
        <Modal
          footer={false}
          maskClosable={false}
          closable={false}
          width={950}
          open={showClinicalNotesTemplates}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          onCancel={() => {
            setShowClinicalNotesTemplates(false)
          }}
          destroyOnClose
        >
          <ClinicalNotesTemplates
            setModalOpen={(value) => setShowClinicalNotesTemplates((prev) => value)}
            setValue={(value) => setNoteState((prev) => ({ ...prev, currentNote: value }))}
            setUpdatingNotes={(value) => setNoteState((prev) => ({ ...prev, isUpdating: value }))}
            debouncedNoteCapture={debouncedNoteCapture}
            noteId={noteState.noteId}
          />
        </Modal>
      </CustomRow>
    </>
  )
}

export default React.memo(ClinicalNotes)
